import { Link } from "react-router-dom";
import "./AccountOptions.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { useSelector } from "react-redux";
import wallet_icon from "../../../../assets/wallet-solid.svg";
import wallet_icon_theme from "../../../../assets/wallet-png.png";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { getUserToken, removeUserToken } from "../../../app/userToken";
import axios from "axios";
import { apiUrl } from "../../../../redux/action/action";

const AccountOptions = () => {
  const location = useLocation();
  const reduxuserdata = useSelector((state) => state.userReducer);
  const [userName, setUserName] = useState("there");

  useEffect(() => {
    let element = $(".menu-options-btn");

    $(element).each((i, e) => {
      if (e.getAttribute("href") === location.pathname) {
        $(e).addClass("focused-menu");
      }

      if (e.getAttribute("href") !== location.pathname) {
        $(e).removeClass("focused-menu");
      }
    });
  });

  const btncont = [
    {
      url: "my-account",
      icon: "fa fa-user",
      txt: "My Account",
    },
    {
      url: "my-order",
      icon: "fa fa-truck",
      txt: "Active Orders",
    },
    {
      url: "order-history",
      icon: "fa fa-history",
      txt: "Order History",
    },
    {
      url: "my-wallet",
      material_icon: <AccountBalanceWalletIcon />,
      txt: "My Wallet",
    },
    {
      url: "my-prescription",
      icon: "fa fa-file-text",
      txt: "My Prescriptions",
    },
    {
      url: "my-insurance",
      icon: "fa fa-shield",
      txt: "My Insurances",
    },
    {
      url: "saved-address",
      icon: "fa fa-map-marker",
      txt: "Saved Address",
    },
    {
      url: "save-for-later",
      icon: "fa fa-save",
      txt: "Save For Later",
    },
    {
      url: "/logout",
      icon: "fa fa-sign-out",
      txt: "Logout",
    },
    {
      action: 'delete',
      icon: "fa fa-trash",
      txt: "Delete Account",
    },
  ];

  useEffect(() => {
    if (reduxuserdata.userdata) {
      setUserName(() => {
        return (
          <>
            {reduxuserdata.userdata?.salutation === null
              ? ""
              : reduxuserdata.userdata?.salutation}{" "}
            {reduxuserdata.userdata?.name === ""
              ? "there"
              : reduxuserdata.userdata?.name}
          </>
        );
      });
    }
  }, [reduxuserdata.userdata]);

  return (
    <>
      <div className="account-options">
        <div
          className="w-100 pt-4 pb-3 border hide-in-phone"
          style={{ background: "#f58220" }}
        >
          <div className="w-100 d-flex align-items-center justify-content-center text-light">
            <i
              className="fa fa-user-circle mr-2"
              style={{ fontSize: "25px" }}
            ></i>
            <h5
              className="p-0 m-0 text-light"
              style={{
                width: "50%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Hey, {userName === "" ? "there" : userName}
            </h5>
            {/* <h6 className="p-0 m-0">{reduxuserdata.userdata? reduxuserdata.userdata.name.split(" ")[0] : "------"}</h6> */}
          </div>
        </div>
        {btncont.map((data, index) => {
          return data.url ? (
            <Link
              to={data.url}
              className="btn w-100 border-bottom py-3 px-0 menu-options-btn"
              key={index}
            >
              {data.material_icon ? (
                <AccountBalanceWalletIcon />
              ) : (
                <i className={data.icon}></i>
              )}
              &nbsp; {data.txt}
            </Link>
          ) : (
            <Link
              to={'#'}
              className="btn w-100 border-bottom py-3 border px-0 menu-options-btn text-danger"
              key={index}
              onClick={() => {
                const confirm = window.confirm('Are you sure, you want to delete your account ?');
                if (confirm) {
                  axios.delete(apiUrl + '/user/deletecustomerDetails', {
                    headers: {
                      Authorization: "Token " + getUserToken(),
                    },
                  }).then((res) => {
                    removeUserToken();
                    window.location = "/";
                  }).catch((err) => {

                  })
                }
              }}
            >
              <i className={data.icon}></i>
              &nbsp; {data.txt}
            </Link>
          );
        })}


        {/* <div className="insurance-confirm py-4">
          <h5 className="mt-3">Are you sure ?</h5>
          <div className="mt-2 d-flex">
            <button
              className="btn border-theme text-theme mr-2"
            >
              No
            </button>
            <button
              className="btn bg-theme text-light"
              onClick={() => {
                removeUserToken();
                window.location = "/";
              }}
            >
              Yes
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default AccountOptions;
