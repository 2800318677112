import Footer from "../../Footer/Footer";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import Navbar from "../../Header/Navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../redux/action/action";
import { toast } from "react-toastify";

const AboutUs = () => {
  const [data, getData] = useState();
  useEffect(() => {
    axios
      .get(apiUrl + "/master/get_policies")
      .then((res) => {
        getData(res.data.data.privacy_policy);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  }, []);

  return (
    <>
      <Navbar />
      <Navbarmob />
      <div className="container mt-5">
        <div className="row body-color">
          <div className="col-md-6" style={{ minHeight: "400px" }}>
            <div>
              <h4 className="m-0 p-0">
                <b>ABOUT US</b>
              </h4>
              <div
                className="mt-1"
                style={{ border: "1px solid #EF7E00", width: "80px" }}
              />
              <div className="w-100 mt-4">
                <b>Who we are - Qatar's most convenient online pharmacy </b>{" "}
                <br />
                medpack.com is one of Qatar’s most trusted pharmacies,
                dispensing quality medicines at reasonable prices to over 7
                million happy customers – PAN Qatar. At medpack.com, we help you
                look after your own health effortlessly as well as take care of
                loved ones wherever they may reside in Qatar. You can buy and
                send medicines from any corner of the country - with just a few
                clicks of the mouse.
                <br />
                <br />
                <b>
                  What we do – Offer fast online access to medicines with
                  convenient home delivery
                </b>{" "}
                <br />
                At medpack.com, we make a wide range of prescription medicines
                and other health products conveniently available all across
                Qatar. Since we also offer
                generic alternatives to most medicines, online buyers can expect
                significant savings.
              </div>
            </div>

            <div className="mt-4">
              <h4 className="m-0 p-0">
                <b>CONVENIENCE</b>
              </h4>
              <div
                className="mt-1"
                style={{ border: "1px solid #EF7E00", width: "80px" }}
              />
              <div className="w-100 mt-4">
                Heavy traffic, lack of parking, monsoons, shop closed,
                forgetfulness… these are some of the reasons that could lead to
                skipping of vital medications. Since taking medicines regularly
                is a critical component of managing chronic medical conditions,
                it’s best not to run out of essential medicines. Just log on to
                medpack.com, place your order online and have your medicines
                delivered to you – without leaving the comfort of your home.
                <br />
                <br />
                What’s more, with easy access to reliable drug information, you
                get to know all about your medicine at medpack.com.
              </div>
            </div>

            <div className="mt-4">
              <h4 className="m-0 p-0">
                <b>ONE-STOP SHOP</b>
              </h4>
              <div
                className="mt-1"
                style={{ border: "1px solid #EF7E00", width: "80px" }}
              />
              <div className="w-100 mt-4">
                At medpack.com, we not only provide you with a wide range of
                medicines listed under various categories, we also offer a wide
                choice of OTC products including wellness products, vitamins,
                diet/fitness supplements, herbal products, pain relievers,
                diabetic care kits, baby/mother care products, beauty care
                products and surgical supplies.
              </div>
            </div>

            <div className="mt-4">
              <h4 className="m-0 p-0">
                <b>TRUST</b>
              </h4>
              <div
                className="mt-1"
                style={{ border: "1px solid #EF7E00", width: "80px" }}
              />
              <div className="w-100 mt-4">
                With the highest standards of ethical pharmacy practice, Medpack
                is committed to providing authentic cum affordable medicines as
                well as a customer service philosophy that is worthy of our
                valued customers’ loyalty. We offer a superior online shopping
                experience, which includes ease of navigation and absolute
                transactional security.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default AboutUs;
