import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CartNavbarMob from "../../../Header/CartNavbarMob/CartNavbarMob";
import Navbarmob from "../../../Header/NavbarMob/Navbarmob";
import AvailableOffer from "../../AvailableOffer/AvailableOffer";
import Cartpricedetails from "../../Cart/CartDesktop/Cartpricedetails/Cartpricedetails";
import PaymentOption from "../PaymentOption/PaymentOption";

const PaymentMob = () => {
  return (
    <>
      <Navbarmob />
      <Container>
        <Row>
          <Col>
            {/* <Link
              className="btn bg-btn-theme cart-price-details-footer-btn mt-4"
              style={{
                width: "100%",
              }}
            >
              Pay now
            </Link> */}
            <PaymentOption />
          </Col>
        </Row>
      </Container>
      <Cartpricedetails />
    </>
  );
};

export default PaymentMob;
