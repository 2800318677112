import { Col, Container, Row } from "react-bootstrap";
import Orderitemcard from "../../MyOrders/Orderitemcard/Orderitemcard";
import { useEffect, useState } from "react";
import { apiUrl } from "../../../../../redux/action/action";
import axios from "axios";
import Navbarmob from "../../../../Header/NavbarMob/Navbarmob";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserToken, removeUserToken } from "../../../../app/userToken";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import Bottommenumob from "../../../../Footer/Bottommenumob/Bottommenumob";

const MobMyOrder = () => {
  const accessToken = getUserToken();
  const [alldata, setalldata] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [pagetitle, setPagetitle] = useState("Active Orders");

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);
  const [TotalNumPages, setTotalNumPages] = useState(1);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const path = location.pathname.split("/");
    if (window.innerWidth > 1000 && path[1] === "profile") {
      window.location = location.pathname.replace("profile", "account");
    }
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    if (location.pathname === "/profile/my-order") {
      setPagetitle("Active Orders");
      fetacOrderDetail("active_orders");
    } else if (location.pathname === "/profile/order-history") {
      setPagetitle("Order History");
      fetacOrderDetail("order_history");
    }
  }, [location.pathname, pageNo, totalItemToBeFetched]);

  useEffect(() => {
    setTotalNumPages(Math.ceil(totalItems / totalItemToBeFetched));
  }, [totalItems, totalItemToBeFetched]);

  const fetacOrderDetail = (apiRoute) => {
    if (accessToken) {
      axios
        .get(
          apiUrl +
          "/order/" +
          apiRoute +
          "?page_no=" +
          pageNo +
          "&page_count=" +
          totalItemToBeFetched,
          {
            headers: {
              Authorization: "Token " + accessToken,
            },
          },
          []
        )
        .then((res) => {
          setLoader(false);
          setTotalItems(res.data.total_count);
          setalldata(res.data.data);
        })
        .catch((err) => {
          setLoader(false);
          const { status, message } = err.response.data.data;
          if (status === "400") {
            toast.error(message);
            window.location = "/";
            removeUserToken();
          } else {
            toast.error(message);
          }

        });
    } else {
      localStorage.setItem("redirect_url", location.pathname);
      navigate("/auth/login");
    }
  };

  return (
    <>
      <Navbarmob />
      <Container>
        <Row>
          <Col>
            <div className="w-100">
              <h5 className="p-0 m-0">
                {pagetitle} ({totalItems})
              </h5>
            </div>
            {loader ? (
              <div className="w-100 d-flex align-items-center justify-content-center py-5">
                <i
                  className="fa fa-spinner fa-spin text-theme"
                  style={{ fontSize: "40px" }}
                ></i>
              </div>
            ) : alldata.length > 0 ? (
              alldata.map((data, index) => {
                return <Orderitemcard data={data} key={index} />;
              })
            ) : (
              <p className="text-center mt-4">No Active Order</p>
            )}

            {!loader && totalItems > 0 ? (
              <div className="order-pagination d-flex flex-column align-items-center mb-5">
                <select
                  className="mb-4"
                  style={{ width: "70px", height: "30px" }}
                  value={totalItemToBeFetched}
                  onChange={(e) => {
                    setTotalItemToBeFetched(e.target.value);
                    setPageNo(1);
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </select>

                <Pagination
                  {...bootstrap5PaginationPreset}
                  current={pageNo}
                  total={TotalNumPages}
                  maxWidth={300}
                  onPageChange={(e) => setPageNo(e)}
                  activeItemClassName="active"
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
      <div className="mt-4" />
      <Bottommenumob />
    </>
  );
};
export default MobMyOrder;
