import { POP_SELECTED_CATEGORY, PUSH_SELECTED_CATEGORY, RESET_SUB_CATEGORY, STORE_ALL_CATEGORIES } from "../state/state";


const initialState = {
    subMenuList: [],
    all: [],
};

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_ALL_CATEGORIES: {
            return {
                ...state,
                subMenuList: [
                    [{ id: 'All', name: 'All', sub_categories: action.payload }],
                ],
                all: [
                    [{ id: 'All', name: 'All', sub_categories: action.payload }],
                ],
            }
        }
        case PUSH_SELECTED_CATEGORY: {
            return {
                ...state,
                subMenuList: [
                    ...state.subMenuList,
                    [action.payload]
                ]
            }
        }
        case POP_SELECTED_CATEGORY: {
            return {
                ...state,
                subMenuList: state.subMenuList.slice(0, state.subMenuList.length - 1)
            }
        }
        case RESET_SUB_CATEGORY: {
            return {
                ...state,
                subMenuList: state.subMenuList.slice(0, 1)
            }
        }
        default:
            return state;
    }
};


export {
    menuReducer
};
