import axios from "axios";
import { Col, Container, ListGroup, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../../redux/action/action";
import { getUserToken } from "../../app/userToken";
import { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { compareProduct } from "../../app/compareProduct";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, displayTopBarCart } from "../../../redux/action/action";
import { Link } from "react-router-dom";
import Prdctcarousel from "../Individualproduct/Prdctcarousel/Prdctcarousel";

const ItemCompareTable = ({ prdct1, setPrdct1, prdct2, setPrdct2 }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [options, setOptions] = useState([]);
  // const [keyword, setKeyword] = useState();
  const [loader, setLoader] = useState(false);
  const [prdctkeys, setPrdctkeys] = useState([]);
  const [showprdctkeys, setshowprdctkeys] = useState(true);
  const reduxselectItemUnit = useSelector(
    (state) => state.selectItemUnitReducer
  );
  const [addtoCartTxt1, setAddtoCartTxt1] = useState("Add to Cart");
  const [addtoCartTxt2, setAddtoCartTxt2] = useState("Add to Cart");

  const detailsObjMap = [
    {
      label: "Product Name",
      prop: "itemName",
    },
    {
      label: "Rating",
      prop: "avg_rating",
    },
    {
      label: "Price / Pcs (QAR)",
      prop: "pricePerPcs",
    },
    {
      label: "Price / Pack (QAR)",
      prop: "pricePerPack",
    },
    {
      label: "Ingredients",
      prop: "ingredients",
    },
    {
      label: "Composition",
      prop: "composition",
    },
    {
      label: "Side Effects",
      prop: "sideEffects",
    },
    {
      label: "Safety Info",
      prop: "safetyInfo",
    },
    {
      label: "Storage & Disposal",
      prop: "storageDisposal",
    },
    {
      label: "Precautions & Warnings",
      prop: "precautionsAndWarnings",
    },
    {
      label: "Directions for use",
      prop: "directionsForUse",
    },
    {
      label: "Contra Indications",
      prop: "contraindications",
    },
    {
      label: "Mode Of Delivery",
      prop: "modeOfDelivery",
    },
    {
      label: "Therapeutic Classification",
      prop: "therapeuticClassification",
    },
  ];

  const onInputSearch = async (e) => {
    const val = e.target.value;
    if (val.length >= 3) {
      if (!val) {
        setshowprdctkeys(false);
        navigate("/product/all");
      } else {
        setshowprdctkeys(true);
        setLoader(true);
      }
      try {
        const result = await axios.get(apiUrl + "/search/products/" + val); //AHP1
        setPrdctkeys(result.data.data);
        setLoader(false);
      } catch (err) {
        toast.error(err.toString());
      }
    } else {
      setshowprdctkeys(false);
    }
  };

  const handleOnclick = (e) => {
    const data = JSON.parse(e.target.getAttribute("data"));
    setPrdct2(data);
    compareProduct(data);
  };

  const renderDetails = (obj) => {
    const { label, prop } = obj;
    return (
      <tr>
        <td style={{ background: "#f2f2f2" }}>{label}</td>
        <td className="text-center">
          {prdct1 && prdct1[prop] ? (
            prop === "itemName" ? (
              <Link to={"/product-details/" + prdct1?.id}>{prdct1[prop]}</Link>
            ) : (
              prdct1[prop]
            )
          ) : (
            "N/A"
          )}
        </td>
        <td className="text-center">
          {prdct2 && prdct2[prop] ? (
            prop === "itemName" ? (
              <Link to={"/product-details/" + prdct2?.id}>{prdct2[prop]}</Link>
            ) : (
              prdct2[prop]
            )
          ) : (
            "N/A"
          )}
        </td>
      </tr>
    );
  };

  return (
    <Container>
      <Row className="my-3">
        <Col className="p-md-5 p-0 px-3">
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Summary</th>
                <th className="text-center">Product 1</th>
                <th className="text-center">Product 2</th>
              </tr>
            </thead>
            <tbody>
              {(prdct1 || prdct2) && (
                <tr>
                  <td></td>
                  <td className="text-center">
                    {prdct1 && (
                      <img
                        src={apiUrl + prdct1?.product_images[0]}
                        alt="product_1_images"
                        width={"300px"}
                      />
                    )}
                    {/* {prdct1 && (
                      <div className="w-75">
                        <Prdctcarousel
                          data={prdct1}
                          loader={false}
                          compare={true}
                        />
                      </div>
                    )} */}
                  </td>
                  <td className="text-center">
                    {prdct2 ? (
                      <div className="w-100">
                        <img
                          src={apiUrl + prdct2?.product_images[0]}
                          alt="product_2_images"
                          width={"300px"}
                        />
                        {/* <Prdctcarousel
                          data={prdct2}
                          loader={false}
                          compare={true}
                        /> */}
                      </div>
                    ) : (
                      <div>
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search for product 2"
                          onInput={onInputSearch}
                        />

                        {showprdctkeys ? (
                          <div
                            className="w-100 body-color"
                            style={{ maxHeight: "300px", overflow: "auto" }}
                          >
                            {loader ? (
                              <SkeletonTheme
                                color="#ccc"
                                highlightColor="#fff"
                                width={"100%"}
                                inline={true}
                              >
                                <Skeleton count={6} height={15} />
                              </SkeletonTheme>
                            ) : (
                              <ListGroup variant="flush">
                                {prdctkeys.map((data, index) => {
                                  return (
                                    <ListGroup.Item
                                      key={index}
                                      onClick={handleOnclick}
                                      data={JSON.stringify(data)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {data.itemName}
                                    </ListGroup.Item>
                                  );
                                })}
                              </ListGroup>
                            )}
                          </div>
                        ) : null}
                      </div>
                    )}

                  </td>
                </tr>
              )}

              {getUserToken()
                ? (prdct1 || prdct2) && (
                  <tr>
                    <td></td>
                    <td className="text-center">
                      {prdct1 ? prdct1?.prescription_required ? (
                        <button
                          className="btn bg-theme text-light"
                          onClick={() => navigate("/upload-prescription")}
                        >
                          Upload Prescription
                        </button>
                      ) : (
                        <button
                          disabled={addtoCartTxt1 === "Adding..."}
                          className="btn bg-theme text-light"
                          onClick={async () => {
                            setAddtoCartTxt1("Adding...");
                            const data = {
                              product_id: prdct1?.id,
                              quantity: 1,
                              unit: reduxselectItemUnit.unittype,
                            };
                            await dispatch(addToCart(data));
                            await dispatch(displayTopBarCart());
                            await setAddtoCartTxt1("Added");
                          }}
                        >
                          {addtoCartTxt1}
                        </button>
                      ) : null}
                    </td>
                    <td className="text-center">
                      {prdct2 ? prdct2?.prescription_required ? (
                        <button
                          className="btn bg-theme text-light"
                          onClick={() => navigate("/upload-prescription")}
                        >
                          Upload Prescription
                        </button>
                      ) : (
                        <button
                          disabled={addtoCartTxt2 === "Adding..."}
                          className="btn bg-theme text-light"
                          onClick={async () => {
                            setAddtoCartTxt2("Adding...");
                            const data = {
                              product_id: prdct2.id,
                              quantity: 1,
                              unit: reduxselectItemUnit.unittype,
                            };
                            await dispatch(addToCart(data));
                            await dispatch(displayTopBarCart());
                            await setAddtoCartTxt2("Added");
                          }}
                        >
                          {addtoCartTxt2}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
                : null}

              {prdct1 &&
                detailsObjMap.map((obj, index) => renderDetails(obj, index))}

              {prdct1 || prdct2 ? (
                <tr>
                  <td className="text-center">
                    {/* {prdct1 ? (
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          axios
                            .delete(
                              apiUrl +
                                "/products/delete_compared_products/" +
                                prdct1?.id,
                              {
                                headers: {
                                  Authorization: "Token " + getUserToken(),
                                },
                              }
                            )
                            .then((res) => {
                              localStorage.removeItem("compareitem");
                              setPrdct1(null);
                            })
                            .catch((err) => {
                              toast.error(err.toString());
                            });
                        }}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className="btn bg-theme text-light"
                        onClick={() => navigate("/product/all")}
                      >
                        Add Item
                      </button>
                    )} */}
                  </td>
                  <td className="text-center">
                    {/* {prdct2 ? (
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          axios
                            .delete(
                              apiUrl +
                                "/products/delete_compared_products/" +
                                prdct1?.id,
                              {
                                headers: {
                                  Authorization: "Token " + getUserToken(),
                                },
                              }
                            )
                            .then((res) => {
                              setPrdct2(null);
                            })
                            .catch((err) => {
                              toast.error(err.toString());
                            });
                        }}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className="btn bg-theme text-light"
                        onClick={() => navigate("/product/all")}
                      >
                        Add Item
                      </button>
                    )} */}
                  </td>
                  <td className="py-3">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        if (getUserToken()) {
                          axios
                            .delete(
                              apiUrl + "/products/clear_all_compared_products",
                              {
                                headers: {
                                  Authorization: "Token " + getUserToken(),
                                },
                              }
                            )
                            .then((res) => {
                              localStorage.removeItem("compareitem");
                              setPrdct1(null);
                              setPrdct2(null);
                            })
                            .catch((err) => {
                              toast.error(err.toString());
                            });
                        } else {
                          setPrdct1(null);
                          setPrdct2(null);
                          localStorage.removeItem("compareitem");
                          localStorage.removeItem("compareitem2");
                        }
                      }}
                    >
                      Clear All
                    </button>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td></td>
                  <td className="text-center">
                    <button
                      className="btn bg-theme text-light"
                      onClick={() => navigate("/product/all")}
                    >
                      Add Item
                    </button>
                  </td>
                  <td className="text-center">
                    <button
                      className="btn bg-theme text-light"
                      onClick={() => navigate("/product/all")}
                    >
                      Add Item
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div className="my-5" />
    </Container>
  );
};

export default ItemCompareTable;
