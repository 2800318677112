import { useEffect, useState, useMemo } from "react";
import "./MyAccount.css";
import { apiUrl } from "../../../../redux/action/action";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";
import Navbarmob from "../../../Header/NavbarMob/Navbarmob";
import { getUserToken, removeUserToken } from "../../../app/userToken";
import { USER_DATA } from "../../../../redux/state/state";
import { getUserDetails } from "../../../app/userDetails";
import Bottommenumob from "../../../Footer/Bottommenumob/Bottommenumob";
import PhoneInput from "react-phone-input-2";

const MyAccount = () => {
  const dispatch = useDispatch();
  const accessToken = getUserToken();
  const navigate = useNavigate();
  const [userdata, setuserdata] = useState({});
  const [saveBtnState, setSaveBtnState] = useState("Save");
  const reduxuserdata = useSelector((state) => state.userReducer);
  const [nationalId, setNationalId] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [validIdProof, setValidIdProof] = useState(true);
  const [idmsg, setidmsg] = useState("");
  const [salutation, setSalutation] = useState(null);
  const [countryName, setCountryName] = useState("");
  const countryoptions = useMemo(() => countryList().getData(), []);
  const [DOB, setDOB] = useState(null);
  const [savebtnstate, setSavebtnstate] = useState(false);
  const [gender, setGender] = useState("");
  const location = useLocation();

  const [phone, setPhone] = useState();
  const [countryCode, setCountryCode] = useState("+974");
  const [phoneCountryCode, setPhoneCountryCode] = useState(null);

  useEffect(() => {
    const path = location.pathname.split("/");
    if (window.innerWidth > 1000 && path[1] === "profile") {
      window.location = location.pathname.replace("profile", "account");
    }
  }, []);

  useEffect(() => {
    setuserdata(reduxuserdata.userdata);
  }, [reduxuserdata.userdata]);

  useEffect(() => {
    if (accessToken) {
      fetchUserDetail();
    }
  }, [accessToken]);

  const fetchUserDetail = () => {
    axios
      .get(apiUrl + "/user/customerDetails", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        dispatch({
          type: USER_DATA,
          payload: getUserDetails(res.data.encrypted),
        });
      }).catch((err) => {
        const { status, message } = err.response.data.data;
        if (status === "400") {
          toast.error(message);
          window.location = "/";
          removeUserToken();
        } else {
          toast.error(message);
        }
      });
  };

  useEffect(() => {
    if (gender === "male") {
      setSalutation("Mr");
    } else if (gender === "female") {
      setSalutation("Miss");
    }
  }, [gender]);

  useEffect(() => {
    if (salutation === "Mr") {
      setGender("male");
    } else if (salutation === "Miss") {
      setGender("female");
    }
  }, [salutation]);

  useEffect(() => {
    setDOB(userdata?.dob);
    setCountryName(userdata?.country);
    setNationalId(userdata?.national_id);
    setPassportNo(userdata?.passport_no);
    setSalutation(userdata?.salutation);
    setGender(userdata?.gender);
    setPhoneCountryCode(countryCode + userdata?.phone_number);
  }, [userdata]);

  useEffect(() => {
    if (nationalId !== "") {
      if (!nationalId?.match(/(2|3)\d{10}/)) {
        setValidIdProof(false);
        setidmsg("Please enter a valid national id");
      } else {
        setValidIdProof(true);
        setidmsg("");
      }
    } else {
      setValidIdProof(true);
      setidmsg("");
    }
  }, [nationalId]);

  const onsubmitUpdateuser = async (e) => {
    e.preventDefault();
    if (validIdProof) {
      const form = e.target;
      const formData = new FormData(form);
      const data = {
        phone_number: phone,
        country_code: countryCode,
        salutation: formData.get("salutation"),
        name: formData.get("name"),
        gender: formData.get("gender"),
        dob: formData.get("dob"),
        country: formData.get("country"),
        email: formData.get("email"),
        national_id: formData.get("national_id"),
        passport_no: formData.get("passport_no"),
      };

      // if (countryName === "Qatar") {
      //   data["national_id"] = formData.get("national_id");
      // } else {
      //   data["passport_no"] = formData.get("passport_no");
      // }

      if (
        data.email &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)
      ) {
        if (passportNo || nationalId) {
          const alphanumericRegex = /^[a-z0-9]+$/i;
          if (alphanumericRegex.test(data.national_id) && alphanumericRegex.test(data.passport_no)) {
            setSaveBtnState("Saving...");
            try {
              const result = await axios.put(
                apiUrl + "/user/updatecustomerDetails",
                data,
                {
                  headers: {
                    Authorization: "Token " + accessToken,
                  },
                }
              );

              if (result.data.status === 200) {
                fetchUserDetail();
                toast.success(result.data.message);
                setSaveBtnState("Saved");
                if (localStorage.getItem("redirect_url")) {
                  setTimeout(() => {
                    navigate(localStorage.getItem("redirect_url"));
                    localStorage.removeItem("redirect_url");
                  }, 1000);
                }
              } else {
                toast.warning(result.data.message);
              }
            } catch (err) {
              setSaveBtnState("Save");
              const { response } = err;
              const { data } = response;
              const { status, message } = data;
              if (status === "400") {
                toast.warning(message);
              } else {
                toast.error(message);
              }
            }
          }
          else {
            toast.warning("Passport / National should contains only alphanumeric characters");
          }
        } else {
          toast.warning("Passport no or national id required");
        }
      } else {
        toast.warning("Please enter a valid email");
      }
    }
  };

  return (
    <>
      <Navbarmob />
      <div className="my-account mb-4">
        <div className="my-account-title mb-3">
          <h4>My Account</h4>
        </div>
        <div className="my-account-user-details px-md-0 px-3">
          <form autoComplete="off" onSubmit={onsubmitUpdateuser}>
            <div className="input-group-cust">
              <label htmlFor="name">
                Name<sup className="text-danger">*</sup>
              </label>
              <div className="w-100 d-flex">
                <select
                  className="form-select w-25"
                  name="salutation"
                  value={salutation}
                  onChange={(e) => setSalutation(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                </select>
                <input
                  type="text"
                  name="name"
                  className="form-control w-75"
                  id="name"
                  defaultValue={userdata?.name}
                  required
                />
              </div>
            </div>
            <div className="input-group-cust">
              <label htmlFor="email">
                Email ID<sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                name="email"
                className="form-control"
                id="email"
                defaultValue={userdata?.email}
                required
              />
            </div>

            <div className="input-group-cust">
              <label htmlFor="phone">Phone No</label>
              {/* <div className="w-100 d-flex">
                <input
                  type="text"
                  name="country_code"
                  className="form-control w-25 rounded-0 text-center"
                  id="country_code"
                  disabled
                  value={
                    userdata?.country_code ? userdata?.country_code : "+974"
                  }
                />
                <input
                  type="text"
                  name="phone_number"
                  className="form-control w-75 rounded-0"
                  id="phone"
                  defaultValue={userdata?.phone_number}
                  // defaultValue={
                  //   userdata?.phone_number?.toString().slice(0, 4) +
                  //   " " +
                  //   userdata?.phone_number?.toString().slice(4)
                  // }
                />
              </div> */}
              <PhoneInput
                inputStyle={{
                  width: "100%",
                  height: "40px",
                }}
                country="qa"
                // onlyCountries={["qa"]}
                defaultMask={".... ...."}
                countryCodeEditable={false}
                value={phoneCountryCode}
                onChange={(phoneNumber, countryCodeObj) => {
                  setPhone(
                    phoneNumber?.substring(countryCodeObj?.dialCode?.length)
                  );
                  setCountryCode(`+${countryCodeObj?.dialCode}`);
                  setPhoneCountryCode(`+${phoneNumber}`);
                }}
              />
            </div>
            {/* <div className="input-group-cust">
              <ReactCountryDropdown onSelect={handleSelect} countryCode="IN" />
            </div> */}
            <div className="input-group-cust">
              <label htmlFor="gender">Gender</label>
              <select
                name="gender"
                id="gender"
                className="form-select"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="input-group-cust">
              <label htmlFor="dob">
                DOB<sup className="text-danger">*</sup>
              </label>
              <input
                type="date"
                name="dob"
                max={new Date(Date.now() - 86400000).toISOString().slice(0, 10)}
                placeholder="dd-mm-yyyy"
                className="form-control"
                id="dob"
                defaultValue={DOB}
                onChange={(e) => {
                  if (
                    new Date(e.target.value).getTime() < new Date().getTime()
                  ) {
                    setDOB(e.target.value);
                    setSavebtnstate(false);
                  } else {
                    setSavebtnstate(true);
                    toast.warning("Please select a past date");
                  }
                }}
                required
              />
            </div>
            <div className="input-group-cust ">
              <label htmlFor="gender" className="label-cust">
                Select Nationality
              </label>
              {/* <Select
                options={countryoptions}
                defaultValue={countryName}
                styles={customStyles}
                onChange={(e) => {
                  setCountryName(e.label);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#f7a86c",
                    primary: "#F58329",
                  },
                })}
              /> */}
              <select
                name="country"
                id="nationality"
                className="form-select"
                value={countryName}
                onChange={(e) => setCountryName(e.target.value)}
              >
                <option value="">Select</option>
                {countryoptions.map((e, i) => {
                  return (
                    <option key={i} value={e.label}>
                      {e.label}
                    </option>
                  );
                })}
              </select>
            </div>

            {countryName === "Qatar" || userdata?.country === "Qatar" ? (
              <div className="input-group-cust">
                <label htmlFor="passport_no">
                  Passport No.<sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  name="passport_no"
                  className="form-control"
                  id="passport_no"
                  value={passportNo}
                  onChange={(e) => setPassportNo(e.target.value)}
                // defaultValue={userdata?.passport_no}
                />
              </div>
            ) : (
              <div className="input-group-cust">
                <label htmlFor="national_id">
                  National Id<sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  name="national_id"
                  className="form-control"
                  id="national_id"
                  value={nationalId}
                  onChange={(e) => setNationalId(e.target.value)}
                />
                <small className="text-danger">{idmsg}</small>
              </div>
            )}

            <div className="input-group-cust-btn">
              <button
                type="submit"
                className="btn border-dark text-theme-outline"
                disabled={savebtnstate}
              >
                {saveBtnState}
              </button>
            </div>

            {/* <div className="input-group-cust-btn-mob">
              <center>
                <button type="submit" className="btn border-dark">
                  Save
                </button>
              </center>
            </div> */}
          </form>
        </div>
      </div>
      <Bottommenumob />
    </>
  );
};

export default MyAccount;
