import axios from "axios";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../../../redux/action/action";

const Prepaymentloader = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(async () => {
    const res = await axios.get(apiUrl + "/user/payment/" + id);
    if (res.data.status === 200) {
      localStorage.setItem("order_details_data", JSON.stringify(res.data.data));
      navigate("/status/payment/success");
    } else {
      navigate("/status/payment/failed");
    }
  }, []);
  return (
    <>
      <Container
        fluid
        style={{ height: "100vh" }}
        className="body-white d-flex justify-content-center align-items-center"
      >
        <Row>
          <Col className="d-flex justify-content-center align-items-center flex-column">
            <img src="/img/payment_page_loader.gif" alt="payment_page_loader" width={200} />
            <h4 className="text-theme">Please Wait . . .</h4>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Prepaymentloader;
