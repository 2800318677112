import AccountOptions from "../../AccountOptions/AccountOptions";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Navbarmob from "../../../../Header/NavbarMob/Navbarmob";
import { getUserToken } from "../../../../app/userToken";
import Bottommenumob from "../../../../Footer/Bottommenumob/Bottommenumob";

const MobOptions = () => {
  const accessToken = getUserToken();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(async () => {
    if (!accessToken) {
      localStorage.setItem("redirect_url", location.pathname);
      navigate("/auth/login");
    }
    // clear all filter
    dispatch({
      type: "CLEAR_FILTER_BY_CATEGORY",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_BRAND",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_PRODUCT_FORM",
    });

    localStorage.removeItem("keywordSearch");
  }, []);
  return (
    <>
      <Navbarmob />
      <AccountOptions />
      <Bottommenumob />
    </>
  );
};

export default MobOptions;
