import React, { useEffect } from "react";
import "./css/Style.css";

import {
    Outlet
} from "react-router-dom";

import Navbar from "../../Header/Navbar/Navbar";
import Footer from "../../Footer/Footer";

const Auth = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    })
    return (
        <>
            <Navbar />
            <div className="container-fluid d-flex border authcomp">
                <Outlet />
            </div>
            <Footer />
        </>
    );

}

export default Auth;

