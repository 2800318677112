import { Container, Row, Col } from "react-bootstrap";
import CartNavbarMob from "../../../Header/CartNavbarMob/CartNavbarMob";
import Cartpricedetails from "../../Cart/CartDesktop/Cartpricedetails/Cartpricedetails";
import { useDispatch, useSelector } from "react-redux";
import "./SetAddressMob.css";
import { useEffect } from "react";
import AddressCardView from "../SetAddressDesktop/AddressCardView/AddressCardView";
import { fetchAllAddress } from "../../../../redux/action/address.action";
import { Link, useNavigate } from "react-router-dom";
import Navbarmob from "../../../Header/NavbarMob/Navbarmob";
import { useState } from "react";
import AddAddressFormCmp from "../SetAddressDesktop/AddAddress/AddAddressFormCmp";
import { getUserToken } from "../../../app/userToken";

const SetAddressMob = () => {
  const accessToken = getUserToken();
  const navigate = useNavigate();
  const reduxresponse = useSelector((state) => state.addressReducer);
  const reduxresponsefetchaddressReducer = useSelector(
    (state) => state.fetchaddressReducer
  );
  const dispatch = useDispatch();
  const [radioId, setRadioId] = useState("");

  useEffect(() => {
    dispatch(fetchAllAddress());
  }, [reduxresponse.message]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h3>{accessToken ? 'Select Delivery Address' : 'Checkout as Guest'}</h3>
            {accessToken && (
              <Link
                to="/payment"
                className="btn bg-btn-theme cart-price-details-footer-btn mt-4"
                style={{
                  width: "100%",
                }}
              >
                Continue to Payment
              </Link>
            )}
            {accessToken && (
              <button onClick={() => navigate("/address/add/checkout")} className="btn border-theme text-theme w-100 mt-2">
                Add New Address
              </button>
            )}

            {accessToken ? (
              reduxresponsefetchaddressReducer.data.length === 0 ? (
                <div className="w-100 d-flex justify-content-center mt-5">
                  <h4
                    className="p-0 m-0 text-theme"
                    style={{ fontSize: "18px" }}
                  >
                    Add an address before checkout
                  </h4>
                </div>
              ) : (
                <form>
                  {reduxresponsefetchaddressReducer.data.map(
                    (address, index) => {
                      return (
                        <AddressCardView
                          data={address}
                          radioId={radioId}
                          setRadioId={setRadioId}
                          index={index}
                          key={index}
                        />
                      );
                    }
                  )}
                </form>
              )
            ) : (
              <div className="p-1 pb-5 body-white mt-3">
                <p className="mb-1">
                  If you have already an account please{" "}
                  <Link
                    to="/auth/login"
                    onClick={() => {
                      localStorage.setItem("redirect_url", "/setaddress");
                    }}
                  >
                    Login
                  </Link>
                  .{" "}
                </p>
                <AddAddressFormCmp
                  address={
                    sessionStorage.getItem("guest_address")
                      ? JSON.parse(sessionStorage.getItem("guest_address"))
                      : null
                  }
                />
              </div>
            )}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Cartpricedetails />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SetAddressMob;
