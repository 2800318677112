import Navbarmob from "../../../../Header/NavbarMob/Navbarmob";
import PresOrderdetails from "../../MyPrescription/Order/PresOrderdetails";

const MobPresOrderDetails = () => {
  return (
    <>
      <Navbarmob />
      <div className="mt-3"></div>
      <PresOrderdetails />
    </>
  );
};

export default MobPresOrderDetails;
