import React from "react";
import { Container } from "react-bootstrap";
// import components
import Navbar from "../../Header/Navbar/Navbar";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import Footer from "../../Footer/Footer";

import Mycarousel from "../Carousel/Mycarousel";
import Upload from "./Upload/Upload";
import Prdtcategory from "./Prdctcategories/Prdctcategories";
import Prdctcategoriesmobile from "./Prdctcategories/Prdctcategoriesmobile/Prdctcategoriesmobile";
// import Bannerads from "./BannerAds/Bannerads";
import Mobileappads from "./MobileappAds/Mobileappads";
import Other from "./Others/Other";
import Bottommenumob from "../../Footer/Bottommenumob/Bottommenumob";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../redux/action/action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SuggetionPrdct from "../SuggetionPrdct/SuggetionPrdct";
import Geocode from "react-geocode";
import ShopByCategoriesMob from "../../Header/Navbar/ShopByCategoriesMob";
import { getUserToken, removeUserToken } from "../../app/userToken";

const Home = () => {
  const accessToken = getUserToken();
  const [state, setState] = useState({});
  const [allcategorieslist, setAllcategorieslist] = useState(null);
  const dispatch = useDispatch();
  const [carousel, setCarousel] = useState(null);
  const [carousel2, setCarousel2] = useState(null);
  const [carousel3, setCarousel3] = useState(null);
  const [topSoldPrdct, setTopSoldPrdct] = useState([]);
  const [recentOrderItemList, setRecentOrderItemList] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("discount_coupon_id");
    localStorage.removeItem("total_loyalty_points_deducted");
    localStorage.removeItem("discount_coupon_code");
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const co_ordinates = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        fetchAddress(position.coords.latitude, position.coords.longitude);
        localStorage.setItem("co-ordinates", JSON.stringify(co_ordinates));
      },
      (err) => {
        if (err.message === "User denied Geolocation") {
          const co_ordinates = {
            latitude: 20.5937,
            longitude: 78.9629,
          };
          localStorage.setItem("co-ordinates", JSON.stringify(co_ordinates));
        }
      },
      { enableHighAccuracy: true }
    );

    // clear all filter
    dispatch({
      type: "CLEAR_FILTER_BY_CATEGORY",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_BRAND",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_PRODUCT_FORM",
    });
    return () => {
      setState({});
    };
  }, []);

  const fetchAddress = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        let addressjson = {};
        const address_components = response.results[0].address_components;
        address_components.forEach((element) => {
          addressjson[element.types[0]] = element.long_name;
        });

        if (addressjson?.country !== "Qatar") {
          toast.warning(
            "This Location is not serviceable yet.We are currently serviceable only in Qatar"
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(async () => {
    // fetch dynamic data
    await axios
      .get(apiUrl + "/master/get_home_page_banners")
      .then((res) => {
        setCarousel(res.data.data.banner_1_data);
        setCarousel2(res.data.data.banner_2_data);
        setCarousel3(res.data.data.banner_3_data);
        if (accessToken) fetchRecentlyOrderedItem();
        else fetchAllCategories();
      })
      .catch(() => {
        if (accessToken) fetchRecentlyOrderedItem();
        else fetchAllCategories();
      });
  }, []);

  const fetchRecentlyOrderedItem = () => {
    axios
      .get(apiUrl + "/order/recently_ordered_item", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        setRecentOrderItemList(res.data.data);
        fetchAllCategories();
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  const fetchAllCategories = () => {
    axios
      .get(apiUrl + "/category/AllCategories")
      .then((res) => {
        setAllcategorieslist(res.data.data);
        fetchTopPrdct();
      })
      .catch((err) => {
        toast.error(err.toString());
        fetchTopPrdct();
      });
  };

  const fetchTopPrdct = () => {
    axios
      .get(apiUrl + "/products/top_products")
      .then((res) => {
        setTopSoldPrdct(res.data.data);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  return (
    <>
      <Navbar />
      <Navbarmob topCategories={allcategorieslist} />
      <Container fluid className="mb-5 pt-md-2 px-md-5 mt-3">
        <ShopByCategoriesMob />
        {/* order medicine */}
        <Upload />
        {/* first carousel */}
        <div className="w-100 mt-md-4 mt-1">
          <Mycarousel data={carousel} />
        </div>
        {/* popular categories */}

        {window.innerWidth > 768 ? (
          <Prdtcategory
            data={allcategorieslist}
            heading="Top Categories"
            setid="carouselprdctCategories"
            setidhas="#carouselprdctCategories"
            viewall="#"
          />
        ) : null}
        <Prdctcategoriesmobile
          heading="Top Categories"
          data={allcategorieslist}
        />

        {/* ) : null} */}
        {/*for mobile*/}
        {/* second carousel */}
        <div className="mt-md-5 mt-3">
          <Mycarousel data={carousel2} />
        </div>
        {/* top sold product */}
        <SuggetionPrdct
          data={topSoldPrdct}
          title="Top Products"
          bgcolor="body-color"
          class="top-sold-product"
        />

        {/* third carousel */}
        <div className="mt-md-5 mt-3">
          <Mycarousel data={carousel3} />
        </div>

        {/* recently Order item */}
        {accessToken && (
          <SuggetionPrdct
            data={recentOrderItemList}
            title="Recently Purchased Items"
            bgcolor="body-color"
            class="recently-order-item"
          />
        )}

        {/*for mobile*/}
        {/* Mobile app */}
        <Mobileappads />
        {/* other */}
        <Other />
      </Container>
      <Bottommenumob />
      {/* {window.innerWidth > 768 && localStorage.getItem("cookiesPermission") ? (
        <div className="cookies-permission">
          <h6>Allow Cookies for better services</h6>
          <div className="w-100 d-flex justify-content-center mt-3">
            <button
              className="btn border-theme text-theme mr-4"
              onClick={() => {
                setShowCookiePermission(false);
                localStorage.setItem("cookiesPermission", false);
              }}
            >
              Deny
            </button>
            <button
              className="btn bg-theme text-light"
              onClick={() => {
                setShowCookiePermission(false);
                localStorage.setItem("cookiesPermission", true);
              }}
            >
              Allow
            </button>
          </div>
        </div>
      ) : null} */}

      <Footer />
    </>
  );
};

export default Home;
