import { getUserDetails } from "../../pages/app/userDetails";
import { USER_DATA } from "../state/state";


const userReducer = (state = '', action) => {
  switch (action.type) {
    case USER_DATA: {
      return {
        userdata: action.payload,
      };
    }
    default:
      return state;
  }
};

export { userReducer };
