import "./MyPrescription.css";
import pdficon from "../../../../assets/pdf-icon.png";
import imgicon from "../../../../assets/img-icon.png";
import documentNotFound from "../../../../assets/documentNotFound.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../../redux/action/action";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import { getUserToken } from "../../../app/userToken";
import ImageViewer from "../../../app/components/ImageViewer";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";

const MyPrescription = () => {
  const accessToken = getUserToken();
  const navigate = useNavigate();
  const [pageItems, setPageItems] = useState([]);
  const [allpresdata, setAllpresdata] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [TotalNumPages, setTotalNumPages] = useState(1);
  const [key, setKey] = useState("active");
  const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);
  const [previewPres, setPreviewPres] = useState(false);
  const [previewPresImg, setPreviewPresImg] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    fetchPrescription();
  }, [key]);

  const fetchPrescription = async () => {
    const url =
      key === "active"
        ? "/order/get_all_prescriptions"
        : "/order/get_all_prescriptions?is_expire=1";
    axios
      .get(apiUrl + url, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setAllpresdata(res.data.data);
          if (res?.data?.data && res.data.data.length > 0) {
            setPageItems(res.data.data.slice(0, 6));
          } else {
            setPageItems([]);
          }
          setLoader(false);
        }
      });
  };

  ///////////////////////////
  const PrescriptionItems = ({ data }) => {
    const [dateFormat, setDateFormat] = useState("");

    useEffect(() => {
      const date = new Date(data.created_on);
      //extract the parts of the date
      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      setDateFormat(day + " " + months[month - 1] + " " + year);
    }, []);

    const deletePrescription = async () => {
      const res = await axios.delete(
        apiUrl + "/order/update_prescriptions/" + deleteItemId,
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      );

      if (res.data.status) {
        setShowDelectConfirmation(false);
        fetchPrescription();
        toast.success(res.data.message);
      }
    };

    useEffect(() => {
      if (allpresdata.length > 0) {
        setTotalNumPages(Math.ceil(allpresdata.length / 6));
      }
    }, [allpresdata]);

    return (
      <>
        <div className="PrescriptionItems border shadow-sm rounded">
          <div className="PrescriptionItems-header">
            <div className="d-flex w-75">
              <div>
                <a
                  target="_blank"
                  href={apiUrl + data.file}
                  download={data.name}
                >
                  {data.file.indexOf(".pdf") >= 0 ? (
                    <img src={pdficon} alt="pdficon" />
                  ) : (
                    <img src={imgicon} alt="imgicon" />
                  )}
                </a>
              </div>
              <div className="border-left">
                <p className="p-0 m-0 pres-insu-name">{data.name}</p>
              </div>
            </div>
            <div>
              <i
                className="fa fa-trash"
                onClick={() => {
                  setShowDelectConfirmation(true);
                  setDeleteItemId(data.id);
                }}
              ></i>
            </div>
          </div>

          <div className="PrescriptionItems-body border-top">
            <p>Uploaded On : {dateFormat}</p>
            <p>
              {data.number_of_products_ordered} Product{data.number_of_products_ordered > 1 && 's'}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              {data.number_of_times_ordered} Order(s) Placed
            </p>
            <div className="mt-3 d-flex justify-content-end">
              {data.file.indexOf(".pdf") >= 0 ? (
                <a
                  // target="_blank"
                  href={apiUrl + data.file}
                  download={data.name}
                >
                  <button className="btn border-theme text-theme">
                    View Prescription
                  </button>
                </a>
              ) : (
                <button
                  className="btn border-theme text-theme"
                  onClick={() => {
                    setPreviewPres(true);
                    setPreviewPresImg(data.file);
                  }}
                >
                  View Prescription
                </button>
              )}
            </div>
          </div>
        </div>

        {showDelectConfirmation ? (
          <div className="insurance-confirm py-4">
            <i
              className="fa fa-close"
              onClick={() => setShowDelectConfirmation(false)}
            ></i>
            <h5 className="mt-3">Are you sure ?</h5>
            <div className="mt-2">
              <button
                className="btn border-theme text-theme mr-2"
                onClick={() => setShowDelectConfirmation(false)}
              >
                No
              </button>
              <button
                className="btn bg-theme text-light"
                onClick={deletePrescription}
              >
                Yes
              </button>
            </div>
          </div>
        ) : null}

        <ImageViewer
          show={previewPres}
          setShow={setPreviewPres}
          url={apiUrl + previewPresImg}
        />
      </>
    );
  };
  //////////////////////////

  return (
    <>
      <div className="my-account pb-5">
        <div
          className={`my-account-title d-flex ${window.innerWidth < 768 ? `flex-column` : null
            } mb-3`}
        >
          <div className={window.innerWidth < 768 ? `w-100` : `w-50`}>
            <h4 className="p-0 m-0">My Prescriptions</h4>
          </div>
          <div
            className={
              window.innerWidth < 768
                ? `w-100 mt-3 d-flex justify-content-end`
                : `w-50 d-flex justify-content-end`
            }
          >
            {allpresdata.length > 0 ? (
              <button
                className="title-btn-right text-theme-outline  mob-profile-uploadbtn btn border-theme text-theme"
                onClick={() => navigate("/upload-prescription")}
              >
                Upload Prescription
              </button>
            ) : null}
          </div>
        </div>
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
          <Tab eventKey="active" title="Active" style={{ display: allpresdata.length > 0 && 'flex', flexFlow: allpresdata.length > 0 && 'column' }}>
            {loader ? (
              <SkeletonTheme
                color="#ccc"
                highlightColor="#fff"
                width={"100%"}
                inline={true}
              >
                <Skeleton count={2} height={260} className="mr-4" />
              </SkeletonTheme>
            ) : (
              <>
                <div>
                  {allpresdata.length > 0 ? (
                    pageItems.map((data, index) => {
                      return <PrescriptionItems key={index} data={data} />;
                    })
                  ) : (
                    <div
                      className="w-100 d-flex align-items-center justify-content-center"
                      style={{ height: "600px" }}
                    >
                      <div className="d-flex flex-column justify-centent-center align-items-center">
                        <img
                          src={documentNotFound}
                          alt="documentNotFound"
                          style={{ width: "150px" }}
                        />
                        <p className="text-theme mt-4">
                          You have not uploaded any prescription yet
                        </p>
                        <button
                          className="title-btn-right btn border-theme text-theme"
                          onClick={() => navigate("/upload-prescription")}
                        >
                          Upload Prescription
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="prdct-pagination mt-4 d-flex justify-content-end">
                  <Pagination
                    {...bootstrap5PaginationPreset}
                    current={pageNo}
                    total={TotalNumPages}
                    maxWidth={300}
                    onPageChange={(e) => { setPageNo(e); setPageItems(allpresdata.slice(6 * (e - 1), (6 * (e - 1)) + 6)) }}
                    activeItemClassName="active"
                  />
                </div>
              </>
            )}
          </Tab>
          <Tab eventKey="expired" title="Expired">
            {loader ? (
              <SkeletonTheme
                color="#ccc"
                highlightColor="#fff"
                width={"100%"}
                inline={true}
              >
                <Skeleton count={2} height={260} className="mr-4" />
              </SkeletonTheme>
            ) : (
              <>
                <div>
                  {allpresdata.length > 0 ? (
                    pageItems.map((data, index) => {
                      return <PrescriptionItems key={index} data={data} />;
                    })
                  ) : (
                    <div
                      className="w-100 d-flex align-items-center justify-content-center"
                      style={{ height: "600px" }}
                    >
                      <div className="d-flex flex-column justify-centent-center align-items-center">
                        <img
                          src={documentNotFound}
                          alt="documentNotFound"
                          style={{ width: "150px" }}
                        />
                        <p className="text-theme mt-4">
                          You have no expired prescription
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="prdct-pagination mt-4 d-flex justify-content-end">
                  <Pagination
                    {...bootstrap5PaginationPreset}
                    current={pageNo}
                    total={TotalNumPages}
                    maxWidth={300}
                    onPageChange={(e) => { setPageNo(e); setPageItems(allpresdata.slice(6 * (e - 1), (6 * (e - 1)) + 6)) }}
                    activeItemClassName="active"
                  />
                </div>
              </>
            )}
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default MyPrescription;
