import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IS_NOT_PRESCRIPTION, IS_PRESCRIPTION, SORT } from "../../../../../redux/state/state";

// import images
import filterimg from "../../../../../assets/icons/filter.png";
import shortby from "../../../../../assets/icons/shortby.png";
import { useNavigate } from "react-router-dom";
import "./filter.css";

const Filteredoption = () => {
  // get data from redux
  const reduxresponse = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxresponseWithPres = useSelector(
    (state) => state.withPresfilterReducer.prescription
  );
  const reduxresponseWithOutPres = useSelector(
    (state) => state.withOutPresfilterReducer.not_prescription
  );

  const clear_all_filter = (e) => {
    // clear all category filter
    dispatch({
      type: "CLEAR_FILTER_BY_CATEGORY",
    });
    if (reduxresponse.filterByCategory.category_id.length === 1) {
      navigate("/product/all");
    }
    // clear all brand filter
    dispatch({
      type: "CLEAR_FILTER_BY_BRAND",
    });
    // clear all product form filter
    dispatch({
      type: "CLEAR_FILTER_BY_PRODUCT_FORM",
    });
    dispatch({
      type: IS_PRESCRIPTION,
      payload: false,
    });
    dispatch({
      type: IS_NOT_PRESCRIPTION,
      payload: false,
    });
  };

  const onChangeSort = (e) => {
    const val = e.target.value;
    dispatch({
      type: SORT,
      payload: val,
    });
  };

  // const removeAgeGroupItem = (e) => {
  //   dispatch({
  //     type: "DELETE_FILTER_BY_AGE_GROUP",
  //     payload: e.target.value,
  //   });
  // };

  const removeBrandItem = (e) => {
    if (e.target.getAttribute("itemvalue")) {
      dispatch({
        type: "DELETE_FILTER_BY_BRAND",
        payload: e.target.getAttribute("itemvalue"),
      });
    } else {
      dispatch({
        type: "DELETE_FILTER_BY_BRAND",
        payload: e.target.value,
      });
    }
  };

  const removeCategoryItem = (e) => {
    let data = {};
    if (e.target.getAttribute("itemvalue")) {
      data = {
        category_id: e.target.getAttribute("itemID"),
        category_name: e.target.getAttribute("itemvalue"),
      };
    } else {
      data = {
        category_id: e.target.getAttribute("itemID"),
        category_name: e.target.value,
      };
    }

    dispatch({
      type: "DELETE_FILTER_BY_CATEGORY",
      payload: data,
    });

    if (reduxresponse.filterByCategory.category_id.length === 1) {
      navigate("/product/all");
    }
  };

  const removeSubCategoryItem = (e) => {
    let data = {};
    if (e.target.getAttribute("itemvalue")) {
      data = {
        sub_category_id: e.target.getAttribute("itemID"),
        sub_category_name: e.target.getAttribute("itemvalue"),
      };
    } else {
      data = {
        sub_category_id: e.target.getAttribute("itemID"),
        sub_category_name: e.target.value,
      };
    }

    dispatch({
      type: "DELETE_FILTER_BY_CATEGORY",
      payload: data,
    });

    if (reduxresponse.filterByCategory.sub_category_id.length === 1) {
      navigate("/product/all");
    }
  };

  const removeProductFormItem = (e) => {
    if (e.target.getAttribute("itemvalue")) {
      dispatch({
        type: "DELETE_FILTER_BY_PRODUCT_FORM",
        payload: e.target.getAttribute("itemvalue"),
      });
    } else {
      dispatch({
        type: "DELETE_FILTER_BY_PRODUCT_FORM",
        payload: e.target.value,
      });
    }
  };

  return (
    <Row className="body-color mt-3 p-0 px-5 d-lg-flex align-items-lg-center">
      <Col xs="1" className="p-0 d-flex align-items-center">
        <div>
          <img src={filterimg} alt="filter" />
        </div>
        <div className="mt-1 ml-2">
          <p className="text-theme p-0 m-0" style={{ fontWeight: "600" }}>
            FILTERS
          </p>
        </div>
      </Col>

      <Col xs="6" className="p-0 filtered-items">
        {/* {reduxresponse.filterByAgeGroup.ageGroup.map((item, index) => {
          return (
            <button
              className="btn btn-light text-capitalize mr-1"
              key={index}
              value={item}
              onClick={removeAgeGroupItem}
            >
              {item}
              <i className="fa fa-times-circle ml-1 text-theme"></i>
            </button>
          );
        })} */}
        {reduxresponse.filterByBrand.brand.map((item, index) => {
          return (
            <button
              className="btn btn-light text-capitalize mr-1 filtered_button"
              key={index}
              value={item}
              onClick={removeBrandItem}
            >
              {item}
              <i
                className="fa fa-times-circle ml-1 text-theme"
                itemvalue={item}
                onClick={removeBrandItem}
              ></i>
            </button>
          );
        })}

        {reduxresponse.filterByCategory.category_name.map((item, index) => {
          return (
            <button
              className="btn btn-light text-capitalize mr-1 filtered_button"
              key={index}
              value={item}
              itemID={reduxresponse.filterByCategory.category_id[index]}
              onClick={removeCategoryItem}
            >
              {item}
              <i
                className="fa fa-times-circle ml-1 text-theme"
                itemvalue={item}
                itemID={reduxresponse.filterByCategory.category_id[index]}
                onClick={removeCategoryItem}
              ></i>
            </button>
          );
        })}






        {
          reduxresponseWithPres ? <button
            className="btn btn-light text-capitalize mr-1 filtered_button"
            onClick={() => {
              dispatch({
                type: IS_PRESCRIPTION,
                payload: false,
              });
            }}
          >
            With Prescription
            <i
              className="fa fa-times-circle ml-1 text-theme"
            ></i>
          </button> : null
        }

        {
          reduxresponseWithOutPres ? <button
            className="btn btn-light text-capitalize mr-1 filtered_button"
            onClick={() => {
              dispatch({
                type: IS_NOT_PRESCRIPTION,
                payload: false,
              });
            }}
          >
            Without Prescription
            <i
              className="fa fa-times-circle ml-1 text-theme"
            ></i>
          </button> : null
        }








        {reduxresponse.filterByCategory.sub_category_name.map((item, index) => {
          return (
            <button
              className="btn btn-light text-capitalize mr-1 filtered_button"
              key={index}
              value={item}
              itemID={reduxresponse.filterByCategory.sub_category_id[index]}
              onClick={removeSubCategoryItem}
            >
              {item}
              <i
                className="fa fa-times-circle ml-1 text-theme"
                itemvalue={item}
                itemID={reduxresponse.filterByCategory.sub_category_id[index]}
                onClick={removeSubCategoryItem}
              ></i>
            </button>
          );
        })}

        {reduxresponse.filterByProductForm.productForm.map((item, index) => {
          return (
            <button
              className="btn btn-light text-capitalize mr-1 filtered_button"
              key={index}
              itemvalue={item}
              onClick={removeProductFormItem}
            >
              {item}
              <i
                className="fa fa-times-circle ml-1 text-theme"
                itemvalue={item}
                onClick={removeProductFormItem}
              ></i>
            </button>
          );
        })}
      </Col>

      {/* clear all filter once */}
      <Col
        xs="2"
        className="d-flex align-items-center justify-content-center p-0 m-0"
      >
        <button
          className="btn bg-theme text-light clear-all-filter"
          onClick={clear_all_filter}
        >
          Clear All <i className="fa fa-times-circle"></i>
        </button>
      </Col>

      {/* short by  */}
      <Col xs="3" className="short-by p-0">
        <div
          className="input-group"
          style={{ backgroundColor: "#FAFAFA", border: "1px solid #E5E6E6" }}
        >
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <div>
                <img src={shortby} alt="shortby" />
              </div>
              <div className="ml-2">Sort By : </div>
            </span>
          </div>
          <select
            className="form-control text-theme"
            style={{ cursor: "pointer" }}
            onChange={onChangeSort}
          >
            <option value="none">Default</option>
            <option value="HTL">Price : High to Low</option>
            <option value="LTH">Price : Low to High</option>
          </select>
        </div>
      </Col>
    </Row>
  );
};

export default Filteredoption;
