import Footer from "../../Footer/Footer";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import Navbar from "../../Header/Navbar/Navbar";
import axios from "axios";
import { apiUrl } from "../../../redux/action/action";
import { toast } from "react-toastify";

const ContactUs = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const name = formData.get("name");
    const email = formData.get("email");
    const phone_number = formData.get("phone");
    const message = formData.get("message");

    axios
      .post(apiUrl + "/user/contact_us_api", {
        name,
        phone_number,
        email,
        message,
      })
      .then((res) => {
        toast.success(res.data.message);
        e.target.reset();
      });
  };

  return (
    <>
      <Navbar />
      <Navbarmob />
      <div className="container mt-5">
        <div className="row body-color">
          <div className="col-md-6 pb-md-0 pb-4">
            <div>
              <h6 className="m-0 p-0">
                <b>CONTACT</b>
              </h6>
              <div
                className="mt-1"
                style={{ border: "1px solid #EF7E00", width: "50px" }}
              ></div>
            </div>

            <div className="d-flex mt-3">
              <div>
                <i className="fa fa-building text-theme"></i>
              </div>
              <div className="p-1">
                <h6 className="m-0 p-0 mb-2">
                  <b>Medpack</b>
                </h6>
                <small className="m-0 p-0">
                  Building 281, Zone 81, Street 04, P.O.
                  <br />
                  Box No.4097, New Industrial Area, <br />
                  Doha Qatar.
                </small>
              </div>
            </div>

            <div className="d-flex mt-3">
              <div>
                <i className="fa fa-phone text-theme"></i>
              </div>
              <div className="px-2">
                <small className="m-0 p-0">
                  Tel: +974 4465 7804 / 4466 7158
                </small>
              </div>
            </div>

            <div className="d-flex mt-3">
              <div>
                <i className="fa fa-fax text-theme"></i>
              </div>
              <div className="px-2">
                <small className="m-0 p-0">Fax: +974 4466 9193</small>
              </div>
            </div>

            <div className="d-flex mt-3">
              <div>
                <i className="fa fa-envelope text-theme"></i>
              </div>
              <div className="px-2">
                <small className="m-0 p-0">Email: info@coastalqatar.com</small>
              </div>
            </div>

            <div className="mt-4" style={{ border: "8px solid #F2F2F2" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24674.530959026815!2d51.49555145501435!3d25.251090001441828!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5dc18b88abe4256d!2sCoastal%20Trading%20%26%20Engineering%20Co.W.L.L!5e0!3m2!1sen!2sin!4v1669634821040!5m2!1sen!2sin"
                width="100%"
                height="300"
                className="border"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h6 className="m-0 p-0">
                <b>DROP US A MESSAGE</b>
              </h6>
              <div
                className="mt-1"
                style={{ border: "1px solid #EF7E00", width: "50px" }}
              ></div>
            </div>

            <form className="mt-4" onSubmit={handleSubmit}>
              <label htmlFor="name">
                Name <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                required
                className="form-control"
              />

              <label htmlFor="email" className="mt-3">
                Email<span className="text-danger">*</span>{" "}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                className="form-control"
              />

              <label htmlFor="phone" className="mt-3">
                Phone<span className="text-danger">*</span>{" "}
              </label>
              <input
                type="number"
                id="phone"
                name="phone"
                required
                className="form-control"
              />

              <label htmlFor="message" className="mt-3">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows="4"
              ></textarea>

              <button className="btn bg-theme text-light mt-4 float-right mb-4" type="submit">
                <b>SEND MESSAGE</b>
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
