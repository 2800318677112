import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

import { Link } from "react-router-dom";
import "./Mobileappads.css";
//import images
import mobilepic from "../../../../assets/home/mobile.png";
import playstore from "../../../../assets/home/playstore.jpg";
import appstore from "../../../../assets/home/appstore.jpg";
import axios from "axios";
import { apiUrl } from "../../../../redux/action/action";
import { toast } from "react-toastify";
import CountryLists from "../../SetAddress/SetAddressDesktop/AddAddress/CountryCodes.json";
import { getUserToken, removeUserToken } from "../../../app/userToken";

const Mobileappads = () => {
  const accessToken = getUserToken();
  const [phone, setPhone] = useState(null);

  const SendLink = (e) => {
    e.preventDefault();
    if (phone) {
      if (phone.length === 8) {
        axios
          .post(
            apiUrl + "/user/send_invitation",
            {
              phone_number: phone,
            },
            {
              headers: {
                Authorization: "Token " + accessToken,
              },
            }
          )
          .then((res) => {
            toast.success(res.data.message);
            setPhone(null);
          })
          .catch((err) => {
            const { response } = err;
            const { data } = response;
            const { status, message } = data;
            if (status === "400") {
              toast.warning(message);
              removeUserToken()
            } else {
              toast.error(message);
            }
          });
      } else {
        toast.warning("Enter a valid number");
      }
    } else {
      toast.warning("Enter a number");
    }
  };

  return (
    <>
      <Row className="mt-5" id="mobileappads">
        <Col
          md={7}
          className="p-md-5 p-4 m-0 d-flex flex-column justify-content-center"
        >
          <div id="AppHeading">
            <h1 className="text-light">
              Experience <span className="text-theme">Medpack</span>
            </h1>
            <h1 className="text-light">on mobile</h1>
          </div>
          <div id="store" className="mt-4">
            <Link to="#">
              <img src={playstore} alt="playstore" />
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="#">
              <img src={appstore} alt="appstore" className="mt-md-0 mt-3" />
            </Link>
          </div>
          <div id="sendapplink" className="mt-5">
            {/* form to take mobile number and send link to download app */}
            <div className="input-group mb-3">
              <select
                name="country_code"
                style={{ width: "80px" }}
                defaultValue={"+974"}
              >
                {CountryLists.map((e, i) => {
                  return (
                    <option value={e.dial_code} key={i}>
                      {e.dial_code}
                      {/* ({e.name}) */}
                    </option>
                  );
                })}
              </select>
              <input
                type="number"
                className="form-control text-light"
                name="mobileno"
                placeholder="Enter mobile no to get App Link"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <div className="input-group-append">
                <button className="btn bg-theme text-light" onClick={SendLink}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Col>
        <Col
          md={5}
          className="pt-md-5 d-flex align-items-end justify-content-center overflow-hidden"
        >
          <img
            src={mobilepic}
            alt="mobilepic"
            className="wow animate__animated animate__slideInUp"
          />
        </Col>
      </Row>
    </>
  );
};

export default Mobileappads;
