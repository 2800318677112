import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import react components
import Notfound from "./Section/404/404";
import Auth from "./Section/Auth/Auth";
import Signup from "./Section/Auth/Signup";
import Signin from "./Section/Auth/Signin";
import AppLogin from "./Section/Auth/AppLogin";
import Otp from "./Section/Auth/Otp";
import Home from "./Section/Home/Home";
import Products from "./Section/Products/Products";
import Individualproduct from "./Section/Individualproduct/Individualproduct";
import Cart from "./Section/Cart/Cart";
import SetAddress from "./Section/SetAddress/SetAddress";
import Accounts from "./Section/Accounts/Accounts";
import MyAccount from "./Section/Accounts/MyAccount/MyAccount";
import MyOrders from "./Section/Accounts/MyOrders/MyOrders";
import Orderdetails from "./Section/Accounts/MyOrders/Orderdetails/Orderdetails";
import InvoiceDetailsPage from "./Section/Accounts/MyOrders/Orderdetails/InvoiceDetailsPage";
import MobOptions from "./Section/Accounts/AccountsMob/MobOptions/MobOptions";
import MobMyOrder from "./Section/Accounts/AccountsMob/MobMyOrder/MobMyOrder";
import MobOrderDetails from "./Section/Accounts/AccountsMob/MobOrderDetails/MobOrderDetails";
import AddAddress from "./Section/SetAddress/SetAddressDesktop/AddAddress/AddAddress";
import MobWishlist from "./Section/Accounts/AccountsMob/MobWishlist/MobWishlist";
import Logout from "./Section/Accounts/Logout";
import Wishlistitems from "./Section/Accounts/MyWishlist/Wishlistitems";
import MyPrescription from "./Section/Accounts/MyPrescription/MyPrescription";
import SavedAddress from "./Section/Accounts/SavedAddress/SavedAddress";
import MyInsurance from "./Section/Accounts/MyInsurance/MyInsurance";
import MobSavedAddress from "./Section/Accounts/AccountsMob/MobSavedAddress/MobSavedAddress";
import MobMyPrescriptions from "./Section/Accounts/AccountsMob/MobMyPrescriptions/MobMyPrescriptions";
import MobMyInsurances from "./Section/Accounts/AccountsMob/MobMyInsurances/MobMyInsurances";
import MobSaveForLater from "./Section/Accounts/AccountsMob/MobSaveForLater";
import WishlistDisplay from "./Section/WishlistDisplay/WishlistDisplay";
import Payment from "./Section/Payment/Payment";
import PaymentSuccess from "./Section/Status/Payment";
import OrderSuccess from "./Section/Status/Order";
import Prepaymentloader from "./Section/Status/Prepaymentloader";
import MobSearch from "./Section/MobSearch/MobSearch";
import UploadPrescription from "./Section/UploadPrescription/UploadPrescription";
import PresCheckout from "./Section/UploadPrescription/PresCheckout";
import Order from "./Section/Accounts/MyPrescription/Order/Order";
import PresOrderdetails from "./Section/Accounts/MyPrescription/Order/PresOrderdetails";
import MobPresOrder from "./Section/Accounts/AccountsMob/MobPresOrder/MobPresOrder";
import MobPresOrderDetails from "./Section/Accounts/AccountsMob/MobPresOrder/MobPresOrderDetails";
import MyWallet from "./Section/Accounts/MyWallet/MyWallet";
import MobMyWallet from "./Section/Accounts/AccountsMob/MobMyWallet/MobMyWallet";
import MobNotification from "./Section/Accounts/AccountsMob/MobNotification/MobNotification";
import EditorialPolicy from "./Section/StaticPages/EditorialPolicy";
import ContactUs from "./Section/StaticPages/ContactUs";
import { useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../redux/action/action";
import { getUserToken, removeUserToken } from "./app/userToken";
import FAQ from "./Section/StaticPages/FAQ";
import AboutUs from "./Section/StaticPages/AboutUs";
import PrescriptionSignin from "./Section/Auth/PrescriptionSignin";
import CompareProduct from "./Section/CompareProduct";
import SaveForLater from "./Section/Accounts/SaveForLater";

const Routersfun = () => {
  const accessToken = getUserToken();

  useEffect(() => {
    if (accessToken) {
      axios
        .post(apiUrl + "/user/verify_token_status", {
          token: accessToken,
        })
        .then((res) => {
          if (!res.data.authentication) {
            removeUserToken();
            window.location = "/";
          }
        });
    }
  }, [accessToken]);

  return (
    <>
      {/* routing */}
      <Router>
        <Routes>
          {/* routers for home */}
          <Route path="/" element={<Home />} />

          {/* routers for 404 */}
          <Route path="*" element={<Notfound />} />

          {/* routers for auth */}
          <Route path="/applogin/:token" element={<AppLogin />} />
          <Route path="/auth" element={<Auth />}>
            <Route path="/authlogin" element={<Signin />} />
            <Route path="/authprescrition-login/:id" element={<Signin />} />
            <Route path="/authsign-up" element={<Signup />} />
            <Route path="/authverify-otp" element={<Otp />} />
          </Route>

          {/* routers for category products */}
          <Route path="/product/all" element={<Products />} />
          <Route path="/product/search/:key" element={<Products />} />
          <Route path="/product/category/:id" element={<Products />} />
          <Route
            path="/product/category/:key-:id?subcategory=true"
            element={<Products />}
          />
          <Route path="/product-details/:id" element={<Individualproduct />} />

          {/* routers for cart */}
          <Route path="/cart" element={<Cart />} />
          <Route path="/cart/:type" element={<Cart />} />

          {/* routers for set address */}
          <Route path="/setaddress" element={<SetAddress />} />

          {/* routers for payment */}
          <Route path="/payment" element={<Payment />} />
          <Route path="/wishlist" element={<WishlistDisplay />} />
          <Route path="/upload-prescription" element={<UploadPrescription />} />
          <Route path="/prescription-checkout" element={<PresCheckout />} />

          {/* routers for accounts */}
          <Route path="/account" element={<Accounts />}>
            <Route path="/accountmy-account" element={<MyAccount />} />
            
            <Route path="/accountmy-order" element={<MyOrders />} />
            <Route path="/accountorder-history" element={<MyOrders />} />
            <Route path="/accountmy-wallet" element={<MyWallet />} />
            <Route path="/accountprescription/order/:id" element={<Order />} />
            <Route
              path="/accountprescription/order/details/:id"
              element={<PresOrderdetails />}
            />
            <Route
              path="/accountmy-order/order-details/:id"
              element={<Orderdetails />}
            />
            <Route
              path="/accountmy-order/invoice-page/:id"
              element={<InvoiceDetailsPage />}
            />
            <Route
              path="/accountmy-prescription"
              element={<MyPrescription />}
            />
            <Route path="/accountsaved-address" element={<SavedAddress />} />
            <Route path="/accountsave-for-later" element={<SaveForLater />} />
            <Route path="/accountmy-insurance" element={<MyInsurance />} />
          </Route>
          <Route path="/logout" element={<Logout />} />

          {/* routers for mobile profile */}
          <Route path="/profile" element={<MobOptions />} />
          <Route path="/profile/my-account" element={<MyAccount />} />
          <Route path="/profile/my-order" element={<MobMyOrder />} />
          <Route path="/profile/order-history" element={<MobMyOrder />} />
          <Route
            path="/profile/my-order/order-details/:id"
            element={<MobOrderDetails />}
          />
          <Route
            path="/profile/my-order/invoice-page/:id"
            element={<InvoiceDetailsPage />}
          />
          <Route path="/profile/my-wishlist" element={<MobWishlist />} />
          <Route path="/profile/my-wishlist/:id" element={<Wishlistitems />} />
          <Route path="/profile/saved-address" element={<MobSavedAddress />} />
          <Route
            path="/profile/my-prescription"
            element={<MobMyPrescriptions />}
          />
          <Route path="/profile/my-wallet" element={<MobMyWallet />} />
          <Route
            path="/profile/prescription/order/:id"
            element={<MobPresOrder />}
          />
          <Route
            path="/profile/prescription/order/details/:id"
            element={<MobPresOrderDetails />}
          />
          <Route path="/profile/my-insurance" element={<MobMyInsurances />} />
          <Route path="/profile/save-for-later" element={<MobSaveForLater />} />

          <Route path="/address/add/:type" element={<AddAddress />} />
          <Route path="/user/payment/:id" element={<Prepaymentloader />} />
          <Route path="/status/payment/:status" element={<PaymentSuccess />} />
          <Route path="/status/order" element={<OrderSuccess />} />
          <Route path="/mob/search" element={<MobSearch />} />
          <Route path="/mob/notification" element={<MobNotification />} />

          <Route path="/policies/*" element={<EditorialPolicy />} />
          <Route path="/terms-conditions" element={<EditorialPolicy />} />
          <Route path="/editorial-policy" element={<EditorialPolicy />} />
          <Route
            path="/cancellation-return-and-refund-policy"
            element={<EditorialPolicy />}
          />
          <Route
            path="/intellectual-property-infringement-policy"
            element={<EditorialPolicy />}
          />
          <Route
            path="/grievance-redressal-policy"
            element={<EditorialPolicy />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />

          <Route path="/faq" element={<FAQ />} />

          <Route path="/compare" element={<CompareProduct />} />
        </Routes>
      </Router>
    </>
  );
};

export default Routersfun;
