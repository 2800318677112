import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import "./Footer.css";
import Implinks from "./Importlinks";

import playstore from "../../assets/home/playstore.jpg";
import appstore from "../../assets/home/appstore.jpg";
import axios from "axios";
import { apiUrl } from "../../redux/action/action";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxresponse = useSelector((state) => state);
  const [popularCategories, setpopularCategories] = useState([]);
  const [allSocialLink, setAllSocialLink] = useState([]);
  const [staticPage, setSaticPage] = useState([]);

  useEffect(() => {
    fetchPopularCategories();
    if (window.innerWidth > 768) {
      fetchAllStaticPages();
    }
  }, []);

  const fetchPopularCategories = () => {
    axios
      .get(apiUrl + "/category/popular_categories")
      .then((res) => {
        setpopularCategories(res.data.data);
        fetchAllSocialLink();
      })
      .catch((err) => {
        toast.error(err.toString());
        fetchAllSocialLink();
      });
  };

  const fetchAllSocialLink = () => {
    // const current_time = new Date();
    // const localData = localStorage.getItem("social_links");
    // const social_data = JSON.parse(localData);
    // if (localData && social_data.expiry_time > current_time.getTime()) {
    //   setAllSocialLink(social_data.data);
    // } else {
    axios
      .get(apiUrl + "/master/get_all_social_media_links")
      .then((res) => {
        setAllSocialLink(res.data.data);
        // set expiry time for 5 days
        // const expiry_time = current_time.getTime() + 432000000;
        // const localStore = {
        //   data: res.data.data,
        //   expiry_time: expiry_time,
        // };
        // localStorage.setItem("social_links", JSON.stringify(localStore));
      })
      .catch((err) => {
        toast.error(err.toString());
      });
    // }
  };

  const fetchAllStaticPages = () => {
    axios
      .get(apiUrl + "/master/get_static_page_list")
      .then((res) => {

        let allLinks = [
          ...Implinks,

        ];
        let transfer = allLinks[1];
        allLinks[1] = {
          heading: "Our Policies",
          items: res.data.data.filter(x => x.is_active).map(item => ({ text: item.page_title.search(';') > 0 ? item.page_title.split(';')[1] : item.page_title, url: '/policies/' + (item.page_slug.search('nbsp') > 0 ? item.page_slug.split('nbsp')[1] : item.page_slug) }))
        };
        allLinks.push(transfer);
        setSaticPage(allLinks);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  return (
    <>
      <div className="footer-container mt-5">
        <Container className="p-0">
          {/* {
        "text": "Deals",
        "url": "#"
      },
      {
        "text": "Fitness",
        "url": "#"
      } */}
          <Row className="footer-row">
            <Col className="footer-col d-lg-flex justify-content-lg-around">
              {/* mapping for Implinks variable */}
              {staticPage.map((item, index) => {
                return (
                  <div key={index} className="mx-md-0 mx-5">
                    <h6>{item.heading}</h6>
                    <ul>
                      {item.heading === "Popular Categories"
                        ? popularCategories?.map((e) => {
                          return (
                            <li key={e.id}>
                              <Link
                                onClick={() => {
                                  dispatch({
                                    type: "CLEAR_FILTER_BY_CATEGORY",
                                  });
                                  if (
                                    reduxresponse.filterByCategory.category_id
                                      .length === 1
                                  ) {
                                    navigate("/product/all");
                                  }
                                }}
                                to={`/product/category/${e.name}-${e.id}`}
                              >
                                {e.name}
                              </Link>
                            </li>
                          );
                        })
                        : item.heading === "Social Links"
                          ? allSocialLink.map((e, i) => {
                            return (
                              <li key={i}>
                                <a href={e.link} target="_blank">
                                  {e.name}
                                </a>
                              </li>
                            );
                          })
                          : item.items.map((link, index) => {
                            return (
                              <li key={index}>
                                {link.a ? (
                                  <a href={link.url} target="_blank">
                                    {link.text}
                                  </a>
                                ) : (
                                  <Link to={link.url} target="_blank">
                                    {link.text}
                                  </Link>
                                )}
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                );
              })}
              {/* 
              <div className="mx-md-0 mx-5">
                <h6>{"Social Links"}</h6>
                {allSocialLink.map((link, index) => {
                  return (
                    <li key={index}>
                      <a href={link.url} target="_blank">
                        {link.name}
                      </a>
                    </li>
                  );
                })}
              </div> */}
            </Col>
          </Row>
          <Row className="footer-row mt-5">
            <Col className="footer-col d-lg-flex justify-content-lg-between align-items-lg-center">
              <div className="text-center">
                <p style={{ color: "#ccc" }}>
                  <i className="fa fa-copyright"></i>
                  2022 Medpack. All rights reserved.
                </p>
              </div>

              <div>
                {/* <p className="text-uppercase text-theme text-center">
                  Experience Medpack on mobile
                </p>
                <div>
                  <Link to="#">
                    <img src={playstore} alt="playstore" />
                  </Link>
                  <Link to="#" className="ml-2">
                    <img src={appstore} alt="appstore" />
                  </Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
