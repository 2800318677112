import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import './SideModal.css';

const SideModal = ({
    title,
    setIsOpen,
    show,
    children,
    clearOnClose,
    contentStyle
}) => {
    const navigate = useNavigate();
    if (show) {
        return (
            <div className='sideModal card' onClick={(e) => { e.stopPropagation(); setIsOpen(false) }}>
                <div className="modal-content" style={contentStyle} onClick={(e) => { e.stopPropagation(); setIsOpen(true) }}>
                    <div className='modal-header d-flex'>
                        <div className='menu-title flex-grow-1 title'>{title}</div>
                        <button
                            className='btn text-white fs-2 pb-2 px-0 pt-0'
                            onClick={(e) => { e.stopPropagation(); setIsOpen(false) }}
                        >&times;</button>
                    </div>
                    <div className='form'>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
    return <></>;
}

export default SideModal;