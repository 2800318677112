import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../../redux/action/action";
import { getUserToken, removeUserToken } from "../../app/userToken";
import upload_insu from "../../../assets/upload_insu.png";

const UploadInsuranceCpm = ({ setUploadInsuranceShow }) => {
  const navigate = useNavigate();
  const accessToken = getUserToken();
  const [allInsurance, setAllInsurance] = useState([]);
  const [insuranceList, setInsuranceList] = useState(null);
  const [PlaceholderTXT, setPlaceholderTXT] = useState(
    "Attach File JPEG, PNG or PDF format (Max File Size : 5mb)"
  );
  const [SelectIsurance, setSelectIsurance] = useState(null);
  const [SaveInsuBtnState, setSaveInsuBtnState] = useState(false);
  const [savebtnstateInsu, setSavebtnstateInsu] = useState("Save");
  const [subinsuranceList, setSubInsuranceList] = useState(null);
  const [insurancefile, setInsurancefile] = useState(null);
  const [InsuranceType, setInsuranceType] = useState(null);
  const [SubInsurance, setSubInsurance] = useState(null);
  const [InsurancePremiumNo, setInsurancePremiumNo] = useState(null);
  const [InsuranceExpiryDate, setInsuranceExpiryDate] = useState(null);

  useEffect(() => {
    // axios request for insurance list
    axios
      .get(apiUrl + "/master/get_insurance_list")
      .then((res) => {
        setInsuranceList(res.data.data);
      })
      .catch((err) => {
        toast.error(err.toString());
      });

    //get all isurance
    axios
      .get(apiUrl + "/user/get_insurances?active_list=1", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        setAllInsurance(res.data.data);
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  }, []);

  const handleChangeInputInsurance = (e) => {
    setInsurancefile(e.target.files[0]);
    setPlaceholderTXT(e.target.files[0].name);
  };

  const handleSaveInsurance = () => {
    if (
      insurancefile &&
      InsuranceType &&
      SubInsurance &&
      InsurancePremiumNo &&
      InsuranceExpiryDate
    ) {
      if (insurancefile.size <= 5000000) {
        setSaveInsuBtnState(true);
        setSavebtnstateInsu("Saving...");
        const formData = new FormData();
        formData.append("file", insurancefile);
        formData.append("insurance_id", InsuranceType);
        formData.append("sub_insurance_id", SubInsurance);
        formData.append("premium_no", InsurancePremiumNo);
        formData.append("expiry_date", InsuranceExpiryDate);
        axios
          .post(apiUrl + "/user/save_insurance", formData, {
            headers: {
              Authorization: "Token " + accessToken,
            },
          })
          .then((res) => {
            setSaveInsuBtnState(false);
            if (res.data.status === 400) {
              toast.error(res.data.message);
              setSavebtnstateInsu("Save");
            } else {
              toast.success(res.data.message);
              setInsurancefile("");
              setInsuranceType("");
              setSubInsurance("");
              setInsurancePremiumNo("");
              setInsuranceExpiryDate("");
              setPlaceholderTXT(
                "Attach File JPEG, PNG or PDF format (Max File Size : 3mb)"
              );
              setSavebtnstateInsu("Saved");
              localStorage.setItem(
                "saved_insu_data",
                JSON.stringify(res.data.data)
              );
              setTimeout(() => {
                setSavebtnstateInsu("Save");
                //   navigate("/prescription-checkout");
                window.location = "/prescription-checkout";
              }, 2000);
            }
          })
          .catch((err) => {
            setSaveInsuBtnState(false);
            setSavebtnstateInsu("Save");
            const { response } = err;
            const { data } = response;
            const { status, message } = data;
            if (status === "400") {
              toast.warning(message);
              removeUserToken()
            } else {
              toast.error(message);
            }
          });

      } else {
        toast.warning("Please upload a file less than 5MB");
      }
    } else {
      toast.warning("All fields are required");
    }
  };

  return (
    <div className="upload-insu pb-5">
      <div className="w-100 d-flex flex-column align-items-center pt-4">
        <img src={upload_insu} alt="icon" />
        <h5>Upload Insurance</h5>
      </div>

      <div className="w-100 px-4 mt-3">
        <div className="input-group-cust">
          <label htmlFor="SelectIsurance" className="text-grey">
            Select from my insurance
          </label>
          <select
            name="SelectIsurance"
            id="SelectIsurance"
            className="form-control"
            value={SelectIsurance}
            onChange={(e) => setSelectIsurance(e.target.value)}
          >
            <option value={null}>Select Insurance</option>
            {allInsurance?.map((e) => {
              return (
                <option value={JSON.stringify(e)} key={e.id}>
                  {e.insurance_data.insuranceName}
                </option>
              );
            })}
          </select>
        </div>
        {SelectIsurance ? (
          <div className="w-100 d-flex justify-content-end mt-3">
            <button
              className="btn border-theme text-theme py-1 mr-2"
              onClick={() => setSelectIsurance(null)}
            >
              Back
            </button>
            <button
              className="btn bg-theme text-light py-1"
              onClick={() => {
                localStorage.setItem("saved_insu_data", SelectIsurance);
                window.location = "/prescription-checkout";
                // navigate("/prescription-checkout");
              }}
            >
              Next
            </button>
          </div>
        ) : null}
      </div>
      {SelectIsurance ? null : (
        <>
          <div className="d-flex align-items-center justify-content-center my-2">
            <hr width="30%" />
            <small className="p-0 m-0 mx-3 text-grey">OR</small>
            <hr width="30%" />
          </div>
          <div className="w-100 px-4">
            <div className="w-100 upload-field-box">
              <div className="input-group rounded mb-3 input-group-lg">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-paperclip"></i>
                  </span>
                </div>
                <span className="form-control upload-input-placeholader-txt">
                  <small>{PlaceholderTXT}</small>
                  <input
                    type="file"
                    accept="image/*, .pdf"
                    onChange={handleChangeInputInsurance}
                  />
                </span>
              </div>

              <div className="w-100 d-flex">
                <div className="w-50 mr-1">
                  <div className="input-group-cust">
                    <label htmlFor="InsuranceType" className="text-grey">
                      Insurance Type
                    </label>
                    <select
                      name="InsuranceType"
                      id="InsuranceType"
                      className="form-control"
                      value={InsuranceType}
                      onChange={(e) => {
                        setInsuranceType(e.target.value);
                        // axios request for sub insurance list
                        axios
                          .get(
                            apiUrl +
                            "/master/get_sub_insurance_list?insurance_id=" +
                            e.target.value
                          )
                          .then((res) => {
                            setSubInsuranceList(res.data.data);
                          })
                          .catch((err) => {
                            toast.error(err.toString());
                          });
                      }}
                    >
                      <option value={null}>Select</option>
                      {insuranceList?.map((e) => {
                        return (
                          <option value={e.id}>
                            {e.insuranceName} ({e.insuranceType})
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="input-group-cust mt-2">
                    <label
                      htmlFor="InsurancePremiumNo"
                      className="text-grey full-width-truncate"
                    >
                      Insurance Premium No.
                    </label>
                    <input
                      type="number"
                      name="InsurancePremiumNo"
                      className="form-control"
                      id="InsurancePremiumNo"
                      value={InsurancePremiumNo}
                      onChange={(e) => setInsurancePremiumNo(e.target.value)}
                    />
                  </div>
                </div>

                <div className="w-50 ml-1">
                  <div className="input-group-cust">
                    <label htmlFor="SubInsurance" className="text-grey">
                      Sub Insurance
                    </label>
                    <select
                      name="SubInsurance"
                      className="form-control"
                      id="SubInsurance"
                      value={SubInsurance}
                      onChange={(e) => setSubInsurance(e.target.value)}
                    >
                      <option value={null}>Select</option>
                      {subinsuranceList?.map((e) => {
                        return e.is_active ? (
                          <option value={e.id}>{e.subInsuranceName}</option>
                        ) : null;
                      })}
                    </select>
                  </div>

                  <div className="input-group-cust mt-2">
                    <label
                      htmlFor="InsuranceExpiryDate"
                      className="text-grey full-width-truncate"
                    >
                      Insurance Expiry Date
                    </label>
                    <input
                      type="date"
                      name="InsuranceExpiryDate"
                      className="form-control"
                      id="InsuranceExpiryDate"
                      defaultValue={InsuranceExpiryDate}
                      onChange={(e) => {
                        if (
                          new Date(e.target.value).getTime() >
                          new Date().getTime()
                        ) {
                          setInsuranceExpiryDate(e.target.value);
                        } else {
                          toast.warning("Please select a future date");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 mt-3 py-2 px-3 cart-NB">
                <small className="text-theme">
                  Medicines with insurance will be delivered after verification.
                  Payment will be done after approval.
                </small>
              </div>

              <div className="w-100 d-flex justify-content-center mt-4 upload-action-btns">
                <button
                  className="btn border-btn-theme px-3"
                  onClick={() => {
                    setUploadInsuranceShow(false);
                    navigate("/prescription-checkout");
                    localStorage.removeItem("saved_insu_data");
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-btn-theme px-4"
                  onClick={handleSaveInsurance}
                  disabled={SaveInsuBtnState}
                >
                  {savebtnstateInsu}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default UploadInsuranceCpm;
