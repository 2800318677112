/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../../../../redux/action/action";
import { getUserToken } from "../../../../app/userToken";
import OrderItemCardUp from "../OrderItemCardUp/OrderItemCardUp";
// import Orderitemcard from "../Orderitemcard/Orderitemcard";
import "./Orderdetails.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Orderdetails = () => {
  const accessToken = getUserToken();
  const { id } = useParams();
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState([]);
  const [action, setAction] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);

    try {
      const res = await axios.get(apiUrl + "/order/get_single_order_data/" + id, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      });

      if (res.data.status === 1) {
        setLoader(false);
        setOrderDetailsData(res.data.data);
      }
      else {
        setLoader(false);
      }
    } catch (error) {
      toast.error(error.toString())
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   const date = new Date(orderDetailsData?.orderdate);
  //   let months = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];
  //   const day = date.getDate();
  //   const month = date.getMonth() + 1;
  //   const year = date.getFullYear();

  //   setOrderPlacedDate(day + " " + months[month - 1] + " " + year);
  // });

  // const data = {
  //   status: "shipped",
  //   message: "Shipped - Arriving by Thu, 10 Feb",
  // };

  const handleCancelAllOrder = async () => {
    setShowModal(true);
    await loadReasons();
    setAction("cancel_orders");
  };

  const handleReturnAllOrder = async () => {
    setShowModal(true);
    await loadReasons();
    setAction("return_orders");
  };

  // load reasons
  const loadReasons = async () => {
    const res = await axios.get(apiUrl + "/master/order_cancel_return_reasons");
    setReason(res.data.data);
  };

  // select reason
  const handleSelectedReason = (e) => {
    const selectedVal = e.target.getAttribute("value");
    returnORcancel(selectedVal);
    setShowModal(false);
  };

  // request for return or cancel
  const returnORcancel = (selectedVal) => {
    try {
      axios
        .post(
          apiUrl + "/order/" + action,
          {
            order_id: id,
            reason: selectedVal,
          },
          {
            headers: {
              Authorization: "Token " + accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
          }
        });
    } catch (err) {
      try {
        toast.error(err.response.data.message);
      } catch (e) {
        toast.error(err.toString());
      }
    }
  };

  const setUpdateResponse = () => {
    setOrderDetailsData(null);
    fetchData();
  };

  return (
    <>
      <div className="my-account">
        <div className="my-account-title mt-3 d-flex">
          <div className="w-50">
            <h4 className="p-0 m-0">Order Details</h4>
          </div>
          <div className="w-50 d-flex justify-content-end">
            <div className="order-filter">
              {/* <button>
                <i className="fa fa-file-text-o"></i>
                &nbsp;&nbsp;
                <span>View Precription</span>
              </button> */}
              &nbsp;&nbsp;
              {/* <button>
                <i className="fa fa-headphones"></i>
                &nbsp;&nbsp;
                <span>Help</span>
              </button> */}
            </div>
          </div>
        </div>

        <div className="w-100 approval-status">
          <p className="text-success">
            {/* <i className="fa fa-check-circle-o"></i>
            &nbsp; Prescription Approval Status - Approved */}
          </p>
        </div>

        {loader ? (
          <SkeletonTheme color="#ccc" highlightColor="#fff">
            <Skeleton count={2} height={300} />
          </SkeletonTheme>
        ) : (
          <>
            <OrderItemCardUp
              status={orderDetailsData?.status === "Order Delivered" ? 4 : null}
              orderdetail={true}
              data={orderDetailsData}
              setUpdateResponse={setUpdateResponse}
            />

            <div className="w-100 mt-5 px-md-3">
              <DeliveryAddress data={orderDetailsData?.address_data} />
            </div>
            {orderDetailsData?.payment_data?.amount ? (
              <div className="w-100 py-4 my-4 border-top border-bottom px-md-3">
                <PaymentDetails
                  amount={orderDetailsData?.payment_data?.amount?.value}
                  card_data={orderDetailsData?.payment_data?.details}
                  loyalty_points_amount_credited={
                    orderDetailsData?.details?.loyalty_points_amount_credited
                  }
                  loyalty_points_amount_deducted={
                    orderDetailsData?.details?.loyalty_points_amount_deducted
                  }
                  total_amount={orderDetailsData?.details?.total_original_price}
                  discount_amount={orderDetailsData?.details?.discount_amount}
                />
              </div>
            ) : null}

            <div className="w-100 px-md-3">
              <OrderTracker order_status={orderDetailsData?.order_status} />
            </div>
          </>
        )}
      </div>

      {showModal ? (
        <div id="modal">
          <div id="reason-main">
            <i className="fa fa-close" onClick={() => setShowModal(false)}></i>
            <h4 className="p-0 m-0 mb-4">Select a reason</h4>
            {reason.map((data, i) => {
              return (
                <div
                  className={`reason-list ${i === 0 ? `border-top border-bottom` : `border-bottom`
                    }`}
                  value={data.name}
                  key={i}
                  onClick={handleSelectedReason}
                >
                  <p className="p-0 m-0" value={data.name}>
                    {data.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

const Orderitemsingleproduct = ({ data, orderDetailsData }) => {
  const accessToken = getUserToken();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState([]);
  // const [action, setAction] = useState(null);

  const handleCancelOrder = async () => {
    setShowModal(true);
    await loadReasons();
    // setAction("cancel_orders_item");
  };

  const handleReturnOrder = async () => {
    setShowModal(true);
    await loadReasons();
    // setAction("return_orders_item");
  };

  // load reasons
  const loadReasons = async () => {
    const res = await axios.get(apiUrl + "/master/order_cancel_return_reasons");
    setReason(res.data.data);
  };

  // select reason
  const handleSelectedReason = (e) => {
    const selectedVal = e.target.getAttribute("value");
    returnORcancel(selectedVal);
    setShowModal(false);
  };

  // request for return or cancel
  const returnORcancel = (selectedVal) => {
    axios
      .post(
        `${apiUrl}/order/${data?.id}`,
        {
          order_id: id,
          reason: selectedVal,
        },
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  return (
    <>
      <div className="Orderitemsingleproduct border-top">
        {/* <div className="w-100 d-flex">
          <div>
            <p
              className={
                data.status === "shipped"
                  ? "text-success"
                  : data.status === "prescription-pending"
                  ? "text-warning"
                  : data.status === "delivered"
                  ? "text-success"
                  : "text-danger"
              }
            >
              {data.message}
            </p>
          </div>
          <div className="border-left">
            <p className="text-grey">Shipped to - Ayub Khan</p>
          </div>
        </div> */}
        <div className="w-100 mx-2 mt-3">
          <h6 className="p-0 m-0">{data?.item_name}</h6>
          {/* <h6 className="p-0 m-0 mt-2 text-theme">QAR 226</h6> */}
        </div>

        <div className="w-100 variety d-flex mt-1 text-grey">
          {/* <div>
            <p>Size : 500mg</p>
          </div>
          <div>
            <p>Flavour : Tasty Chocolate</p>
          </div> */}
          <div>
            <p>Quantity : {data?.quantity}</p>
          </div>
        </div>

        <div className="w-100 mt-2 d-flex justify-content-end">
          {orderDetailsData?.allow_return ? (
            <button
              className="btn border-theme text-theme mr-2"
              onClick={handleReturnOrder}
            >
              Return
            </button>
          ) : null}
          {orderDetailsData?.allow_cancel ? (
            <button
              className="btn bg-theme text-light"
              onClick={handleCancelOrder}
            >
              Cancel
            </button>
          ) : null}
        </div>
      </div>

      {showModal ? (
        <div id="modal">
          <div id="reason-main">
            <i className="fa fa-close" onClick={() => setShowModal(false)}></i>
            <h4 className="p-0 m-0 mb-4">Select a reason</h4>
            {reason.map((data, i) => {
              return (
                <div
                  className={`reason-list ${i === 0 ? `border-top border-bottom` : `border-bottom`
                    }`}
                  value={data.name}
                  key={i}
                  onClick={handleSelectedReason}
                >
                  <p className="p-0 m-0" value={data.name}>
                    {data.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export const DeliveryAddress = ({ data }) => {
  return (
    <>
      <div className="DeliveryAddress">
        <h6>Delivery Address</h6>
        <p className="my-2">{data?.name}</p>

        {data ? (
          <>
            <p className="text-grey">
              Address : {data.address} <br />
              Building no : {data.building_number}
              <br />
              Street : {data.street}
              <br />
              Zone : {data.zone}
              <br />
              Unit no : {data.unit_number}
              <br />
              Mobile : {data?.country_code} {data?.phone_number}
            </p>
          </>
        ) : null}
      </div>
    </>
  );
};

export const PaymentDetails = ({
  amount,
  card_data,
  loyalty_points_amount_credited,
  loyalty_points_amount_deducted,
  total_amount,
  discount_amount,
}) => {
  return (
    <>
      <div className="Orderdetails-PaymentDetails">
        <div className="order-summary">
          <h5>Order Summary</h5>
          <div className="price-details-body mt-4">
            <div className="w-100">
              <p className="price-details-subject">Sub Total</p>
              <p className="price-details-price">
                {"QAR"} {total_amount}
              </p>
            </div>
            <div className="w-100">
              <p className="price-details-subject">Loyalty Points Credited</p>
              <p className="price-details-price text-success">
                +{loyalty_points_amount_credited}
              </p>
            </div>
            <div className="w-100">
              <p className="price-details-subject">Loyalty Points Deducted</p>
              <p className="price-details-price text-danger">
                -{loyalty_points_amount_deducted}
              </p>
            </div>
            <div className="w-100">
              <p className="price-details-subject">Discount</p>
              <p className="price-details-price">
                {"QAR"} {discount_amount?.toFixed(2)}
              </p>
            </div>
            <div className="w-100">
              <p className="price-details-subject">Delivery Fee</p>
              <p className="price-details-price">Free</p>
            </div>
            <div className="w-100 totalprice">
              <p>Total Amount</p>
              <p>
                {"QAR"} {amount}
              </p>
            </div>
          </div>
        </div>
        <div className="payment-method">
          <h5>Payment Method</h5>
          <div className="w-100 mt-4">
            <p className="text-grey">
              Paid by {card_data?.cardLabel} Credit Card
            </p>
            <p>{card_data?.cardNumber}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export const OrderTracker = ({ order_status }) => {
  return (
    <>
      <div className="order-tracker mt-4 px-md-0 px-3">
        <h6 className="p-0 m-0 mb-4">Track</h6>

        {order_status?.map((data, index) => {
          return (
            <div className="w-100" key={index}>
              <div className="order-tracker-txt w-100">
                {data?.status === "Order Payment Failed" ||
                  data?.status === "Prescription Order Payment Failed" ||
                  data?.status === "Item Cancel Request Accepted" ||
                  data?.status === "Cancel Request Initiated" ||
                  data?.status === "Cancel Request Accepted" ? (
                  <>
                    <p>
                      <i className="fa fa-check-circle text-danger"></i>
                    </p>
                    <p className="ml-4 text-danger">{data?.status}</p>
                  </>
                ) : (
                  <>
                    <p>
                      <i className="fa fa-check-circle text-success-custom"></i>
                    </p>
                    <p className="ml-4 text-success-custom">{data?.status}</p>
                  </>
                )}
              </div>
              {index + 1 === order_status.length ? (
                <div className="order-tracker-bar">
                  <small>{data?.status_date_api.split(".")[0]}</small>
                </div>
              ) : (
                <div
                  className={`order-tracker-bar ${data?.status === "Order Payment Failed" ||
                    data?.status === "Prescription Order Payment Failed" ||
                    data?.status === "Item Cancel Request Accepted"
                    ? `border-danger border-success-custom`
                    : `border-success-custom`
                    }`}
                >
                  <small>{data?.status_date_api.split(".")[0]}</small>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

// const DateFormat = ({date}) => {
//   const [orderPlacedDate, setOrderPlacedDate] = useState("");
//   const date_ = new Date(date);
//   let months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   const day = date_.getDate();
//   const month = date_.getMonth() + 1;
//   const year = date_.getFullYear();

//   setOrderPlacedDate(day + " " + months[month - 1] + " " + year);
//   return (
//     <>
//       {orderPlacedDate}
//     </>
//   )
// };

export default Orderdetails;
