import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect,useState } from "react";
import $ from "jquery"
import "./CartNavbarMob.css";
import { useNavigate } from "react-router-dom";

import filter_grey_icon from "./../../../assets/icons/filter-grey.png";

const CartNavbarMob = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let [pagetitle, setpagetitle] = useState("");
  let [top_right_txt_class, settop_right_txt_class] = useState("");
  let [top_right_txt, settop_right_txt] = useState("");
  const [myOrderFilterOption, setMyOrderFilterOption] = useState(false);
  // let [dynamic_backurl, setdynamic_backurl] = useState("");
  useEffect(() => {
    $("html").click((e) => {
      if (e.target.id !== "filter_grey_icon") {
        setMyOrderFilterOption(false);
      }
    });
  }, []);

  useEffect(()=>{
    if (location.pathname === "/cart" || location.pathname === "/cart/upload-prescription") {
      setpagetitle("Your Cart");
      // setdynamic_backurl("/");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/setaddress"){
      setpagetitle("Address");
      // setdynamic_backurl("/cart");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/payment"){
      setpagetitle("Payment");
      // setdynamic_backurl("/setaddress");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/profile"){
      setpagetitle("Profile");
      // setdynamic_backurl("/");
      settop_right_txt_class("p-0 m-0 mx-1 text-theme");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/profile/my-account"){
      setpagetitle("My Account");
      // setdynamic_backurl("/profile");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/profile/my-order"){
      setpagetitle("My Orders");
      // setdynamic_backurl("/profile");
      settop_right_txt_class("p-0 m-0 mx-1 text-grey");
      settop_right_txt("Filter");
      $("#filter_grey_icon").removeClass("d-none");
    }
    else if(location.pathname === "/profile/order-details"){
      setpagetitle("Order Details");
      // setdynamic_backurl("/profile/my-order");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/profile/my-wishlist"){
      setpagetitle("My Wishlist");
      // setdynamic_backurl("/profile");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/profile/saved-address"){
      setpagetitle("Saved Address");
      // setdynamic_backurl("/profile");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/profile/my-prescription"){
      setpagetitle("My Prescription");
      // setdynamic_backurl("/profile");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/profile/my-insurance"){
      setpagetitle("My Insurance");
      // setdynamic_backurl("/profile");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/profile/saved-card"){
      setpagetitle("Saved Card");
      // setdynamic_backurl("/profile");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/address/add"){
      setpagetitle("Address");
      // setdynamic_backurl("/setaddress");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
    else if(location.pathname === "/upload-prescription"){
      setpagetitle("Upload Prescription");
      settop_right_txt("");
      $("#filter_grey_icon").addClass("d-none");
    }
  },[]);

  return (
    <>
      <Container fluid className="CartNavbarMob-individual-mob p-0 m-0 border">
        <Row className="m-0 p-0 pt-4 pb-3">
          <Col
            xs="1"
            className="d-flex align-items-center"
          >
            <Link to='#' onClick={() => navigate(-1)}>
              <i
                className="fa fa-arrow-left text-dark ml-2"
                style={{ fontSize: "17px" }}
              ></i>
            </Link>
          </Col>
          <Col xs="6" className="d-flex align-item-center">
            <p className="p-0 m-0 mx-1">{pagetitle}</p>
          </Col>
          <Col
            xs="5"
            className="d-flex justify-content-end align-items-center nav-mob-filter-text-side"
          >
            <img src={filter_grey_icon} alt="filter_grey_icon" className="d-none" id="filter_grey_icon" onClick={()=>setMyOrderFilterOption(!myOrderFilterOption)} />
            <p className={top_right_txt_class}>{top_right_txt}</p>
            {myOrderFilterOption ? (
                <div className="my-order-filter-option animate__animated animate__fadeIn animate__faster">
                  <span>Delivered</span>
                  <span>Cancelled</span>
                  <span>Pending</span>
                  <span>Refunded</span>
                  <span>Last 30 Days</span>
                  <span>Last 2 Year</span>
                  <span>Last 3 Year</span>
                </div>
              ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CartNavbarMob;
