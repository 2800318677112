import { useEffect, useState } from "react";
import "./Addtowishlist.css";
import Wishlist from "./Wishlist";
import axios from "axios";
import { apiUrl } from "../../../../redux/action/action";
import { getUserToken, removeUserToken } from "../../../app/userToken";
import { toast } from "react-toastify";

const Addtowishlist = ({ prdctid, action }) => {
  const accessToken = getUserToken();
  const [wishlists, setwishlists] = useState([]);
  const [createlist, setCreatelist] = useState(false);
  useEffect(async () => {
    try {
      const result = await axios.get(apiUrl + "/wishlist/get_all_wishlist", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      });
      setwishlists(result.data.data);
    }
    catch (err) {
      const { response } = err;
      const { data } = response;
      const { status, message } = data;
      if (status === "400") {
        toast.warning(message);
        removeUserToken()
      } else {
        toast.error(message);
      }
    };
  }, []);

  const randomIconColor = [
    "wishlist-color-a",
    "wishlist-color-b",
    "wishlist-color-c",
    "wishlist-color-d",
    "wishlist-color-e",
  ];

  return (
    <>
      <div className="w-100 Addtowishlist mb-4">
        <div className="w-100 text-center">
          <h5 className="p-0 m-0">Add to Wishlist</h5>
        </div>
        <div className="w-100 px-md-3 mt-4">
          {wishlists.map((data, index) => {
            return (
              <Wishlist
                key={index}
                iconcolor={
                  randomIconColor[
                  Math.floor(Math.random() * randomIconColor.length)
                  ]
                }
                title={data.name}
                listid={data.id}
                totalitem={data.product_list.length}
                prdctid={prdctid}
                action={action}
                editable={false}
              />
            );
          })}
          {createlist ? (
            <Wishlist
              title="New List"
              iconcolor="wishlist-color-a"
              totalitem="0"
              prdctid={prdctid}
              action={action}
              editable={true}
            />
          ) : null}
        </div>
        {action === "save_for_later" ? null : (
          <div className="w-100 px-4 mt-4">
            <button
              className="btn w-100 border-dark"
              onClick={() => setCreatelist(true)}
            >
              Create New Collection
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default Addtowishlist;
