import axios from "axios";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../../../../redux/action/action";
import { getUserToken } from "../../../../app/userToken";
import {
  DeliveryAddress,
  PaymentDetails,
  OrderTracker,
} from "../../MyOrders/Orderdetails/Orderdetails";

const PresOrderdetails = () => {
  const accessToken = getUserToken();
  const { id } = useParams();
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [orderPlacedDate, setOrderPlacedDate] = useState("");
  const [ApproveCartTxt, setApproveCartTxt] = useState("Approve Cart");
  const [displayApproveBtn, setDisplayApproveBtn] = useState(true);

  useEffect(() => {
    axios
      .get(apiUrl + "/order/get_single_prescription_order_data/" + id, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        setOrderDetailsData(res.data.data);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  }, []);

  useEffect(() => {
    const date = new Date(orderDetailsData?.orderdate);
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    setOrderPlacedDate(day + " " + months[month - 1] + " " + year);

    // show approval btn
    {
      orderDetailsData?.order_status.map((e) => {
        return e.status === "Order Placed Successfully"
          ? setDisplayApproveBtn(false)
          : setDisplayApproveBtn(true);
      });
    }
  }, [orderDetailsData]);

  return (
    <div className="my-account">
      <div className="my-account-title mb-3 d-flex">
        <div className="w-50">
          <h4 className="p-0 m-0">Order Details</h4>
        </div>
        <div className="w-50 d-flex justify-content-end">
          <div className="order-filter">
            {/* <button>
              <i className="fa fa-file-text-o"></i>
              &nbsp;&nbsp;
              <span>View Precription</span>
            </button> */}
            &nbsp;&nbsp;
            <button>
              <i className="fa fa-headphones"></i>
              &nbsp;&nbsp;
              <span>Help</span>
            </button>
          </div>
        </div>
      </div>

      <div className="w-100 approval-status">
        <p className="text-success">
          {/* <i className="fa fa-check-circle-o"></i>
          &nbsp; Prescription Approval Status - Approved */}
        </p>
      </div>

      <div className="w-100">
        <div className="w-100 border rounded overflow-hidden">
          <div className="Orderitemcard-header">
            <div className="Orderitemcard-header-left">
              <div>
                {/* order date */}
                <p>Order Placed - {orderPlacedDate}</p>
              </div>
              <div className="border-left">
                {/* order price */}
                <p>
                  Total Order Amount - QAR {orderDetailsData?.total_cart_amount}
                </p>
              </div>
            </div>

            <div className="Orderitemcard-header-right">
              <div>
                {/* order id */}
                <p>Id : # {orderDetailsData?.id}</p>
              </div>
              <div className="border-left">
                {/* download invoice */}
                <button disabled>Download Invoice</button>
              </div>
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Item name</th>
                <th>Net Amount</th>
                <th>Quantity</th>
                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              {orderDetailsData?.product_data.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data?.item_name}</td>
                    <td>QAR {data?.itemNetAmount}</td>
                    <td>{data?.quantity}</td>
                    <td>{data?.unit}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <div className="w-100 mt-5">
        <DeliveryAddress data={orderDetailsData?.address_data} />
      </div>
      <div className="w-100 py-4 my-4 border-top border-bottom">
        {displayApproveBtn ? (
          <button
            className="btn bg-theme text-light"
            onClick={() => {
              setApproveCartTxt("Approving...");
              axios
                .post(
                  apiUrl + "/order/approve_prescription_orders",
                  {
                    order_id: orderDetailsData?.id,
                    method: "creditcard",
                  },
                  {
                    headers: {
                      Authorization: "Token " + accessToken,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.status === 200) {
                    window.location.assign(res.data.data._links.checkout.href);
                  } else {
                    toast.warning(res.data.message);
                    setApproveCartTxt("Approve");
                  }
                })
                .catch((err) => {
                  toast.error(err.toString());
                });
            }}
          >
            {ApproveCartTxt}
          </button>
        ) : (
          <PaymentDetails
            amount={orderDetailsData?.payment_data_json?.amount}
            card_data={orderDetailsData?.payment_data_json?.details}
          />
        )}
      </div>
      <div className="w-100">
        <OrderTracker order_status={orderDetailsData?.order_status} />
      </div>
    </div>
  );
};

export default PresOrderdetails;
