import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { apiUrl } from "../../../../../redux/action/action";
import { getUserToken, removeUserToken } from "../../../../app/userToken";
import Navbarmob from "../../../../Header/NavbarMob/Navbarmob";
import { useSelector } from "react-redux";
import Bottommenumob from "../../../../Footer/Bottommenumob/Bottommenumob";

const MobMyWallet = () => {
  const accessToken = getUserToken();
  const [loyalty, setLoyalty] = useState(null);
  const reduxuserdata = useSelector((state) => state.userReducer);

  useEffect(() => {
    axios
      .get(apiUrl + "/user/get_loyalty_points", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        setLoyalty(res.data.data);
      })
      .catch((err) => {
        const { status, message } = err.response.data.data;
        if (status === "400") {
          toast.error(message);
          window.location = "/";
          removeUserToken();
        } else {
          toast.error(message);
        }
      });
  }, []);

  return (
    <>
      <Navbarmob />
      <Container>
        <Row>
          <Col className="pt-3 body-color">
            <div className="card rounded p-4">
              <h6>
                Available Balance :{" "}
                <b>
                  {reduxuserdata?.userdata?.total_loyalty_points
                    ? reduxuserdata?.userdata?.total_loyalty_points
                    : 0}
                </b>
              </h6>
              {/* <div className="card mt-3 p-2 rounded-lg">
                <p className="p-0 m-0">
                  <b>Coins Expiry :</b> Coins are valid for 1 year from the date
                  they are credited on.
                </p>
              </div> */}
              {/* <div className="w-100 mt-3 d-flex justify-content-between">
                <p>Recent Coin Activity</p>
                <p>Coins</p>
              </div> */}
              {loyalty?.length > 0 ? (
                <>
                  <div className="w-100 mt-3 d-flex justify-content-between">
                    <p>Recent Coin Activity</p>
                    <p>Coins</p>
                  </div>
                  {loyalty?.map((e, i) => {
                    return (
                      <div
                        className="w-100 border-top py-2 d-flex justify-content-between"
                        key={i}
                      >
                        <small>
                          {" "}
                          <b>{e.loyalty_point_reason}</b> <br />
                          <span className="text-grey">
                            {e.transaction_type === "credit"
                              ? "Credited on "
                              : "Debited on "}
                            {new Date(e.created_on).toLocaleDateString(
                              "en-us",
                              {
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </span>
                          <br />
                          {e.transaction_type === "credit" ? (
                            <span className="text-grey">
                              {new Date(e.expiry_date).getTime() <
                                new Date().getTime()
                                ? "Expired "
                                : "Expire "}
                              on {e.expiry_date}
                            </span>
                          ) : null}
                        </small>
                        <small
                          className={
                            e.transaction_type === "credit"
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {e.transaction_type === "credit" ? "+" : "-"}
                          {e.points}
                        </small>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="w-100 text-center">
                  <h6 className="my-5 text-theme">
                    You haven't received any Coins yet !!!
                  </h6>
                </div>
              )}

              {/* <div className="w-100 border-top py-2 d-flex justify-content-between">
            <small>
              {" "}
              <b>Mi Basic Wired Headset</b> <br />
              <span className="text-grey">Credited on 05 Jan 2022</span>
            </small>
            <small className="text-success">+12</small>
          </div> */}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="py-3 mt-4" />
      <Bottommenumob />
    </>
  );
};
export default MobMyWallet;
