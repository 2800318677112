import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Navbar.css";
import axios from "axios";
import {
  apiUrl,
  popSelectedSubCategoryList,
  pushSelectedSubCategoryList,
  resetSubCategoryList,
  setAllCategoryList,
} from "../../../redux/action/action";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
// import Drawer from 'react-modern-drawer';
// import 'react-modern-drawer/dist/index.css'
import {
  CURRENT_CITY,
  USER_DATA,
} from "../../../redux/state/state";
import { toast } from "react-toastify";
import Geocode from "react-geocode";
import { getUserToken, removeUserToken } from "../../app/userToken";
import { getUserDetails } from "../../app/userDetails";

Geocode.setApiKey("AIzaSyB-i8_omurLDIXbP2yI1v0Ry5MtHtd-FyU");
Geocode.enableDebug();

const ShopByCategoriesMob = () => {
  const accessToken = getUserToken();
  const [state, setState] = useState({});
  const navigate = useNavigate();
  const reduxuserdata = useSelector((state) => state.userReducer);
  const reduxMenuData = useSelector((state) => state.menuReducer);

  let userName;
  if (reduxuserdata.userdata?.name) {
    userName = reduxuserdata.userdata.name;
  }
  const reduxresponse = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showCategoryDropDown, setshowCategoryDropDown] = useState(false);

  useEffect(() => {
    // get user data
    if (accessToken && window.innerWidth < 768) {
      axios
        .get(apiUrl + "/user/customerDetails", {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          dispatch({
            type: USER_DATA,
            payload: getUserDetails(res.data.encrypted),
          });
        }).catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            toast.error(message);
            window.location = "/";
            removeUserToken();
          } else {
            toast.error(message);
          }
        });
    }
    // // //
    navigator.geolocation.getCurrentPosition(
      (position) => {
        fetchAddress(position.coords.latitude, position.coords.longitude);
      },
      (err) => {
        // toast.error(err.message);
      },
      { enableHighAccuracy: true }
    );
  }, []);

  const fetchAddress = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        let addressjson = {};
        const address_components = response.results[0].address_components;
        address_components.forEach((element) => {
          addressjson[element.types[0]] = element.long_name;
        });

        //mohalla - political
        //premise + neighbour hood (opt) - hosuse no
        dispatch({
          type: CURRENT_CITY,
          payload: addressjson?.locality,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };


  useEffect(() => {
    if (window.innerWidth < 768) {
      axios
        .get(apiUrl + "/category/get_category_tree")
        .then((res) => {
          dispatch(setAllCategoryList(res.data.data));
        })
        .catch((err) => {
          toast.error(err.toString());
        });
    }
  }, []);

  // useEffect(() => {
  //   dispatch(resetSubCategoryList());
  // }, [window.location.href]);

  const searchOninput = async (e) => {
    const val = e.target.value;
    sessionStorage.setItem("searchInputKey", e.target.value);

    dispatch({
      type: "CLEAR_FILTER_BY_CATEGORY",
    });
    if (reduxresponse.filterByCategory.category_id.length === 1) {
      navigate("/product/all");
    }
    // clear all brand filter
    dispatch({
      type: "CLEAR_FILTER_BY_BRAND",
    });
    // clear all product form filter
    dispatch({
      type: "CLEAR_FILTER_BY_PRODUCT_FORM",
    });
  };


  return (
    <>
      <ul className="navbar-nav navbar-mega-nav pe-lg-2 me-lg-2 mobile-categories">
        <li
          className="nav-item dropdown"
          style={{ position: "relative", zIndex: "99" }}
        // onClick={(e) => { e.stopPropagation(); setshowCategoryDropDown(prevState => !prevState) }}
        >
          <button
            className={
              "btn bg-theme navigation-link dropdown-toggle ps-lg-0" +
              " " +
              `${showCategoryDropDown ? "nav-link-mob" : "drop-down-hide"}`
            }
            // to="#"
            data-bs-toggle="dropdown"
            style={{ display: "inline-block" }}
            onClick={(e) => {
              setshowCategoryDropDown((prevState) => !prevState);
            }}
          >
            Shop By Category
          </button>
          {showCategoryDropDown && reduxMenuData.subMenuList.length > 0 ? (
            <>
              <div className="dropdown-menu-category px-2 shadow w-100">
                <div>
                  <div
                    className="menu-header d-flex felx-row pt-2 align-items-center"
                    style={{ width: "290px" }}
                  >
                    {reduxMenuData.subMenuList.length > 1 && (
                      <>
                        <button
                          className="btn pl-0"
                          onClick={() => dispatch(popSelectedSubCategoryList())}
                        >
                          <h6>
                            <i className="fa fa-arrow-left" />
                          </h6>
                        </button>
                        <h6 className="flex-grow-1">
                          {
                            reduxMenuData.subMenuList[
                              reduxMenuData.subMenuList.length - 1
                            ][0].name
                          }
                          &nbsp; (
                          {
                            reduxMenuData.subMenuList[
                              reduxMenuData.subMenuList.length - 1
                            ][0].sub_categories.length
                          }
                          )
                        </h6>
                        <div class="dropdown-divider"></div>
                      </>
                    )}
                  </div>
                  {reduxMenuData.subMenuList?.length > 0 &&
                    reduxMenuData.subMenuList[
                      reduxMenuData.subMenuList.length - 1
                    ][0].sub_categories?.map((data, index) => {
                      return (
                        <li class="dropdown-item" href="#">
                          <div
                            className={
                              "menu-item d-flex flex-row align-items-center"
                            }
                            // style={{ width: '290px' }}
                            key={index}
                          >
                            <Link
                              to={
                                reduxMenuData.subMenuList?.length > 1
                                  ? `/product/category/${data.name}-${data.id}?subcategory=true`
                                  : `/product/category/${data.name}-${data.id}`
                              }
                              className="d-flex flex-row align-items-center  flex-grow-1"
                              onClick={() => {
                                setshowCategoryDropDown(false);
                                dispatch(resetSubCategoryList());
                              }}
                            >
                              <h6 className="text-capitalize text-center mt-2 menu-item-name">
                                {data.name}
                              </h6>
                            </Link>
                            {data.sub_categories.length > 0 && (
                              <button
                                className="btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(pushSelectedSubCategoryList(data));
                                }}
                              >
                                <h6 style={{ paddingRight: "10px" }}>{">"}</h6>
                              </button>
                            )}
                          </div>
                        </li>
                      );
                    })}
                </div>
              </div>
            </>
          ) : null}
        </li>
      </ul>
    </>
  );
};

export default ShopByCategoriesMob;
