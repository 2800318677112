import React from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import loginimg from "../../../assets/login.png";
import signupimg from "../../../assets/signup.png";
import otpimg from "../../../assets/otp.png";
import "./css/Style.css";

import './AuthCarousel.css';

const Authcarousel = ({ images }) => {
  return (
    <>
      <Row className="body-color m-0 p-0">
        <Col className="px-0 pb-3 auth-carousel mx-auto" xs="9">
          <Carousel
            variant="dark"
            fade
            slideshowspeed="1000"
            prevIcon={false}
            nextIcon={false}
          >
            {images.map(image =>
              <Carousel.Item>
                <img src={'https://be.medpack.qa' + image.image} alt="loginimg" style={{ width: "100%", minHeight: '100px', maxHeight: '257px' }} />
              </Carousel.Item>
            )}
          </Carousel>
        </Col>
      </Row>
    </>
  );
};

export default Authcarousel;
