import React from "react";
import axios from "axios";
import { apiUrl } from "../../../../../redux/action/action";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import "./filter.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ADD_FILTER_BY_CATEGORY,
  ADD_FILTER_BY_BRAND,
  ADD_FILTER_BY_PRODUCT_FORM,
  IS_PRESCRIPTION,
  IS_NOT_PRESCRIPTION,
} from "../../../../../redux/state/state";

const Filter = ({ showMobilter }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxresponse = useSelector((state) => state);
  const [CATEGORY, SETCATEGORY] = useState([]);
  const [BRANDS, SETBRANDS] = useState([]);
  const [PRODUCT_FORM, SETPRODUCT_FORM] = useState([]);
  const [activeCategoryBtn, setActiveCategoryBtn] = useState("1");
  const [checkboxstate, setCheckboxstate] = useState(false);
  const [ApplyTXT, setApplyTXT] = useState("Apply");

  useEffect(() => {
    setCheckboxstate(false);
  }, [reduxresponse.reducer]);

  useEffect(() => {
    if (window.innerWidth < 768)
      categorydata();
  }, []);

  const categorydata = () => {
    axios
      .get(apiUrl + "/category/get_category_tree")
      .then((e) => {
        SETCATEGORY(e.data.data);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };
  const brandname = () => {
    axios
      .get(apiUrl + "/category/brand")
      .then((e) => {
        SETBRANDS(e.data.data);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };
  const productForm = () => {
    axios
      .get(apiUrl + "/category/product_form")
      .then((e) => {
        SETPRODUCT_FORM(e.data.data);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  const onClickCatBtn = (e) => {
    const index = e.target.getAttribute("index");
    setActiveCategoryBtn(index);
    if (index === "0") {
      SETCATEGORY([]);
      SETBRANDS([]);
      SETPRODUCT_FORM([]);
    } else if (index === "1") {
      categorydata();
      SETBRANDS([]);
      SETPRODUCT_FORM([]);
    } else if (index === "2") {
      brandname();
      SETCATEGORY([]);
      SETPRODUCT_FORM([]);
    } else {
      productForm();
      SETCATEGORY([]);
      SETBRANDS([]);
    }
  };

  const onclick_CATEGORY = (e) => {
    navigate("/product/all");
    setCheckboxstate(true);
    const data = {
      category_id: e.target.getAttribute("categoryid"),
      category_name: e.target.value,
    };

    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      dispatch({
        type: ADD_FILTER_BY_CATEGORY,
        payload: data,
      });
    } else {
      dispatch({
        type: "DELETE_FILTER_BY_CATEGORY",
        payload: data,
      });

      if (reduxresponse.filterByCategory.category_id.length === 1) {
        navigate("/product/all");
      }
    }
  };

  const onclick_BRANDS = (e) => {
    navigate("/product/all");
    setCheckboxstate(true);
    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      dispatch({
        type: ADD_FILTER_BY_BRAND,
        payload: e.target.value,
      });
    } else {
      dispatch({
        type: "DELETE_FILTER_BY_BRAND",
        payload: e.target.value,
      });
    }
  };

  const onclick_PRODUCT_FORM = (e) => {
    navigate("/product/all");
    setCheckboxstate(true);
    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      dispatch({
        type: ADD_FILTER_BY_PRODUCT_FORM,
        payload: e.target.value,
      });
    } else {
      dispatch({
        type: "DELETE_FILTER_BY_PRODUCT_FORM",
        payload: e.target.value,
      });
    }
  };

  const clear_all_filter = (e) => {
    // clear all category filter
    dispatch({
      type: "CLEAR_FILTER_BY_CATEGORY",
    });
    if (reduxresponse.filterByCategory.category_id.length === 1) {
      navigate("/product/all");
    }
    // clear all brand filter
    dispatch({
      type: "CLEAR_FILTER_BY_BRAND",
    });
    // clear all product form filter
    dispatch({
      type: "CLEAR_FILTER_BY_PRODUCT_FORM",
    });
    // clear all age group filter
    // dispatch({
    //   type: "CLEAR_FILTER_BY_AGE_GROUP",
    // });
  };

  const onclick_is_pres = (e) => {
    navigate("/product/all");
    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      dispatch({
        type: IS_PRESCRIPTION,
        payload: true,
      });
    } else {
      dispatch({
        type: IS_PRESCRIPTION,
        payload: false,
      });
    }
  };

  const onclick_is_not_pres = (e) => {
    navigate("/product/all");
    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      dispatch({
        type: IS_NOT_PRESCRIPTION,
        payload: true,
      });
    } else {
      dispatch({
        type: IS_NOT_PRESCRIPTION,
        payload: false,
      });
    }
  };

  const [selectedCatId, setSelectcatId] = useState();

  const CategoryTree = ({ item, selectedCatId, setSelectcatId }) => {
    const [showSubCat, setShowSubCat] = useState(selectedCatId === item?.id);

    const onclick_SUB_CATEGORY = (e) => {
      navigate("/product/all");
      setCheckboxstate(true);
      // setShowSubCat(true);
      const data = {
        sub_category_id: e.target.getAttribute("subcategoryid"),
        sub_category_name: e.target.value,
      };
      // check this element is checked or not
      let ischecked = e.target.checked;
      if (ischecked) {
        dispatch({
          type: ADD_FILTER_BY_CATEGORY,
          payload: data,
        });
      } else {
        dispatch({
          type: "DELETE_FILTER_BY_CATEGORY",
          payload: data,
        });

        if (reduxresponse.filterByCategory.sub_category_id.length === 1) {
          navigate("/product/all");
        }
      }
    };

    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div
            className="form-check"
            onClick={() =>
              setSelectcatId(selectedCatId === item?.id ? null : item.id)
            }
          >
            <input
              disabled={checkboxstate}
              className="form-check-input"
              type="checkbox"
              value={item.name}
              id={item.id + "CATEGORY_"}
              categoryid={item.id}
              onClick={onclick_CATEGORY}
              readOnly
              checked={
                reduxresponse.filterByCategory.category_name.indexOf(
                  item.name
                ) >= 0
              }
            />
            <label
              className="form-check-label text-grey text-capitalize"
              htmlFor={item.id + "CATEGORY_"}
            >
              {item.name}
            </label>
          </div>
          <i
            className={`fa ${showSubCat ? `fa-minus` : `fa-plus`}`}
            onClick={() =>
              setSelectcatId(selectedCatId === item?.id ? null : item.id)
            }
          ></i>
        </div>
        {showSubCat ? (
          <div>
            {item?.sub_categories.map((e, i) => {
              return (
                <div className="form-check ml-3" key={i}>
                  <input
                    disabled={checkboxstate}
                    className="form-check-input"
                    type="checkbox"
                    value={e.name}
                    id={e.id + "_SUB_CATEGORY"}
                    subcategoryid={e.id}
                    onClick={onclick_SUB_CATEGORY}
                    readOnly
                    checked={
                      reduxresponse.filterByCategory.sub_category_id.indexOf(
                        e.id.toString()
                      ) >= 0
                    }
                  />
                  <label
                    className="form-check-label text-grey text-capitalize"
                    htmlFor={e.id + "_SUB_CATEGORY"}
                  >
                    {e.name}
                  </label>
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className="container-custm p-0 m-0">
        <div className="left-box d-flex flex-column">
          <button
            className={`btn ${activeCategoryBtn === "0" ? `focus-click-btn` : null
              }`}
            index="0"
            onClick={onClickCatBtn}
          >
            Prescription
          </button>
          <button
            className={`btn ${activeCategoryBtn === "1" ? `focus-click-btn` : null
              }`}
            index="1"
            onClick={onClickCatBtn}
          >
            Category
            <small>{reduxresponse.filterByCategory.length}</small>
          </button>
          <button
            className={`btn ${activeCategoryBtn === "2" ? `focus-click-btn` : null
              }`}
            index="2"
            onClick={onClickCatBtn}
          >
            Brandname
            <small>{reduxresponse.filterByBrand.length}</small>
          </button>
          <button
            className={`btn ${activeCategoryBtn === "3" ? `focus-click-btn` : null
              }`}
            index="3"
            onClick={onClickCatBtn}
          >
            ProductForm
            <small>{reduxresponse.filterByProductForm.length}</small>
          </button>
        </div>

        <div className="right-box">
          {activeCategoryBtn === "0" ? (
            <>
              <div className="d-flex align-items-center justify-content-between mt-1 mb-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="with_pres"
                    onClick={onclick_is_pres}
                  />
                  <label
                    className="form-check-label text-grey text-capitalize"
                    htmlFor={"with_pres"}
                  >
                    with prescription
                  </label>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="with_pres"
                    onClick={onclick_is_not_pres}
                  />
                  <label
                    className="form-check-label text-grey text-capitalize"
                    htmlFor={"with_pres"}
                  >
                    Without prescription
                  </label>
                </div>
              </div>
            </>
          ) : null}

          {CATEGORY.length > 0
            ? CATEGORY.map((e, i) => {
              return (
                <CategoryTree
                  item={e}
                  selectedCatId={selectedCatId}
                  setSelectcatId={setSelectcatId}
                  key={i}
                />
              );
            })
            : null}

          {BRANDS.length > 0
            ? BRANDS.map((item, index) => {
              return (
                <div className="form-check" key={index}>
                  <input
                    disabled={checkboxstate}
                    className="form-check-input"
                    type="checkbox"
                    value={item.manufacturer}
                    id={index + "BRANDS"}
                    onClick={onclick_BRANDS}
                    readOnly
                    checked={
                      reduxresponse.filterByBrand.brand.indexOf(
                        item.manufacturer
                      ) >= 0
                    }
                  />
                  <label
                    className="form-check-label text-grey text-capitalize"
                    htmlFor={index + "BRANDS"}
                  >
                    {item.manufacturer} ({item.products_count})
                  </label>
                </div>
              );
            })
            : null}

          {PRODUCT_FORM.length > 0
            ? PRODUCT_FORM.map((item, index) => {
              return (
                <div key={index} className="form-check">
                  <input
                    disabled={checkboxstate}
                    className="form-check-input"
                    type="checkbox"
                    value={item.product_form}
                    id={index + "PRODUCT_FORM"}
                    onClick={onclick_PRODUCT_FORM}
                    readOnly
                    checked={
                      reduxresponse.filterByProductForm.productForm.indexOf(
                        item.product_form
                      ) >= 0
                    }
                  />
                  <label
                    className="form-check-label text-grey text-capitalize"
                    htmlFor={index + "PRODUCT_FORM"}
                  >
                    {item.product_form} ({item.products_count})
                  </label>
                </div>
              );
            })
            : null}
        </div>
      </div>
      <div className="button-box">
        <button
          className="btn border-theme text-theme"
          onClick={clear_all_filter}
        >
          Clear all filter
        </button>
        <button
          className="btn bg-theme text-light"
          onClick={() => {
            setApplyTXT("Applying...");
            setTimeout(() => {
              showMobilter(false);
              setApplyTXT("Apply");
            }, 1000);
          }}
        >
          {ApplyTXT}
        </button>
      </div>
    </>
  );
};
export default Filter;
