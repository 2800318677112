import "./PaymentOption.css";
import mastercard from "../../../../assets/icons/Mastercard-logo.png";
import AddCreditDebitCard from "./AddCreditDebitCard/AddCreditDebitCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../../redux/action/action";
import { useDispatch } from "react-redux";
import {
  CARD_SAVED,
  CARD_WITHOUT_SAVED,
  PAY_ON_DELIVERY,
} from "../../../../redux/state/state";
import { getUserToken, removeUserToken } from "../../../app/userToken";
import { toast } from "react-toastify";

const PaymentOption = () => {
  const accessToken = getUserToken();
  const [savedCard, setSavedCard] = useState([]);
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      const res = await axios.get(apiUrl + "/user/add_card", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      });

      setSavedCard(res.data.data);
    } catch (err) {
      const { response } = err;
      const { data } = response;
      const { status, message } = data;
      if (status === "400") {
        toast.warning(message);
        removeUserToken()
      } else {
        toast.error(message);
      }
    }
  }, []);

  const onClickPaymentMethod = (e) => {
    const val = e.target.value;
    if (val === "w-o-s-c") {
      dispatch({
        type: CARD_WITHOUT_SAVED,
        payload: "creditcard",
      });
    } else if (val === "apple-pay") {
      dispatch({
        type: CARD_WITHOUT_SAVED,
        payload: "applepay",
      });
    } else {
      const data = {
        method: "creditcard",
        cardname: e.target.getAttribute("cardname"),
      };
      dispatch({
        type: CARD_SAVED,
        payload: data,
      });
    }
  };

  return (
    <>
      <form>
        {/* Saved Payment Methods */}
        {/* <div className="w-100 float-left mt-5">
        <h5>Saved Credit Card</h5>
        {savedCard.length !== 0 ? (
          <>
            {savedCard.map((data, index) => {
              return (
                <div className="w-100 mt-3 p-3 border shadow-sm" key={index}>
                  <input
                    type="radio"
                    name="payment-method"
                    className="mr-2"
                    value={data}
                    id={data}
                    cardname={data}
                    onClick={onClickPaymentMethod}
                  />
                  <label htmlFor={data} className="m-0 p-0">
                    {data}
                  </label>
                </div>
              );
            })}
          </>
        ) : <small className="text-theme">No card saved</small>}
      </div> */}

        {/* Other Payment Methods */}
        <div className="w-100 float-left mt-4 pt-4 other-payment-methods pb-3">
          <h5>Payment Methods</h5>
          <div className="mt-4 pb-2 d-flex align-items-center justify-content-start">
            <input
              type="radio"
              name="payment-method"
              className="mr-3 checkBox"
              id="with-out-saved-card"
              value="w-o-s-c"
              checked
              onClick={onClickPaymentMethod}
            />
            <label htmlFor="with-out-saved-card" className="p-0 m-0">
              <p className="p-0 m-0">
                <i className="fa fa-credit-card text-grey"></i>
                &nbsp;&nbsp;Credit Card Without Saving{" "}
                <span className="text-grey">(By Default)</span>
                {/*<span className="text-grey">(By default)</span>*/}
              </p>
            </label>
          </div>
{/* 
          <div className="mt-4 pb-2 d-flex align-items-center justify-content-start">
            <input
              type="radio"
              name="payment-method"
              className="mr-3 checkBox"
              id="apple-pay"
              value="apple-pay"
              onClick={onClickPaymentMethod}
            />
            <label htmlFor="apple-pay" className="p-0 m-0">
              <p className="p-0 m-0">
                <i className="fa fa-apple text-grey"></i>
                &nbsp;&nbsp;Apple pay
              </p>
            </label>
          </div> */}







          {/* <div className="mt-2 pb-3 d-flex align-items-center justify-content-start border-bottom"> */}
          {/* <input
            type="radio"
            name="payment-method"
            className="mr-3"
            id="pay-on-delivery"
            value="pod"
            onClick={onClickPaymentMethod}
          />
          <label htmlFor="pay-on-delivery" className="p-0 m-0">
            <p className="p-0 m-0">
              <i className="fa fa-truck text-grey"></i>
              &nbsp;&nbsp; Pay On Delivery{" "}
            </p>
          </label>
        </div> */}

          {/* add credit card / debit card */}
          {/* <AddCreditDebitCard /> */}

          {/* <div className="border-top mt-4 pt-3">
          <p>
            <i className="fa fa-bank text-grey"></i>
            &nbsp;&nbsp; Net Banking
          </p>
        </div> */}
        </div>
      </form>
    </>
  );
};

export default PaymentOption;
