import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../../redux/action/action";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserToken, removeUserToken } from "../../../app/userToken";

const MyWishlist = () => {
  const accessToken = getUserToken();
  const navigate = useNavigate();
  const [wishlists, setwishlists] = useState([]);
  const [showCreateWishlis, setShowCreateWishlis] = useState(false);
  const [createWishlistName, setCreateWishlistName] = useState(null);
  const [showEditPop, setShowEditPop] = useState(false);
  const [listIdRename, setListIdRename] = useState();
  const [listNameRename, setListNameRename] = useState("");

  useEffect(() => {
    fetchallwishlist();
  }, []);

  const fetchallwishlist = async () => {
    try {
      const result = await axios.get(apiUrl + "/wishlist/get_all_wishlist", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      });
      setwishlists(result.data.data);
    }
    catch (err) {
      const { response } = err;
      const { data } = response;
      const { status, message } = data;
      if (status === "400") {
        toast.warning(message);
        removeUserToken()
      } else {
        toast.error(message);
      }
    }
  };

  const deletewisllist = async (e) => {
    const id = e.target.getAttribute("listid");
    const result = await axios.delete(apiUrl + "/wishlist/get_wishlist/" + id, {
      headers: {
        Authorization: "Token " + accessToken,
      },
    });
    if (result) {
      fetchallwishlist();
    }
  };

  const createWishlist = () => {
    if (createWishlistName) {
      axios
        .post(
          apiUrl + "/wishlist/add_wishlist",
          {
            name: createWishlistName,
          },
          {
            headers: {
              Authorization: "Token " + accessToken,
            },
          }
        )
        .then((res) => {
          fetchallwishlist();
          setShowCreateWishlis(false);
          setCreateWishlistName(null);
        })
        .catch((err) => {
          try {
            toast.error(err.response.data.message);
          } catch (e) {
            toast.error(err.toString());
          }
        });
    } else {
      toast.warning("Enter a name to create a wishlist");
    }
  };

  return (
    <>
      {/* <div className="my-account"> */}
      {/* <div className="my-account-title mb-3">
          <h3>My Wishlist</h3>
        </div> */}
      <div className="w-100 px-3 mt-2">
        <button
          className="btn border border-dark w-100"
          onClick={() => setShowCreateWishlis(true)}
        >
          Create Wishlist
        </button>
      </div>
      <div className="my-wishlist-items mt-4">
        {wishlists.map((data, index) => {
          return (
            <div className="my-wishlist-items-div border mt-1" key={index}>
              <div
                className="mywishlistdetail w-75"
                onClick={() => navigate("/profile/my-wishlist/" + data.id)}
              >
                <h5 className="text-capitalize p-0 m-0">
                  {data.name} ({data.product_list.length})
                </h5>
              </div>
              <div className="d-flex align-items-center justify-content-end border-left pl-2">
                <i
                  className="fa fa-pencil"
                  onClick={() => {
                    setShowEditPop(true);
                    setListIdRename(data?.id);
                    setListNameRename(data?.name);
                  }}
                ></i>
              </div>
              {!data?.is_default ? (
                <div className="d-flex align-items-center justify-content-end border-left pl-3">
                  <i
                    className="fa fa-trash"
                    listid={data.id}
                    onClick={deletewisllist}
                  ></i>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      {showEditPop ? (
        <div className="insurance-confirm py-4">
          <i className="fa fa-close" onClick={() => setShowEditPop(false)}></i>
          <h5 className="mt-3">Edit Wishlist Name</h5>
          <div className="mt-4 mb-2">
            <input
              type="text"
              className="form-control"
              defaultValue={listNameRename}
              onChange={(e) => setListNameRename(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <button
              className="btn border-theme text-theme mr-2"
              onClick={() => setShowEditPop(false)}
            >
              Cancel
            </button>
            <button
              className="btn bg-theme text-light"
              onClick={() => {
                axios
                  .put(
                    apiUrl + "/wishlist/update_wishlist/" + listIdRename,
                    {
                      name: listNameRename,
                    },
                    {
                      headers: {
                        Authorization: "Token " + accessToken,
                      },
                    }
                  )
                  .then((res) => {
                    setShowEditPop(false);
                    const { data } = res;
                    const { message, status } = data;
                    if (status === 200) {
                      fetchallwishlist();
                      toast.success(message);
                    } else {
                      toast.warning(message);
                    }
                  })
                  .catch((err) => {
                    const { response } = err;
                    const { data } = response;
                    const { status, message } = data;
                    if (status === "400") {
                      toast.warning(message);
                      removeUserToken()
                    } else {
                      if (data.data.name) {
                        toast.error(data.data.name[0]);
                      }
                      else {
                        toast.error(message);
                      }
                    }

                  });
              }}
            >
              Save
            </button>
          </div>
        </div>
      ) : null}
      {/* </div> */}

      {/* create wishlist */}
      {showCreateWishlis ? (
        <div className="edit-wishlist p-5 px-4 animate__animated animate__fadeIn animate__faster">
          <i
            className="fa fa-close"
            onClick={() => setShowCreateWishlis(false)}
          ></i>
          <h5 className="text-center mb-5">Create Wishlist</h5>

          <input
            type="text"
            className="form-control"
            placeholder="Enter wishlist name"
            value={createWishlistName}
            onChange={(e) => setCreateWishlistName(e.target.value)}
          />
          <button
            className="btn bg-theme text-light float-right mt-3"
            onClick={createWishlist}
          >
            Create
          </button>
        </div>
      ) : null}
    </>
  );
};

export default MyWishlist;
