import { memo } from "react";
import { Modal } from "react-bootstrap";
import "./style.css";

const ImageViewer = ({ show, setShow, url }) =>
  show && (
    <Modal className="ImageViewer" show={show} onHide={() => setShow(false)} fullscreen>
      
      <div>
        <i className="fa fa-close" onClick={() => setShow(false)}></i>
      </div>
      <Modal.Body>
        <img src={url} width="100%" />
      </Modal.Body>
    </Modal>
  );
export default memo(ImageViewer);
