import CryptoJS from "crypto-js";
const secretKey = "}S?R2n0|Cy=_]Z+{gwT?]fAXr?,++Pi&I&8q4g$#";
const localStoreKey = "i5q6oudycjpy";

export const getUserToken = () => {
  const EncryptToken = localStorage.getItem(localStoreKey);
  if (EncryptToken) {
    const bytes = CryptoJS.AES.decrypt(EncryptToken, secretKey);
    const token = bytes.toString(CryptoJS.enc.Utf8);
    return token;
  }
};

export const storeUserToken = (token) => {
  var encriptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
  localStorage.setItem(localStoreKey, encriptedToken);
};

export const removeUserToken = () => {
  sessionStorage.clear();
  localStorage.removeItem(localStoreKey);
  localStorage.removeItem("saved_insu_data");
  localStorage.removeItem("saved_pres_data");
  localStorage.removeItem("logindtls");
  localStorage.removeItem("discount_coupon_id");
  localStorage.removeItem("total_loyalty_points_deducted");
  window.location = "/";
};
