import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const GetDeviceId = async () => {
  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    deviceId = result.visitorId;
    localStorage.setItem("deviceId", deviceId);
  }

  return deviceId;
};
