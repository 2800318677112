import { Container, Navbar, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListGroup } from "react-bootstrap";
import "./Navbar.css";
import LogoDesktop from "../../../assets/img/logo-dark.png";
import axios from "axios";
import {
  apiUrl,
  displayTopBarCart,
  popSelectedSubCategoryList,
  pushSelectedSubCategoryList,
  resetSubCategoryList,
  setAllCategoryList,
} from "../../../redux/action/action";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
// import Drawer from 'react-modern-drawer';
// import 'react-modern-drawer/dist/index.css'
import {
  CURRENT_CITY,
  DISPLAY_CART_POPUP,
  USER_DATA,
} from "../../../redux/state/state";
import { useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { toast } from "react-toastify";
import Geocode from "react-geocode";
import shopping_bag from "../../../assets/shopping-bag.png";
import wallet_icon from "../../../assets/wallet-outline.svg";
import { getUserToken, removeUserToken } from "../../app/userToken";
import { getUserDetails } from "../../app/userDetails";
import SideModal from "./SideModal";
import { GetDeviceId } from "../../app/GetDeviceId";

Geocode.setApiKey("AIzaSyB-i8_omurLDIXbP2yI1v0Ry5MtHtd-FyU");
Geocode.enableDebug();

const Menu = () => {
  const accessToken = getUserToken();
  const location = useLocation();
  const [state, setState] = useState({});
  const { key } = useParams();
  const navigate = useNavigate();
  const reduxuserdata = useSelector((state) => state.userReducer);
  const reduxresponseTopCart = useSelector((state) => state.topBarCartCounter);
  const reduxMenuData = useSelector((state) => state.menuReducer);
  const reduxresponsedisplayCartPopReducer = useSelector(
    (state) => state.displayCartPopReducer
  );
  let userName;
  if (reduxuserdata.userdata?.name) {
    userName = reduxuserdata.userdata.name;
  }
  const reduxresponse = useSelector((state) => state);
  const reduxcurrentcity = useSelector((state) => state.fetchCurrentCity);
  const [categoryList, setCategoryList] = useState([]);
  const dispatch = useDispatch();
  const [prdctkeys, setPrdctkeys] = useState([]);
  const [showprdctkeys, setshowprdctkeys] = useState(true);
  const [loader, setLoader] = useState(false);
  const [topMenuList, setTopMenuList] = useState([]);
  const [city, setCity] = useState("Loading...");
  const [showNotification, setshowNotification] = useState(false);
  const [showCategoryDropDown, setshowCategoryDropDown] = useState(false);
  const [notification, setNotification] = useState([]);
  const [searchKey, setSearchKey] = useState();

  useEffect(() => {
    setSearchKey(key);
  }, [key, searchKey]);

  useEffect(() => {
    // get user data
    if (accessToken && window.innerWidth > 768) {
      axios
        .get(apiUrl + "/user/customerDetails", {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          dispatch({
            type: USER_DATA,
            payload: getUserDetails(res.data.encrypted),
          });
        }).catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            toast.error(message);
            window.location = "/";
            removeUserToken();
          } else {
            toast.error(message);
          }
        });
    }
    // // //
    navigator.geolocation.getCurrentPosition(
      (position) => {
        fetchAddress(position.coords.latitude, position.coords.longitude);
      },
      (err) => {
        // toast.error(err.message);
      },
      { enableHighAccuracy: true }
    );
  }, []);

  const fetchAddress = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        let addressjson = {};
        const address_components = response.results[0].address_components;
        address_components.forEach((element) => {
          addressjson[element.types[0]] = element.long_name;
        });

        //mohalla - political
        //premise + neighbour hood (opt) - hosuse no
        dispatch({
          type: CURRENT_CITY,
          payload: addressjson?.locality,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    setCity(reduxcurrentcity.city);
  }, [reduxcurrentcity]);

  useEffect(() => {
    dispatch(displayTopBarCart());

    $("html").click((e) => {
      if (e.target.id !== "keyinput") {
        setshowprdctkeys(false);
      }
      // dispatch({
      //   type: DISPLAY_CART_POPUP,
      //   payload: false,
      // });
    });
    return () => {
      setState({});
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth > 768 && !location.pathname.match("/product/")) {
      axios
        .get(apiUrl + "/master/top_menu_category_list")
        .then((res) => {
          setTopMenuList(res.data.data);
        })
        .catch((err) => {
          toast.error(err.toString());
        });

      axios
        .get(apiUrl + "/category/get_category_tree")
        .then((res) => {
          setCategoryList(res.data.data);
          dispatch(setAllCategoryList(res.data.data));
        })
        .catch((err) => {
          toast.error(err.toString());
        });
    }
  }, []);

  // useEffect(() => {
  //   dispatch(resetSubCategoryList());
  // }, [window.location.href]);

  const searchOninput = async (e) => {
    const val = e.target.value;
    setSearchKey(e.target.value);
    dispatch({
      type: "CLEAR_FILTER_BY_CATEGORY",
    });
    if (reduxresponse.filterByCategory.category_id.length === 1) {
      navigate("/product/all");
    }
    // clear all brand filter
    dispatch({
      type: "CLEAR_FILTER_BY_BRAND",
    });
    // clear all product form filter
    dispatch({
      type: "CLEAR_FILTER_BY_PRODUCT_FORM",
    });

    if (val.length >= 3) {
      if (!val) {
        setshowprdctkeys(false);
        navigate("/product/all");
      } else {
        setshowprdctkeys(true);
        setLoader(true);
      }
      try {
        const result = await axios.get(apiUrl + "/search/products/" + val.replace(/\//g, ' ')); //AHP1
        setPrdctkeys(result.data.data);
        setLoader(false);
      } catch (err) {
        toast.error(err.toString());
      }
    } else {
      setshowprdctkeys(false);
    }
  };
  const onsubmitsearchinput = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const keyinput = formData.get("keyinput");
    if (keyinput !== "") {
      navigate("/product/search/" + keyinput?.replace(/\//g, '|'));
      setshowprdctkeys(false);
      window.location.reload(false);
    }
  };

  const onMouseEnterCartIcon = () => {
    setshowNotification(false);
    dispatch({
      type: DISPLAY_CART_POPUP,
      payload: true,
    });
  };

  const onMouseLeaveCartIcon = () => {
    dispatch({
      type: DISPLAY_CART_POPUP,
      payload: false,
    });
  };

  const remove_item_cart = async (e) => {
    const id = e.target.getAttribute("prdctcartid");
    if (accessToken) {
      // axios for delete item from cart
      try {
        axios
          .delete(apiUrl + "/order/update_cart_data/" + id, {
            headers: {
              Authorization: "Token " + accessToken,
            },
          })
          .then((res) => {
            dispatch(displayTopBarCart());
            // toast.success(res.data.message);
          });
      } catch (err) {
        const { status, message } = err.response.data.data;
        if (status === "400") {
          toast.error(message);
          window.location = "/";
          removeUserToken();
        } else {
          toast.error(message);
        }

      }
    } else {
      const deviceId = await GetDeviceId();
      axios
        .delete(
          apiUrl +
          "/order/delete_guest_cart_data/" +
          id +
          "?unique_id=" +
          deviceId
        )
        .then((res) => {
          dispatch(displayTopBarCart());
        })
        .catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          toast.error(message);
        });
    }
  };

  const onMouseEnterCategoryDropdown = () => {
    setshowCategoryDropDown(true);
  };

  const onMouseLeaveCategoryDropdown = () => {
    setshowCategoryDropDown(false);
  };

  const capitalizeFirstLetter = (str) => {
    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2;
  };

  useEffect(() => {
    if (accessToken) {
      getNotification();
    }
  }, []);

  const [newNotificationCount, setNewNotificationCount] = useState();

  const getNotification = () => {
    axios
      .get(apiUrl + "/user/get_notifications", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        setNotification(res.data);
        setNewNotificationCount(res.data.new_notifications_count);
      })
      .catch((err) => {

        const { status, message } = err.response.data.data;
        if (status === "400") {
          toast.error(message);
          window.location = "/";
          removeUserToken();
        } else {
          toast.error(message);
        }

      });
  };

  return (
    <>
      <Navbar
        expand="lg"
        id="desktop-nav"
        className="py-2 sticky-top"
        style={{
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 3px",
        }}
      >
        <Container fluid className="d-flex flex-column">
          <Row className="w-100 pb-2">
            <Col xs={2} className="p-0 m-0">
              <Link className="navbar-brand" to="/">
                <img src={LogoDesktop} alt="logo" id="logoDesktop" />
              </Link>
            </Col>
            <Col xs={6} className="p-0 m-0 pt-1 d-flex">
              {!(
                location.pathname.match("/profile/") ||
                location.pathname.match("/account/") ||
                location.pathname.match("/notification") ||
                location.pathname.match("/setaddress") ||
                location.pathname.match("/contact-us") ||
                location.pathname.match("/cancellation-return-and-refund-policy") ||
                location.pathname.match("/terms-conditions") ||
                location.pathname.match("/editorial-policy") ||
                location.pathname.match("/intellectual-property-infringement-policy") ||
                location.pathname.match("/grievance-redressal-policy") ||
                location.pathname.match("/about-us") ||
                location.pathname.match("/faq") ||
                location.pathname.match("/policies/")
              ) && (
                  <>
                    <div id="display-location">
                      <p>{city}</p>
                      <i className="fa fa-map-marker text-grey"></i>
                    </div>
                    <div id="seach-box">
                      <div className="input-group">
                        <div className="input-group-prepend bg-light">
                          <span className="input-group-text">
                            <i className="fa fa-search text-grey"></i>
                          </span>
                        </div>
                        <form onSubmit={onsubmitsearchinput}>
                          <input
                            type="text"
                            name="keyinput"
                            className="form-control"
                            id="keyinput"
                            placeholder="Search for products, brands and more"
                            defaultValue={searchKey?.replace('|', '/')}
                            onInput={searchOninput}
                            autoComplete="off"
                          />
                        </form>
                      </div>
                      {showprdctkeys ? (
                        <div className="searched-keywords">
                          {loader ? (
                            <SkeletonTheme
                              color="#ccc"
                              highlightColor="#fff"
                              width={"100%"}
                              inline={true}
                            >
                              <Skeleton count={20} height={15} />
                            </SkeletonTheme>
                          ) : (
                            <ListGroup variant="flush">
                              {prdctkeys.map((data, index) => {
                                return (
                                  <ListGroup.Item
                                    key={index}
                                    onClick={() => {
                                      navigate("/product/search/" + data?.itemName?.replace(/\//g, '|'));
                                      setSearchKey(data?.itemName);
                                    }}
                                    data={data.itemName}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.itemName}
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
            </Col>
            <Col
              xs={4}
              className="p-0 m-0 pt-1 d-flex align-items-center justify-content-end"
            >
              <div className="mr-4 nav-wallet-icon">
                <Link to="/account/my-wallet">
                  <img src={wallet_icon} alt="wallet_icon" width={22} />
                  {/* {accessToken ? (
                    <sup>
                      {reduxuserdata?.userdata?.total_loyalty_points > 999
                        ? "999+"
                        : reduxuserdata?.userdata?.total_loyalty_points}
                    </sup>
                  ) : null} */}
                </Link>
              </div>

              <div
                className="bell-icons mr-3"
                // onMouseEnter={()=>setshowNotification(true)}
                onMouseLeave={() => setshowNotification(false)}
              >
                <i
                  className="fa fa-bell-o"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (accessToken) {
                      setNewNotificationCount(0);
                      setshowNotification(!showNotification);
                      if (!showNotification) {
                        axios.post(
                          apiUrl + "/user/mark_notifications_read",
                          {
                            token: accessToken,
                          },
                          {
                            headers: {
                              Authorization: "Token " + accessToken,
                            },
                          }
                        );
                      }
                    } else {
                      localStorage.setItem("redirect_url", location.pathname);
                      navigate("/auth/login");
                    }
                  }}
                ></i>
                {accessToken && newNotificationCount > 0 ? (
                  <sup>{notification?.new_notifications_count}</sup>
                ) : null}

                {showNotification ? (
                  <div className="display-notification-box animate__animated animate__fadeIn animate__faster">
                    <div className="w-100 sticky-top border-bottom pb-2">
                      <small className="text-theme">
                        <i className="fa fa-bell"></i>
                        &nbsp; Notification ({notification?.data.length})
                      </small>
                    </div>
                    <div className="notification-body">
                      {notification?.data.length > 0
                        ? notification?.data.map((e, i) => {
                          return (
                            <div key={i} className="alert alert-light">
                              <small
                                onClick={() =>
                                  (window.location = e.redirect_url)
                                }
                              >
                                {e.message}
                              </small>
                            </div>
                          );
                        })
                        : null}
                    </div>
                    <button
                      className="w-100 btn text-theme p-0 pt-2"
                      style={{ borderTop: "1px solid #f2f2f2" }}
                      onClick={() => setshowNotification(false)}
                    >
                      close
                    </button>
                  </div>
                ) : null}
              </div>
              <Link className="wishlist-icons mr-3" to="/wishlist">
                {location.pathname === "/wishlist" ? (
                  <i className="fa fa-heart" style={{ fontSize: "23px" }}></i>
                ) : (
                  <i
                    className="fa fa-heart-o text-dark"
                    style={{ fontSize: "23px" }}
                  ></i>
                )}
              </Link>
              <div
                className="navbar-brand nav-cart-icon mr-3"
                onMouseEnter={onMouseEnterCartIcon}
                onMouseLeave={onMouseLeaveCartIcon}
              >
                <Link to="/cart">
                  {/* <img src={cartimg} alt="cartimg" className="menufa" /> */}
                  <i
                    className="fa fa-shopping-cart text-dark"
                    style={{ fontSize: "23px" }}
                  ></i>
                  {reduxresponseTopCart?.state?.data.length > 0 ? (
                    <sup>{reduxresponseTopCart?.state?.data.length}</sup>
                  ) : null}
                </Link>
                {reduxresponsedisplayCartPopReducer.action ? (
                  <div className="display-cartitems animate__animated animate__fadeIn animate__faster">
                    <div className="w-100 p-2 d-flex justify-centent-between">
                      <div className="w-50">
                        <small className="float-left">Order summary</small>
                      </div>
                      <div className="w-50">
                        <small className="float-right">
                          {reduxresponseTopCart?.state?.data.length} {reduxresponseTopCart?.state?.data?.length === 1 ? 'Item' : 'Items'}
                        </small>
                      </div>
                    </div>
                    <hr className="p-0 m-0" />
                    <div className="display-cartitems-div px-2">
                      {reduxresponseTopCart?.state?.data.length > 0 ? (
                        reduxresponseTopCart?.state?.data?.map(
                          (datalist, index) => {
                            return (
                              <div
                                className="w-100 d-flex bg-light px-2 py-1 mt-2 align-items-center"
                                key={index}
                              >
                                <img
                                  src={apiUrl + datalist?.image1}
                                  alt="img"
                                  style={{
                                    width: "auto",
                                    height: "40px",
                                    borderRadius: "5px",
                                  }}
                                />
                                <div className="w-75 overflow-hidden ml-2">
                                  <Link
                                    to={`/product-details/${datalist.product_id}`}
                                  >
                                    <p
                                      className="text-theme p-0 m-0"
                                      style={{
                                        width: "100%",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {datalist.itemName}
                                    </p>
                                  </Link>
                                </div>
                                <div className="d-flex justify-content-end pr-2">
                                  <i
                                    className="fa fa-close"
                                    style={{
                                      fontSize: "15px",
                                      cursor: "pointer",
                                    }}
                                    prdctcartid={datalist.id}
                                    onClick={remove_item_cart}
                                  ></i>
                                  {/* <p className="m-0 p-0">{datalist.quantity}</p> */}
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <div className="empty-cart-msg w-100 mt-3">
                          <img
                            src={shopping_bag}
                            alt="shopping_bag"
                            style={{ width: "70px" }}
                          />
                          <h6>Hey, it feels so light!</h6>
                          <small
                            style={{ fontSize: "12px" }}
                            className="text-center"
                          >
                            There is nothing in your bag,
                            <br /> let’s add some items.
                          </small>
                        </div>
                      )}
                    </div>
                    {reduxresponseTopCart?.state?.data.length > 0 ? (
                      <div className="d-flex">
                        <Link to="/cart" className="w-50">
                          <button className="btn w-100 text-theme border-right view-cart">
                            Expand Cart
                          </button>
                        </Link>
                        <Link to="/setaddress" className="w-50">
                          <button className="btn bg-theme text-light text-light w-100 view-cart rounded-0">
                            Checkout
                          </button>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>

              {/* {accessToken ? } */}
              {/* <Link className="navbar-brand" to="/account/my-account"> */}
              {/* <img src={userimg} alt="userimg" className="menufa" /> */}
              <i
                className="fa fa-user-circle"
                style={{ fontSize: "23px", cursor: "pointer" }}
                onClick={() => {
                  if (accessToken) {
                    navigate("/account/my-account");
                  } else {
                    localStorage.setItem("redirect_url", location.pathname);
                    navigate("/auth/login");
                  }
                }}
              ></i>
              {/* </Link> */}
            </Col>
          </Row>

          {!(
            location.pathname.match("/profile/") ||
            location.pathname.match("/account/") ||
            location.pathname.match("/notification") ||
            location.pathname.match("/setaddress") ||
            location.pathname.match("/contact-us") ||
            location.pathname.match("/cancellation-return-and-refund-policy") ||
            location.pathname.match("/terms-conditions") ||
            location.pathname.match("/editorial-policy") ||
            location.pathname.match("/intellectual-property-infringement-policy") ||
            location.pathname.match("/grievance-redressal-policy") ||
            location.pathname.match("/about-us") ||
            location.pathname.match("/faq") ||
            location.pathname.match("/policies/") ||
            location.pathname.match("/product/")
          ) && (
              <Row className="w-100">
                <Col className="d-flex justify-content-start border-top">
                  <ul className="navbar-nav navbar-mega-nav pe-lg-2 me-lg-2">
                    <li
                      className="nav-item dropdown"
                      style={{ position: "relative" }}
                      onClick={onMouseEnterCategoryDropdown}
                    // onMouseLeave={onMouseLeaveCategoryDropdown}
                    >
                      <Link
                        className="nav-link ps-lg-0"
                        to="#"
                      // data-bs-toggle="dropdown"
                      >
                        <i className="fa fa-th-list me-2"></i>Category
                      </Link>
                    </li>
                    <li>
                      <SideModal
                        title={
                          <h4>
                            <Link
                              className="navbar-brand"
                              to={userName ? "#" : "/auth/login"}
                            >
                              <i className="fa fa-user-circle mr-2" />
                              <span className="navbar-brandTitle">
                                Hello,{" "}
                                {(userName &&
                                  `${reduxuserdata.userdata?.salutation
                                  } ${userName
                                    .slice(0, 1)
                                    .toUpperCase()}${userName.slice(1)}`) ||
                                  "Sign in"}
                              </span>
                            </Link>
                          </h4>
                        }
                        setIsOpen={setshowCategoryDropDown}
                        show={
                          showCategoryDropDown &&
                          reduxMenuData.subMenuList?.length > 0
                        }
                        // clearOnClose={() => {
                        //   onMouseLeaveCategoryDropdown();
                        // }}
                        contentStyle={{ height: "100%" }}
                      >
                        <div className="dropdown-menu-category w-100">
                          <div>
                            {reduxMenuData.subMenuList?.length > 1 && (
                              <div
                                className="menu-header d-flex flex-row pt-2 align-items-center"
                              // style={{ width: '290px' }}
                              >
                                {reduxMenuData.subMenuList?.length > 1 && (
                                  <button
                                    className="btn p-0 mr-2"
                                    onClick={() =>
                                      dispatch(popSelectedSubCategoryList())
                                    }
                                  >
                                    <i className="fa fa-arrow-left" />
                                  </button>
                                )}
                                {reduxMenuData.subMenuList?.length &&
                                  reduxMenuData.subMenuList.length > 1 && (
                                    <div className="menu-header-list-title flex-grow-1">
                                      {
                                        reduxMenuData.subMenuList[
                                          reduxMenuData.subMenuList.length - 1
                                        ][0].name
                                      }
                                      &nbsp;(
                                      {
                                        reduxMenuData.subMenuList[
                                          reduxMenuData.subMenuList.length - 1
                                        ][0].sub_categories.length
                                      }
                                      )
                                    </div>
                                  )}
                                {/* <button
                                className="btn"
                                onClick={() => {
                                  setshowCategoryDropDown(prevState => !prevState);
                                  dispatch(resetSubCategoryList());
                                }}
                              >
                                <i className="fa fa-times pb-2" />
                              </button> */}
                              </div>
                            )}
                            {reduxMenuData.subMenuList?.length === 1 && (
                              <div>
                                <h5 className="h5-category">Shop By Category</h5>
                              </div>
                            )}
                            {reduxMenuData.subMenuList?.length > 0 &&
                              reduxMenuData.subMenuList[
                                reduxMenuData.subMenuList.length - 1
                              ][0].sub_categories?.map((data, index) => {
                                return (
                                  <div
                                    className={
                                      "menu-item d-flex flex-row align-items-center"
                                    }
                                    // style={{ width: '290px' }}
                                    key={index}
                                  >
                                    <Link
                                      to={
                                        reduxMenuData.subMenuList?.length > 1
                                          ? `/product/category/${data.name}-${data.id}?subcategory=true`
                                          : `/product/category/${data.name}-${data.id}`
                                      }
                                      className="d-flex flex-row align-items-center  flex-grow-1"
                                      onClick={() => {
                                        setshowCategoryDropDown(false);
                                        dispatch(resetSubCategoryList());
                                      }}
                                    >
                                      <h6 className="text-capitalize text-center mt-2 menu-item-name">
                                        {data.name}
                                      </h6>
                                    </Link>
                                    {data.sub_categories.length > 0 && (
                                      <button
                                        className="btn"
                                        onClick={() =>
                                          dispatch(
                                            pushSelectedSubCategoryList(data)
                                          )
                                        }
                                      >
                                        <h6 style={{ paddingRight: "10px" }}>
                                          {">"}
                                        </h6>
                                      </button>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </SideModal>
                    </li>
                  </ul>
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to={`/product/all`}>
                        <span className="text-theme">{"Browse Products"}</span>
                      </Link>
                    </li>
                  </ul>
                  {topMenuList?.map((data, index) => {
                    return data.is_active ? (
                      <ul className="navbar-nav" key={index}>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link text-capitalize"
                            to={`/product/category/${capitalizeFirstLetter(
                              data.category_name
                            )}-${data.category_id}`}
                          >
                            {data.category_name}
                          </Link>
                        </li>
                      </ul>
                    ) : null;
                  })}
                </Col>
              </Row>
            )}
        </Container>
      </Navbar>
    </>
  );
};

export default Menu;
