import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addToCart, apiUrl, displayTopBarCart } from "../../../../../redux/action/action";
import { getUserToken, removeUserToken } from "../../../../app/userToken";
import CartNavbarMob from "../../../../Header/CartNavbarMob/CartNavbarMob";
import Navbarmob from "../../../../Header/NavbarMob/Navbarmob";
// import SavedCard from "../../SavedCard/SavedCard";

const MobSaveForLater = () => {
  const accessToken = getUserToken()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState()
  const [itemlist, setItemList] = useState([]);
  const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);


  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = () => {
    setLoader(true);
    axios
      .get(apiUrl + "/wishlist/get_saved_for_later", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        const { data } = res;
        const { data: data_, message, status } = data;
        if (status === 200) {
          setItemList(data_);
        } else {
          toast.warning(message);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };


  const handle_clear_list = () => {
    axios
      .get(apiUrl + "/wishlist/clear_save_for_later", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then(() => {
        setItemList([]);
        setShowDelectConfirmation(false);
      }).catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  const onCLickDelete = (e) => {
    const itemid = e.target.getAttribute("itemID");
    deleteItems(itemid);
  };

  const deleteItems = (itemid) => {
    axios
      .delete(apiUrl + "/wishlist/delete_saved_for_later/" + itemid, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then(() => {
        fetchdata();
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };


  return (
    <>
      <Navbarmob />
      <Container>
        <Row>
          <Col className="pt-3">
            {
              itemlist.length > 0 && (
                <div className="d-flex justify-content-end">
                  <button className="btn border-theme text-theme w-50" onClick={() => setShowDelectConfirmation(true)}>clear all</button>

                  {showDelectConfirmation ? (
                    <div className="insurance-confirm py-4">
                      <i
                        className="fa fa-close"
                        onClick={() => setShowDelectConfirmation(false)}
                      ></i>
                      <h5 className="mt-3">Are you sure ?</h5>
                      <div className="mt-2">
                        <button
                          className="btn border-theme text-theme mr-2"
                          onClick={() => setShowDelectConfirmation(false)}
                        >
                          No
                        </button>
                        <button
                          className="btn bg-theme text-light"
                          onClick={handle_clear_list}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>)
            }

            <div className="my-wishlist-items mt-3">
              {itemlist.length > 0 ? (
                itemlist.map((data, index) => {
                  return (
                    <div
                      className="wishlist-item mt-3 rounded p-2 border"
                      key={index}
                    >
                      <div
                        className="wishlist-item-details mt-2 d-flex align-items-center"
                        onClick={() =>
                          navigate("/product-details/" + data?.product_data.id)
                        }
                      >
                        <div className="w-50">
                          <img
                            src={apiUrl + data?.product_data?.product_images[0]}
                            alt="img"
                            width={100}
                          />
                        </div>
                        <div className="d-flex flex-column ml-4 w-50">
                          <small>{data?.product_data.itemName}</small>
                          <small className="p-0 m-0 text-theme mt-3">
                            QAR {data?.product_data.pricePerPcs}
                          </small>
                        </div>
                      </div>
                      <div className="wishlist-item-action-btn mt-4">
                        {
                          data.prescription_required ?
                            <button
                              className="btn addcart"
                              onClick={() => navigate("/upload-prescription")}
                            >
                              Upload prescription
                            </button> :
                            <AddToCartBtn data={data} />
                        }
                        <button
                          className="btn removewishlist"
                          itemID={data?.id}
                          onClick={onCLickDelete}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="d-flex align-items-center justify-content-center py-5">
                  <p className="text-theme">No item yet !</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const AddToCartBtn = ({ data }) => {
  const dispatch = useDispatch();
  const [btntxt, setBtnTxt] = useState('Add to cart');

  const add_to_cart = async (e) => {
    setBtnTxt('Adding...');
    const id = e.target.getAttribute("product_id");
    const itemUnit = e.target.getAttribute("itemUnit");
    const data = {
      product_id: Number(id),
      quantity: 1,
      unit: itemUnit,
    };

    await dispatch(addToCart(data));
    await dispatch(displayTopBarCart());
    // await deleteItems(prdctid);
    setBtnTxt('Add to cart');
  };

  return (
    <button
      className="btn addcart"
      product_id={data?.product_data?.id}
      itemUnit={data?.unit}
      onClick={add_to_cart}
      disabled={btntxt === "Adding..."}
    >
      {btntxt}
    </button>
  )
}

export default MobSaveForLater;
