import $ from "jquery";
import "./Filtermob.css";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SORT } from "../../../../../redux/state/state";
import FilterOpt from "./FilterOpt";
import { useSelector } from "react-redux";

const Filtermob = () => {
  const dispatch = useDispatch();
  const reduxresponse = useSelector((state) => state);
  const mob_sort = () => {
    $(".filtermob-items").removeClass("d-none");
    $(".mob-sort").removeClass("d-none");
  };

  const mob_filter = () => {
    $(".filtermob-items").removeClass("d-none");
    $(".mob-filter").removeClass("d-none");
  };

  const close_filtermob_items = () => {
    $(".filtermob-items").addClass("d-none");
    $(".mob-sort").addClass("d-none");
    $(".mob-filter").addClass("d-none");
  };

  const onClickShort = (e) => {
    window.scroll(0, 0);
    const shortValue = e.target.getAttribute("shortValue");
    dispatch({
      type: SORT,
      payload: shortValue,
    });
    $(".filtermob-items").addClass("d-none");
    $(".mob-sort").addClass("d-none");
    $(".mob-filter").addClass("d-none");
  };

  const showMobilter = (e) => {
    $(".filtermob-items").addClass("d-none");
    $(".mob-sort").addClass("d-none");
    $(".mob-filter").addClass("d-none");
  };

  return (
    <>
      <div className="filtermob shadow-lg">
        <button className="btn border-right" onClick={mob_sort}>
          <i className="fa fa-sort"></i>
          &nbsp; Sort
        </button>
        <button className="btn border-left" onClick={mob_filter}>
          <i className="fa fa-filter"></i>
          &nbsp; Filter&nbsp;
          {reduxresponse.filterByCategory.category_name.length +
            reduxresponse.filterByCategory.sub_category_name.length +
            reduxresponse.filterByBrand.brand.length +
            reduxresponse.filterByProductForm.productForm.length !==
            0 && (
              <span>
                (
                {reduxresponse.filterByCategory.category_name.length +
                  reduxresponse.filterByCategory.sub_category_name.length +
                  reduxresponse.filterByBrand.brand.length +
                  reduxresponse.filterByProductForm.productForm.length}
                )
              </span>
            )}
        </button>

        <div className="filtermob-items animate__animated animate__fadeIn animate__faster d-none">
          {/* for sorting products */}
          <div className="mob-sort py-3 animate__animated animate__slideInUp animate__faster d-none">
            <div className="title-sec px-4 pt-2">
              <p className="p-0 m-0">Sort by -</p>
              <i className="fa fa-times" onClick={close_filtermob_items}></i>
            </div>
            <button
              className="btn py-2"
              shortValue="none"
              onClick={onClickShort}
            >
              Default
            </button>
            <button
              className="btn py-2"
              shortValue="LTH"
              onClick={onClickShort}
            >
              Low to High
            </button>
            <button
              className="btn py-2"
              shortValue="HTL"
              onClick={onClickShort}
            >
              High to Low
            </button>
          </div>

          {/* for filtering products */}
          <div className="mob-filter animate__animated animate__slideInDown animate__faster d-none">
            <div className="title-sec d-flex justify-content-start py-3">
              <i
                className="fa fa-arrow-left mr-2"
                onClick={close_filtermob_items}
              ></i>
              <p className="p-0 m-0 ">Filters</p>
            </div>
            <Container className="p-0">
              <Row>
                <Col xs="12" className="p-0 filter-checkbox">
                  <FilterOpt showMobilter={showMobilter} />
                  {/* <Filters /> */}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filtermob;
