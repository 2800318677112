import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiUrl, displayCart } from "../../../../../redux/action/action";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import GooglePayButton from "@google-pay/button-react";
import "./Cartpricedetails.css";
import { toast } from "react-toastify";
import AvailableOffer from "../../../../Section/AvailableOffer/AvailableOffer";
import { DISPLAY_CART } from "../../../../../redux/state/state";
import { getUserToken, removeUserToken } from "../../../../app/userToken";

const Cartpricedetails = () => {
  const accessToken = getUserToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const reduxuserdata = useSelector((state) => state.userReducer);
  const reduxresponse = useSelector((state) => state.displayCartReducer);
  const reduxresponsefetchaddressReducer = useSelector(
    (state) => state.fetchaddressReducer
  );
  const reduxresponsepaymentmethod = useSelector(
    (state) => state.paymentmethodReducer
  );
  const [placeorderbtnstate, setPlaceorderbtnstate] = useState("Pay Now");
  const [showCoupon, setShowCoupon] = useState(false);
  const [applyCouponBtnTxt, setApplyCouponBtnTxt] = useState(() => {
    return (
      <>
        <i className="fa fa-ticket mr-2"></i>
        No Coupon Applied
      </>
    );
  });
  const [deliveryNotes, setDeliveryNotes] = useState(null);
  const [useLoyltyPoint, setUseLoyltyPoint] = useState(false);

  // useEffect(() => {
  //   const localdeliveryNotes = localStorage.getItem("deliveryNotes");
  //   if (localdeliveryNotes) {
  //     setDeliveryNotes(localdeliveryNotes)
  //   }
  //   else {
  //     localStorage.setItem("deliveryNotes", deliveryNotes);
  //   }
  // }, [location.pathname, deliveryNotes])

  useEffect(() => {
    if (reduxresponse?.discount_amount !== 0) {
      setApplyCouponBtnTxt(() => {
        return (
          <>
            <i className="fa fa-ticket mr-2"></i>
            Coupon code {reduxresponse?.discount_coupon_code} applied
          </>
        );
      });
    } else {
      setApplyCouponBtnTxt(() => {
        return (
          <>
            <i className="fa fa-ticket mr-2"></i>
            No Coupon Applied
          </>
        );
      });
    }
  }, [reduxresponse]);

  useEffect(() => {
    dispatch(displayCart());
    // if (localStorage.getItem("discount_coupon_id")) {
    //   axios
    //     .get(
    //       apiUrl +
    //       "/order/get_cart_data?discount_coupon_id=" +
    //       localStorage.getItem("discount_coupon_id"),
    //       {
    //         headers: {
    //           Authorization: "Token " + accessToken,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       localStorage.setItem(
    //         "total_loyalty_points_deducted",
    //         res.data.total_loyalty_points_deducted
    //       );
    //       dispatch({
    //         type: DISPLAY_CART,
    //         payload: res.data,
    //       });
    //     }).catch((err) => {
    //       const { response } = err;
    //       const { data } = response;
    //       const { status, message } = data;
    //       if (status === "400") {
    //         toast.warning(message);
    //         removeUserToken()
    //       } else {
    //         toast.error(message);
    //       }
    //     });
    // } else if (localStorage.getItem("discount_coupon_code")) {
    //   axios
    //     .get(
    //       apiUrl +
    //       "/order/get_cart_data?discount_coupon_code=" +
    //       localStorage.getItem("discount_coupon_code"),
    //       {
    //         headers: {
    //           Authorization: "Token " + accessToken,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       localStorage.setItem(
    //         "total_loyalty_points_deducted",
    //         res.data.total_loyalty_points_deducted
    //       );
    //       dispatch({
    //         type: DISPLAY_CART,
    //         payload: res.data,
    //       });
    //     }).catch((err) => {
    //       const { response } = err;
    //       const { data } = response;
    //       const { status, message } = data;
    //       if (status === "400") {
    //         toast.warning(message);
    //         removeUserToken()
    //       } else {
    //         toast.error(message);
    //       }
    //     });
    // } else {
    //   dispatch(displayCart());
    // }
  }, [location.pathname, reduxresponse.wishlistReducer]);

  const placeorder = async () => {
    if (reduxuserdata?.userdata?.email) {
      const selectedAddress_id = localStorage.getItem("selectedAddress_id");
      const discount_coupon_id = localStorage.getItem("discount_coupon_id");
      const discount_coupon_code = localStorage.getItem("discount_coupon_code");
      const total_loyalty_points_deducted = localStorage.getItem(
        "total_loyalty_points_deducted"
      );
      let data = {};
      if (discount_coupon_id) {
        if (selectedAddress_id) {
          data = {
            method: reduxresponsepaymentmethod.data,
            address_id: selectedAddress_id,
            discount_coupon_id,
            total_loyalty_points_deducted,
          };
        } else {
          data = {
            method: reduxresponsepaymentmethod.data,
            discount_coupon_id,
            total_loyalty_points_deducted,
          };
        }
      } else if (discount_coupon_code) {
        if (selectedAddress_id) {
          data = {
            method: reduxresponsepaymentmethod.data,
            address_id: selectedAddress_id,
            discount_coupon_code,
            total_loyalty_points_deducted,
          };
          localStorage.removeItem("selectedAddress_id");
        } else {
          data = {
            method: reduxresponsepaymentmethod.data,
            total_loyalty_points_deducted,
            discount_coupon_code,
          };
        }
        localStorage.removeItem("total_loyalty_points_deducted");
      } else {
        if (selectedAddress_id) {
          data = {
            method: reduxresponsepaymentmethod.data,
            address_id: selectedAddress_id,
            total_loyalty_points_deducted,
          };
          localStorage.removeItem("selectedAddress_id");
        } else {
          data = {
            method: reduxresponsepaymentmethod.data,
            total_loyalty_points_deducted,
          };
        }
        localStorage.removeItem("total_loyalty_points_deducted");
      }
      if (reduxresponsepaymentmethod.method === "pay on delivery") {
        //////////////
      } else if (reduxresponsepaymentmethod.method === "card without saved") {
        setPlaceorderbtnstate("Loading...");
        data.delivery_notes = deliveryNotes;
        try {
          const res = await axios.post(
            apiUrl + "/user/make_payment_directly",
            data,
            {
              headers: {
                Authorization: "Token " + accessToken,
              },
            }
          );
          setPlaceorderbtnstate("Pay Now");
          if (res.data.message === "Success") {
            localStorage.setItem("redirect_url", "/cart");
            localStorage.removeItem("discount_coupon_code");
            localStorage.removeItem("discount_coupon_id");
            window.location.assign(res.data.data._links.checkout.href);
          } else {
            toast.warning(res.data.message);
          }
        } catch (err) {
          setPlaceorderbtnstate("Pay Now");

          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }

        }
      } else {
        try {
          const res = await axios.post(
            apiUrl + "/user/make_payment_with_saved_card",
            data,
            {
              headers: {
                Authorization: "Token " + accessToken,
              },
            }
          );
        } catch (err) {

          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }

        }
      }
    } else {
      toast.warning("Compelete your profile");
      localStorage.setItem("redirect_url", location.pathname);
      setTimeout(() => {
        if (window.innerWidth < 768) {
          navigate("/profile/my-account");
        } else {
          navigate("/account/my-account");
        }
      }, 600);
    }
  };

  const showCouponModal = (e) => {
    setShowCoupon(e);
  };
  const selectedCouponCode = (e) => {
    setApplyCouponBtnTxt(() => {
      return (
        <>
          <i className="fa fa-ticket mr-2"></i>
          Coupon code {e} applied
        </>
      );
    });
  };

  return (
    <>
      <div className="cart-price-details border p-4 shadow-sm">
        <div className="cart-price-details-header">
          <h6>Price Details</h6>
        </div>
        <div className="cart-price-details-body mt-4">
          <div className="w-100">
            <p className="cart-price-details-subject">Sub Total</p>
            <p className="cart-price-details-price">
              QAR {reduxresponse?.mrp_amount?.toFixed(2)}
            </p>
          </div>

          <div className="w-100">
            <p className="cart-price-details-subject">
              Total loyalty points credited
            </p>
            <p className="cart-price-details-price text-success">
              +{reduxresponse?.total_loyalty_points_to_be_credited}
            </p>
          </div>
          <div className="w-100">
            <p className="cart-price-details-subject">
              Total loyalty points deducted
            </p>
            <p className="cart-price-details-price text-danger">
              -{reduxresponse?.total_loyalty_points_deducted}
            </p>
          </div>
          <div className="w-100">
            <p className="cart-price-details-subject">Delivery Fee</p>
            <p className="cart-price-details-price">Free</p>
          </div>
          <div
            className="w-100 rounded"
            style={{
              background:
                reduxresponse?.discount_amount === 0 ? null : "#FEF0E8",
            }}
          >
            <p className="cart-price-details-subject">Discount</p>
            <p className="cart-price-details-price">
              QAR {reduxresponse?.discount_amount?.toFixed(2)}
            </p>
          </div>

          <div className="w-100 cart-totalprice px-2">
            <p>Total Amount</p>
            <p>QAR {reduxresponse?.total_cart_amount?.toFixed(2)}</p>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <p className="mt-3">{applyCouponBtnTxt}</p>
          {reduxresponse?.discount_amount !== 0 ? (
            <i
              className="fa fa-close text-theme"
              style={{ cursor: "pointer" }}
              onClick={() => {
                axios
                  .get(apiUrl + "/order/get_cart_data?lp_flag=" + !useLoyltyPoint, {
                    headers: {
                      Authorization: "Token " + accessToken,
                    },
                  })
                  .then((res) => {
                    if (res.data.status === 1) {
                      localStorage.removeItem("discount_coupon_id");
                      localStorage.removeItem("discount_coupon_code");
                      dispatch({
                        type: DISPLAY_CART,
                        payload: res.data,
                      });
                    }
                  }).catch((err) => {
                    const { response } = err;
                    const { data } = response;
                    const { status, message } = data;
                    if (status === "400") {
                      toast.warning(message);
                      removeUserToken()
                    } else {
                      toast.error(message);
                    }
                  });
              }}
            ></i>
          ) : null}
        </div>
        {location.pathname === "/cart" ||
          location.pathname === "/setaddress" ? (
          <>
            <div className="mt-3" />
            <button
              className="btn text-theme w-100"
              style={{ background: "#FEF1E8" }}
              onClick={() => setShowCoupon(true)}
            >
              <i className="fa fa-ticket mr-2"></i>
              Apply Coupon
            </button>
          </>
        ) : null}
        {(accessToken && location.pathname === "/payment") &&
          <>
            <textarea
              placeholder="write a note for the delivery boy"
              name="delivery_notes" id="delivery_notes"
              className="form-control mt-4"
              value={deliveryNotes}
              onChange={(e) => setDeliveryNotes(e.target.value)}
            ></textarea>
            <div class="form-check my-2">
              <input
                class="form-check-input" type="checkbox"
                name="lp_flag" id="lp_flag"
                checked={useLoyltyPoint}
                onChange={() => {
                  setUseLoyltyPoint(!useLoyltyPoint)
                  axios
                    .get(apiUrl + "/order/get_cart_data?lp_flag=" + !useLoyltyPoint, {
                      headers: {
                        Authorization: "Token " + accessToken,
                      },
                    })
                    .then((res) => {
                      if (res.data.status === 1) {
                        dispatch({
                          type: DISPLAY_CART,
                          payload: res.data,
                        });
                      }
                    }).catch((err) => {
                      const { response } = err;
                      const { data } = response;
                      const { status, message } = data;
                      if (status === "400") {
                        toast.warning(message);
                        removeUserToken()
                      } else {
                        toast.error(message);
                      }
                    });
                }}
              />
              <label class="form-check-label" for="lp_flag">
                Use loyalty points
              </label>
            </div>
          </>
        }
        <div className="cart-price-details-footer mt-3">
          {location.pathname === "/cart" ||
            location.pathname === "/cart/upload-prescription" ? (
            <>
              {/* <button
                className="btn border-btn-theme w-49 cart-price-details-footer-btn"
                onClick={OpenuploadInsurance}
              >
                Add Insurance
              </button> */}
              {reduxresponse?.data.length === 0 ? (
                <button
                  className="btn bg-theme text-light cart-price-details-footer-btn w-100"
                  disabled
                >
                  Add items first
                </button>
              ) : (
                <Link
                  to="/setaddress"
                  className="btn bg-btn-theme cart-price-details-footer-btn w-100"
                >
                  Place Order
                </Link>
              )}
            </>
          ) : (
            <>
              {location.pathname === "/setaddress" ? (
                <>
                  {reduxresponsefetchaddressReducer?.data.length === 0 ? (
                    <div className="w-100">
                      <Link
                        to="/cart"
                        className="btn border-btn-theme cart-price-details-footer-btn w-100"
                      >
                        Back
                      </Link>
                      {accessToken &&
                        <button
                          className="btn bg-btn-theme cart-price-details-footer-btn mt-2 w-100"
                          disabled
                        >
                          Add Address first
                        </button>}
                    </div>
                  ) : (
                    <div className="w-100 d-flex align-items-center justify-content-between   row-wrap">
                      <Link
                        to="/cart"
                        className="btn border-theme text-theme mt-2 cart-price-details-footer-btn mr-1 w-40"
                      >
                        Back
                      </Link>
                      <Link
                        to="/payment"
                        style={{ border: "1px solid #F5832A" }}
                        className="btn bg-btn-theme cart-price-details-footer-btn mt-2 w-60"
                      >
                        Continue to Payment
                      </Link>
                    </div>
                  )}
                </>
              ) : (
                <div className="w-100 d-flex align-items-center row-wrap justify-content-between">
                  <Link
                    to="/setaddress"
                    className="btn border-theme text-theme mt-2 cart-price-details-footer-btn mr-1 w-40"
                  >
                    Back
                  </Link>
                  <button
                    className="btn bg-btn-theme cart-price-details-footer-btn  mt-2 w-60"
                    onClick={placeorder}
                    style={{ border: "1px solid #F5832A" }}
                    disabled={
                      placeorderbtnstate === "Loading..."
                    }
                  >
                    {placeorderbtnstate}
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {/* {location.pathname === "/payment" ? (
          <div className="w-100 mt-3">
            <center>
              <GooglePayButton
                environment="TEST"
                paymentRequest={{
                  apiVersion: 2,
                  apiVersionMinor: 0,
                  allowedPaymentMethods: [
                    {
                      type: "CARD",
                      parameters: {
                        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                        allowedCardNetworks: ["MASTERCARD", "VISA"],
                      },
                      tokenizationSpecification: {
                        type: "PAYMENT_GATEWAY",
                        parameters: {
                          gateway: "example",
                          gatewayMerchantId: "exampleGatewayMerchantId",
                        },
                      },
                    },
                  ],
                  merchantInfo: {
                    merchantId: "12345678901234567890",
                    merchantName: "Demo Merchant",
                  },
                  transactionInfo: {
                    totalPriceStatus: "FINAL",
                    totalPrice: reduxresponse?.total_cart_amount?.toString(),
                    currencyCode: "QAR",
                    countryCode: "QA",
                  },
                  shippingAddressRequired: true,
                  callbackIntents: [
                    "SHIPPING_ADDRESS",
                    "PAYMENT_AUTHORIZATION",
                  ],
                }}
                onLoadPaymentData={(paymentRequest) => {
                  console.log("Success", paymentRequest);
                }}
                onPaymentAuthorized={(paymentData) => {
                  console.log("Payment Authorised Success", paymentData);
                  return { transactionState: "SUCCESS" };
                }}
                onPaymentDataChanged={(paymentData) => {
                  console.log("On Payment Data Changed", paymentData);
                  return {};
                }}
                existingPaymentMethodRequired="false"
                buttonColor="white"
                buttonType="Buy"
                className="w-100"
              />
            </center>
          </div>
        ) : null} */}
      </div>

      {showCoupon ? (
        <div className="upload-pres px-md-3 px-0 pb-5 pt-0 animate__animated animate__fadeIn animate__faster">
          <div className="w-100 d-flex flex-column align-items-center mt-3">
            <div className="w-100 pt-3 pr-3">
              <i
                className="fa fa-close float-right"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setShowCoupon(false)}
              ></i>
            </div>
            <h4>
              <i className="fa fa-ticket"></i> Apply Coupon
            </h4>
          </div>
          <AvailableOffer
            loaddata={showCoupon}
            showCouponModal={showCouponModal}
            selectedCouponCode={selectedCouponCode}
          />
        </div>
      ) : null}
    </>
  );
};

export default Cartpricedetails;
