import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./SuggetionPrdct.css";
import { addToCart, apiUrl } from "../../../redux/action/action";
import { useDispatch } from "react-redux";

const SuggetionPrdct = (data) => {
  const dispatch = useDispatch();
  const [scrollWidth, setScrollWidth] = useState(0);
  const [loader, setLoader] = useState(true);
  const [scrollamount, setscrollamount] = useState(300);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setscrollamount(120);
    }
    $("." + data.class).scroll(function () {
      var leftwidth = $(this).scrollLeft();
      setScrollWidth(leftwidth);
    });
  });

  useEffect(() => {
    if (data?.data?.length !== 0) {
      setLoader(false);
    }
  }, [data.data]);

  const controlPrev = () => {
    // if (scrollWidth > 0) {
    //   setScrollWidth(scrollWidth - 300);
    // }
    $("." + data.class).animate(
      {
        scrollLeft: scrollWidth - scrollamount,
      },
      200
    );
  };
  const controlNext = () => {
    $("." + data.class).animate(
      {
        scrollLeft: scrollWidth + scrollamount,
      },
      200
    );
  };

  return (
    data?.data?.length > 0 && (
      <Row className={`${data.bgcolor}`}>
        <Col md={12} className="p-0" id="prdctCategories">
          <div className="w-100 pb-4 px-3 px-md-0 d-flex align-items-center justify-content-between">
            <h4 className="p-0 m-0">{data.title}</h4>
            {data?.class === "top-sold-product" ||
              data?.class === "similar-product" ? (
              <Link
                to={
                  data?.class === "recently-order-item"
                    ? "/account/order-history"
                    : "/product/all"
                }
              >
                view all
              </Link>
            ) : null}
          </div>
          {loader ? (
            <SkeletonTheme
              color="#ccc"
              highlightColor="#fff"
              width={"18.5%"}
              inline={true}
            >
              <Skeleton count={5} height={250} className="mr-3" />
            </SkeletonTheme>
          ) : (
            <div className={`w-100 slider-main-div ${data.class}`}>
              <div className="slider d-flex pb-3">
                {data?.data?.map((e, i) => {
                  return (
                    <div className="tps-i border-bottom" key={i}>
                      <a
                        href={"/product-details/" + e.id}
                      // target={location.pathname === "/" ? null : "_blank"}
                      >
                        <div className="tps-i-img">
                          {e?.avg_rating > 0 ? (
                            <div className="rating text-dark">
                              <i
                                className="fa fa-star text-theme"
                                aria-hidden="true"
                              ></i>{" "}
                              {e.avg_rating}
                            </div>
                          ) : null}

                          {data?.class === "recently-order-item" ? (
                            <img
                              src={apiUrl + e?.product_images[0]}
                              alt={"img"}
                            />
                          ) : (
                            <img src={apiUrl + e.image1} alt={"img"} />
                          )}
                        </div>
                      </a>
                      <div className="tps-i-txt d-flex flex-column">
                        <p className="p-0 m-0">{e.itemName}</p>
                        <div className="d-flex justify-content-between align-items-center pt-md-3">
                          {/* <span className="text-theme py-3">
                          QAR {e.pricePerPcs}
                        </span> */}
                          {Number(e?.discounted_price_pcs) === 0 ? (
                            <span className="text-theme">
                              QAR {e?.pricePerPcs}
                            </span>
                          ) : (
                            <div>
                              <small className="text-theme" style={{ fontSize: "12px" }}>
                                QAR {e?.discounted_price_pcs}
                              </small>
                              <small
                                style={{
                                  position: "relative",
                                  marginLeft: "6px",
                                  fontSize: "12px"
                                }}
                              >
                                {e?.pricePerPcs}
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "7px",
                                    left: 0,
                                    background: "#F5832B",
                                    width: "100%",
                                    height: "1.2px",
                                  }}
                                />
                              </small>
                            </div>
                          )}

                          {e.prescription_required ? null : (
                            <i
                              className="fa fa-cart-plus"
                              onClick={async () => {
                                const cartdata = {
                                  product_id: Number(e.id),
                                  quantity: 1,
                                  unit: "pcs",
                                };
                                await dispatch(addToCart(cartdata));
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <button className="slider-control-prev" onClick={controlPrev}>
                <i className="fa fa-arrow-left text-light"></i>
              </button>
              <button className="slider-control-next" onClick={controlNext}>
                <i className="fa fa-arrow-right text-light"></i>
              </button>
            </div>
          )}
        </Col>
      </Row>
    )
  );
};
export default SuggetionPrdct;
