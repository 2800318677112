import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./Prdctcategoriesmobile.css";

const Prdctcategoriesmobile = ({ heading, data }) => {
  return (
    <>
      <Container className="prdctCategoriesmobile">
        <Row className="body-color">
          <Col
            md={12}
            className="mt-3 mb-2 p-0 d-flex align-items-center justify-content-between"
          >
            <h5 className="p-0 m-0">{heading}</h5>
            <Link to={"/product/all"}>
              <small>View All Products</small>
            </Link>
          </Col>
          <Col
            xs="12"
            className="py-2 px-0"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {data?.map((datas, index) => {
              return (
                <div className="prdctdivmob" key={index}>
                  <Link to={`product/category/${datas.name}-${datas.id}`}>
                    <center>
                      <img
                        src={datas.image}
                        alt={datas.label}
                        className="prdctimgmob"
                      />
                    </center>
                  </Link>
                  <p className="prdctlabelmob">{datas.name}</p>
                </div>
              );
            })}
            {data?.length % 3 === 2 && <div style={{ width: "30%" }} />}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Prdctcategoriesmobile;
