import { Container, Row, Col } from "react-bootstrap";
import Bottommenumob from "../../../../Footer/Bottommenumob/Bottommenumob";
import CartNavbarMob from "../../../../Header/CartNavbarMob/CartNavbarMob";
import Navbarmob from "../../../../Header/NavbarMob/Navbarmob";
import MyWishlist from "../../MyWishlist/MyWishlist";

const MobWishlist = () => {
  return (
    <>
      <Navbarmob />
      <Container>
        <Row className="body-color">
          <Col className="mt-3">
            <MyWishlist />
          </Col>
        </Row>
      </Container>
      <div className="py-4 mt-4" />
      <Bottommenumob />
    </>
  );
};

export default MobWishlist;
