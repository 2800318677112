import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import wallet_icon from "../../../../assets/wallet-solid.svg";
import { apiUrl } from "../../../../redux/action/action";
import { getUserToken, removeUserToken } from "../../../app/userToken";

const MyWallet = () => {
  const accessToken = getUserToken();
  const [loyalty, setLoyalty] = useState(null);
  // const reduxresponse = useSelector((state) => state.displayCartReducer);
  const reduxuserdata = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (accessToken) {
      axios
        .get(apiUrl + "/user/get_loyalty_points", {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          setLoyalty(res.data.data);
        })
        .catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        });
    }
  }, []);

  return (
    <>
      <div className="my-account">
        <div className="my-account-title mb-3">
          <h4 className="d-flex align-items-center">
            <img
              src={wallet_icon}
              alt="wallet_icon"
              width={22}
              className="mr-2"
            />{" "}
            My Wallet
          </h4>
        </div>
        <div className="card rounded p-4">
          <h6>
            Available Balance :{" "}
            <b>
              {reduxuserdata?.userdata?.total_loyalty_points
                ? reduxuserdata?.userdata?.total_loyalty_points
                : 0}
            </b>
          </h6>
          {/* <div className="card mt-3 p-2 rounded-lg">
            <p className="p-0 m-0">
              <b>Coins Expiry :</b> Coins are valid for 1 year from the date
              they are credited on.
            </p>
          </div> */}
          {loyalty?.length > 0 ? (
            <>
              <div className="w-100 mt-3 d-flex justify-content-between">
                <p>Recent Coin Activity</p>
                <p>Coins</p>
              </div>
              {loyalty?.map((e, i) => {
                return (
                  <div
                    className="w-100 border-top py-2 d-flex justify-content-between"
                    key={i}
                  >
                    <small>
                      {" "}
                      <b>{e.loyalty_point_reason}</b> <br />
                      <span className="text-grey">
                        {e.transaction_type === "credit"
                          ? "Credited on "
                          : "Debited on "}
                        {new Date(e.created_on).toLocaleDateString("en-us", {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </span>
                      <br />
                      {e.transaction_type === "credit" ? (
                        <span className="text-grey">
                          {new Date(e.expiry_date).getTime() <
                            new Date().getTime()
                            ? "Expired "
                            : "Expire "}
                          on {e.expiry_date}
                        </span>
                      ) : null}
                    </small>
                    <small
                      className={
                        e.transaction_type === "credit"
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      {e.transaction_type === "credit" ? "+" : "-"}
                      {e.points}
                    </small>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="w-100 mt-5 text-center">
              <h6 className="my-5 text-theme">
                You haven't received any Coins yet !!!
              </h6>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyWallet;
