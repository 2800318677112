import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import "./Prdctcategories.css";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Categories = (data) => {
  const navigate = useNavigate()
  const [scrollWidth, setScrollWidth] = useState(0);
  const [scrollleft, setScrollleft] = useState(0);
  const [loader, setLoader] = useState(true);

  // useEffect(() => {
  //   $(".slider-main-div").animate(
  //     {
  //       scrollLeft: scrollWidth,
  //     },
  //     400
  //   );
  // }, [scrollWidth]);
  useEffect(() => {
    $(".slider-main-div").scroll(function () {
      var leftwidth = $(this).scrollLeft();
      setScrollWidth(leftwidth);
    });
  });

  useEffect(() => {
    if (data?.data?.length !== 0) {
      setLoader(false);
    }
  }, [data?.data]);

  const controlPrev = () => {
    // if (scrollWidth > 0) {
    //   setScrollWidth(scrollWidth - 300);
    // }
    $(".slider-main-div").animate(
      {
        scrollLeft: scrollWidth - 300,
      },
      200
    );
  };
  const controlNext = () => {
    // if (scrollWidth - scrollleft === 300 || scrollWidth === scrollleft) {
    //   setScrollWidth(scrollWidth + 300);
    // }

    $(".slider-main-div").animate(
      {
        scrollLeft: scrollWidth + 300,
      },
      200
    );
  };

  return (
    <>
      <Row className="mt-3 body-color">
        <Col md={12} className="px-md-0">
          <div className="w-100" id="heading-sec-popcat">
            <h5>{data.heading}</h5>
            {/* <Link to={data.viewall} id="view-all-popcat">
              View All <i className="fa fa-arrow-right"></i>
            </Link> */}
          </div>
        </Col>

        <Col md={12} className="p-0" id="prdctCategories">
          {/* p-c -> popular category */}
          {/* p-c-i -> popular category item */}
          {/* p-c-i-img -> popular category item's image */}
          {/* p-c-i-txt -> popular category item's text */}
          {loader ? (
            <SkeletonTheme
              color="#ccc"
              highlightColor="#fff"
              width={"18.5%"}
              inline={true}
            >
              <Skeleton count={5} height={250} className="mr-3" />
            </SkeletonTheme>
          ) : (
            <div className="w-100 slider-main-div">
              <div
                className="slider d-flex"
                style={{ width: 20 * data?.data?.length + "%" }}
              >
                {data?.data?.length > 0
                  ? data?.data.map((data, index) => {
                    return (
                      <div className="p-c-i" key={index} onClick={() => navigate(`product/category/${data.name}-${data.id}`)}>
                        <Link to={`product/category/${data.name}-${data.id}`}>
                          <div className="p-c-i-img">
                            <img src={data.image} alt={data.name} />
                          </div>
                        </Link>
                        <div className="p-c-i-txt">
                          <h6>{data.name}</h6>
                        </div>
                      </div>
                    );
                  })
                  : null}
              </div>
              <button className="slider-control-prev" onClick={controlPrev}>
                <i className="fa fa-arrow-left text-light"></i>
              </button>
              <button className="slider-control-next" onClick={controlNext}>
                <i className="fa fa-arrow-right text-light"></i>
              </button>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Categories;
