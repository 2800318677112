import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Orderitemcard.css";

const Orderitemcard = ({ data }) => {
  let [OderDetailsUrl, setOderDetailsUrl] = useState("");
  let [InvoicePageUrl, setInvoicePageUrl] = useState("");

  const [orderPlacedDate, setOrderPlacedDate] = useState("");

  useEffect(() => {
    if (window.innerWidth > 1000) {
      setOderDetailsUrl("/account/my-order/order-details");
    } else {
      setOderDetailsUrl("/profile/my-order/order-details");
    }
    if (window.innerWidth > 1000) {
      setInvoicePageUrl("/account/my-order/invoice-page/");
    } else {
      setInvoicePageUrl("/profile/my-order/invoice-page/");
    }
  });

  useEffect(() => {
    const date = new Date(data?.orderdate);
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    setOrderPlacedDate(day + " " + months[month - 1] + " " + year);
  }, []);

  return (
    <>
      <div className="w-100 border rounded mt-2 mb-5">
        <div
          className="alert alert-theme p-0 px-2 m-0"
        // style={{ width: "fit-content" }}
        >
          {data?.status}
        </div>
        <div className="Orderitemcard-header">
          <div className="Orderitemcard-header-left">
            <div>
              <p><b>Order Placed - </b>{data?.details?.order_placed_date}</p>
            </div>
            <div>
              {/* order price */}
              <p><b>Total Order Amount -</b> QAR {data?.details?.paid_amount}</p>
            </div>
            <div>
              {/* order id */}
              {data?.details?.order_number ? (
                <p>
                  <b>
                    ORDER NO -
                  </b>
                  {data?.details?.order_number?.split("-")[1]}
                </p>
              ) : null}
            </div>
          </div>

          <div className="Orderitemcard-header-right d-flex justify-content-between">
            <div className="border p-0" />
            <div>
              {/* <button
                disabled={data?.details?.invoice_file ? false : true}
                onClick={() => {
                  let alink = document.createElement("a");
                  alink.href = data?.details?.invoice_file;
                  alink.download = "Invoice.pdf";
                  // alink.target = "_blank";
                  alink.click();
                }}
              >
                Download Invoice
              </button> */}
               <Link to={InvoicePageUrl + data?.details?.order_id}>
                <button
              className={`btn p-0 ${data?.details?.invoice_file
                ? `text-primary`
                : `text-grey`
                }`}
              disabled={!data?.details?.invoice_file}
            >
              Invoice
            </button>
              </Link>
            </div>
          </div>
        </div>
        <Orderitemsingleproduct data={data} orderdetailurl={OderDetailsUrl} />
      </div>
    </>
  );
};

const Orderitemsingleproduct = ({ data, orderdetailurl }) => {
  const navigate = useNavigate();
  const onClickViewDetails = () => {
    navigate(orderdetailurl + "/" + data?.details?.order_id);
  };

  return (
    <>
      <div className="Orderitemsingleproduct">
        <div className="w-100 btns">
          <button className="border-dark w-100" onClick={onClickViewDetails}>
            View Details
          </button>
        </div>
      </div>
    </>
  );
};

export default Orderitemcard;
