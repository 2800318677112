import axios from "axios";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../../../../redux/action/action";
import { getUserToken, removeUserToken } from "../../../../app/userToken";
import Bottommenumob from "../../../../Footer/Bottommenumob/Bottommenumob";
import Navbarmob from "../../../../Header/NavbarMob/Navbarmob";

const MobNotification = () => {
  const accessToken = getUserToken();
  const [notification, setNotification] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (accessToken) {
      axios
        .get(apiUrl + "/user/get_notifications", {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          setNotification(res.data.data);
        })
        .catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            toast.error(message);
            window.location = "/";
            removeUserToken();
          } else {
            toast.error(message);
          }
        });
    } else {
      localStorage.setItem("redirect_url", location.pathname);
      navigate("/auth/login");
    }
  }, []);

  return (
    <>
      <Navbarmob />
      <Container>
        <Row>
          <Col className="pt-3 body-color">
            <div className="w-100 border-bottom pb-2">
              <small className="text-theme">
                <i className="fa fa-bell"></i>
                &nbsp; Notification ({notification.length})
              </small>
            </div>
            <div className="notification-body">
              {notification.length > 0
                ? notification.map((e, i) => {
                  return (
                    <div key={i} className="alert alert-theme mt-0">
                      <small
                        // className="text-grey"
                        onClick={() =>
                          e.redirect_url
                            ? (window.location = e.redirect_url)
                            : null
                        }
                      >
                        {e.message}
                      </small>
                    </div>
                  );
                })
                : null}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="py-4 mt-4" />
      <Bottommenumob />
    </>
  );
};

export default MobNotification;
