/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../../../../redux/action/action";
import { getUserToken } from "../../../../app/userToken";
import OrderItemCardUp from "../OrderItemCardUp/OrderItemCardUp";
// import Orderitemcard from "../Orderitemcard/Orderitemcard";
import "./Orderdetails.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Orderdetails = () => {
  const accessToken = getUserToken();
  const { id } = useParams();
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState([]);
  const [action, setAction] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);

    try {
      const res = await axios.get(apiUrl + "/order/get_single_order_data/" + id, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      });

      if (res.data.status === 1) {
        setLoader(false);
        // alert(res.data.data);
        setOrderDetailsData(res.data.data);
      }
      else {
        setLoader(false);
      }
    } catch (error) {
      toast.error(error.toString())
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   const date = new Date(orderDetailsData?.orderdate);
  //   let months = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];
  //   const day = date.getDate();
  //   const month = date.getMonth() + 1;
  //   const year = date.getFullYear();

  //   setOrderPlacedDate(day + " " + months[month - 1] + " " + year);
  // });

  // const data = {
  //   status: "shipped",
  //   message: "Shipped - Arriving by Thu, 10 Feb",
  // };

  const handleCancelAllOrder = async () => {
    setShowModal(true);
    await loadReasons();
    setAction("cancel_orders");
  };

  const handleReturnAllOrder = async () => {
    setShowModal(true);
    await loadReasons();
    setAction("return_orders");
  };

  // load reasons
  const loadReasons = async () => {
    const res = await axios.get(apiUrl + "/master/order_cancel_return_reasons");
    setReason(res.data.data);
  };

  // select reason
  const handleSelectedReason = (e) => {
    const selectedVal = e.target.getAttribute("value");
    returnORcancel(selectedVal);
    setShowModal(false);
  };

  // request for return or cancel
  const returnORcancel = (selectedVal) => {
    try {
      axios
        .post(
          apiUrl + "/order/" + action,
          {
            order_id: id,
            reason: selectedVal,
          },
          {
            headers: {
              Authorization: "Token " + accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
          }
        });
    } catch (err) {
      try {
        toast.error(err.response.data.message);
      } catch (e) {
        toast.error(err.toString());
      }
    }
  };

  const setUpdateResponse = () => {
    setOrderDetailsData(null);
    fetchData();
  };

  return (
    <>
      <div className="my-account">
      {orderDetailsData ? <iframe 
          className="responsive-iframe"
          // src="http://tanjerinfo.com:9005/heartbeat/api/emrdocs/2023/November/23-11-2023/PharmacyCashInvoiceA5_20489_20840.pdf"
          src={orderDetailsData.details.invoice_file}
          style={{ width: '100%', height: '500px' }}
          frameBorder="0"
          title="PDF Document"
        ></iframe> : null}
        </div>
    </>
  );
};



export default Orderdetails;
