import { Modal } from "react-bootstrap";
import "./Prdctdetails.css";
import {
  addToCart,
  apiUrl,
  displayTopBarCart,
} from "../../../../redux/action/action";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { COUNT_QUANTITY } from "../../../../redux/state/state";
import UpdateQuantityCart from "../../UpdateQuantityCart/UpdateQuantityCart";
import Addtowishlist from "../../Products/Addtowishlist/Addtowishlist";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getUserToken } from "../../../app/userToken";
import axios from "axios";
import { toast } from "react-toastify";
import { compareProduct } from "../../../app/compareProduct";

const Prdctdetails = ({ data, loader }) => {
  const { id } = useParams();
  const accessToken = getUserToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const reduxresponseFilter = useSelector((state) => state);
  const [prdctid, setPrdctid] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const reduxcountquantity = useSelector((state) => state.countQuantity);
  const reduxselectItemUnit = useSelector(
    (state) => state.selectItemUnitReducer
  );
  const [loaderP, setLoaderP] = useState(true);
  const [showChangePin, setShowChangePin] = useState(false);
  const [pinVal, setPinVal] = useState();
  const [finalPin, setFinalPin] = useState(765382);
  const [AddtoCartTxt, setAddtoCartTxt] = useState("Add to Cart");
  const [addtocomparetxt, setaddtocomparetxt] = useState("Add to compare");

  useEffect(() => {
    setShow(reduxresponseFilter.wishlistReducer.state);
  }, [reduxresponseFilter.wishlistReducer]);
  useEffect(() => {
    dispatch({
      type: COUNT_QUANTITY,
      payload: 1,
    });
  }, []);

  useEffect(() => {
    setLoaderP(loader);
  }, [loader]);

  useEffect(() => {
    if (!showChangePin) {
      setPinVal("");
    }
  }, [showChangePin]);

  const add_to_cart = async () => {
    setAddtoCartTxt("Adding...");
    const cartdata = {
      product_id: data.id,
      quantity: reduxcountquantity.total,
      unit: reduxselectItemUnit.unittype,
    };
    await dispatch(addToCart(cartdata));
    await setAddtoCartTxt("Added");
  };

  const add_to_wishlist = async () => {
    if (accessToken) {
      const id = data.id.toString();
      setPrdctid(id);
      setShow(true);
    } else {
      localStorage.setItem("redirect_url", location.pathname);
      navigate("/auth/login");
    }
  };

  const onChangeUpdatePin = (e) => {
    const pin = e.target.value;
    if (pin.length <= 6) {
      if (!isNaN(pin)) {
        setPinVal(pin);
      }
    }
  };

  const onClickUpdatePin = () => {
    setFinalPin(pinVal);
    setShowChangePin(false);
  };

  return (
    <>
      <div className="w-100 main-shadow mt-md-0 mt-4">
        {/* <div className="recent-bought">
          <small>
            <i className="fa fa-users"></i>
            &nbsp; 712 people bought this recently
          </small>
        </div> */}
        <div className="w-100 mt-4 px-4">
          {loaderP ? (
            <SkeletonTheme color="#ccc" highlightColor="#fff" width={"300px"}>
              <Skeleton count={2} height={"30px"} />
            </SkeletonTheme>
          ) : (
            // <p>Flavour - Tasty Chocolate</p>
            <></>
          )}
        </div>
        <div className="w-100 mt-4 px-3">
          <div className="w-100 p-0 ml-1">
            <p>Quantity</p>
          </div>
          <div className="w-100">
            {loaderP ? (
              <SkeletonTheme color="#ccc" highlightColor="#fff" width={"300px"}>
                <Skeleton count={1} height={"20px"} />
              </SkeletonTheme>
            ) : (
              !data?.prescription_required && (
                <UpdateQuantityCart
                  data={data}
                  totalQ={reduxcountquantity.total}
                  selectunit={reduxselectItemUnit?.unit}
                />
              )
            )}
          </div>
        </div>
        <div className="w-100 mt-3 px-3">
          {loaderP ? (
            <SkeletonTheme color="#ccc" highlightColor="#fff" width={"200px"}>
              <Skeleton count={1} height={"20px"} />
            </SkeletonTheme>
          ) : (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex prdctdetail-price">
                {reduxselectItemUnit.unittype === "pack" ? (
                  data.discounted_price_pack === 0 ? (
                    <small className="p-0 m-0 text-theme">
                      QAR{" "}
                      {(data.pricePerPack * reduxcountquantity.total).toFixed(
                        2
                      )}
                    </small>
                  ) : (
                    <small className="p-0 m-0 text-theme d-flex align-items-center">
                      QAR{" "}
                      {(
                        data.discounted_price_pack * reduxcountquantity.total
                      ).toFixed(2)}
                      &nbsp;&nbsp;
                      <small className="p-0 m-0 text-grey d-flex">
                        <span
                          style={{
                            position: "relative",
                          }}
                        >
                          {data.pricePerPack}
                          <div
                            style={{
                              position: "absolute",
                              top: "9px",
                              left: 0,
                              background: "#F5832B",
                              width: "100%",
                              height: "2px",
                            }}
                          />
                        </span>
                      </small>
                    </small>
                  )
                ) : data.discounted_price_pcs === 0 ? (
                  <small className="p-0 m-0 text-theme">
                    QAR{" "}
                    {(data.pricePerPcs * reduxcountquantity.total).toFixed(2)}
                  </small>
                ) : (
                  <small className="p-0 m-0 text-theme d-flex align-items-center">
                    QAR{" "}
                    {(
                      data.discounted_price_pcs * reduxcountquantity.total
                    ).toFixed(2)}
                    &nbsp;&nbsp;
                    <small className="p-0 m-0 text-grey d-flex">
                      <span
                        style={{
                          position: "relative",
                        }}
                      >
                        {data.pricePerPcs}
                        <div
                          style={{
                            position: "absolute",
                            top: "9px",
                            left: 0,
                            background: "#F5832B",
                            width: "100%",
                            height: "2px",
                          }}
                        />
                      </span>
                    </small>
                  </small>
                )}
              </div>
              {data?.in_stock ? (
                <div className="alert alert-success p-0 px-2 stock-or-outOfStuck">
                  <small>In Stock</small>
                </div>
              ) : (
                <div className="alert alert-danger p-0 px-2 stock-or-outOfStuck">
                  <small>Out of Stock</small>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="w-100 cart-coupon p-3 border-top mt-3">
          <div className="w-100 d-flex justify-content-center">
            {!data?.prescription_required ? (
              <div className="w-25">
                {data?.wishlist_status ? (
                  <button
                    className="btn border-dark py-2 px-0"
                    style={{ width: "70%", cursor: "default" }}
                  >
                    <i className="fa fa-heart text-theme"></i>
                  </button>
                ) : (
                  <button
                    className="btn border-dark py-2 px-0"
                    style={{ width: "70%" }}
                    onClick={add_to_wishlist}
                  >
                    <i className="fa fa-heart-o"></i>
                  </button>
                )}
              </div>
            ) : null}
            <div className="w-75">
              {data?.prescription_required ? (
                <button
                  className="btn bg-theme text-light py-2"
                  onClick={() => navigate("/upload-prescription")}
                >
                  Upload Prescription
                </button>
              ) : (
                <button
                  className="btn bg-theme text-light py-2"
                  disabled={!data?.in_stock || AddtoCartTxt === "Adding..."}
                  onClick={add_to_cart}
                >
                  {AddtoCartTxt}
                </button>
              )}
            </div>
          </div>

          <button
            className="btn border-theme text-theme mt-3"
            onClick={() => {
              setaddtocomparetxt("Adding...");
              compareProduct(data);
            }}
          >
            {addtocomparetxt}
          </button>
        </div>
      </div>

      {/* address */}
      <div className="delivery-payment border">
        <h5>Delivery & Services for</h5>
        {/* <button onClick={() => setShowChangePin(true)}>
          <small>{finalPin}</small>
          <small className="text-theme">change</small>
        </button> */}
        <div className="w-100 d-flex flex-column">
          {/* <small>
            <i className="fa fa-truck"></i>
            &nbsp;&nbsp; Get it by Thursday, 10 Feb
          </small> */}
          <small>
            <i className="fa fa-credit-card"></i>
            &nbsp;&nbsp; Online Payment
          </small>
          {/* <small>
            <i className="fa fa-exchange"></i>
            &nbsp;&nbsp; The Product is not returnable
          </small> */}
        </div>

        {showChangePin ? (
          <div className="change-pin-field animate__animated animate__fadeIn animate__faster">
            <i
              className="fa fa-close"
              onClick={() => setShowChangePin(false)}
            ></i>
            <div>
              <input
                type="text"
                className="form-control"
                value={pinVal}
                onChange={onChangeUpdatePin}
                placeholder="2 1 2 1 2 1"
              />
              <button className="btn bg-theme" onClick={onClickUpdatePin}>
                Update
              </button>
            </div>
          </div>
        ) : null}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Addtowishlist prdctid={prdctid} action="add_wishlist" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Prdctdetails;
