import { Card } from "react-bootstrap";
import $ from "jquery";
import upload_insu from "../../../assets/upload_insu.png";
import upload_pres from "../../../assets/upload_pres.png";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../../redux/action/action";
import { toast } from "react-toastify";
import pdficon from "../../../assets/pdf-icon.png";
import imgicon from "../../../assets/img-icon.png";
import { useSelector } from "react-redux";
import { getUserToken, removeUserToken } from "../../app/userToken";
import { useEffect } from "react";
import UploadInsuranceCpm from "./UploadInsuranceCmp";
import ImageViewer from "../../app/components/ImageViewer";

const ActionCmp = () => {
  const accessToken = getUserToken();
  const location = useLocation();
  const navigate = useNavigate();
  const reduxuserdata = useSelector((state) => state.userReducer);
  const [insuranceConfirmShow, setInsuranceConfirmShow] = useState(false);
  const [uploadInsuranceShow, setUploadInsuranceShow] = useState(false);
  const [savebtnstate, setsavebtnstate] = useState("Proceed");
  const [imageError, setImageError] = useState(false);
  const [uploadtxtPlaceholader, setuploadtxtPlaceholader] = useState(
    `Upload only .jpg, .png and .pdf files. Size limit is 10 MB`
  );
  const [uploadPrescriptionShow, setUploadPrescriptionShow] = useState(false);
  const [presName, setPresName] = useState();
  const [presRemark, setPresRemark] = useState();

  const [SavePresBtnState, setSavePresBtnState] = useState(false);
  const [prevPresImg, setPrevPresImg] = useState(null);
  const [displayMyPrescriptionShow, setDisplayMyPrescriptionShow] =
    useState(false);
  const [myPrescriptionList, setMyPrescriptionList] = useState([]);
  const [loaderPresList, setLoaderPresList] = useState(false);
  const [hideNext, setHideNext] = useState(false);
  // start
  const [addremark, setaddremark] = useState(false);
  const [updatedRemark, setUpdatedRemark] = useState(null);
  const [add, setadd] = useState("Add");
  // end

  useEffect(() => {
    if (
      sessionStorage.getItem("prescription_uuid") ||
      sessionStorage.getItem("prescription_id")
    ) {
      const prescription_id = sessionStorage.getItem("prescription_id");
      axios
        .get(apiUrl + "/order/get_single_prescription/" + prescription_id, {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          const { data } = res;
          const { status, message } = data;
          sessionStorage.removeItem("prescription_id");
          sessionStorage.removeItem("prescription_uuid");
          if (status === 200) {
            setHideNext(true);
            setPrevPresImg(data.data);
            setPresName(data.data.name);
            setPresRemark(data.data.remark);

            localStorage.setItem("saved_pres_data", JSON.stringify(data.data));
          } else {
            toast.warning(message);
          }
        })
        .catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        });
    }
  }, []);

  const handleuploadPres = async (e) => {
    e.preventDefault();
    // const form = e.target;
    const formData = new FormData();
    formData.append("file", prevPresImg);
    formData.append("name", presName);
    if (presRemark) {
      formData.append("remark", presRemark);
    }
    const prescription = formData.get("file");

    // check file size
    if (prescription.size >= 10000000) {
      toast.warning("Please upload a file less than 10MB");
    } else {
      if (presName && presName.length >= 2) {
        setSavePresBtnState(true);
        setsavebtnstate("Wait...");
        try {
          const res = await axios.post(
            apiUrl + "/order/add_prescriptions",
            formData,
            {
              headers: {
                Authorization: "Token " + accessToken,
              },
            }
          );
          // check response status and display alert
          if (res.data.status === 200) {
            //success
            setsavebtnstate("Saved");
            toast.success(res.data.message);
            $(".upload-field-box").trigger("reset");
            setPrevPresImg(res.data.data);
            localStorage.setItem(
              "saved_pres_data",
              JSON.stringify(res.data.data)
            );
            setInsuranceConfirmShow(true);
          } else {
            toast.warning(res.data.message);
            setsavebtnstate("Save");
            setSavePresBtnState(false);
          }
        }
        catch (err) {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        }
      } else {
        toast.warning(
          "Prescription name required and should be atleast 2 character"
        );
      }
    }
  };

  const [previewPres, setPreviewPres] = useState(false);
  const [previewPresImg, setPreviewPresImg] = useState(null);

  return (
    <>
      <Card body className="p-md-3 p-1">
        <p className="p-0 m-0">
          Choose from the following to upload prescription:
        </p>
        <small className="text-theme">{uploadtxtPlaceholader}</small>
        <div className="w-100 pre-browse-btn mt-md-4 mt-1">
          <button
            className="btn border-dark mt-2 mr-2"
            onClick={() => {
              setLoaderPresList(true)
              if (accessToken) {
                if (reduxuserdata.userdata?.email) {
                  if (
                    reduxuserdata.userdata?.passport_no ||
                    reduxuserdata.userdata?.national_id
                  ) {
                    setDisplayMyPrescriptionShow(true);
                    // fetch prescription list
                    axios
                      .get(apiUrl + "/order/get_all_prescriptions", {
                        headers: {
                          Authorization: "Token " + accessToken,
                        },
                      })
                      .then((res) => {
                        setMyPrescriptionList(res.data.data);
                        localStorage.removeItem("redirect_url");
                        setLoaderPresList(false)
                      })
                      .catch((err) => {
                        setLoaderPresList(false)
                        const { response } = err;
                        const { data } = response;
                        const { status, message } = data;
                        if (status === "400") {
                          toast.warning(message);
                          removeUserToken()
                        } else {
                          toast.error(message);
                        }
                      });
                  } else {
                    toast.warning("Passport or national id required");
                    localStorage.setItem("redirect_url", location.pathname);
                    setTimeout(() => {
                      if (window.innerWidth < 768) {
                        navigate("/profile/my-account");
                      } else {
                        navigate("/account/my-account");
                      }
                    }, 600);
                  }
                } else {
                  toast.warning("Passport or national id required");
                  localStorage.setItem("redirect_url", location.pathname);
                  setTimeout(() => {
                    if (window.innerWidth < 768) {
                      navigate("/profile/my-account");
                    } else {
                      navigate("/account/my-account");
                    }
                  }, 600);
                }
              } else {
                localStorage.setItem("redirect_url", location.pathname);
                navigate("/auth/login");
              }
            }}
          >
            Browse from My Prescriptions
          </button>
          <button
            className="btn border-theme text-theme mt-2 mr-2"
            onClick={() => {
              if (accessToken) {
                if (reduxuserdata.userdata?.email) {
                  if (
                    reduxuserdata.userdata?.passport_no ||
                    reduxuserdata.userdata?.national_id
                  ) {
                    var input = document.createElement("input");
                    input.type = "file";
                    input.accept = "image/*,.pdf";
                    input.click();
                    input.onchange = (e) => {
                      if (
                        e.target.files[0] &&
                        [
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                          "application/pdf",
                        ].some((x) => x === e.target.files[0]?.type)
                      ) {
                        setPrevPresImg(e.target.files[0]);
                        setImageError(false);
                      } else {
                        setImageError(true);
                        setuploadtxtPlaceholader(
                          <span style={{ fontSize: "12px", color: "red" }}>
                            Attach File JPEG, PNG or PDF format (Max File Size :
                            3mb)
                          </span>
                        );
                      }
                    };
                    localStorage.removeItem("redirect_url");
                  } else {
                    toast.warning("Passport or national id required");
                    localStorage.setItem("redirect_url", location.pathname);
                    setTimeout(() => {
                      if (window.innerWidth < 768) {
                        navigate("/profile/my-account");
                      } else {
                        navigate("/account/my-account");
                      }
                    }, 600);
                  }
                } else {
                  toast.warning("Compelete your profile");
                  localStorage.setItem("redirect_url", location.pathname);
                  setTimeout(() => {
                    if (window.innerWidth < 768) {
                      navigate("/profile/my-account");
                    } else {
                      navigate("/account/my-account");
                    }
                  }, 600);
                }
              } else {
                localStorage.setItem("redirect_url", location.pathname);
                navigate("/auth/login");
              }
            }}
          >
            Browse from Gallery (Max Size 10MB)
          </button>
        </div>

        {prevPresImg && !imageError ? (
          <>
            <div className="w-100 pre-view-pres mt-4">
              {prevPresImg?.name}
              <i className="fa fa-close" onClick={() => setPrevPresImg("")}></i>
            </div>
            <input
              type="text"
              name="name"
              value={presName}
              onChange={(e) => setPresName(e.target.value)}
              required
              className="form-control mt-3"
              placeholder="Enter prescription name"
            />
            <textarea
              name="remark"
              rows="2"
              maxLength={50}
              className="form-control mt-3"
              placeholder="Enter your remark"
              value={presRemark}
              onChange={(e) => setPresRemark(e.target.value)}
            ></textarea>
            {!hideNext ? (
              <button
                className="btn bg-theme text-light float-right mt-4"
                onClick={handleuploadPres}
                disabled={SavePresBtnState}
              >
                {/* Continue to Add Delivery Address */}
                {savebtnstate}
              </button>
            ) : null}
          </>
        ) : null}

        {hideNext && prevPresImg ? (
          <div className="w-100 mt-3">
            <button
              className="btn bg-theme text-light float-right"
              onClick={() => {
                const pres_local_data = JSON.parse(localStorage.getItem("saved_pres_data"));
                pres_local_data.remark = presRemark;
                localStorage.setItem("saved_pres_data", JSON.stringify(pres_local_data));
                localStorage.removeItem("saved_insu_data");
                setInsuranceConfirmShow(true);
              }}
            >
              Next
              <i className="fa fa-arrow-right ml-2"></i>
            </button>
          </div>
        ) : null}
      </Card>

      {/* popup */}

      {displayMyPrescriptionShow ? (
        <>
          <div className="upload-pres animate__animated animate__fadeIn animate__faster">
            <div className="w-100 pt-3 pr-3">
              <i
                className="fa fa-close float-right"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setDisplayMyPrescriptionShow(false)}
              ></i>
            </div>
            <div className="w-100 d-flex flex-column align-items-center pt-4">
              <img src={upload_pres} alt="icon" />
              <h4>Select Prescription</h4>
            </div>
            <div className="w-100 p-4">
              {loaderPresList ? (
                <p className="text-center">Loading...</p>
              ) : myPrescriptionList?.length > 0 ? (
                myPrescriptionList?.map((e) => {
                  return (
                    <div
                      key={e.id}
                      className="w-100 border border-dark px-3 py-2 mt-2 rounded d-flex align-items-center justify-content-between"
                    >
                      {e.file.indexOf(".pdf") >= 0 ? (
                        <img src={pdficon} alt="pdficon" width={20} />
                      ) : (
                        <img src={imgicon} alt="imgicon" width={20} />
                      )}
                      <span className="truncate">{e.name}</span>

                      <button
                        className="btn border-theme text-theme py-1"
                        style={{ fontSize: "13px" }}
                        onClick={() => {
                          setPreviewPresImg(e?.file)
                          setPreviewPres(true)
                          setDisplayMyPrescriptionShow(false)
                        }}
                      >
                        Preview
                      </button>

                      <button
                        className="btn bg-theme text-light py-1"
                        style={{ fontSize: "13px" }}
                        onClick={() => {
                          localStorage.setItem(
                            "saved_pres_data",
                            JSON.stringify(e)
                          );
                          // setPrevPresImg(e);
                          // setInsuranceConfirmShow(true);    ...
                          setaddremark(true);
                          setDisplayMyPrescriptionShow(false);
                        }}
                      >
                        Select
                      </button>
                    </div>
                  );
                })
              ) : (
                <p className="text-center">No Prescription</p>
              )}
            </div>
          </div>
        </>
      ) : null}

      <ImageViewer
        show={previewPres}
        setShow={setPreviewPres}
        url={apiUrl + previewPresImg}
      />
      {/*     start  update code */}
      {addremark ? (
        <>
          <div className="upload-pres animate__animated animate__fadeIn animate__faster ">
            <div className="w-100 pt-3 pr-3">
              <i
                className="fa fa-close float-right"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setaddremark(false);
                  setHideNext(true);
                  const pres = JSON.parse(
                    localStorage.getItem("saved_pres_data")
                  );
                  setPresName(pres?.name);
                  setPresRemark(pres?.remark);
                  setPrevPresImg(pres);
                }}
              ></i>
            </div>
            <div className="w-100 d-flex flex-column align-items-center">
              <h4>Add Remark</h4>
            </div>
            <div className="w-100 px-md-5 px-3 mt-3">
              <textarea
                value={updatedRemark}
                onChange={(e) => setUpdatedRemark(e.target.value)}
                maxLength={50}
                className="form-control px-3"
                rows={2}
                placeholder="Add new remark"
              ></textarea>
              <button
                className="btn bg-theme text-light py-1 float-right my-3"
                onClick={() => {
                  if (updatedRemark && updatedRemark.length >= 2) {
                    setadd("Adding...");
                    const pres = JSON.parse(
                      localStorage.getItem("saved_pres_data")
                    );
                    setPresName(pres?.name);
                    setPresRemark(pres?.remark);
                    pres["remark"] = updatedRemark;
                    localStorage.setItem(
                      "saved_pres_data",
                      JSON.stringify(pres)
                    );
                    setTimeout(() => {
                      setPrevPresImg(pres);
                      setadd("Add");
                      // addremark
                      setaddremark(false);
                      setInsuranceConfirmShow(true);
                      setUpdatedRemark(null);
                    }, 500);
                  } else {
                    toast.warning(
                      "Remark required and it should be atleast 2 character"
                    );
                  }
                }}
              >
                {add}
              </button>

              <button
                className="btn border-theme text-theme py-1 float-right my-3 mr-3"
                onClick={() => {
                  setaddremark(false);
                  setHideNext(true);
                  const pres = JSON.parse(
                    localStorage.getItem("saved_pres_data")
                  );
                  setPresName(pres?.name);
                  setPresRemark(pres?.remark);
                  setPrevPresImg(pres);
                }}
              >
                Skip
              </button>
            </div>
          </div>
        </>
      ) : null}
      {/* end update code */}

      {insuranceConfirmShow ? (
        <div className="insurance-confirm py-5">
          {/* <i
            className="fa fa-close"
            onClick={() => {
              setInsuranceConfirmShow(false);
              // setHideNext(true);
            }}
          ></i> */}
          <div className="icons">
            <img src={upload_insu} alt="upload_insu" />
          </div>
          <small className="mt-3">Do you have any insurance to upload ?</small>
          <div className="mt-4">
            <button
              className="btn border-dark mr-2"
              onClick={() => {
                navigate("/prescription-checkout");
                localStorage.removeItem("saved_insu_data");
              }}
            >
              No,I don't
            </button>
            <button
              className="btn bg-theme text-light"
              onClick={() => {
                setUploadInsuranceShow(true);
                setInsuranceConfirmShow(false);
              }}
            >
              Yes,I have
            </button>
          </div>
        </div>
      ) : null}

      {uploadInsuranceShow ? (
        <UploadInsuranceCpm setUploadInsuranceShow={setUploadInsuranceShow} />
      ) : null}
    </>
  );
};

export default ActionCmp;
