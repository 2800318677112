import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./MobSearch.css";
import axios from "axios";
import { apiUrl } from "../../../redux/action/action";
import { ListGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import $ from "jquery";

const MobSearch = ({ showSearchCmp, setShowSearchCmp }) => {
  const navigate = useNavigate();
  const [prdctkeys, setPrdctkeys] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    $("#keyinput").focus();
  }, []);

  const searchOninput = async (e) => {
    const val = e.target.value;
    if (val === "") {
      setPrdctkeys([]);
    } else {
      setLoader(true);
      await axios.get(apiUrl + "/search/products/" + val).then((res) => {
        if (res.data) {
          setLoader(false);
          setPrdctkeys(res.data.data);
        }
      });
      // await axios
      //   .get(apiUrl + "/products/getData?search_string=" + val)
      //   .then((res) => {
      //     if (res.data) {
      //       setLoader(false);
      //       setPrdctkeys(res.data);
      //     }
      //   });
    }
  };

  const onsubmitsearchinput = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const keyinput = formData.get("keyinput");
    if (keyinput !== "") {
      navigate("/product/search/" + keyinput);
      setShowSearchCmp(false);
      window.location.reload(false);
    }
  };
  const onClickredirect = (e) => {
    // localStorage.setItem("searchkey", e.target.getAttribute("data"));
    navigate("/product/search/" + e.target.getAttribute("data"));
    setShowSearchCmp(false);
    window.location.reload(false);
  };

  return (
    <>
      {showSearchCmp && (
        <Container
          fluid
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            background: "#fff",
            height: "100vh",
            zIndex: 100000,
          }}
        >
          <Row className="sticky-top">
            <Col className="d-flex align-items-center border-bottom border-dark p-0 my-2 py-1">
              <div className="search-back-icon-div">
                <i
                  className="fa fa-arrow-left"
                  onClick={() => setShowSearchCmp(false)}
                ></i>
              </div>
              <div className="search-form-div">
                <form onSubmit={onsubmitsearchinput}>
                  <input
                    type="search"
                    name="keyinput"
                    className="form-control"
                    id="keyinput"
                    placeholder="Search for products, brands and more"
                    onInput={searchOninput}
                    autoComplete="off"
                    autoFocus
                  />
                </form>
              </div>
            </Col>
          </Row>
          <Row>
            {loader ? (
              <SkeletonTheme
                color="#ccc"
                highlightColor="#fff"
                width={"100%"}
                inline={true}
              >
                <Skeleton count={25} height={15} />
              </SkeletonTheme>
            ) : (
              <ListGroup variant="flush">
                {prdctkeys.map((data, index) => {
                  return (
                    <ListGroup.Item
                      key={index}
                      onClick={onClickredirect}
                      data={data.itemName}
                    >
                      {data.itemName}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default MobSearch;
