import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./Upload.css";
import prescriptionImg from "../../../../assets/home/prescription 1.png";
import insuranceImg from "../../../../assets/home/health-insurance 1.png";
import axios from "axios";
import { apiUrl } from "../../../../redux/action/action";
import { toast } from "react-toastify";
import { getUserToken, removeUserToken } from "../../../app/userToken";

const Upload = () => {
  const accessToken = getUserToken();
  const [uploadInsuranceShow, setUploadInsuranceShow] = useState(false);
  const [PlaceholderTXT, setPlaceholderTXT] = useState(
    "Attach File JPEG, PNG or PDF format (Max File Size : 5mb)"
  );
  const [insurancefile, setInsurancefile] = useState(null);
  const [InsuranceType, setInsuranceType] = useState(null);
  const [SubInsurance, setSubInsurance] = useState(null);
  const [InsurancePremiumNo, setInsurancePremiumNo] = useState(null);
  const [InsuranceExpiryDate, setInsuranceExpiryDate] = useState(null);
  const [insuranceList, setInsuranceList] = useState(null);
  const [subinsuranceList, setSubInsuranceList] = useState(null);
  const [SaveInsuBtnState, setSaveInsuBtnState] = useState(false);
  const [savebtnstateInsu, setSavebtnstateInsu] = useState("Save");

  const handleSaveInsurance = () => {
    if (
      insurancefile &&
      InsuranceType &&
      SubInsurance &&
      InsurancePremiumNo &&
      InsuranceExpiryDate
    ) {
      setSaveInsuBtnState(true);
      setSavebtnstateInsu("Saving...");
      const formData = new FormData();
      formData.append("file", insurancefile);
      formData.append("insurance_id", InsuranceType);
      formData.append("sub_insurance_id", SubInsurance);
      formData.append("premium_no", InsurancePremiumNo);
      formData.append("expiry_date", InsuranceExpiryDate);
      axios
        .post(apiUrl + "/user/save_insurance", formData, {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          setSaveInsuBtnState(false);
          if (res.data.status === 400) {
            toast.error(res.data.message);
            setSavebtnstateInsu("Save");
          } else {
            toast.success(res.data.message);
            setInsurancefile("");
            setInsuranceType("");
            setSubInsurance("");
            setInsurancePremiumNo("");
            setInsuranceExpiryDate("");
            setPlaceholderTXT(
              "Attach File JPEG, PNG or PDF format (Max File Size : 3mb)"
            );
            setSavebtnstateInsu("Saved");
            setTimeout(() => {
              setSavebtnstateInsu("Save");
            }, 2000);
          }
        })
        .catch((err) => {
          setSaveInsuBtnState(false);
          setSavebtnstateInsu("Save");
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        });
    } else {
      toast.warning("All fields are required");
    }
  };

  return (
    <>
      <Row className="pt-0 body-color">
        <Col md={12} className="py-0">
          <Link to="/upload-prescription">
            <div id="order-prescription">
              <div>
                <p className="p-0 m-0 text-dark">Order with prescription</p>
                Upload Prescription <i className="fa fa-arrow-right"></i>
              </div>
              <div>
                <img src={prescriptionImg} alt="Prescription" />
              </div>
            </div>
          </Link>
        </Col>
        {/* <Col md={6} className="py-md-3 py-0 mt-md-0 mt-2">
          <div
            id="order-insurance"
            onClick={() => {
              setUploadInsuranceShow(true);
              // axios request for insurance list
              axios
                .get(apiUrl + "/master/get_insurance_list")
                .then((res) => {
                  setInsuranceList(res.data.data);
                })
                .catch((err) => {
                  toast.error(err.toString());
                });
            }}
          >
            <div>
              <p className="p-0 m-0">Order with insurance</p>
              <Link to="#">
                Upload Insurance <i className="fa fa-arrow-right"></i>
              </Link>
            </div>
            <div>
              <img src={insuranceImg} alt="Insurance" />
            </div>
          </div>
        </Col> */}
      </Row>

      {uploadInsuranceShow ? (
        <div className="upload-insu">
          <div className="w-100 d-flex flex-column align-items-center pt-4">
            <img src={insuranceImg} alt="icon" />
            <h5>Upload Insurance</h5>
          </div>
          <div className="w-100 px-4 mt-4">
            <div className="w-100 upload-field-box">
              <div className="input-group rounded mb-3 input-group-lg">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-paperclip"></i>
                  </span>
                </div>
                <span className="form-control upload-input-placeholader-txt">
                  <small>{PlaceholderTXT}</small>
                  <input
                    type="file"
                    accept="image/*, .pdf"
                    onChange={(e) => {
                      setInsurancefile(e.target.files[0]);
                      setPlaceholderTXT(e.target.files[0].name);
                    }}
                  />
                </span>
              </div>

              <div className="w-100 d-flex">
                <div className="w-50 mr-1">
                  <div className="input-group-cust">
                    <label htmlFor="InsuranceType" className="text-grey">
                      Insurance Type
                    </label>
                    <select
                      name="InsuranceType"
                      id="InsuranceType"
                      className="form-control"
                      value={InsuranceType}
                      onChange={(e) => {
                        setInsuranceType(e.target.value);
                        // axios request for sub insurance list
                        axios
                          .get(
                            apiUrl +
                            "/master/get_sub_insurance_list?insurance_id=" +
                            e.target.value
                          )
                          .then((res) => {
                            setSubInsuranceList(res.data.data);
                          })
                          .catch((err) => {
                            toast.error(err.toString());
                          });
                      }}
                    >
                      <option value={null}>Select</option>
                      {insuranceList?.map((e) => {
                        return (
                          <option value={e.id}>
                            {e.insuranceName} ({e.insuranceType})
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="input-group-cust mt-2">
                    <label htmlFor="InsurancePremiumNo" className="text-grey">
                      Insurance Premium No.
                    </label>
                    <input
                      type="number"
                      name="InsurancePremiumNo"
                      className="form-control"
                      id="InsurancePremiumNo"
                      value={InsurancePremiumNo}
                      onChange={(e) => setInsurancePremiumNo(e.target.value)}
                    />
                  </div>
                </div>

                <div className="w-50 ml-1">
                  <div className="input-group-cust">
                    <label htmlFor="SubInsurance" className="text-grey">
                      Sub Insurance
                    </label>
                    <select
                      name="SubInsurance"
                      className="form-control"
                      id="SubInsurance"
                      value={SubInsurance}
                      onChange={(e) => setSubInsurance(e.target.value)}
                    >
                      <option value={null}>Select</option>
                      {subinsuranceList?.map((e) => {
                        return e.is_active ? (
                          <option value={e.id}>{e.subInsuranceName}</option>
                        ) : null;
                      })}
                    </select>
                  </div>
                  <div className="input-group-cust mt-2">
                    <label htmlFor="InsuranceExpiryDate" className="text-grey">
                      Insurance Expiry Date
                    </label>
                    <input
                      type="date"
                      name="InsuranceExpiryDate"
                      className="form-control"
                      id="InsuranceExpiryDate"
                      value={InsuranceExpiryDate}
                      onChange={(e) => {
                        if (
                          new Date(e.target.value).getTime() >
                          new Date().getTime()
                        ) {
                          setInsuranceExpiryDate(e.target.value);
                        } else {
                          toast.warning("Please select a future date");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 mt-3 py-2 px-3 cart-NB">
                <small className="text-theme">
                  Medicines with insurance will be delivered after verification.
                  Payment will be done after approval.
                </small>
              </div>

              <div className="w-100 d-flex justify-content-center mt-4 upload-action-btns">
                <button
                  className="btn border-btn-theme"
                  onClick={() => setUploadInsuranceShow(false)}
                >
                  Close
                </button>
                <button
                  className="btn bg-btn-theme"
                  onClick={handleSaveInsurance}
                  disabled={SaveInsuBtnState}
                >
                  {savebtnstateInsu}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Upload;
