import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Routerscom from "./pages/Router";
import storage from "./redux/storage";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Provider store={storage}>
        <Routerscom />
      </Provider>
      {/* for toastify */}
      <ToastContainer />
    </>
  );
}

export default App;
