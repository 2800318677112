import {
  NUMBER,
  ALL_PRODUCTS,
  LOADER,
  ADD_FILTER_BY_CATEGORY,
  ADD_FILTER_BY_BRAND,
  ADD_FILTER_BY_PRODUCT_FORM,
  ADD_FILTER_BY_AGE_GROUP,
  SORT,
  PAY_ON_DELIVERY,
  CARD_SAVED,
  CARD_WITHOUT_SAVED,
  DISPLAY_WISHLIST,
  SELECT_UNIT,
  IS_PRESCRIPTION,
  IS_NOT_PRESCRIPTION,
} from "../state/state";

const reducer = (state = {}, action) => {
  switch (action.type) {
    // return phone number
    case NUMBER:
      return {
        number: action.payload,
      };
    // return all products
    case ALL_PRODUCTS:
      return {
        allProducts: action.payload,
      };
    default:
      return state;
  }
};

const sortReducer = (state = { data: "none" }, action) => {
  switch (action.type) {
    case SORT:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

const withPresfilterReducer = (state = { prescription: false }, action) => {
  switch (action.type) {
    case IS_PRESCRIPTION:
      return {
        prescription: action.payload,
      };
    default:
      return state;
  }
};

const withOutPresfilterReducer = (
  state = { not_prescription: false },
  action
) => {
  switch (action.type) {
    case IS_NOT_PRESCRIPTION:
      return {
        not_prescription: action.payload,
      };
    default:
      return state;
  }
};

const loaderReducer = (state = true, action) => {
  switch (action.type) {
    case LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

const initialCategoryfilter = {
  category_id: [],
  category_name: [],
  sub_category_id: [],
  sub_category_name: [],
};
// reducer for filter by category
const filterByCategory = (state = initialCategoryfilter, action) => {
  switch (action.type) {
    case ADD_FILTER_BY_CATEGORY:
      // console.log(action.payload);
      return {
        ...state,
        category_id: action.payload.category_id
          ? [...state.category_id, action.payload.category_id]
          : [],
        category_name: action.payload.category_name
          ? [...state.category_name, action.payload.category_name]
          : [],
        sub_category_id: action.payload?.sub_category_id
          ? [...state.sub_category_id, action.payload?.sub_category_id]
          : [],
        sub_category_name: action.payload.sub_category_name
          ? [...state.sub_category_name, action.payload.sub_category_name]
          : [],
      };
    case "DELETE_FILTER_BY_CATEGORY":
      return {
        ...state,
        category_id: [
          ...state.category_id.filter(
            (item) => item !== action.payload.category_id
          ),
        ],
        category_name: [
          ...state.category_name.filter(
            (item) => item !== action.payload.category_name
          ),
        ],
        sub_category_id: [
          ...state.sub_category_id.filter(
            (item) => item !== action.payload.sub_category_id
          ),
        ],
        sub_category_name: [
          ...state.sub_category_name.filter(
            (item) => item !== action.payload.sub_category_name
          ),
        ],
      };
    case "CLEAR_FILTER_BY_CATEGORY":
      return {
        ...state,
        category_id: [],
        category_name: [],
        sub_category_id: [],
        sub_category_name: [],
      };
    default:
      return state;
  }
};

// reducer for filter by brand
const initialBrandfilter = {
  brand: [],
};
const filterByBrand = (state = initialBrandfilter, action) => {
  switch (action.type) {
    case ADD_FILTER_BY_BRAND:
      return {
        ...state,
        brand: [...state.brand, action.payload],
      };
    case "DELETE_FILTER_BY_BRAND":
      return {
        ...state,
        brand: [...state.brand.filter((item) => item !== action.payload)],
      };
    case "CLEAR_FILTER_BY_BRAND":
      return {
        ...state,
        brand: [],
      };
    default:
      return state;
  }
};

// reducer for filter by product form
const initialProductFormfilter = {
  productForm: [],
};
const filterByProductForm = (state = initialProductFormfilter, action) => {
  switch (action.type) {
    case ADD_FILTER_BY_PRODUCT_FORM:
      return {
        ...state,
        productForm: [...state.productForm, action.payload],
      };
    case "DELETE_FILTER_BY_PRODUCT_FORM":
      return {
        ...state,
        productForm: [
          ...state.productForm.filter((item) => item !== action.payload),
        ],
      };
    case "CLEAR_FILTER_BY_PRODUCT_FORM":
      return {
        ...state,
        productForm: [],
      };
    default:
      return state;
  }
};

// reducer for filter by age group
const initialAgeGroupfilter = {
  ageGroup: [],
};
const filterByAgeGroup = (state = initialAgeGroupfilter, action) => {
  switch (action.type) {
    case ADD_FILTER_BY_AGE_GROUP:
      return {
        ...state,
        ageGroup: [...state.ageGroup, action.payload],
      };
    case "DELETE_FILTER_BY_AGE_GROUP":
      return {
        ...state,
        ageGroup: [...state.ageGroup.filter((item) => item !== action.payload)],
      };
    case "CLEAR_FILTER_BY_AGE_GROUP":
      return {
        ...state,
        ageGroup: [],
      };
    default:
      return state;
  }
};

// store payment method
const initialValpaymentmethodReducer = {
  method: "card without saved",
  data: "creditcard",
};
const paymentmethodReducer = (
  state = initialValpaymentmethodReducer,
  action
) => {
  switch (action.type) {
    case PAY_ON_DELIVERY:
      return {
        method: "pay on delivery",
        data: "pod",
      };
    case CARD_SAVED:
      return {
        method: "card saved",
        data: action.payload,
      };
    case CARD_WITHOUT_SAVED:
      return {
        method: "card without saved",
        data: action.payload,
      };
    default:
      return state;
  }
};

const wishlistReducer = (
  state = {
    state: "",
  },
  action
) => {
  switch (action.type) {
    case DISPLAY_WISHLIST:
      return {
        state: action.payload,
      };
    default:
      return state;
  }
};

const selectItemUnitReducer = (
  state = {
    unit: "",
    unittype: "pcs",
  },
  action
) => {
  switch (action.type) {
    case SELECT_UNIT:
      return {
        unit: action.payload.unit,
        unittype: action.payload.unittype,
      };
    default:
      return state;
  }
};

export {
  reducer,
  sortReducer,
  loaderReducer,
  filterByCategory,
  filterByBrand,
  filterByProductForm,
  filterByAgeGroup,
  paymentmethodReducer,
  wishlistReducer,
  selectItemUnitReducer,
  withPresfilterReducer,
  withOutPresfilterReducer,
};
