import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../../redux/action/action";
import { Autocomplete } from "@react-google-maps/api";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import MapPicker from "react-google-map-picker";
import Geocode from "react-geocode";
import { addNewAddress } from "../../../redux/action/address.action";
import { getUserToken, removeUserToken } from "../../app/userToken";
import { getUserDetails } from "../../app/userDetails";
import { toast } from "react-toastify";

const AddAddressCmp = () => {
  const accessToken = getUserToken();
  const reduxuserdata = useSelector((state) => state.userReducer);
  const [userdata, setuserdata] = useState({
    name: "",
    phone_number: "",
  });
  const [defaultPosition, setDefaultPosition] = useState({
    lat: 25.2840728,
    lng: 51.2413713,
  });
  const [address, setAddress] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [country, setContry] = useState("");
  const [is_default, setis_default] = useState(false);
  const [otherInput, setOtherInput] = useState("");
  const [otherName, setOtherName] = useState("");

  const [location, setLocation] = useState(defaultPosition);
  const [loader, setLoader] = useState(true);
  const [showAddressPicker, setShowAddressPicker] = useState(false);
  const [showSearchBarFields, setShowSearchBarFields] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [AddAddressState, SetAddAddressState] = useState("Add");

  const reduxresponse = useSelector((state) => state.addressReducer);
  const reduxresponsefetchaddressReducer = useSelector(
    (state) => state.fetchaddressReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const [locationval, setLocationval] = useState("");

  useEffect(() => {
    fetchUserData();

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setDefaultPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => {
        // if (err.message === "User denied Geolocation") {
        //   setShowAllowMap(false);
        // }
      },
      { enableHighAccuracy: true }
    );
  }, []);

  useEffect(() => {
    if (reduxresponsefetchaddressReducer.data.length !== 0) {
      setLoader(false);
    }
  }, [reduxresponsefetchaddressReducer.data]);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       setShowAllowMap(true);
  //     },
  //     (err) => {
  //       if (err.message === "User denied Geolocation") {
  //         setShowAllowMap(false);
  //       }
  //     },
  //     { enableHighAccuracy: true }
  //   );
  // });

  const fetchUserData = async () => {
    if (!reduxuserdata?.userdata?.name && accessToken) {
      axios
        .get(apiUrl + "/user/customerDetails", {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          setuserdata({
            name: getUserDetails(res.data.encrypted).name,
            phone_number: getUserDetails(res.data.encrypted).phone_number,
          });
        }).catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        });
    } else {
      setuserdata({
        name: reduxuserdata?.userdata?.name,
        phone_number: reduxuserdata?.userdata?.phone_number,
      });
    }
  };

  function handleChangeLocation(lat, lng) {
    fetchAddress(lat, lng);
  }

  function handleChangeZoom(newZoom) {
    //setZoom(newZoom);
  }

  const fetchAddress = (lat, lng) => {
    setLocation({ lat: lat, lng: lng });
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        let addressjson = {};
        const address_components = response.results[0].address_components;
        address_components.forEach((element) => {
          addressjson[element.types[0]] = element.long_name;
        });

        //mohalla - political
        //premise + neighbour hood (opt) - hosuse no
        debugger;

        if (addressjson?.premise && addressjson?.neighborhood) {
          setAddress1(
            (addressjson?.premise + " " + addressjson?.neighborhood).trim()
          );
        } else if (addressjson?.premise) {
          setAddress1(addressjson?.premise);
        } else if (addressjson?.neighborhood) {
          setAddress1(addressjson?.neighborhood);
        }

        setAddress2(addressjson?.political);
        setCity(addressjson?.locality);
        setState(addressjson?.administrative_area_level_1);
        setContry(addressjson?.country);
        setPinCode(addressjson?.postal_code);
        setAddress(response.results[0].formatted_address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const oncheckdefaultaddress = () => {
    let checkBox = document.getElementById("address-default");
    // Check if the element is selected/checked
    if (checkBox.checked) {
      setis_default(true);
    } else {
      setis_default(false);
    }
  };

  const OnSubmit_AddNewAddress = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    SetAddAddressState("Adding...");
    const data = {
      name:
        formData.get("name") === "Others" ? otherName : formData.get("name"),
      address1: formData.get("address1"),
      address2: formData.get("address2"),
      zip_code: formData.get("pincode"),
      city: formData.get("city"),
      state: formData.get("state"),
      country: formData.get("country"),
      phone_number: formData.get("phone"),
      is_default: is_default,
      customer_id: 1,
    };
    await dispatch(addNewAddress(data));
    await form.reset();
    await setShowAddressFields(false);
    await SetAddAddressState("Add");
  };
  const Map = ({ height }) => {
    return (
      <div>
        <MapPicker
          defaultLocation={location}
          zoom={15}
          mapTypeId="roadmap"
          style={{ height: height }}
          onChangeLocation={handleChangeLocation}
          onChangeZoom={handleChangeZoom}
          id="map"
          apiKey="AIzaSyB-i8_omurLDIXbP2yI1v0Ry5MtHtd-FyU"
        />
      </div>
    );
  };
  return (
    <>
      {showAddressPicker ? (
        <div
          id="write-review"
          className="animate__animated animate__fadeIn animate__faster"
        >
          <div className="w-100">
            <div>
              <Map height="250px" />

              <h3 className="my-2">Set your delivery location</h3>
              <div class="input-group-cust">
                <label for="name">DELIVERY AREA</label>
                <input
                  type="text"
                  name="name"
                  onClick={() => {
                    setShowSearchBarFields(true);
                    setShowAddressFields(false);
                    setShowAddressPicker(false);
                  }}
                  class="form-control"
                  value={address}
                />
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center mt-3">
              <button
                className="btn border-dark"
                onClick={() => setShowAddressPicker(false)}
                type="button"
              >
                Close
              </button>
              <button
                className="btn bg-theme text-light ml-2"
                onClick={() => {
                  setShowAddressFields(true);
                  setShowAddressPicker(false);
                }}
                type="button"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {showSearchBarFields ? (
        <div
          id="write-review"
          className="animate__animated animate__fadeIn animate__faster"
        >
          <div className="w-100 mt-3">
            <form className="row" onSubmit={OnSubmit_AddNewAddress}>
              <div
                className="input-group-cust col-12"
                style={{ height: "360px" }}
              >
                <label htmlFor="address-name">
                  <i class="fa fa-search"></i> Search Address
                </label>

                <Autocomplete>
                  <input
                    id="search-location"
                    className="form-control"
                    label="Search your location..."
                    placeholder="Search location..."
                    variant="filled"
                    defaultValue={locationval}
                    onChange={(e) => setLocationval(e.target.value)}
                    onBlur={(e) => {
                      geocodeByAddress(e.target.value)
                        .then((results) => getLatLng(results[0]))
                        .then((latLng) => {
                          fetchAddress(latLng.lat, latLng.lng);
                          // update center state
                          setLocation({
                            lat: latLng.lat,
                            lng: latLng.lng,
                          });

                          setShowSearchBarFields(false);
                          setShowAddressFields(false);
                          setShowAddressPicker(true);

                          this.setState({ mapCenter: latLng });
                        })
                        .catch((error) => console.error("Error", error));
                    }}
                  />
                </Autocomplete>
              </div>

              <div className="w-100 d-flex justify-content-center mt-3">
                <button
                  className="btn border-dark"
                  onClick={() => {
                    setShowSearchBarFields(false);
                    setShowAddressPicker(false);
                    setShowAddressFields(false);
                  }}
                  type="button"
                >
                  Close
                </button>
                {/* <button className="btn bg-theme text-light ml-2" type="submit">
                  {AddAddressState}
                </button> */}
              </div>
            </form>
          </div>
        </div>
      ) : null}

      {showAddressFields ? (
        <div
          id="write-review"
          className="animate__animated animate__fadeIn animate__faster"
        >
          <Map height="150px" />

          <div className="w-100">
            <form className="row" onSubmit={OnSubmit_AddNewAddress}>
              <div className="input-group-cust col-12">
                <label htmlFor="address-address1" className="mb-0 mt-2">
                  Address 1<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="address1"
                  className="form-control"
                  defaultValue={address1}
                  id="address-address1"
                  required
                />
              </div>
              <div className="input-group-cust col-8">
                <label htmlFor="address-address1" className="mb-0 mt-2">
                  Address 2<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="address2"
                  className="form-control"
                  id="address-address2"
                  defaultValue={address2}
                  required
                />
              </div>

              <div className="input-group-cust col-4">
                <label htmlFor="address-phone" className="mb-0 mt-2">
                  Phone Number<sup>*</sup>
                </label>
                <input
                  type="number"
                  name="phone"
                  className="form-control"
                  id="address-phone"
                  defaultValue={userdata.phone_number}
                  required
                />
              </div>

              <div
                className="input-group-cust col-12 mt-4 d-flex"
                style={{ alignItems: "baseline" }}
              >
                <input
                  type="radio"
                  name="name"
                  value="Home"
                  id="Home"
                  onClick={(e) => setOtherInput(e.target.value)}
                />
                &nbsp;<label htmlFor="Home">Home</label>
                &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  name="name"
                  value="Office"
                  id="Office"
                  onClick={(e) => setOtherInput(e.target.value)}
                />
                &nbsp;<label htmlFor="Office">Office</label>
                &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  name="name"
                  value="Hotel"
                  id="Hotel"
                  onClick={(e) => setOtherInput(e.target.value)}
                />
                &nbsp;<label htmlFor="Hotel">Hotel</label>
                &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  name="name"
                  value="Others"
                  id="Others"
                  onClick={(e) => setOtherInput(e.target.value)}
                />
                &nbsp;<label htmlFor="Others">Others</label>
                &nbsp;&nbsp;&nbsp;
                {otherInput === "Others" ? (
                  <input
                    type="text"
                    name="name"
                    onChange={(e) => setOtherName(e.target.value)}
                    className="form-control"
                    required
                  />
                ) : null}
              </div>

              <div className="input-group-cust col-4 mt-3 ml-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="true"
                  name="default"
                  id="address-default"
                  onClick={oncheckdefaultaddress}
                />
                <label
                  className="form-check-label text-grey"
                  htmlFor="address-default"
                >
                  Make as Default Address
                </label>
              </div>

              <div className="w-100 d-flex justify-content-center mt-3">
                <button
                  className="btn border-dark"
                  onClick={() => {
                    setShowSearchBarFields(false);
                    setShowAddressPicker(false);
                    setShowAddressFields(false);
                  }}
                  type="button"
                >
                  Close
                </button>
                <button className="btn bg-theme text-light ml-2" type="submit">
                  {AddAddressState}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AddAddressCmp;
