import React, { useState, useEffect } from "react";
import "./css/Style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import logoMob from "../../../assets/img/logo-dark.png";
import Authcarousel from "./Authcarousel";
import { Container } from "react-bootstrap";
import { apiUrl } from "../../../redux/action/action";
import { toast } from "react-toastify";
import { getUserToken, storeUserToken } from "../../app/userToken";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import CountryCodes from "./CountryCodes.json";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { auth } from "./FirebaseConfig";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { GetDeviceId } from "../../app/GetDeviceId";
import { useDispatch } from "react-redux";
import { CART_COUNT } from "../../../redux/state/state";
import { async } from "@firebase/util";

function Signin() {
  const { id } = useParams();
  const provider = new GoogleAuthProvider();

  const accessToken = getUserToken();
  const dispatch = useDispatch();
  const [submitbtntxt, setSubmitbtntxt] = useState("Send OTP");
  // const [term_checked, setTerm_checked] = useState(true);
  const [AlertMsg, setAlertMsg] = useState("");
  const [dailCodeLength, setDailCodeLength] = useState(0);
  // const [number, setNumber] = useState(0);
  const [phnval, setPhnval] = useState("");
  let navigate = useNavigate();
  // const [countryCode, setCountryCode] = useState([]);
  // const [countryCode, setCountryCode] = useState();
  // const [phoneCountryCode, setPhoneCountryCode] = useState("+974");
  // const [phone, setPhone] = useState();
  const [images, setImages] = useState([]);

  //New Code
  const [phone, setPhone] = useState();
  const [otp, setOtp] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [confirmationObj, setConfirmationObj] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [phoneCountryCode, setPhoneCountryCode] = useState(null);
  const [otpActionBtnTxt, setOtpActionBtnTxt] = useState("Verify OTP");
  // prescription_uuid
  const [prescription_uuid, setPrescriptionUuid] = useState();
  const [selectedCountryCode, setSelectedCountryCode] = useState([]);

  //New Code Ends
  const googleSignIn = () => {
    //Google Sign in
    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const unique_id = await GetDeviceId();
        const prescription_uuid = sessionStorage.getItem("prescription_uuid");
        let data = {};
        if (prescription_uuid) {
          data = {
            prescription_uuid,
            email: user.email,
            unique_id,
          };
        } else {
          data = {
            email: user.email,
            unique_id,
          };
        }

        // axios request
        try {
          const response = await axios.post(apiUrl + "/user/verify", data);

          if (response.data.status === 200) {
            setOtpActionBtnTxt("Verified");
            // store user token
            storeUserToken(response.data.token);
            // check login
            if (!prescription_uuid) {
              if (response.data?.type === "login") {
                if (localStorage.getItem("redirect_url")) {
                  window.location = localStorage.getItem("redirect_url");
                  localStorage.removeItem("redirect_url");
                } else {
                  window.location = "/";
                }
                // remove logindtls from local storage after login success
                localStorage.removeItem("logindtls");
              } else {
                const logindtls = {
                  email: user.email,
                  name: user.displayName,
                  type: "email"
                }
                sessionStorage.setItem("logindtls", JSON.stringify(logindtls))
                navigate("/auth/sign-up");
              }
            } else {
              // sessionStorage.removeItem("prescription_uuid");
              sessionStorage.setItem(
                "prescription_id",
                response.data.prescription_id
              );
              navigate("/upload-prescription");
            }
            // header cart count
            axios
              .get(apiUrl + "/order/header_cart_api", {
                headers: {
                  Authorization: "Token " + response.data.token,
                },
              })
              .then((res) => {
                dispatch({
                  type: CART_COUNT,
                  payload: res.data,
                });
              });
          } else {
            setOtpActionBtnTxt("Verify");
            toast.warning(response.data.message);
          }
        } catch (error) {
          setOtpActionBtnTxt("Verify");
          try {
            toast.error(error.response.data.message);
          } catch (e) {
            toast.error(error.toString());
          }
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        toast.error(errorMessage);
      });

    //Google sign in ends
  };
  const fetchData = () => {
    axios({
      method: "GET",
      url: apiUrl + "/master/get_login_page_banners",
    })
      .then(function (response) {
        if (response.status === 200) {
          setImages(response.data.data);
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        toast.error(error.toString());
      });
  };

  useEffect(() => {
    // get data from prescription_uuid
    if (id) {
      axios
        .get(apiUrl + "/order/get_prescription_login_data/" + id)
        .then((res) => {
          const { data } = res;
          const { status, message } = data;
          if (status === 200) {
            setPhone(data.data.phone_number);
            setPhoneCountryCode(`+${data.data.country_code + data.data.phone_number}`);
            setDailCodeLength(data.data.country_code.length);
            setPrescriptionUuid(data.data.prescription_uuid);
          } else {
            toast.warning(message);
          }
        })
        .catch(function (error) {
          toast.error(error.toString());
        });
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (accessToken) {
      if (window.innerWidth > 768) {
        navigate("/account/my-account");
      } else {
        navigate("/profile/my-account");
      }
    }
  });

  const [loader, setLoader] = useState(false)
  useEffect(() => {
    Ccode()
  }, []);

  const Ccode = async () => {
    try {
      const res = await axios.get(apiUrl + "/master/get_country_codes");
      if (res.data.status === 200) {
        setLoader(true)
        const codes = res.data.data;
        let MatchedData = [];
        for (let i = 0; i < codes.length; i++) {
          const cCode = codes[i];
          for (let i = 0; i < CountryCodes.length; i++) {
            const country = CountryCodes[i];
            if (country?.dial_code === cCode?.country_code) {
              MatchedData.push(country)
            }
          }

        }
        for (let j = 0; j < MatchedData.length; j++) {
          const code_ = MatchedData[j].code.toLowerCase();
          setSelectedCountryCode((oldCode) => [...oldCode, code_]);
        }
      }
      else {
        setLoader(false)
      }
    }
    catch (err) {
      setLoader(false)
    }
  }

  // const onclick_terms = (e) => {
  //   // check this element is checked or not
  //   let ischecked = e.target.checked;
  //   if (ischecked) {
  //     setTerm_checked(true);
  //   } else {
  //     setTerm_checked(false);
  //   }
  // };

  // const onKeyDownPhone = (e) => {
  //   const val = e.target.value;
  //   setPhnval(val);
  //   if (isNaN(val)) {
  //     // e.preventDefault();
  //     $(".alert-box").removeClass("d-none");
  //     setAlertMsg("Enter number only");
  //   } else {
  //     $(".alert-box").addClass("d-none");
  //     setAlertMsg("");
  //   }
  // };

  // submit form for signin
  // const submitLogin = async (e) => {
  //   e.preventDefault();
  //   const form = e.target;
  //   const formData = new FormData(form);

  //   if (!isNaN(phnval)) {
  //     // check terms
  //     if (term_checked) {
  //       // send mobile no to verify and store
  //       const phone_number = phone.toString().slice(dailCodeLength);
  //       const country_code = phoneCountryCode;
  //       const data = {
  //         phone_number,
  //         country_code,
  //       };

  //       // axios request
  //       if (phone_number && phone_number.length === 8) {
  //         try {
  //           setSubmitbtntxt("Wait...");
  //           const response = await axios.post(
  //             apiUrl + "/user/add_customerDetails",
  //             data
  //           );
  //           if (response.data.status === 200) {
  //             // store mobile number and signup or login indicator in localstorage
  //             const data = {
  //               number: phone_number,
  //               type: response.data.type,
  //             };
  //             localStorage.setItem("logindtls", JSON.stringify(data));

  //             navigate("/auth/verify-otp");
  //           } else {
  //             setSubmitbtntxt("Submit");
  //             toast.warning(response.data.message);
  //           }
  //         } catch (error) {
  //           if (error.response.data.status === 400) {
  //             toast.warning(error.response.data.message);
  //           } else {
  //             toast.error(error.response.data.message);
  //           }
  //           setSubmitbtntxt("Submit");
  //         }
  //       } else {
  //         // toast.warning("Please enter a valid number");
  //         $(".alert-box").removeClass("d-none");
  //         setAlertMsg("Please enter a valid number");
  //         setTimeout(() => {
  //           $(".alert-box").addClass("d-none");
  //           setAlertMsg("");
  //         }, 2000);
  //       }
  //     } else {
  //       toast.warning("Kindly check our terms before proceed");
  //     }
  //   }
  // };

  const sendOTP = () => {
    if (!phoneCountryCode) {
      toast.warning("Phone number can't be empty")
    }
    else if (phone.length < 8) {
      toast.warning("Phone number should be 8 character")
    }
    else {
      setSubmitbtntxt("Sending...");
      const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: 'invisible'
        },
        auth
      );
      recaptchaVerifier.render();
      signInWithPhoneNumber(auth, phoneCountryCode, recaptchaVerifier)
        .then((confirmationResult) => {
          setConfirmationObj(confirmationResult);
          setOtpSent(true);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const verifyOTP = () => {
    // setOtpActionBtnTxt("Verifing...");
    confirmationObj
      .confirm(otp)
      .then(async (result) => {
        // alert("verified otp");
        const unique_id = await GetDeviceId();
        let data = {};
        if (prescription_uuid) {
          data = {
            prescription_uuid,
            phone_number: phone,
            country_code: countryCode || phoneCountryCode,
            unique_id,
          };
        } else {
          console.log('>>', phone, countryCode, phoneCountryCode);
          data = {
            phone_number: phone,
            country_code: countryCode || phoneCountryCode,
            unique_id,
          };
        }

        // axios request
        try {
          const response = await axios.post(apiUrl + "/user/verify", data);

          if (response.data.status === 200) {
            setOtpActionBtnTxt("Verified");
            // store user token
            storeUserToken(response.data.token);
            // check login
            if (!prescription_uuid) {
              if (response.data?.type === "login") {
                if (localStorage.getItem("redirect_url")) {
                  window.location = localStorage.getItem("redirect_url");
                  localStorage.removeItem("redirect_url");
                } else {
                  window.location = "/";
                }
                // remove logindtls from local storage after login success
                localStorage.removeItem("logindtls");
              } else {
                const logindtls = {
                  phone: phone,
                  type: "phone"
                }
                sessionStorage.setItem("logindtls", JSON.stringify(logindtls))
                navigate("/auth/sign-up");
              }
            } else {
              // sessionStorage.removeItem("prescription_uuid");
              sessionStorage.setItem("prescription_id", response.data.prescription_id);
              navigate("/upload-prescription");
            }
            // header cart count
            axios
              .get(apiUrl + "/order/header_cart_api", {
                headers: {
                  Authorization: "Token " + response.data.token,
                },
              })
              .then((res) => {
                dispatch({
                  type: CART_COUNT,
                  payload: res.data,
                });
              });
          } else {
            setOtpActionBtnTxt("Verify");
            toast.warning(response.data.message);
          }
        } catch (error) {
          setOtpActionBtnTxt("Verify");
          try {
            toast.error(error.response.data.message);
          } catch (e) {
            toast.error(error.toString());
          }
        }
      })
      .catch((err) => {
        // toast.error('err');
        // console.log(err);
        toast.error("Invalid OTP");
      });
  };

  return (
    <>
      <div id="authbox" className="row">
        <i className="fa fa-arrow-left" onClick={() => navigate("/")}></i>
        <div id="imgside" className="col-md-6">
          <Container>
            <Authcarousel images={images} />
          </Container>
          {/* <img src={loginimg} alt="loginimg" /> */}
        </div>

        <div
          id="authside"
          className="col-md-6 mx-auto"
          style={{ flexFlow: "column" }}
        >
          <div
            style={{
              position: "relative",
              bottom: "10%",
            }}
          >
            <img
              src={logoMob}
              alt="logo"
              id="logoMob"
              width="130"
              onClick={() => navigate("/")}
            />
          </div>
          <center>
            <h5 className="mb-4">Login / Signup</h5>
            <p>Continue with mobile no</p>
            {/* login form */}
            {/* <form className="mt-3" onSubmit={submitLogin}> */}
            <div>
              {selectedCountryCode.length > 0 &&
                <div className="my-4">
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                    }}
                    country="qa"
                    onlyCountries={selectedCountryCode}
                    defaultMask={".... ...."}
                    countryCodeEditable={false}
                    value={phoneCountryCode}
                    onChange={(phoneNumber, countryCodeObj) => {
                      setPhone(
                        phoneNumber?.substring(countryCodeObj?.dialCode?.length)
                      );
                      setCountryCode(`+${countryCodeObj?.dialCode}`);
                      setPhoneCountryCode(`+${phoneNumber}`);
                    }}
                  />
                </div>}
              {otpSent ? null : (
                <div
                  id="recaptcha-container"
                  className="text-center mb-3 mb-0"
                />
              )}
              {otpSent ? (
                <>
                  <div className="my-4 w-100 d-flex justify-content-center">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      separator={<span>&nbsp;&nbsp;</span>}
                      isInputNum={true}
                      inputStyle={{
                        width: "45px",
                        height: "45px",
                      }}
                      focusStyle={{
                        outline: "red",
                      }}
                    />
                  </div>
                  <div className="mb-3 mb-0 text-center">
                    {/* <Button className="btn btn-primary w-100" onClick={verifyOTP} label="Verify OTP" /> */}
                    <button
                      className="btn bg-theme text-light w-100"
                      onClick={verifyOTP}
                      disabled={otpActionBtnTxt === "Verifing..."}
                    >
                      {otpActionBtnTxt}
                    </button>
                  </div>
                </>
              ) : (
                <div className="mb-3 mb-0 text-center">
                  {/*  */}
                  {/* <Button className="btn btn-primary w-100" onClick={sendOTP} label="Send OTP" /> */}
                  <button
                    className="btn bg-theme text-light w-100"
                    onClick={sendOTP}
                    label="Send OTP"
                    disabled={submitbtntxt === "Sending..."}
                  >
                    {submitbtntxt}
                  </button>
                </div>
              )}
            </div>
            {/* {
              window.innerWidth > 768 && (
                <> */}
            <span className="text-grey">-- OR --</span>
            <div className="login-buttons mt-3">
              <button className="btn border-dark p-0" onClick={googleSignIn}>
                <img
                  src="/img/google.png"
                  alt="google icon"
                  width={50}
                  height={50}
                />
              </button>
            </div>
            {/* </>
              )
            } */}

            {/* alert message */}
            <div
              className="alert alert-warning alert-dismissible fade show p-1 alert-box d-none"
              role="alert"
            >
              <p className="p-0 m-0">{AlertMsg}</p>
            </div>

            {/* checkbox for terms & condition */}
            <div className="form-check mt-4">
              <label className="form-check-label" htmlFor="flexCheckChecked">
                By login into Medpack you agree to our{" "}
                <Link to="/policies/terms-conditions">terms of use</Link> &{" "}
                <Link to="/policies/privacy-policy">policy</Link>
              </label>
            </div>

            {/* <button
                type="submit"
                id="submit-btn"
                className="form-control mt-3 py-2"
              >
                {submitbtntxt}
              </button> */}
            {/* </form> */}
          </center>
        </div>
      </div>
    </>
  );
}

export default Signin;
