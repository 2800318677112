import CryptoJS from "crypto-js";
const secretKey = "C*F-JaNdRgUkXp2r5u8x/A?D(G+KbPeS";

export const getUserDetails = (EncryptedData) => {
  var key = CryptoJS.enc.Utf8.parse(secretKey);
  var decrypted = CryptoJS.AES.decrypt(EncryptedData, key, {
    mode: CryptoJS.mode.ECB,
  });
  var originalText = decrypted.toString(CryptoJS.enc.Utf8);
  return JSON.parse(originalText.replaceAll('None', '\'\'').replaceAll("'", "\""));
};
