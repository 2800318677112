import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { apiUrl } from "../../../redux/action/action";
import { DISPLAY_CART } from "../../../redux/state/state";
import { getUserToken, removeUserToken } from "../../app/userToken";
import "./AvailableOffer.css";

const CheckoutOffer = ({ loaddata, showCouponModal, selectedCouponCode }) => {
  const accessToken = getUserToken();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (loaddata) {
      axios
        .get(apiUrl + "/order/get_cart_discount_coupons", {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        });
    }
  }, [loaddata]);

  const onSubmitCouponCode = (e) => {
    e.preventDefault();
    localStorage.removeItem("discount_coupon_id");
    const form = e.target;
    const formData = new FormData(form);
    const discount_coupon_code = formData.get("discount_coupon_code");

    axios
      .get(
        apiUrl +
        "/order/get_cart_data?discount_coupon_code=" +
        discount_coupon_code,
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 400) {
          toast.warning(res.data.message);
        } else {
          localStorage.setItem("discount_coupon_code", discount_coupon_code);
          localStorage.setItem(
            "total_loyalty_points_deducted",
            res.data.total_loyalty_points_deducted
          );
          toast.success("Coupon applied");
          dispatch({
            type: DISPLAY_CART,
            payload: res.data,
          });
          showCouponModal(false);
          selectedCouponCode(discount_coupon_code);
        }
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  return (
    <>
      <div className="w-100 available-offers">
        <div className="accordion mx-4" id="order-options">
          <form
            className="accordion-body needs-validation px-0 d-flex"
            onSubmit={onSubmitCouponCode}
          >
            <div className="w-75">
              <input
                className="form-control"
                type="text"
                name="discount_coupon_code"
                placeholder="Enter coupon code"
                required
                style={{ borderRadius: "3px 0 0 3px" }}
              />
            </div>
            <button
              className="btn bg-theme text-light w-25"
              type="submit"
              style={{ borderRadius: "0 3px 3px 0" }}
            >
              Apply
            </button>
          </form>

          {data.length > 0 ? (
            data.map((e, i) => {
              return (
                <div className="card rounded" key={i}>
                  <div
                    className="card-body d-flex justify-content-between align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.removeItem("discount_coupon_code");
                      axios
                        .get(
                          apiUrl +
                          "/order/get_cart_data?discount_coupon_id=" +
                          e.id,
                          {
                            headers: {
                              Authorization: "Token " + accessToken,
                            },
                          }
                        )
                        .then((res) => {
                          localStorage.setItem("discount_coupon_id", e.id);
                          localStorage.setItem(
                            "total_loyalty_points_deducted",
                            res.data.total_loyalty_points_deducted
                          );
                          toast.success("Coupon applied");
                          dispatch({
                            type: DISPLAY_CART,
                            payload: res.data,
                          });
                          showCouponModal(false);
                          selectedCouponCode(e.coupon_code);
                        })
                        .catch((err) => {
                          const { response } = err;
                          const { data } = response;
                          const { status, message } = data;
                          if (status === "400") {
                            toast.warning(message);
                            removeUserToken()
                          } else {
                            toast.error(message);
                          }
                        });
                    }}
                  >
                    <div className="w-100 pr-4">
                      <div className="w-100">
                        <p className="p-0 m-0">{e.coupon_code}</p>
                      </div>
                      <div className="w-100 d-flex flex-column mt-2">
                        {/* {e.offer_percent && (
                          <small className="p-0 m-0">
                            Offer Percent :{" "}
                            <span className="text-theme">
                              {e.offer_percent}%
                            </span>
                          </small>
                        )} */}

                        {e.offer_amount ? (
                          <small className="p-0 m-0">
                            Offer Amount :{" "}
                            <span className="text-theme">
                              QAR {e.offer_amount}
                            </span>
                          </small>
                        ) : (
                          <small className="p-0 m-0">
                            Offer Percent :{" "}
                            <span className="text-theme">
                              {e.offer_percent}%
                            </span>
                          </small>
                        )}
                        
                        <small>Expiry on - {e.end_date}</small>
                        <small>{e.offer_description}</small>
                        <small>T&C - {e.terms_and_conditions}</small>
                      </div>
                    </div>
                    <div>
                      <button className="btn p-0 text-theme">
                        <b>APPLY</b>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-center mt-3">
              There is no coupon for these products
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckoutOffer;
