import Navbar from "../../Header/Navbar/Navbar";
import Footer from "../../Footer/Footer";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../redux/action/action";
import { Accordion } from "react-bootstrap";

const FAQ = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get(apiUrl + "/master/get_faq").then((res) => {
      setData(res?.data?.data);
    });
  }, []);

  return (
    <>
      <Navbar />
      <Navbarmob />
      <div className="container mt-5">
        <div className="row body-color">
          <div className="col-sm-10 mx-auto" style={{ minHeight: "400px" }}>
            <Accordion defaultActiveKey="0">
              {data?.map((e, i) => {
                return (
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>
                      #{i + 1}. {e.question}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div dangerouslySetInnerHTML={{ __html: e?.answer }} />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default FAQ;
