import React, { useState, useEffect } from "react";
import "./css/Style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import logoMob from "../../../assets/img/logo-dark.png";
import Authcarousel from "./Authcarousel";
import { Container } from "react-bootstrap";
import { apiUrl } from "../../../redux/action/action";
import { toast } from "react-toastify";
import { getUserToken } from "../../app/userToken";
import PhoneInput from "react-phone-input-2";

function PrescriptionSignin() {
  const { id } = useParams();
  const accessToken = getUserToken();
  const [submitbtntxt, setSubmitbtntxt] = useState("Submit");
  const [term_checked, setTerm_checked] = useState(true);
  const [AlertMsg, setAlertMsg] = useState("");
  // const [number, setNumber] = useState(0);
  const [phnval, setPhnval] = useState("");
  let navigate = useNavigate();
  // const [countryCode, setCountryCode] = useState([]);
  const [dailCodeLength, setDailCodeLength] = useState(0);
  const [countryCode, setCountryCode] = useState();
  const [phoneCountryCode, setPhoneCountryCode] = useState("+974");
  const [phone, setPhone] = useState();
  const [images, setImages] = useState([]);

  const fetchData = () => {
    axios({
      method: "GET",
      url: apiUrl + "/master/get_login_page_banners",
    })
      .then(function (response) {
        if (response.status === 200) {
          setImages(response.data.data);
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        toast.error(error.toString());
      });
  };

  useEffect(() => {
    // get data from prescription_uuid
    axios
      .get(apiUrl + "/order/get_prescription_login_data/" + id)
      .then((res) => {
        const { data } = res;
        const { status, message } = data;
        if (status === 200) {
          setPhone(data.data.country_code + data.data.phone_number);
          setDailCodeLength(data.data.country_code.length);
          sessionStorage.setItem(
            "prescription_uuid",
            data.data.prescription_uuid
          );
        } else {
          toast.warning(message);
        }
      })
      .catch(function (error) {
        toast.error(error.toString());
      });

    fetchData();
  }, []);

  useEffect(() => {
    if (accessToken) {
      localStorage.clear();
      sessionStorage.clear();
      // if (window.innerWidth < 768) {
      //   navigate("/account/my-account");
      // } else {
      //   navigate("/profile/my-account");
      // }
    }
  });

  useEffect(async () => {
    const res = await axios.get(apiUrl + "/master/get_country_codes");
    if (res.data.status === 200) {
      setCountryCode(res.data.data);
    }
  }, []);

  const onclick_terms = (e) => {
    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      setTerm_checked(true);
    } else {
      setTerm_checked(false);
    }
  };

  const onKeyDownPhone = (e) => {
    const val = e.target.value;
    setPhnval(val);
    if (isNaN(val)) {
      // e.preventDefault();
      $(".alert-box").removeClass("d-none");
      setAlertMsg("Enter number only");
    } else {
      $(".alert-box").addClass("d-none");
      setAlertMsg("");
    }
  };

  // submit form for signin
  const submitLogin = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    if (!isNaN(phnval)) {
      // check terms
      if (term_checked) {
        // send mobile no to verify and store
        const phone_number = phone.toString().slice(dailCodeLength);
        // const phone_number = onlyPhone;
        const country_code = phoneCountryCode;
        const data = {
          phone_number,
          country_code,
        };

        // axios request
        if (phone_number && phone_number.length === 8) {
          try {
            setSubmitbtntxt("Wait...");
            const response = await axios.post(
              apiUrl + "/user/add_customerDetails",
              data
            );
            if (response.data.status === 200) {
              // store mobile number and signup or login indicator in localstorage
              const data = {
                number: phone_number,
                type: response.data.type,
              };
              localStorage.setItem("logindtls", JSON.stringify(data));

              navigate("/auth/verify-otp");
            } else {
              setSubmitbtntxt("Submit");
              toast.warning(response.data.message);
            }
          } catch (error) {
            if (error.response.data.status === 400) {
              toast.warning(error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
            setSubmitbtntxt("Submit");
          }
        } else {
          // toast.warning("Please enter a valid number");
          $(".alert-box").removeClass("d-none");
          setAlertMsg("Please enter a valid number");
          setTimeout(() => {
            $(".alert-box").addClass("d-none");
            setAlertMsg("");
          }, 2000);
        }
      } else {
        toast.warning("Kindly check our terms before proceed");
      }
    }
  };

  return (
    <>
      <div id="authbox" className="row">
        <i className="fa fa-arrow-left" onClick={() => navigate("/")}></i>
        <div id="imgside" className="col-md-6">
          <Container>
            <Authcarousel images={images} />
          </Container>
          {/* <img src={loginimg} alt="loginimg" /> */}
        </div>

        <div id="authside" className="col-md-6 mx-auto">
          <center>
            <img
              src={logoMob}
              alt="logo"
              id="logoMob"
              width="130"
              onClick={() => navigate("/")}
            />
            <h5>Login / Signup</h5>
            <p className="mt-5">Continue with mobile no</p>
            {/* login form */}
            <form className="mt-3" onSubmit={submitLogin}>
              <div>
                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    height: "40px",
                  }}
                  dropdownStyle={{
                    textAlign: "left",
                  }}
                  countryCodeEditable={false}
                  defaultMask={".... ...."}
                  onChange={(phoneNumber, countryCodeObj) => {
                    setDailCodeLength(countryCodeObj.dialCode.length);
                    setPhoneCountryCode(countryCodeObj.dialCode);
                    setPhone(phoneNumber);
                  }}
                  country="qa"
                  onlyCountries={["qa"]}
                  value={phone}
                />
              </div>

              {/* alert message */}
              <div
                className="alert alert-warning alert-dismissible fade show p-1 alert-box d-none"
                role="alert"
              >
                <p className="p-0 m-0">{AlertMsg}</p>
              </div>

              {/* checkbox for terms & condition */}
              <div className="form-check mt-4">
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  By login into Medpack you agree to our{" "}
                  <Link to="/terms-conditions">terms of use</Link> &{" "}
                  <Link to="/privacy-policy">policy</Link>
                </label>
              </div>

              <button
                type="submit"
                id="submit-btn"
                className="form-control mt-3 py-2"
              >
                {submitbtntxt}
              </button>
            </form>
          </center>
        </div>
      </div>
    </>
  );
}

export default PrescriptionSignin;
