import Breadcrumb from "react-bootstrap/Breadcrumb";

const Breadcrumbs = ({ data }) => {
  return (
    <Breadcrumb>
      {window.innerWidth > 768 && (
        <>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/product/all">Product</Breadcrumb.Item>
        </>
      )}
      <Breadcrumb.Item
        href={`/product/category/${data?.category_name}-${data?.category_id}`}
      >
        {data?.category_name}
      </Breadcrumb.Item>
      <Breadcrumb.Item
        href={`/product/category/${data?.sub_category_name}-${data?.sub_category_id}?subcategory=true`}
      >
        {data?.sub_category_name}
      </Breadcrumb.Item>
      <Breadcrumb.Item active>{data?.itemName}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
