/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import { Container, Row, Col } from "react-bootstrap";
import "./style.css";
import upload_insu from "../../../../../assets/upload_insu.png";
import upload_pres from "../../../../../assets/upload_pres.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiUrl, displayTopBarCart } from "../../../../../redux/action/action";
import axios from "axios";
import { toast } from "react-toastify";
import { getUserToken } from "../../../../app/userToken";
import ImageViewer from "../../../../app/components/ImageViewer";
import { useDispatch } from "react-redux";
import { DISPLAY_CART_POPUP } from "../../../../../redux/state/state";

const OrderItemCardUp = (props) => {
  const accessToken = getUserToken();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let [OderDetailsUrl, setOderDetailsUrl] = useState("");
  let [InvoicePageUrl, setInvoicePageUrl] = useState("");
  const [showAddress, setShowAddress] = useState(false);
  const [ApproveCartTxt, setApproveCartTxt] = useState("Approve & Pay");
  const [showHelp, setshowHelp] = useState(false);
  const [showHelpRequestForm, setShowHelpRequestForm] = useState(false);
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  const [reason, setReason] = useState();
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState(null);
  const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 1000) {
      setOderDetailsUrl("/account/my-order/order-details/");
    } else {
      setOderDetailsUrl("/profile/my-order/order-details/");
    }

    if (window.innerWidth > 1000) {
      setInvoicePageUrl("/account/my-order/invoice-page/");
    } else {
      setInvoicePageUrl("/profile/my-order/invoice-page/");
    }
  });

  const setUpdateResponse = (e) => {
    props.setUpdateResponse(e);
  };

  const handleRequestForCallBack = (e) => {
    e.preventDefault();

    axios
      .post(
        apiUrl + "/order/order_help_api",
        {
          order_id: props?.data?.details?.order_id,
          subject,
          message,
        },
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      )
      .then((res) => {
        setSubject("");
        setMessage("");
        setShowHelpRequestForm(false);
        toast.success(res.data.message);
      });
  };

  const handleCancelOrder = async () => {
    setShowDelectConfirmation(false);
    setShowModal(true);
    await loadReasons();
    setAction("cancel_orders");
  };

  // load reasons
  const loadReasons = async () => {
    const res = await axios.get(apiUrl + "/master/order_cancel_return_reasons");
    setReason(res.data.data);
  };

  // select reason
  const handleSelectedReason = (e) => {
    const selectedVal = e.target.value;
    returnORcancel(selectedVal);
    setShowModal(false);
  };

  // request for return or cancel
  const returnORcancel = (selectedVal) => {
    axios
      .post(
        apiUrl + "/order/" + action,
        {
          order_id: props?.data?.details?.order_id,
          reason: selectedVal,
        },
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          setUpdateResponse("update");
        }
      });
  };

  return (
    <Container className="mb-2 mt-3">
      {/* order status */}
      <Row>
        <Col
          md={
            !props.data?.otp && !props?.data?.tracking_link
              ? 12
              : props.data?.otp && props?.data?.tracking_link
                ? 6
                : 9
          }
          className="mt-2 mb-0 alert alert-theme pt-2 pb-1"
          style={{ borderRadius: "4px 0 0 0" }}
        >
          <h6 className="d-flex p-0 m-0 order-sts">
            {props?.data?.status} &nbsp;
            {props?.data?.status === "Cancel Request Accepted"
              ? `(${props?.data?.details?.cancel_reason})`
              : null}
          </h6>
        </Col>
        {props.data?.otp && (
          <Col
            md={3}
            className={`mt-md-2 mb-0 alert alert-theme pt-2 pb-1 rounded-0 ${window.innerWidth > 768 ? `d-flex justify-content-center` : null
              }`}
          >
            <h6 className="p-0 m-0">Delivery OTP : {props.data?.otp}</h6>
          </Col>
        )}
        {props?.data?.tracking_link && (
          <Col
            md={3}
            className={`mt-md-2 mb-0 alert alert-theme pt-1 pb-2 pb-md-1 rounded-0 ${window.innerWidth > 768
              ? `d-flex align-items-center justify-content-end`
              : null
              }`}
            style={{ borderRadius: "0 4px 0 0" }}
          >
            <a href={props?.data?.tracking_link} className="text-primary" target="_blank">
              Tracking Link
            </a>
          </Col>
        )}
      </Row>

      {/* order details */}
      <Row className="order-detail-info border-left border-right pb-2 pt-3">
        <Col md={3}>
          <p>ORDER NO</p>
          <small>{props?.data?.details?.order_number?.split("-")[1]}</small>
        </Col>
        <Col md={2}>
          <p>TOTAL</p>
          <small>QAR {props?.data?.details?.paid_amount}</small>
        </Col>

        {window.innerWidth > 768 ? (
          <Col md={3} className="position-relative flex-grow-1">
            {props?.data?.details?.ship_to &&
              props?.data?.details?.ship_to !== "" ? (
              <>
                <p className="p-0 m-0">SHIP TO</p>
                <button
                  className="btn p-0 text-primary"
                  onMouseEnter={() => setShowAddress(!showAddress)}
                  onMouseLeave={() => setShowAddress(!showAddress)}
                >
                  {props?.data?.details?.ship_to}{" "}
                  <i className="fa fa-caret-down text-dark"></i>
                </button>
                {showAddress ? (
                  <div
                    className="show-ordered-address"
                    onMouseEnter={() => setShowAddress(true)}
                    onMouseLeave={() => setShowAddress(false)}
                  >
                    <small>{props.data?.address_data?.address}</small>
                  </div>
                ) : null}
              </>
            ) : null}
          </Col>
        ) : null}

        <Col md={4}>
          <div className={`pb-2`}>
            {props?.data?.details?.order_number ? (
              <>
                <p>ORDER PLACED</p> &nbsp;
                <small>{props?.data?.details?.order_placed_date}</small>
              </>
            ) : null}
          </div>

          <div className="d-flex align-items-center justify-content-end">
            {location.pathname.match("/order-details/") ? null : (
              <Link to={OderDetailsUrl + props?.data?.details?.order_id}>
                <button className="btn p-0 text-primary border-right px-2 mx-2">
                  View Order Details
                </button>
              </Link>
            )}
            
              <Link to={InvoicePageUrl + props?.data?.details?.order_id}>
                <button
              className={`btn p-0 ${props?.data?.details?.invoice_file
                ? `text-primary`
                : `text-grey`
                }`}
              disabled={!props?.data?.details?.invoice_file}
            >
              Invoice
            </button>
              </Link>
           
            {/* <button
              className={`btn p-0 ${props?.data?.details?.invoice_file
                ? `text-primary`
                : `text-grey`
                }`}
              disabled={!props?.data?.details?.invoice_file}
              onClick={() => {
                let alink = document.createElement("a");
                alink.href = props?.data?.details?.invoice_file;
                // alink.target = "_blank";
                alink.download =
                  "medpack_invoice_order_id_" +
                  props?.data?.details?.order_id +
                  ".pdf";
                alink.click();
              }}
            >
              Invoice
            </button> */}
          </div>
        </Col>
      </Row>

      {/* help for order */}
      <div className="row d-flex justify-content-between order-help border py-3">
        <span className="d-flex mx-2 align-items-center w-50">
          <button className="btn p-0 d-flex align-items-center">
            <i className="fa fa-headphones mr-1"></i>
            <p
              className="text-primary full-width-truncate p-0 m-0"
              onClick={() => setshowHelp(true)}
            >
              {window.innerWidth < 768
                ? "HELP"
                : "CLICK HERE FOR HELP WITH YOUR ORDER"}
            </p>
          </button>
        </span>
        <span className="d-flex mx-2 justify-content-end w-25">
          {/* {props?.data?.payment_status} */}
          {/* need to chnage this */}

          {props?.data?.payment_status === 0 &&
            props?.data?.cancel_order_status ? (
            <button
              className="btn btn-danger"
              onClick={() => setShowDelectConfirmation(true)}
            >
              Cancel
            </button>
          ) : null}

          {props.status === 4 ||
            props?.data?.status === "Cancel Request Accepted" ? (
            <button
              className="btn btn-info mr-2"
              onClick={() => {
                if (props?.data?.prescription?.name === "") {
                  axios
                    .get(
                      apiUrl +
                      "/order/reorder/" +
                      props?.data?.details?.order_id,
                      {
                        headers: {
                          Authorization: "Token " + accessToken,
                        },
                      }
                    )
                    .then((res) => {
                      dispatch(displayTopBarCart());
                      dispatch({
                        type: DISPLAY_CART_POPUP,
                        payload: true,
                      });
                      setTimeout(() => {
                        dispatch({
                          type: DISPLAY_CART_POPUP,
                          payload: false,
                        });
                      }, 4000);
                    })
                    .catch((err) => {
                      toast.error(err.toString());
                    });
                } else {
                  // localStorage.setItem(
                  //   "saved_pres_data",
                  //   JSON.stringify(props?.data?.prescription)
                  // );
                  sessionStorage.setItem("prescription_id", props?.data?.prescription?.id);
                  navigate("/upload-prescription");
                }
              }}
            >
              Re-Order
            </button>
          ) : null}

          {showDelectConfirmation ? (
            <div id="modal">
              <div className="insurance-confirm border-success py-4">
                <i
                  className="fa fa-close"
                  onClick={() => setShowDelectConfirmation(false)}
                ></i>
                <h5 className="mt-3">Are you sure ?</h5>
                <div className="mt-2">
                  <button
                    className="btn border-theme text-theme mr-2"
                    onClick={() => setShowDelectConfirmation(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn bg-theme text-light"
                    onClick={handleCancelOrder}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          {props?.data?.payment_status === 0 &&
            props?.data?.status === "Order Payment Failed" ? (
            <button
              className="btn bg-theme text-light py-1"
              onClick={() => {
                axios
                  .get(
                    apiUrl +
                    "/order/retry_payment/" +
                    props?.data?.details?.order_id,
                    {
                      headers: {
                        Authorization: "Token " + accessToken,
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.status === 200) {
                      toast.success(res.data.message);
                      setTimeout(() => {
                        navigate("/payment");
                      }, 3000);
                    } else {
                      toast.warning(res.data.message);
                    }
                  })
                  .catch((err) => {
                    toast.error(err.toString());
                  });
              }}
            >
              {"Retry"}
            </button>
          ) : null}

          {props?.data?.payment_status === 1 ? (
            <button className="btn btn-success py-1" disabled>
              PAID
            </button>
          ) : null}

          {props?.data?.payment_status === 2 ? (
            <button
              className="btn bg-theme text-light py-1 ml-3"
              onClick={() => {
                setApproveCartTxt("Approving...");
                axios
                  .post(
                    apiUrl + "/order/approve_prescription_orders",
                    {
                      order_id: props?.data?.details?.order_id,
                      method: "creditcard",
                    },
                    {
                      headers: {
                        Authorization: "Token " + accessToken,
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.status === 200) {
                      localStorage.setItem(
                        "redirect_url",
                        OderDetailsUrl + props?.data?.details?.order_id
                      );
                      window.location.assign(
                        res.data.data._links.checkout.href
                      );
                    } else {
                      toast.warning(res.data.message);
                      setApproveCartTxt("Approve & Pay");
                    }
                  })
                  .catch((err) => {
                    toast.error(err.toString());
                  });
              }}
            >
              {ApproveCartTxt}
            </button>
          ) : null}
        </span>
      </div>

      {showHelp ? (
        <div className="help-popup border p-5">
          <i className="fa fa-close" onClick={() => setshowHelp(false)}></i>
          <div className="d-flex justify-content-end pb-2">
            {props?.data?.details?.order_number ? (
              <p className="text-theme">
                Get help on Order no{" "}
                {props?.data?.details?.order_number?.split("-")[1]}
              </p>
            ) : null}
          </div>
          <a href="https://wa.me/+97433990270" target="_blank">
            <button className="btn border-success text-success">
              <i className="fa fa-whatsapp text-success"></i> What's App
            </button>
          </a>
          <button
            className="btn bg-theme text-light mt-2"
            onClick={() => {
              setshowHelp(false);
              setShowHelpRequestForm(true);
            }}
          >
            <i className="fa fa-phone"></i> Request for call back
          </button>
        </div>
      ) : null}

      {showHelpRequestForm ? (
        <div className="help-popup border p-md-5 p-4">
          <i
            className="fa fa-close"
            onClick={() => setShowHelpRequestForm(false)}
          ></i>
          <div className="d-flex justify-content-end pb-2">
            {props?.data?.details?.order_number ? (
              <p className="text-theme">
                ORDER NO{" - "}
                {props?.data?.details?.order_number?.split("-")[1]}
              </p>
            ) : null}
          </div>
          <div className="w-100">
            <form onSubmit={handleRequestForCallBack}>
              <input
                type="text"
                className="form-control"
                placeholder="Subject"
                required
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <textarea
                className="form-control mt-3"
                rows="3"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <button
                className="btn bg-theme text-light w-100 mt-3"
                type="submit"
              >
                <i className="fa fa-phone"></i> Request for call back
              </button>
            </form>
          </div>
          {/* <button className="btn border-success text-success">
            <i className="fa fa-whatsapp text-success"></i> What's App
          </button>
           */}
        </div>
      ) : null}

      {/* order item */}
      {props?.data?.details?.products.length > 0 ? (
        <OrderItems
          alldata={props?.data}
          data={props?.data?.details?.products}
          orderid={props?.data?.details?.order_id}
          status={props?.status}
          setUpdateResponse={setUpdateResponse}
        />
      ) : null}
      {/* prescription details */}
      {props?.data?.prescription?.name === "" ||
        props?.data?.prescription?.name === null ? null : (
        <PrescriptionDetailsPart data={props?.data?.prescription} />
      )}
      {/* insurance details */}
      {props?.data?.insurance?.insurance_type === "" ||
        props?.data?.insurance?.insurance_type === null ? null : (
        <InsuranceDetailsPart data={props?.data?.insurance} />
      )}

      {showModal ? (
        <div id="modal">
          <div id="reason-main">
            <i className="fa fa-close" onClick={() => setShowModal(false)}></i>
            <h4 className="p-0 m-0 mb-4">Select reason for cancellation</h4>
            {reason?.map((data, i) => {
              return (
                <div
                  className={`reason-list d-flex align-items-center ${i === 0 ? `border-top border-bottom` : `border-bottom`
                    }`}
                  key={data.id}
                >
                  <input
                    type="radio"
                    id={"reason" + data.id}
                    value={data.name}
                    className="checkBox"
                    onClick={handleSelectedReason}
                  />
                  &nbsp;
                  <label htmlFor={"reason" + data.id} className="p-0 m-0">
                    {data.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </Container>
  );
};

export const OrderItems = ({
  alldata,
  data,
  orderid,
  status,
  setUpdateResponse,
}) => {
  const accessToken = getUserToken();
  const [reason, setReason] = useState();
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState(null);
  const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);

  const handleCancelOrder = async () => {
    setShowDelectConfirmation(false);
    setShowModal(true);
    await loadReasons();
    setAction("cancel_orders");
  };
  const handleReturnOrder = async () => {
    setShowModal(true);
    await loadReasons();
    setAction("return_orders");
  };

  // load reasons
  const loadReasons = async () => {
    const res = await axios.get(apiUrl + "/master/order_cancel_return_reasons");
    setReason(res.data.data);
  };

  // select reason
  const handleSelectedReason = (e) => {
    const selectedVal = e.target.value;
    returnORcancel(selectedVal);
    setShowModal(false);
  };

  // request for return or cancel
  const returnORcancel = (selectedVal) => {
    axios
      .post(
        apiUrl + "/order/" + action,
        {
          order_id: orderid,
          reason: selectedVal,
        },
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          setUpdateResponse("update");
        }
      });
  };

  const ItemDetails = ({ e }) => {
    const accessToken = getUserToken();
    const [reason, setReason] = useState();
    const [showModal, setShowModal] = useState(false);
    const [action, setAction] = useState(null);
    const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);

    const handleSingleOrderCancel = async () => {
      setShowDelectConfirmation(false);
      setShowModal(true);
      await loadReasons();
      setAction("cancel_single_item");
    };

    // load reasons
    const loadReasons = async () => {
      const res = await axios.get(
        apiUrl + "/master/order_cancel_return_reasons"
      );
      setReason(res.data.data);
    };

    // select reason
    const handleSelectedReason = (e) => {
      const selectedVal = e.target.value;
      returnORcancel(selectedVal);
      setShowModal(false);
    };

    // request for return or cancel
    const returnORcancel = (selectedVal) => {
      axios
        .post(
          apiUrl + "/order/" + action,
          {
            order_id: alldata?.details?.order_id,
            reason: selectedVal,
            cancel_cart_item_id: e.id,
          },
          {
            headers: {
              Authorization: "Token " + accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setUpdateResponse("update");
          }
        });
    };

    return (
      <>
        {status === 4 ? (
          <>
            <Col
              xs={window.innerWidth > 768 ? 1 : 4}
              className="d-flex align-items-center mt-3 pl-1"
            >
              <Link
                to={`/product-details/${e.product_id}`}
                className="text-primary full-width-truncate"
                title={e.itemName}
              >
                <img src={apiUrl + e.image} width={50} />
              </Link>
            </Col>

            {window.innerWidth > 768 ? (
              <Col xs={3} className="d-flex align-items-center mt-3">
                {!e?.item_cancel ? (
                  <Link
                    to={`/product-details/${e.product_id}`}
                    className="text-primary full-width-truncate"
                    title={e.itemName}
                  >
                    {e.itemName}
                  </Link>
                ) : (
                  <>
                    <Link
                      to={`/product-details/${e.product_id}`}
                      className="text-primary full-width-truncate"
                      title={e.itemName}
                      style={{
                        position: "relative",
                        marginLeft: "6px",
                      }}
                    >
                      {e?.itemName}
                      <div
                        style={{
                          position: "absolute",
                          top: "12px",
                          left: 0,
                          background: "#F5832B",
                          width: "100%",
                          height: "2px",
                        }}
                      />
                    </Link>
                  </>
                )}
              </Col>
            ) : null}
            <Col
              xs={2}
              className="d-flex align-items-center justify-content-center mt-3"
            >
              <span>{e.sizeUnit}</span>
            </Col>
            <Col
              xs={1}
              className="d-flex align-items-center justify-content-center mt-3"
            >
              <span>{e.quantity}</span>
            </Col>
            <Col
              xs={2}
              className="d-flex justify-content-start align-items-center mt-3"
            >
              <center>
                {Number(e?.itemAmountAfterOffer) === Number(e?.item_price) ? (
                  <span className="text-theme">{e?.item_price}</span>
                ) : (
                  <>
                    <span className="text-theme">
                      {e?.itemAmountAfterOffer}
                    </span>
                    {window.innerWidth < 768 && <br />}
                    <span
                      style={{
                        position: "relative",
                        marginLeft: "6px",
                      }}
                    >
                      {e?.item_price}
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: 0,
                          background: "#F5832B",
                          width: "100%",
                          height: "2px",
                        }}
                      />
                    </span>
                  </>
                )}
              </center>
            </Col>
            <Col
              xs={3}
              className="d-flex align-items-center justify-content-end mt-3 p-0"
            >
              {window.innerWidth > 768 && (!e?.item_cancel ? (
                alldata?.cancel_order_status ? (
                  <button
                    className="btn btn-danger py-1"
                    onClick={() => setShowDelectConfirmation(true)}
                  >
                    {window.innerWidth > 768 ? (
                      "Cancel"
                    ) : (
                      <i className="fa fa-close"></i>
                    )}
                  </button>
                ) : null
              ) : (
                <button className="btn btn-danger py-1" disabled>
                  Cancelled
                </button>
              ))}

              {!e?.item_cancel && window.innerWidth > 768 && status === 4 ? (
                <Link to={`/product-details/${e.product_id}`}>
                  <button className="btn btn-warning py-1">Review</button>
                </Link>
              ) : null}
            </Col>
            {/* <Col
              xs={3}
              className="d-flex align-items-center justify-content-end mt-3"
            >
              {status === 4 ? (
                <button className="btn btn-success py-1">
                  <i className="fa fa-refresh"></i> Order Again
                </button>
              ) : null} 
            </Col> */}
            {window.innerWidth < 768 ? (
              <>
                <Col xs={8} className="d-flex align-items-center mt-3">
                  {!e?.item_cancel ? (
                    <Link
                      to={`/product-details/${e.product_id}`}
                      className="text-primary full-width-truncate"
                      title={e.itemName}
                    >
                      <small>{e.itemName}</small>
                    </Link>
                  ) : (
                    <>
                      <Link
                        to={`/product-details/${e.product_id}`}
                        className="text-primary full-width-truncate"
                        title={e.itemName}
                      >
                        <span
                          style={{
                            position: "relative",
                          }}
                        >
                          <small>{e.itemName}</small>
                          <div
                            style={{
                              position: "absolute",
                              top: "12px",
                              left: 0,
                              background: "#F5832B",
                              width: "100%",
                              height: "1.4px",
                            }}
                          />
                        </span>
                      </Link>
                    </>
                  )}
                </Col>
                <Col
                  xs={4}
                  className="d-flex align-items-center justify-content-end mt-3 p-0"
                >
                  {window.innerWidth < 768 && (!e?.item_cancel ? (
                    alldata?.cancel_order_status ? (
                      <button
                        className="btn btn-danger py-1"
                        onClick={() => setShowDelectConfirmation(true)}
                      >
                        {window.innerWidth > 768 ? (
                          "Cancel"
                        ) : (
                          <i className="fa fa-close"></i>
                        )}
                      </button>
                    ) : null
                  ) : (
                    <button className="btn btn-danger py-1" disabled>
                      Cancelled
                    </button>
                  ))}

                  {!e?.item_cancel && status === 4 ? (
                    <Link to={`/product-details/${e.product_id}`}>
                      <button className="btn btn-warning py-1">Review</button>
                    </Link>
                  ) : null}
                </Col>
              </>
            ) : null}
          </>
        ) : (
          <>
            <Col
              xs={window.innerWidth > 768 ? 1 : 4}
              className="d-flex align-items-center mt-3"
            >
              <Link
                to={`/product-details/${e.product_id}`}
                className="text-primary full-width-truncate"
                title={e.itemName}
              >
                <img
                  src={apiUrl + e.image}
                  width={window.innerWidth > 768 ? 25 : 50}
                />
              </Link>
            </Col>
            {window.innerWidth > 768 ? (
              <Col xs={3} className="d-flex align-items-center mt-3">
                {!e?.item_cancel ? (
                  <Link
                    to={`/product-details/${e.product_id}`}
                    className="text-primary full-width-truncate"
                    title={e.itemName}
                  >
                    {e.itemName}
                  </Link>
                ) : (
                  <>
                    <Link
                      to={`/product-details/${e.product_id}`}
                      className="text-primary full-width-truncate"
                      title={e.itemName}
                      style={{
                        position: "relative",
                        marginLeft: "6px",
                      }}
                    >
                      {e?.itemName}
                      <div
                        style={{
                          position: "absolute",
                          top: "12px",
                          left: 0,
                          background: "#F5832B",
                          width: "100%",
                          height: "2px",
                        }}
                      />
                    </Link>
                  </>
                )}
              </Col>
            ) : null}
            <Col xs={2} className="text-center mt-3">
              <span>{e.sizeUnit}</span>
            </Col>
            <Col xs={1} className="text-center mt-3">
              <span>{e.quantity}</span>
            </Col>
            <Col
              xs={2}
              className="d-flex justify-content-start align-items-center mt-3"
            >
              <center>
                {Number(e?.itemAmountAfterOffer) === Number(e?.item_price) ? (
                  <span className="text-theme">{e?.item_price}</span>
                ) : (
                  <>
                    <span className="text-theme">
                      {e?.itemAmountAfterOffer}
                    </span>
                    {window.innerWidth < 768 && <br />}
                    <span
                      style={{
                        position: "relative",
                        marginLeft: "6px",
                      }}
                    >
                      {e?.item_price}
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: 0,
                          background: "#F5832B",
                          width: "100%",
                          height: "2px",
                        }}
                      />
                    </span>
                  </>
                )}
              </center>
            </Col>
            {window.innerWidth > 768 ? <Col
              xs={3}
              className="d-flex align-items-center justify-content-end mt-3 p-0"
            >
              {!e?.item_cancel ? (
                alldata?.cancel_order_status ? (
                  <button
                    className="btn btn-danger py-1"
                    onClick={() => setShowDelectConfirmation(true)}
                  >
                    {window.innerWidth > 768 ? (
                      "Cancel"
                    ) : (
                      <i className="fa fa-close"></i>
                    )}
                  </button>
                ) : null
              ) : (
                <button className="btn btn-danger py-1" disabled>
                  Cancelled
                </button>
              )}
            </Col> : null}
            {window.innerWidth < 768 ? (
              <>
                <Col xs={8} className="d-flex align-items-center mt-3">
                  {!e?.item_cancel ? (
                    <Link
                      to={`/product-details/${e.product_id}`}
                      className="text-primary full-width-truncate"
                      title={e.itemName}
                    >
                      <small>{e.itemName}</small>
                    </Link>
                  ) : (
                    <>
                      <Link
                        to={`/product-details/${e.product_id}`}
                        className="text-primary full-width-truncate"
                        title={e.itemName}
                      >
                        <span
                          style={{
                            position: "relative",
                          }}
                        >
                          <small>{e.itemName}</small>
                          <div
                            style={{
                              position: "absolute",
                              top: "12px",
                              left: 0,
                              background: "#F5832B",
                              width: "100%",
                              height: "1.4px",
                            }}
                          />
                        </span>
                      </Link>
                    </>
                  )}
                </Col>
                <Col xs={4} className="d-flex align-items-center justify-content-end mt-3">
                  {!e?.item_cancel ? (
                    alldata?.cancel_order_status ? (
                      <button
                        className="btn btn-danger py-1"
                        onClick={() => setShowDelectConfirmation(true)}
                      >
                        {window.innerWidth > 768 ? (
                          "Cancel"
                        ) : (
                          <i className="fa fa-close"></i>
                        )}
                      </button>
                    ) : null
                  ) : (
                    <button className="btn btn-danger py-1" disabled>
                      Cancelled
                    </button>
                  )}
                </Col>
              </>
            ) : null}
          </>
        )}

        {showDelectConfirmation ? (
          <div id="modal">
            <div className="insurance-confirm border-success py-4">
              <i
                className="fa fa-close"
                onClick={() => setShowDelectConfirmation(false)}
              ></i>
              <h5 className="mt-3">Are you sure ?</h5>
              <div className="mt-2">
                <button
                  className="btn border-theme text-theme mr-2"
                  onClick={() => setShowDelectConfirmation(false)}
                >
                  No
                </button>
                <button
                  className="btn bg-theme text-light"
                  onClick={handleSingleOrderCancel}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {showModal ? (
          <div id="modal">
            <div id="reason-main">
              <i
                className="fa fa-close"
                onClick={() => setShowModal(false)}
              ></i>
              <h4 className="p-0 m-0 mb-4">Select reason for cancellation</h4>
              {reason?.map((data, i) => {
                return (
                  <div
                    className={`reason-list d-flex align-items-center ${i === 0 ? `border-top border-bottom` : `border-bottom`
                      }`}
                    key={data.id}
                  >
                    <input
                      type="radio"
                      id={"reason" + data.id}
                      value={data.name}
                      className="checkBox"
                      onClick={handleSelectedReason}
                    />
                    &nbsp;
                    <label htmlFor={"reason" + data.id} className="p-0 m-0">
                      {data.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      {/* order item */}
      <Row className="orderitems-order border-left border-right py-3">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <b>{data?.length} ITEM{data?.length > 1 ? 'S' : null} IN THIS ORDER</b>
          {alldata?.cancel_order_status ? (
            <button
              className="btn btn-danger py-1"
              onClick={() => setShowDelectConfirmation(true)}
            >
              Cancel All
            </button>
          ) : null}

          {showDelectConfirmation ? (
            <div id="modal">
              <div className="insurance-confirm border-success py-4">
                <i
                  className="fa fa-close"
                  onClick={() => setShowDelectConfirmation(false)}
                ></i>
                <h5 className="mt-3">Are you sure ?</h5>
                <div className="mt-2">
                  <button
                    className="btn border-theme text-theme mr-2"
                    onClick={() => setShowDelectConfirmation(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn bg-theme text-light"
                    onClick={handleCancelOrder}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Row>
      <Row className="prescription-order border-left border-right p-3">
        <Col xs={4}>
          <p>PRODUCT</p>
        </Col>
        <Col xs={2} className="d-flex justify-content-center">
          <p>PACK SIZE</p>
        </Col>
        <Col xs={1} className="d-flex justify-content-center">
          <p>QTY</p>
        </Col>
        <Col xs={2}>
          <p>{`TOTAL (QAR)`}</p>
        </Col>
        <Col xs={1}></Col>
        <Col xs={2}></Col>
      </Row>
      <Row className="prescription-order pb-4 px-3 d-flex align-items-center border-left border-right border-bottom border">
        {data?.map((e, i) => {
          return (
            <>
              <ItemDetails e={e} key={i} />
            </>
          );
        })}
      </Row>

      {showModal ? (
        <div id="modal">
          <div id="reason-main">
            <i className="fa fa-close" onClick={() => setShowModal(false)}></i>
            <h4 className="p-0 m-0 mb-4">Select reason for cancellation</h4>
            {reason?.map((data, i) => {
              return (
                <div
                  className={`reason-list d-flex align-items-center ${i === 0 ? `border-top border-bottom` : `border-bottom`
                    }`}
                  key={data.id}
                >
                  <input
                    type="radio"
                    id={"reason" + data.id}
                    value={data.name}
                    className="checkBox"
                    onClick={handleSelectedReason}
                  />
                  &nbsp;
                  <label htmlFor={"reason" + data.id} className="p-0 m-0">
                    {data.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export const PrescriptionDetailsPart = ({ data }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      {/* prescription details */}
      <Row className="prescription-order border-left border-right py-3">
        <div className="w-100 d-flex align-items-center">
          <img src={upload_pres} alt="upload_pres" width={25} />
          <p className="ml-1">PRESCRIPTION</p>
        </div>
      </Row>
      <Row className="prescription-order border-left border-right border-bottom pb-3">
        <Col xs={4}>
          <p>NAME</p>
        </Col>
        <Col xs={6}>
          <p>REMARKS</p>
        </Col>
        <Col xs={2}></Col>
        <div className="mt-3" />

        {/* prescription content part */}
        <Col xs={4}>
          <p className="pres-p-truncate">{data?.name}</p>
        </Col>
        <Col xs={6}>
          <p className="pres-p-truncate">{data?.remarks}</p>
        </Col>
        <Col xs={2} className="d-flex align-items-center justify-content-end">
          {data?.file.indexOf(".pdf") >= 0 ? (
            <a
              target="_blank"
              className="float-right"
              href={apiUrl + data.file}
              download={data.name}
            >
              <button className="btn p-0 mr-2 text-primary">Preview</button>
            </a>
          ) : (
            <button
              className="btn p-0 mr-2 text-primary float-right"
              onClick={() => setShow(true)}
            >
              Preview
            </button>
          )}
        </Col>
      </Row>

      <ImageViewer show={show} setShow={setShow} url={apiUrl + data?.file} />
    </>
  );
};

export const InsuranceDetailsPart = ({ data }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      {/* insurance details */}
      <Row className="insurance-order border-left border-right py-3">
        <div className="w-100 d-flex align-items-center">
          <img src={upload_insu} alt="upload_pres" width={25} />
          <p className="ml-1">INSURANCE</p>
        </div>
      </Row>
      {window.innerWidth > 768 ? (
        <Row className="prescription-order border-left border-right border-bottom pb-3 pr-3">
          <Col xs={3}>
            <p>INSURANCE TYPE</p>
          </Col>
          <Col xs={3}>
            <p>SUB INSURANCE</p>
          </Col>
          <Col xs={3}>
            <p>PREMIUM NO.</p>
          </Col>
          <Col xs={2}>
            <p>EXPIRY DATE</p>
          </Col>
          <Col xs={1}></Col>
          <div className="mt-3" />

          {/* insurance content part */}
          <Col xs={3}>
            <small>{data?.insurance_type}</small>
          </Col>
          <Col xs={3}>
            <small>{data?.sub_insurance}</small>
          </Col>
          <Col xs={3}>
            <small>{data?.premium_no}</small>
          </Col>
          <Col xs={2}>
            <small>{data?.expiry_date}</small>
          </Col>
          <Col xs={1} className="d-flex align-items-center justify-content-end">
            {/* <button
              className="btn p-0 text-primary"
              onClick={() => {
                let alink = document.createElement("a");
                alink.href = apiUrl + data?.file;
                alink.target = "_blank";
                alink.click();
              }}
            >
              Preview
            </button> */}
            {data?.file.indexOf(".pdf") >= 0 ? (
              <a
                target="_blank"
                className="float-right"
                href={apiUrl + data.file}
                download={data.name}
              >
                <button className="btn p-0 mr-2 text-primary">Preview</button>
              </a>
            ) : (
              <button
                className="btn p-0 mr-2 text-primary float-right"
                onClick={() => setShow(true)}
              >
                Preview
              </button>
            )}
          </Col>
        </Row>
      ) : (
        <Row className="prescription-order border-left border-right border-bottom pb-3 pr-3">
          <Col xs={12} className="d-flex align-items-center">
            <p>INSURANCE TYPE</p> &nbsp;
            <p>-</p> &nbsp;{" "}
            <small className="pres-p-truncate">{data?.insurance_type}</small>
          </Col>
          <Col xs={12} className="d-flex align-items-center">
            <p>SUB INSURANCE</p> &nbsp;<p>-</p> &nbsp;{" "}
            <small>{data?.sub_insurance}</small>
          </Col>
          <Col xs={12} className="d-flex align-items-center">
            <p>PREMIUM NO.</p> &nbsp;<p>-</p> &nbsp;{" "}
            <small>{data?.premium_no}</small>
          </Col>
          <Col xs={12} className="d-flex align-items-center">
            <p>EXPIRY DATE</p> &nbsp;<p>-</p> &nbsp;{" "}
            <small>{data?.expiry_date}</small>
          </Col>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-end"
          >
            {data?.file.indexOf(".pdf") >= 0 ? (
              <a
                target="_blank"
                className="float-right"
                href={apiUrl + data.file}
                download={data.name}
              >
                <button className="btn p-0 mr-2 text-primary">Preview</button>
              </a>
            ) : (
              <button
                className="btn p-0 mr-2 text-primary float-right"
                onClick={() => setShow(true)}
              >
                Preview
              </button>
            )}
          </Col>
        </Row>
      )}

      <ImageViewer show={show} setShow={setShow} url={apiUrl + data?.file} />
    </>
  );
};

export default OrderItemCardUp;
