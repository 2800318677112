import { useNavigate } from "react-router-dom";
import "./Wishlistitems.css";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiUrl, displayTopBarCart } from "../../../../redux/action/action";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../../redux/action/action";
import Navbarmob from "../../../Header/NavbarMob/Navbarmob";
import { toast } from "react-toastify";
import { getUserToken, removeUserToken } from "../../../app/userToken";
import Bottommenumob from "../../../Footer/Bottommenumob/Bottommenumob";

const Wishlistitems = () => {
  const accessToken = getUserToken();
  const { id } = useParams();
  const [alldata, setAlldata] = useState({
    name: "",
    product_list: [],
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    fetchwishlistdata();
  }, [id]);

  const fetchwishlistdata = () => {
    setLoader(true)
    axios
      .get(`${apiUrl}/wishlist/get_wishlist/${id}`, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        setLoader(false)
        setAlldata(res.data.data);
      }).catch((err) => {
        setLoader(false)
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  const removeitemwishlist = async (e) => {
    const wishlist_id = e.target.getAttribute("wishlist_id");
    const product_list = e.target.getAttribute("product_list");
    deleteItems(product_list, wishlist_id);
  };

  const add_to_cart = async (e) => {
    const product_id = e.target.getAttribute("product_id");
    const itemUnit = e.target.getAttribute("itemUnit");
    const data = {
      product_id: product_id,
      quantity: 1,
      unit: itemUnit,
    };

    await dispatch(addToCart(data));
    await dispatch(displayTopBarCart());
  };

  const deleteItems = async (product_list, wishlist_id) => {
    axios
      .post(
        apiUrl + "/wishlist/delete_wishlist_products",
        {
          product_list,
          wishlist_id,
        },
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      )
      .then(() => {
        // toast.success(res.data.message);
        fetchwishlistdata();
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  const AddAllToCart = async () => {
    const res = await axios.get(
      apiUrl + "/wishlist/add_complete_wishlist_to_cart/" + id,
      {
        headers: {
          Authorization: "Token " + accessToken,
        },
      }
    );

    if (res.data?.status) {
      toast.success(res.data.message);
      await dispatch(displayTopBarCart());
    }
  };

  const handleClearAll = () => {
    axios
      .get(apiUrl + "/wishlist/clear_all/" + id, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        setShowDelectConfirmation(false);
        fetchwishlistdata();
        toast.success(res.data.message);
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  const handleChangeUnit = (e) => {
    const val = e.target.value;
    const wishlistId = e.target.getAttribute("wishlistId");
    const productId = e.target.getAttribute("productId");
    setLoader(true);
    axios
      .get(
        apiUrl +
        "/wishlist/update_wishlist_prod_unit/" +
        wishlistId +
        "?product_id=" +
        productId +
        "&unit=" +
        val,
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      )
      .then((res) => {
        setLoader(false);
        fetchwishlistdata();
      }).catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }

      });
  };

  return (
    <>
      <Navbarmob />
      {alldata?.product_list?.length !== 0 ? (
        <div className="d-flex justify-content-between px-3 mt-3">
          <button
            className="btn border-theme text-theme"
            onClick={AddAllToCart}
          >
            <i className="fa fa-shopping-bag"></i>
            &nbsp; Add All to Cart
          </button>
          &nbsp;
          <button
            className="btn border-theme text-theme"
            onClick={() => setShowDelectConfirmation(true)}
          >
            Clear All
          </button>
        </div>
      ) : null}
      {showDelectConfirmation ? (
        <div className="insurance-confirm py-4">
          <i
            className="fa fa-close"
            onClick={() => setShowDelectConfirmation(false)}
          ></i>
          <h5 className="mt-3">Are you sure ?</h5>
          <div className="mt-2">
            <button
              className="btn border-theme text-theme mr-2"
              onClick={() => setShowDelectConfirmation(false)}
            >
              No
            </button>
            <button
              className="btn bg-theme text-light"
              onClick={handleClearAll}
            >
              Yes
            </button>
          </div>
        </div>
      ) : null}
      <div className="my-wishlist-items mt-3">
        {loader ? <div className="d-flex align-items-center justify-content-center py-5">
          <p className="text-theme">Loading...</p>
        </div> :
          alldata?.product_list.length > 0 ? (
            alldata?.product_list.map((data, index) => {
              return (
                <div
                  className="wishlist-item mt-3 rounded p-4 border"
                  key={index}
                >
                  <div
                    className="wishlist-item-details mt-2 d-flex align-items-center"
                  >
                    <div
                      onClick={() =>
                        navigate("/product-details/" + data?.product_data.id)
                      }
                    >
                      <img
                        src={apiUrl + data?.product_data?.product_images[0]}
                        alt="img"
                        width={100}
                      />
                    </div>
                    <div className="d-flex flex-column ml-4">
                      <small
                        onClick={() =>
                          navigate("/product-details/" + data?.product_data.id)
                        }
                      >
                        {data?.product_data.itemName}
                      </small>


                      <div className="d-flex justify-content-between pr-3 pt-2">
                        {data?.unit === "pcs" ? (
                          Number(data?.product_data?.discounted_price_pcs) === Number(data?.product_data?.pricePerPcs) ? (
                            <small className="text-theme">
                              QAR {data?.product_data?.pricePerPcs}
                            </small>
                          ) : (
                            <div>
                              <small className="text-theme">
                                QAR{" "}
                                {data?.product_data?.discounted_price_pcs}
                              </small>
                              <small
                                style={{
                                  position: "relative",
                                  marginLeft: "6px",
                                }}
                              >
                                {data?.product_data?.pricePerPcs}
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "8px",
                                    left: 0,
                                    background: "#F5832B",
                                    width: "100%",
                                    height: "2px",
                                  }}
                                />
                              </small>
                            </div>
                          )
                        ) : Number(data?.product_data?.discounted_price_pack) ===
                          Number(data?.product_data?.pricePerPack) ? (
                          <small className="text-theme">
                            QAR {data?.product_data?.pricePerPack}
                          </small>
                        ) : (
                          <div>
                            <small className="text-theme">
                              QAR{" "}
                              {data?.product_data?.discounted_price_pack}
                            </small>
                            <small
                              style={{
                                position: "relative",
                                marginLeft: "6px",
                              }}
                            >
                              {data?.product_data?.pricePerPack}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "8px",
                                  left: 0,
                                  background: "#F5832B",
                                  width: "100%",
                                  height: "2px",
                                }}
                              />
                            </small>
                          </div>
                        )}



                        {data?.product_data?.pcsUnit?.toLowerCase() ===
                          data?.product_data?.packUnit.toLowerCase() ? (
                          data?.unit === "pcs" ? (
                            data?.product_data?.pcsUnit
                          ) : (
                            data?.product_data?.packUnit
                          )
                        ) : (
                          <select
                            className="ml-2"
                            style={{ width: "fit-content" }}
                            value={
                              data?.unit === "pcs"
                                ? data?.product_data?.pcsUnit
                                : data?.product_data?.packUnit
                            }
                            wishlistId={data?.wishlist_id}
                            productId={data?.product_id}
                            onChange={handleChangeUnit}
                          >
                            <option>
                              {data?.unit === "pcs"
                                ? data?.product_data?.pcsUnit
                                : data?.product_data?.packUnit}
                            </option>
                            <option>
                              {data?.unit !== "pcs"
                                ? data?.product_data?.pcsUnit
                                : data?.product_data?.packUnit}
                            </option>
                          </select>
                        )}


                      </div>
                    </div>
                  </div>
                  <div className="wishlist-item-action-btn mt-4">
                    <AddToCartBtn alldata={alldata} data={data} />
                    <button
                      className="btn removewishlist"
                      wishlist_id={alldata?.id}
                      product_list={data?.id}
                      onClick={removeitemwishlist}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="d-flex align-items-center justify-content-center py-5">
              <p className="text-theme">No item yet !</p>
            </div>
          )}
      </div>
      <div className="py-3 mt-4" />
      <Bottommenumob />
    </>
  );
};

const AddToCartBtn = ({ alldata, data }) => {
  const dispatch = useDispatch();
  const [btntxt, setBtnTxt] = useState('Add to cart');

  const add_to_cart = async (e) => {
    setBtnTxt('Adding...');
    const product_id = e.target.getAttribute("product_id");
    const itemUnit = e.target.getAttribute("itemUnit");
    const data = {
      product_id: product_id,
      quantity: 1,
      unit: itemUnit,
    };

    await dispatch(addToCart(data));
    await dispatch(displayTopBarCart());
    setBtnTxt('Add to Cart')
  };

  return (
    <button
      className="btn addcart p-0"
      wishlist_id={alldata?.id}
      product_id={data?.product_id}
      itemUnit={data?.unit}
      onClick={add_to_cart}
      disabled={btntxt === "Adding..."}
    >
      {btntxt}
    </button>
  )
}

export default Wishlistitems;
