import SetAddressDesktop from "./SetAddressDesktop/SetAddressDesktop";
import SetAddressMob from "./SetAddressMob/SetAddressMob";
import "./SetAddress.css";
import { useEffect } from "react";
import Bottommenumob from "../../Footer/Bottommenumob/Bottommenumob";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";

const Address = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbarmob />
      <div className="AddressforDesktop">
        <SetAddressDesktop />
      </div>
      <div className="AddressforMob mt-3">
        <SetAddressMob />
      </div>
      <div className="py-3 mt-4" />
      <Bottommenumob />
    </>
  );
};

export default Address;
