import Navbar from "../../Header/Navbar/Navbar";

import { Col, Container, Row } from "react-bootstrap";
import "./UploadPrescription.css";

// import icon images
import icon1 from "../../../assets/icons/UploadPres/icon1.png";
import icon2 from "../../../assets/icons/UploadPres/icon2.png";
import icon3 from "../../../assets/icons/UploadPres/icon3.png";
import icon4 from "../../../assets/icons/UploadPres/icon4.png";
import icon5 from "../../../assets/icons/UploadPres/icon5.png";
import icon6 from "../../../assets/icons/UploadPres/icon6.png";
import icon7 from "../../../assets/icons/UploadPres/icon7.png";

import { useNavigate } from "react-router-dom";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import ActionCmp from "./ActionCmp";
import Bottommenumob from "../../Footer/Bottommenumob/Bottommenumob";

const UploadPrescription = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Navbarmob />
      <div className="upload-prescription-title d-flex mt-md-4 mt-2 ml-3">
        <h5 onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
          <i className="fa fa-arrow-left"></i>
        </h5>
        <h5 className="ml-3">Upload prescription and order medicines</h5>
      </div>
      <Container fluid className="mt-1 px-md-5 px-3">
        <Row className="body-color upload-prescription">
          <Col md={6} className="px-2">
            <div className="uploadPresActionCmp-Mob">
              <ActionCmp />
            </div>
            {/* How to order with prescription */}
            <div className="w-100 pb-md-5">
              <p>How to order with prescription</p>
              <div className="w-100 pres-order-step px-4">
                <div className="icons">
                  <img src={icon1} alt="icon1" />
                </div>
                <hr />
                <div className="icons">
                  <img src={icon2} alt="icon1" />
                </div>
                <hr />
                <div className="icons">
                  <img src={icon3} alt="icon1" />
                </div>
              </div>
              <div className="pres-order-step-desc mt-2">
                <small>
                  Upload prescription <br /> for a new order
                </small>
                <small>
                  Fill user information, <br /> delivery address{" "}
                </small>
                <small>
                  We will reach out to you <br /> regarding your order
                </small>
              </div>
            </div>

            {/* Make sure the prescription you upload contains the following elements: */}
            <div className="w-100 mt-md-5 mt-4">
              <p>
                Make sure the prescription you upload contains the following
                elements:
              </p>
              <div className="w-100 pres-order-NB">
                <div className="pres-order-NB-list">
                  <div className="icons">
                    <img src={icon4} alt="icon2" />
                    <i className="fa fa-check-circle text-success"></i>
                  </div>
                  <small className="mt-3">Clinic Details</small>
                </div>
                <div className="pres-order-NB-list">
                  <div className="icons">
                    <img src={icon5} alt="icon2" />
                    <i className="fa fa-check-circle text-success"></i>
                  </div>
                  <small className="mt-3">Date of Prescription</small>
                </div>
                <div className="pres-order-NB-list">
                  <div className="icons">
                    <img src={icon6} alt="icon2" />
                    <i className="fa fa-check-circle text-success"></i>
                  </div>
                  <small className="mt-3">Patient Details</small>
                </div>
                <div className="pres-order-NB-list">
                  <div className="icons">
                    <img src={icon7} alt="icon2" />
                    <i className="fa fa-check-circle text-success"></i>
                  </div>
                  <small className="mt-3">Medicine Details</small>
                </div>
              </div>
            </div>
          </Col>

          <Col md={6} className="px-md-4 px-0 mt-3 mt-md-0 pb-5 pb-md-0">
            <div className="uploadPresActionCmp-Desk">
              <ActionCmp />
            </div>
            <div className="w-100 pres-order-warning">
              <span className="icon">
                <i className="fa fa-exclamation-triangle"></i>
              </span>
              <span className="title">
                <i>
                  Our pharmacist will dispense medicines only if the
                  prescription is valid & it meets all government regulations.
                </i>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="mt-5" />
      <Bottommenumob />
    </>
  );
};
export default UploadPrescription;
