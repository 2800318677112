import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import { fetchAllAddress } from "../../../../../redux/action/address.action";
import { useDispatch, useSelector } from "react-redux";
import AddressCardView from "../../../SetAddress/SetAddressDesktop/AddressCardView/AddressCardView";
import { useNavigate } from "react-router-dom";
import Navbarmob from "../../../../Header/NavbarMob/Navbarmob";
import AddAddressCmp from "../../../AddAddressCmp/AddAddressCmp";
import Bottommenumob from "../../../../Footer/Bottommenumob/Bottommenumob";

const MobSavedAddress = () => {
  const reduxresponse = useSelector((state) => state.addressReducer);
  const reduxresponsefetchaddressReducer = useSelector(
    (state) => state.fetchaddressReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllAddress());
  }, [reduxresponse.message]);

  return (
    <>
      <Navbarmob />
      <Container>
        <Row className="body-color">
          <Col>
            <div className="w-100 addresses">
              <button
                className="btn border border-dark w-100 mt-4"
                onClick={() => navigate("/address/add/checkout")}
              >
                Add New Address
              </button>
            </div>
            {reduxresponsefetchaddressReducer.data.length > 0 ? (
              <>
                {reduxresponsefetchaddressReducer.data.map((address, index) => {
                  return <AddressCardView data={address} key={index} />;
                })}
              </>
            ) : (
              <div className="w-100 py-5 d-flex justify-content-center">
                <h5 className="text-theme animate__animated animate__pulse animate__infinite">
                  No Address Found
                </h5>
              </div>
            )}
          </Col>
        </Row>
        <AddAddressCmp />
      </Container>
      <div className="py-3 mt-4" />
      <Bottommenumob />
    </>
  );
};

export default MobSavedAddress;
