import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Bottommenumob from "../../../../Footer/Bottommenumob/Bottommenumob";
import CartNavbarMob from "../../../../Header/CartNavbarMob/CartNavbarMob";
import Navbarmob from "../../../../Header/NavbarMob/Navbarmob";
import MyInsurance from "../../MyInsurance/MyInsurance";

const MobMyInsurances = () => {
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname.split("/");
    if (window.innerWidth > 1000 && path[1] === "profile") {
      window.location = location.pathname.replace("profile", "account");
    }
  }, []);
  return (
    <>
      <Navbarmob />
      <Container>
        <Row>
          <Col>
            <MyInsurance />
          </Col>
        </Row>
      </Container>
      <div className="py-3 mt-4" />
      <Bottommenumob />
    </>
  );
};

export default MobMyInsurances;
