import { Col, Container, Row } from "react-bootstrap";
import Cartitem from "../CartDesktop/Cartitem/Cartitem";
import Cartpricedetails from "../CartDesktop/Cartpricedetails/Cartpricedetails";
import SafetyAd from "../../Individualproduct/Smalldevice/SafetyAd/SafetyAd";
import { Link } from "react-router-dom";
import shopping_bag from "../../../../assets/shopping-bag.png";
import "./CartMob.css";
import { useSelector } from "react-redux";

const CartMob = () => {
  const reduxresponse = useSelector((state) => state.displayCartReducer);

  return (
    <>
      <Cartitem />
      <Container className="p-0 pb-5">
        <Row className="body-color">
          <Col>
            {reduxresponse?.data?.length === 0 ? (
              <div className="empty-cart-msg">
                <img src={shopping_bag} alt="shopping_bag" />
                <h5>Hey, it feels so light!</h5>
                <small>
                  There is nothing in your bag, let’s add some items.
                </small>
                <Link to="/product/all">
                  <button className="btn border-theme text-theme">
                    Add Items
                  </button>
                </Link>
              </div>
            ) : null}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs="12">
            {reduxresponse?.data?.length === 0 ? null : <Cartpricedetails />}
          </Col>
        </Row>
        {/* <AvailableOffer /> */}
        {reduxresponse?.data?.length === 0 ? null : (
          <Row className="mt-4 mb-5 mx-3 pt-3 shadow-sm border">
            <SafetyAd />
          </Row>
        )}
      </Container>
    </>
  );
};

export default CartMob;
