import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../../../../redux/action/action";
import { getUserToken } from "../../../../app/userToken";
import { getUserDetails } from "../../../../app/userDetails";
import { addNewAddress } from "../../../../../redux/action/address.action";

const AddAddressFormCmp = ({ address }) => {
  const accessToken = getUserToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [AddAddressState, SetAddAddressState] = useState(
    location.pathname.match("/setaddress") ? "Checkout Now" : "Add"
  );
  const [otherInput, setOtherInput] = useState("Home");
  const [otherName, setOtherName] = useState("");
  const [building_number, setBuilding_number] = useState(null);
  const [street, setStreet] = useState(null);
  const [zone, setZone] = useState(null);
  const [unit_number, setUnit_number] = useState(null);
  const [is_default, setis_default] = useState(false);
  const [userdata, setuserdata] = useState({
    name: "",
    phone_number: "",
    country_code: "",
  });
  const [apartment_name, setApartment_name] = useState();
  const [door_number, setDoor_number] = useState();

  useEffect(() => {
    if (accessToken) {
      fetchUserData();
    }
  }, []);

  const fetchUserData = async () => {
    const res = await axios.get(apiUrl + "/user/customerDetails", {
      headers: {
        Authorization: "Token " + accessToken,
      },
    });
    setuserdata({
      name: getUserDetails(res.data.encrypted).name,
      phone_number: getUserDetails(res.data.encrypted).phone_number,
      country_code: getUserDetails(res.data.encrypted).country_code,
    });
  };

  const oncheckdefaultaddress = () => {
    let checkBox = document.getElementById("address-default");
    // Check if the element is selected/checked
    if (checkBox.checked) {
      setis_default(true);
    } else {
      setis_default(false);
    }
  };

  const OnSubmit_AddNewAddress = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    if (
      formData.get("building_number").length >= 1 &&
      formData.get("street").length >= 1 &&
      formData.get("zone").length >= 1
    ) {
      const data = {
        name:
          formData.get("name") === "Others" ? otherName : formData.get("name"),
        address: formData.get("address"),
        building_number: formData.get("building_number"),
        street: formData.get("street"),
        zone: formData.get("zone"),
        unit_number: formData.get("unit_number"),
        apartment_name: formData.get("apartment_name"),
        door_number: formData.get("door_number"),
        // country_code: formData.get("country_code"),
        country_code: "+974",
        phone_number: formData.get("phone"),
        email: formData.get("email"),
        is_default: is_default,
        customer_id: 1,
      };
      if (data.address.length > 5) {
        if (data.phone_number.length === 8) {
          if (accessToken) {
            await SetAddAddressState("Adding...");
            await dispatch(addNewAddress(data));
            form.reset();
            // await setShowAddressFields(false);
            SetAddAddressState("Add");
          } else {
            await SetAddAddressState("Wait a sec...");
            if (location.pathname === "/setaddress") {
              data["full_name"] = formData.get("full_name");
            }
            await dispatch(addNewAddress(data));
            SetAddAddressState("Checkout Now");
            sessionStorage.removeItem("guestData");
          }
        }
        else {
          toast.warning("Phone number should be 8 character")
        }
      } else {
        toast.warning("Address should be atleast 5 characters");
      }
    } else {
      toast.warning(
        "Building no., Street, and Zone should be atleast 2 characters"
      );
    }
  };

  const [guestData, setGuestData] = useState({
    phone: "",
    name: "",
  })

  useEffect(() => {
    const localdata = sessionStorage.getItem("guestData");
    if (localdata) {
      const data = JSON.parse(localdata);
      setGuestData(data)
      // sessionStorage.removeItem("guestData");
    }
    // sessionStorage.removeItem("guest_address");
  }, [])

  return (
    <div className="w-100">
      <form className="row" onSubmit={OnSubmit_AddNewAddress}>
        {location.pathname === "/setaddress" && (
          <>
            <h5 className="mt-3">Contact information</h5>
            <div className="input-group-cust col-12">
              <label htmlFor="address-phone" className="mb-0 mt-2">
                Phone Number<sup>*</sup>
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  name="country_code"
                  className="form-control p-0 text-center"
                  id="country_code"
                  disabled
                  defaultValue={"+974"}
                  required
                  style={{
                    width: "70px",
                    borderRadius: "4px 0 0 4px",
                  }}
                />
                <input
                  type="number"
                  name="phone"
                  className="form-control"
                  id="address-phone"
                  value={guestData.phone}
                  onChange={(e) => {
                    if (e.target.value.length <= 8) {
                      setGuestData({ ...guestData, phone: e.target.value })
                    }
                    else {
                      return false
                    }
                  }}
                  required
                  style={{
                    borderRadius: "0 4px 4px 0",
                  }}
                />
              </div>
            </div>
          </>
        )}

        {accessToken ? null : (
          <>
            <div className="input-group-cust col-12 mt-4">
              <h5>Shipping address</h5>
            </div>
            <div className="input-group-cust col-12">
              <label htmlFor="address" className="mb-0 mt-2">
                Full Name<sup>*</sup>
              </label>
              <input
                type="text"
                name="full_name"
                className="form-control"
                id="address"
                required={location.pathname === "/setaddress"}
                defaultValue={guestData.name}
                onChange={(e) => setGuestData({ ...guestData, name: e.target.value })}
              />
            </div>
            <div className="col-12 mt-3 mb-2">
              <button
                className="btn p-0 mt-2 text-theme"
                onClick={() => {
                  navigate("/address/add/checkout")
                  sessionStorage.setItem("guestData", JSON.stringify(guestData))
                }}
              >
                <i className="fa fa-map-marker" style={{ fontSize: "25px" }}></i> &nbsp;Select location on map
              </button>
            </div>
          </>
        )}
        <div className="input-group-cust col-12">
          <label htmlFor="address" className="mb-0 mt-2">
            Address<sup>*</sup>
          </label>
          <input
            type="text"
            name="address"
            className="form-control"
            defaultValue={address}
            id="address"
            required
          />
        </div>
        <div className="input-group-cust col-md-3 col-6">
          <label htmlFor="building_number" className="mb-0 mt-2">
            Building no.<sup>*</sup>
          </label>
          <input
            type="number"
            name="building_number"
            className="form-control"
            id="building_number"
            value={building_number}
            onChange={(e) => setBuilding_number(e.target.value)}
            required
          />
        </div>
        <div className="input-group-cust col-md-3 col-6">
          <label htmlFor="street" className="mb-0 mt-2">
            Street<sup>*</sup>
          </label>
          <input
            type="number"
            name="street"
            className="form-control"
            id="street"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            required
          />
        </div>
        <div className="input-group-cust col-md-3 col-6">
          <label htmlFor="zone" className="mb-0 mt-2">
            Zone<sup>*</sup>
          </label>
          <input
            type="number"
            name="zone"
            className="form-control"
            id="zone"
            value={zone}
            onChange={(e) => setZone(e.target.value)}
            required
          />
        </div>
        <div className="input-group-cust col-md-3 col-6">
          <label htmlFor="unit_number" className="mb-0 mt-2">
            Unit Number
          </label>
          <input
            type="number"
            name="unit_number"
            className="form-control"
            id="unit_number"
            value={unit_number}
            onChange={(e) => setUnit_number(e.target.value)}
          />
        </div>

        <div className="input-group-cust col-6">
          <label htmlFor="unit_number" className="mb-0 mt-2">
            Apartment Name
          </label>
          <input
            type="text"
            name="apartment_name"
            className="form-control"
            id="apartment_name"
            value={apartment_name}
            onChange={(e) => setApartment_name(e.target.value)}
          />
        </div>
        <div className="input-group-cust col-6">
          <label htmlFor="unit_number" className="mb-0 mt-2">
            Door Number
          </label>
          <input
            type="number"
            name="door_number"
            className="form-control"
            id="door_number"
            value={door_number}
            onChange={(e) => setDoor_number(e.target.value)}
          />
        </div>

        {location.pathname.match("/setaddress") ? (
          <div className="input-group-cust col-12 mb-4">
            <label htmlFor="email" className="mb-0 mt-2">
              Email<sup>*</sup>
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              required={location.pathname.match("/setaddress") && !accessToken}
            />
          </div>
        ) : (
          <>
            <div className="input-group-cust col-md-5 col-12">
              <label htmlFor="address-phone" className="mb-0 mt-2">
                Phone Number<sup>*</sup>
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  name="country_code"
                  className="form-control p-0 text-center"
                  id="country_code"
                  disabled
                  defaultValue={"+974"}
                  required
                  style={{
                    width: "70px",
                    borderRadius: "4px 0 0 4px",
                  }}
                />
                <input
                  type="number"
                  name="phone"
                  className="form-control"
                  id="address-phone"
                  defaultValue={userdata.phone_number}
                  required
                  style={{
                    borderRadius: "0 4px 4px 0",
                  }}
                />
              </div>
            </div>
            <div
              className="input-group-cust col-md-7 col-12 mt-4 pt-2 d-flex align-items-center"
              style={{ alignItems: "baseline" }}
            >
              <input
                type="radio"
                name="name"
                value="Home"
                id="Home"
                defaultChecked
                onClick={(e) => setOtherInput(e.target.value)}
              />
              &nbsp;
              <label htmlFor="Home" className="mt-2">
                Home
              </label>
              &nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                name="name"
                value="Office"
                id="Office"
                onClick={(e) => setOtherInput(e.target.value)}
              />
              &nbsp;
              <label htmlFor="Office" className="mt-2">
                Office
              </label>
              &nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                name="name"
                value="Others"
                id="Others"
                onClick={(e) => {
                  setOtherInput(e.target.value);
                  document.getElementById("others-textfield").focus();
                }}
              />
              &nbsp;
              <label htmlFor="Others" className="mt-2">
                Others
              </label>
              &nbsp;&nbsp;&nbsp;
              {otherInput === "Others" ? (
                <input
                  type="text"
                  name="name"
                  autoFocus
                  onChange={(e) => setOtherName(e.target.value)}
                  className="form-control"
                  id="others-textfield"
                  required
                />
              ) : null}
            </div>
            <div className="input-group-cust col-md-4 col-10 mt-3 ml-4">
              <input
                className="form-check-input"
                type="checkbox"
                value="true"
                name="default"
                id="address-default"
                onClick={oncheckdefaultaddress}
              />
              <label
                className="form-check-label text-grey"
                htmlFor="address-default"
              >
                Make as Default Address
              </label>
            </div>
          </>
        )}

        <div className="w-100 d-flex justify-content-center mt-3">
          {location.pathname.match("/setaddress") ? null : (
            <button
              className="btn border-dark"
              // onClick={() => {
              //   setShowSearchBarFields(false);
              //   setShowAddressPicker(false);
              //   setShowAddressFields(false);
              // }}
              onClick={() => navigate(-1)}
              type="button"
            >
              Close
            </button>
          )}
          <button
            className="btn bg-theme text-light ml-2"
            type="submit"
            disabled={
              AddAddressState === "Wait a sec..." ||
              AddAddressState === "Adding..."
            }
          >
            {AddAddressState}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAddressFormCmp;
