import { Col } from "react-bootstrap";

const detailsObjMap = [
  {
    label: "Ingredients",
    prop: 'ingredients'
  },
  {
    label: 'Composition',
    prop: 'composition'
  }, {
    label: 'Side Effects',
    prop: 'sideEffects'
  }, {
    label: 'Safety Info',
    prop: 'safetyInfo'
  }, {
    label: 'Storage & Disposal',
    prop: 'storageDisposal'
  },
  {
    label: 'Precautions & Warnings',
    prop: 'precautionsAndWarnings'
  },
  {
    label: 'Directions for use',
    prop: 'directionsForUse'
  }, {
    label: 'Contra Indications',
    prop: 'contraindications'
  }, {
    label: 'Mode Of Delivery',
    prop: 'modeOfDelivery'
  }, {
    label: 'Therapeutic Classification',
    prop: 'therapeuticClassification'
  },
];

const renderDetails = (obj, data, index) => {
  const { label, prop } = obj;
  return (
    data && data[prop] ? (
      <Col md={6} className='mt-4 pl-0'
        style={{
          paddingRight: '4  0px'
        }}
      >
        <h6 className="m-0 p-0 mt-3">{label} :</h6>
        <small className="text-grey d-block text-capitalize mt-3">
          {data[prop]}
        </small>
      </Col>
    ) : null
  );
}

const Otherdetails = ({ data }) => {
  return (
    <>
      {detailsObjMap.map((obj, index) => renderDetails(obj, data, index))}
    </>
  );
};

export default Otherdetails;
