import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//     apiKey: "AIzaSyAGY1lmvrV5CkvsBVlV6RdNtaOwTubQqFE",
//     authDomain: "medpack-uat.firebaseapp.com",
//     projectId: "medpack-uat",
//     storageBucket: "medpack-uat.appspot.com",
//     messagingSenderId: "485159008534",
//     appId: "1:485159008534:web:8be664894c706d2240f837",
//     measurementId: "G-SPJ8EK7M49"
//   };
const firebaseConfig = {
  apiKey: "AIzaSyD1SqL6VnYlE8Bb08wI2tCFqZWQvaV-zTE",
  authDomain: "medpack-6b8b0.firebaseapp.com",
  projectId: "medpack-6b8b0",
  storageBucket: "medpack-6b8b0.appspot.com",
  messagingSenderId: "487716036895",
  appId: "1:487716036895:web:21c3f9edddd3ab9d32102a",
  measurementId: "G-KDDKHT9CL6"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;