/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import $ from "jquery";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../../../../redux/action/action";
import {
  ADD_FILTER_BY_BRAND,
  ADD_FILTER_BY_CATEGORY,
  ADD_FILTER_BY_PRODUCT_FORM,
  IS_NOT_PRESCRIPTION,
  IS_PRESCRIPTION,
} from "../../../../../redux/state/state";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Filters = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxresponse = useSelector((state) => state);
  const [CATEGORY, SETCATEGORY] = useState([]);
  const [BRANDS, SETBRANDS] = useState([]);
  const [PRODUCT_FORM, SETPRODUCT_FORM] = useState([]);
  const [loader, setLoader] = useState(true);
  const [categoryseemore, setCategoryseemore] = useState(false);
  const [brandseemore, setBrandseemore] = useState(false);
  const [checkboxstate, setCheckboxstate] = useState(false);

  const [categoryKey, setCategoryKey] = useState();
  const [brandKey, setBrandKey] = useState();
  const [prdctFormKey, setPrdctFormKey] = useState();
  const reduxresponseWithPres = useSelector(
    (state) => state.withPresfilterReducer.prescription
  );
  const reduxresponseWithOutPres = useSelector(
    (state) => state.withOutPresfilterReducer.not_prescription
  );

  useEffect(() => {
    setCheckboxstate(false);
  }, [reduxresponse.reducer]);

  useEffect(() => {
    if (window.innerWidth > 768)
      loadAllCategories();
  }, []);

  useEffect(() => {
    loadAllBrand();
  }, [
    reduxresponse.filterByCategory.category_id,
    reduxresponse.filterByCategory.sub_category_id,
    reduxresponse.filterByProductForm.productForm,
    reduxresponseWithPres,
    reduxresponseWithOutPres,
  ]);

  useEffect(() => {
    loadAllProducForm();
  }, [
    reduxresponse.filterByCategory.category_id,
    reduxresponse.filterByCategory.sub_category_id,
    reduxresponse.filterByBrand.brand,
    reduxresponseWithPres,
    reduxresponseWithOutPres,
  ]);

  const loadAllCategories = () => {
    axios
      .get(apiUrl + "/category/get_category_tree")
      .then((resCategory) => {
        if (resCategory.data.status === 200) {
          SETCATEGORY(resCategory.data.data);
          if (resCategory.data.data.length > 10) {
            setCategoryseemore(true);
          }
        } else {
          // toast.error(resCategory.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  const loadAllBrand = () => {
    // reduxresponse.filterByCategory.sub_category_id.join(",")
    axios
      .get(
        apiUrl +
        "/category/brand?category_id=" +
        reduxresponse.filterByCategory.category_id.join(",") +
        "&sub_category_id=" +
        reduxresponse.filterByCategory.sub_category_id.join(",") +
        "&product_form=" +
        reduxresponse.filterByProductForm.productForm.join(",") +
        "&is_prescription_mandatory=" +
        reduxresponseWithPres +
        "&is_prescriotion_not_mandatory=" +
        reduxresponseWithOutPres
      )
      .then((resBrand) => {
        setLoader(false);
        if (resBrand.data.status === 200) {
          SETBRANDS(resBrand.data.data);
          if (resBrand.data.data.length > 10) {
            setBrandseemore(true);
          }
        } else {
          // toast.error(resBrand.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  const loadAllProducForm = () => {
    axios
      .get(
        apiUrl +
        "/category/product_form?category_id=" +
        reduxresponse.filterByCategory.category_id.join(",") +
        "&sub_category_id=" +
        reduxresponse.filterByCategory.sub_category_id.join(",") +
        "&brand=" +
        reduxresponse.filterByBrand.brand.join(",") +
        "&is_prescription_mandatory=" +
        reduxresponseWithPres +
        "&is_prescriotion_not_mandatory=" +
        reduxresponseWithOutPres
      )
      .then((resProductFrom) => {
        if (resProductFrom.data.status === 200) {
          SETPRODUCT_FORM(resProductFrom.data.data);
        } else {
          toast.error(resProductFrom.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  const onclick_CATEGORY = (e) => {
    navigate("/product/all");
    setCheckboxstate(true);
    const data = {
      category_id: e.target.getAttribute("categoryid"),
      category_name: e.target.value,
    };
    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      dispatch({
        type: ADD_FILTER_BY_CATEGORY,
        payload: data,
      });
    } else {
      dispatch({
        type: "DELETE_FILTER_BY_CATEGORY",
        payload: data,
      });

      if (reduxresponse.filterByCategory.category_id.length === 1) {
        navigate("/product/all");
      }
    }
  };

  const onclick_BRANDS = (e) => {
    navigate("/product/all");
    setCheckboxstate(true);
    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      dispatch({
        type: ADD_FILTER_BY_BRAND,
        payload: e.target.value,
      });
    } else {
      dispatch({
        type: "DELETE_FILTER_BY_BRAND",
        payload: e.target.value,
      });
    }
  };

  const onclick_PRODUCT_FORM = (e) => {
    navigate("/product/all");
    setCheckboxstate(true);
    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      dispatch({
        type: ADD_FILTER_BY_PRODUCT_FORM,
        payload: e.target.value,
      });
    } else {
      dispatch({
        type: "DELETE_FILTER_BY_PRODUCT_FORM",
        payload: e.target.value,
      });
    }
  };

  const onclick_is_pres = (e) => {
    navigate("/product/all");
    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      dispatch({
        type: IS_PRESCRIPTION,
        payload: true,
      });
    } else {
      dispatch({
        type: IS_PRESCRIPTION,
        payload: false,
      });
    }
  };

  const onclick_is_not_pres = (e) => {
    navigate("/product/all");
    // check this element is checked or not
    let ischecked = e.target.checked;
    if (ischecked) {
      dispatch({
        type: IS_NOT_PRESCRIPTION,
        payload: true,
      });
    } else {
      dispatch({
        type: IS_NOT_PRESCRIPTION,
        payload: false,
      });
    }
  };

  const open_category_search = () => {
    // check if class d-none is present or not
    if ($(".category_search").hasClass("d-none")) {
      // if class d-none is present then remove it
      $(".category_search").removeClass("d-none");
    } else {
      // if class d-none is not present then add it
      $(".category_search").addClass("d-none");
    }
  };

  const open_brand_search = () => {
    // check if class d-none is present or not
    if ($(".brand_search").hasClass("d-none")) {
      // if class d-none is present then remove it
      $(".brand_search").removeClass("d-none");
    } else {
      // if class d-none is not present then add it
      $(".brand_search").addClass("d-none");
    }
  };

  const [selectedCatId, setSelectcatId] = useState();

  const CatCmp = ({ item, selectedCatId, setSelectcatId }) => {
    const [showSubCat] = useState(selectedCatId === item?.id);
    const [subcategoryKey, setSubcategoryKey] = useState();

    const onclick_SUB_CATEGORY = (e) => {
      navigate("/product/all");
      setCheckboxstate(true);
      // setShowSubCat(true);
      const data = {
        sub_category_id: e.target.getAttribute("subcategoryid"),
        sub_category_name: e.target.value,
      };
      // check this element is checked or not
      let ischecked = e.target.checked;
      if (ischecked) {
        dispatch({
          type: ADD_FILTER_BY_CATEGORY,
          payload: data,
        });
      } else {
        dispatch({
          type: "DELETE_FILTER_BY_CATEGORY",
          payload: data,
        });

        if (reduxresponse.filterByCategory.sub_category_id.length === 1) {
          navigate("/product/all");
        }
      }
    };

    return (
      <>
        <div className="d-flex align-items-center justify-content-between pr-5">
          <div
            className="form-check m-0"
            onClick={() =>
              setSelectcatId(selectedCatId === item?.id ? null : item.id)
            }
          >
            <input
              disabled={checkboxstate}
              className="form-check-input"
              type="checkbox"
              value={item.name}
              id={item.id + "CATEGORY"}
              categoryid={item.id}
              onClick={onclick_CATEGORY}
              readOnly
              checked={
                reduxresponse.filterByCategory.category_id.indexOf(
                  item.id.toString()
                ) >= 0
              }
            />
            <label
              style={{ cursor: "pointer", width: "160px" }}
              className="form-check-label text-grey text-capitalize truncate"
              htmlFor={item.id + "CATEGORY"}
            >
              {item.name}
            </label>
          </div>
          <i
            className={`fa ${showSubCat ? `fa-minus` : `fa-plus`}`}
            onClick={() =>
              setSelectcatId(selectedCatId === item?.id ? null : item.id)
            }
          ></i>
        </div>

        {showSubCat ? (
          <div>
            {/* <input
              type="search"
              name="category-search"
              className="category_search form-control animate__animated animate__fadeIn"
              placeholder="Search for sub category"
              onInput={(e) => setSubcategoryKey(e.target.value)}
            /> */}
            {(subcategoryKey
              ? item?.sub_categories.filter((e) =>
                e.name?.toLowerCase().includes(subcategoryKey)
              )
              : item?.sub_categories
            ).map((e, i) => {
              return (
                <div className="form-check ml-3" key={i}>
                  <input
                    disabled={checkboxstate}
                    className="form-check-input"
                    type="checkbox"
                    value={e.name}
                    id={e.id + "_SUB_CATEGORY"}
                    subcategoryid={e.id}
                    onClick={onclick_SUB_CATEGORY}
                    readOnly
                    checked={
                      reduxresponse.filterByCategory.sub_category_id.indexOf(
                        e.id.toString()
                      ) >= 0
                    }
                  />
                  <label
                    className="form-check-label text-grey text-capitalize"
                    htmlFor={e.id + "_SUB_CATEGORY"}
                  >
                    {e.name}
                  </label>
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  };
  const BrandCmp = ({ item, index }) => {
    return (
      <div className="form-check">
        <input
          disabled={checkboxstate}
          className="form-check-input"
          type="checkbox"
          value={item.manufacturer}
          id={index + "BRANDS"}
          onClick={onclick_BRANDS}
          readOnly
          checked={
            reduxresponse.filterByBrand.brand.indexOf(item.manufacturer) >= 0
          }
        />
        <label
          className="form-check-label text-grey text-capitalize"
          htmlFor={index + "BRANDS"}
        >
          {item.manufacturer} ({item.products_count})
        </label>
      </div>
    );
  };

  function searchAndFilter(test, searchInput) {
    return test
      .map((category) => {
        return {
          ...category,
          sub_categories: category.sub_categories.filter((subCategory) =>
            subCategory.name.toLowerCase().includes(searchInput.toLowerCase())
          ),
        };
      })
      .filter((category) => category.sub_categories.length > 0);
  }

  return (
    <>
      <div className="w-100 px-5 mt-4">
        {loader ? (
          <SkeletonTheme color="#ccc" highlightColor="#fff">
            <Skeleton count={40} height={10} />
          </SkeletonTheme>
        ) : null}
      </div>

      {CATEGORY.length === 0 ? null : (
        <div className="w-100 pl-3 mt-4">
          {/* CATEGORY */}
          <div className="d-flex align-items-center justify-content-between mt-4 mb-2">
            <p className="m-0">CATEGORY</p>
            {/* <i className="fa fa-search mr-4" onClick={open_category_search}></i> */}
          </div>
          <input
            type="search"
            name="category-search"
            className="category_search form-control animate__animated animate__fadeIn m-0"
            placeholder="Search"
            onInput={(e) => setCategoryKey(e.target.value)}
          />
          <div className="mt-2" />
          <div
            style={{
              maxHeight: "250px",
              overflowY: "auto",
            }}
          >
            {CATEGORY.length > 0
              ? (categoryKey
                ? // ? CATEGORY.filter((e) =>
                //     e.name?.toLowerCase().includes(categoryKey)
                //   )

                // ? CATEGORY.filter((e1) => {
                //   return e1.name?.toLowerCase().includes(categoryKey)
                // })[0].sub_categories.filter((e2) => {
                //   return e2.name?.toLowerCase().includes(categoryKey)
                // })

                // ? CATEGORY?.filter((e1) => {
                //   return e1?.sub_categories?.filter((e2) => {
                //     return e2.name?.toLowerCase().includes(categoryKey)
                //   })
                // })

                // ? CATEGORY.filter((e1) => (e1.sub_categories.filter((e2) => e2.name.includes(categoryKey)).length > 0  ?  ))

                searchAndFilter(CATEGORY, categoryKey)
                : CATEGORY
              ).map((item, index) => (
                <CatCmp
                  item={item}
                  index={index}
                  selectedCatId={selectedCatId}
                  setSelectcatId={setSelectcatId}
                />
              ))
              : null}

            {/* {CATEGORY.length <= 10 ? null : (
              <button
                className="btn p-0 mt-2 text-theme"
                style={{ boxShadow: "none" }}
                onClick={() => setCategoryseemore(!categoryseemore)}
              >
                {categoryseemore ? "see more" : "see less"}
              </button>
            )} */}
          </div>
        </div>
      )}

      {BRANDS.length === 0 ? null : (
        <div className="w-100 pl-3 border-line-top mt-4">
          {/* BRANDS */}
          <div className="d-flex align-items-center justify-content-between mt-4 mb-2">
            <p className="m-0">BRANDS</p>
            {/* <i className="fa fa-search mr-4" onClick={open_brand_search}></i> */}
          </div>
          <input
            type="search"
            name="brands-search"
            className="brand_search form-control animate__animated animate__fadeIn m-0"
            placeholder="Search for brands"
            onInput={(e) => setBrandKey(e.target.value)}
          />
          <div
            style={{
              maxHeight: "250px",
              overflowY: "auto",
            }}
          >
            {BRANDS.length > 0
              ? (brandKey
                ? BRANDS.filter((e) =>
                  e.manufacturer?.toLowerCase().includes(brandKey)
                )
                : BRANDS
              ).map((item, index) => <BrandCmp item={item} index={index} />)
              : null}
            {/* {BRANDS.length <= 10 ? null : (
              <button
                className="btn p-0 mt-2 text-theme"
                style={{ boxShadow: "none" }}
                onClick={() => setBrandseemore(!brandseemore)}
              >
                {brandseemore ? "see more" : "see less"}
              </button>
            )} */}
          </div>
        </div>
      )}

      {PRODUCT_FORM.length === 0 ? null : (
        <div className="w-100 pl-3 border-line-top mt-4">
          {/* PRODUCT FORM */}
          <div className="d-flex align-items-center justify-content-between mt-4 mb-2">
            <p className="m-0">PRODUCT FORM</p>
            {/* <i
              className="fa fa-search mr-4"
              onClick={open_prdctfrom_search}
            ></i> */}
          </div>
          <input
            type="search"
            name="brands-search"
            className="prdctfrom_search form-control animate__animated animate__fadeIn m-0"
            placeholder="Search for product from"
            onInput={(e) => setPrdctFormKey(e.target.value)}
          />
          <div
            style={{
              maxHeight: "250px",
              overflowY: "auto",
            }}
          >
            {PRODUCT_FORM.length > 0
              ? (prdctFormKey
                ? PRODUCT_FORM.filter((e) =>
                  e.product_form?.toLowerCase().includes(prdctFormKey)
                )
                : PRODUCT_FORM
              ).map((item, index) => {
                return (
                  <div key={index} className="form-check">
                    <input
                      disabled={checkboxstate}
                      className="form-check-input"
                      type="checkbox"
                      value={item.product_form}
                      id={index + "PRODUCT_FORM"}
                      onClick={onclick_PRODUCT_FORM}
                      readOnly
                      checked={
                        reduxresponse.filterByProductForm.productForm.indexOf(
                          item.product_form
                        ) >= 0
                      }
                    />
                    <label
                      className="form-check-label text-grey text-capitalize"
                      htmlFor={index + "PRODUCT_FORM"}
                    >
                      {item.product_form} ({item.products_count})
                    </label>
                  </div>
                );
              })
              : null}
          </div>
        </div>
      )}

      <div className="w-100 pl-3 mt-4 border-top">
        <p className="m-0 mt-4">PRESCRIPTION</p>
        <div className="d-flex align-items-center justify-content-between">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="with_pres"
              checked={reduxresponseWithPres}
              onClick={onclick_is_pres}
            />
            <label
              className="form-check-label text-grey text-capitalize"
              htmlFor={"with_pres"}
            >
              with prescription
            </label>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="with_pres"
              checked={reduxresponseWithOutPres}
              onClick={onclick_is_not_pres}
            />
            <label
              className="form-check-label text-grey text-capitalize"
              htmlFor={"with_pres"}
            >
              Without prescription
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filters;
