import { Col, Container, Row } from "react-bootstrap";
import "./CartDesktop.css";
import Footer from "../../../Footer/Footer";
import Cartitem from "./Cartitem/Cartitem";
import Cartpricedetails from "./Cartpricedetails/Cartpricedetails";
import Other from "../../Home/Others/Other";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import shopping_bag from "../../../../assets/shopping-bag.png";
import { useEffect } from "react";
import SuggetionPrdct from "../../SuggetionPrdct/SuggetionPrdct";
import { getUserToken, removeUserToken } from "../../../app/userToken";
import axios from "axios";
import { apiUrl } from "../../../../redux/action/action";
import { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { toast } from "react-toastify";

const CartDesktop = () => {
  const accessToken = getUserToken();
  const reduxresponse = useSelector((state) => state.displayCartReducer);
  const [recentOrderItemList, setRecentOrderItemList] = useState();

  useEffect(() => {
    localStorage.removeItem("cart_data");
    localStorage.removeItem("redirect_url");
    fetchRecentlyOrderedItem();
  }, []);

  const fetchRecentlyOrderedItem = () => {
    axios
      .get(apiUrl + "/order/recently_ordered_item", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        setRecentOrderItemList(res.data.data);
      }).catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  return (
    <>
      <Container className="mt-5">
        {reduxresponse.loader ? (
          <Row className="body-color">
            <Col md={7}>
              <SkeletonTheme color="#ccc" highlightColor="#fff">
                <Skeleton count={2} height={200} />
              </SkeletonTheme>
            </Col>
            <Col md={1}></Col>
            <Col md={4}>
              <SkeletonTheme color="#ccc" highlightColor="#fff">
                <Skeleton count={1} height={500} />
              </SkeletonTheme>
            </Col>
          </Row>
        ) : reduxresponse?.data.length === 0 ? (
          <div className="empty-cart-msg">
            <img src={shopping_bag} alt="shopping_bag" />
            <h5>Hey, it feels so light!</h5>
            <small>There is nothing in your bag, let’s add some items.</small>
            <Link to="/product/all">
              <button className="btn border-theme text-theme">Add Items</button>
            </Link>
          </div>
        ) : (
          <>
            <Row className="mb-5 body-color pb-3">
              <Col xs="7">
                {/* view all items added to cart */}
                <div
                  className="px-2"
                  style={{
                    height: "80vh",
                    overflow: "auto",
                  }}
                >
                  <Cartitem />
                </div>
              </Col>
              <Col xs="1"></Col>
              <Col xs="4">
                <Cartpricedetails />
              </Col>
            </Row>
            {/* recently Order item */}
            {accessToken && (
              <SuggetionPrdct
                data={recentOrderItemList}
                title="Recently Purchased Items"
                bgcolor="body-color"
                class="recently-order-item"
              />
            )}
            <Other />
          </>
        )}

        {/* Similar Products */}
        {/* <Topprdctsold
          data={Topprdctsoldjson}
          heading="Similar Products"
          viewall="#"
          bgcolor="body-color"
        />
        <div className="more-prdct-by-brand">
          <button>More Products by ProteinX</button>
          <button>More Nutrional Drinks</button>
        </div> */}
      </Container>
      <Footer />
    </>
  );
};

export default CartDesktop;
