import CartNavbarMob from "../../../../Header/CartNavbarMob/CartNavbarMob";
import Order from "../../MyPrescription/Order/Order";


const MobPresOrder = () => {
    return (
        <>
            <CartNavbarMob/>
            <div className="mt-3"></div>
            <Order/>
        </>
    )
}
export default MobPresOrder;