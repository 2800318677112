import "./Prdctcarousel.css";
import $ from "jquery";
import ReactImageMagnify from "react-image-magnify";
import { useEffect, useState } from "react";
import { apiUrl } from "../../../../redux/action/action";
import { useLocation } from "react-router-dom";

const Prdctcarousel = ({ data, compare }) => {
  const location = useLocation();
  const [imgs, setImgs] = useState(data?.product_images);
  const [bigImg, setBigImg] = useState(data?.product_images[0]);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [onClickArrowCount, setOnClickArrowCount] = useState(0);

  useEffect(() => {
    setImgs(data?.product_images);
    setBigImg(data?.product_images[0]);
  }, [data?.product_images]);

  useEffect(() => {
    $(".small-img-option-scroll").scroll(function () {
      var leftwidth = $(this).scrollLeft();
      setScrollWidth(leftwidth);
    });
  });

  const onClickChangeImg = (e) => {
    const index = Number(e.target.getAttribute("index"));
    setBigImg(imgs[index]);
    setOnClickArrowCount(index);
  };

  // onclick left and right arrow change img
  useEffect(() => {
    setBigImg(imgs[onClickArrowCount]);
  }, [onClickArrowCount]);

  const onClickLeft = () => {
    setBigImg(imgs[onClickArrowCount]);
    if (onClickArrowCount > 0) {
      setOnClickArrowCount(onClickArrowCount - 1);
      $(".small-img-option-scroll").animate(
        {
          scrollLeft: scrollWidth - 100,
        },
        400
      );
    } else {
      setOnClickArrowCount(imgs.length - 1);
      $(".small-img-option-scroll").animate(
        {
          scrollLeft: imgs.length * 70,
        },
        200
      );
    }
  };
  const onClickRight = () => {
    setBigImg(imgs[onClickArrowCount]);
    if (onClickArrowCount === imgs.length - 1) {
      setOnClickArrowCount(0);
      $(".small-img-option-scroll").animate(
        {
          scrollLeft: 0,
        },
        200
      );
    } else {
      setOnClickArrowCount(onClickArrowCount + 1);
      $(".small-img-option-scroll").animate(
        {
          scrollLeft: scrollWidth + 55,
        },
        400
      );
    }
  };

  return (
    <>
      <div
        className="w-100 d-flex flex-column"
        style={{
          height: "auto",
          position: "relative",
          zIndex: "+1",
        }}
      >
        <div className="large-img border d-flex align-items-center justify-content-center pb-1">
          {compare ? (
            <img src={apiUrl + bigImg} />
          ) : (
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "Product images",
                  isFluidWidth: true,
                  src: apiUrl + bigImg,
                  // width: 100,
                  // height: 200,
                  enlargedImagePosition: "beside",
                },
                largeImage: {
                  src: apiUrl + bigImg,
                  width: 800,
                  height: 900,
                },
                enlargedImagePosition: "beside",
              }}
            />
          )}
        </div>
        {location.pathname === "/compare" && window.innerWidth < 768 ? null : (
          <div className="small-img-option">
            <div id="small-img-option-scrollControl-left" onClick={onClickLeft}>
              <i className="fa fa-angle-left"></i>
            </div>
            <div className="small-img-option-scroll">
              {data?.product_images.map((data, index) => {
                return (
                  <img
                    src={apiUrl + data}
                    alt="p1"
                    key={index}
                    index={index}
                    onClick={onClickChangeImg}
                    className={
                      onClickArrowCount === index ? "active-small-img" : null
                    }
                  />
                );
              })}
            </div>
            <div
              id="small-img-option-scrollControl-right"
              onClick={onClickRight}
            >
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Prdctcarousel;
