import { useEffect, useState } from "react";
import { apiUrl } from "../../../../redux/action/action";
import axios from "axios";
import "./MyOrders.css";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import OrderItemCardUp from "./OrderItemCardUp/OrderItemCardUp";
import { getUserToken } from "../../../app/userToken";

const MyOrders = () => {
  const accessToken = getUserToken();
  const location = useLocation();
  const [alldata, setalldata] = useState([]);
  // const [myOrderFilterOption, setMyOrderFilterOption] = useState(false);
  const [pagetitle, setPagetitle] = useState("Active Orders");
  const [loader, setLoader] = useState(true);

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);
  const [TotalNumPages, setTotalNumPages] = useState(1);

  useEffect(() => {
    setTotalNumPages(Math.ceil(totalItems / totalItemToBeFetched));
  }, [totalItems, totalItemToBeFetched]);

  // useEffect(() => {
  //   $("html").click((e) => {
  //     if (e.target.id !== "myorderfilterbtn") {
  //       setMyOrderFilterOption(false);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    setalldata([]);
    window.scroll(0, 0);
    setLoader(true);
    if (location.pathname === "/account/my-order") {
      setPagetitle("Active Orders");
      fetacOrderDetail("active_orders");
    } else if (location.pathname === "/account/order-history") {
      setPagetitle("Order History");
      fetacOrderDetail("order_history");
    }
  }, [location.pathname, pageNo, totalItemToBeFetched]);

  const fetacOrderDetail = (apiRoute) => {
    axios
      .get(
        apiUrl +
        "/order/" +
        apiRoute +
        "?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        },
        []
      )
      .then((res) => {
        setTotalItems(res.data.total_count);
        setalldata(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
        } else {
          toast.error(message);
        }
      });
  };

  const setUpdateResponse = () => {
    setalldata([]);
    setLoader(true);
    if (location.pathname === "/account/my-order") {
      setPagetitle("Active Orders");
      fetacOrderDetail("active_orders");
    } else if (location.pathname === "/account/order-history") {
      setPagetitle("Order History");
      fetacOrderDetail("order_history");
    }
  };

  return (
    <>
      <div className="my-account">
        <div className="my-account-title mb-3 d-flex">
          <div className="w-30">
            <h4 className="p-0 m-0">
              {pagetitle} ({totalItems})
            </h4>
          </div>
          <div className="w-70 d-flex justify-content-end align-items-center">
            {/* <div className="order-search mr-3">
              <input
                type="search"
                name="order"
                placeholder="Search your Orders"
                id="order-search"
                className="border-right"
              />
              <label htmlFor="order-search">
                <i className="fa fa-search text-grey"></i>
              </label>
            </div>
            <div className="order-filter">
              <button
                id="myorderfilterbtn"
                onClick={() => setMyOrderFilterOption(true)}
              >
                <img
                  src={FilterIconBlack}
                  alt="FilterIconBlack"
                  id="myorderfilterbtn"
                  onClick={() => setMyOrderFilterOption(true)}
                />
                &nbsp;&nbsp; Filter
              </button>
              {myOrderFilterOption ? (
                <div className="my-order-filter-option animate__animated animate__fadeIn animate__faster">
                  <span>Delivered</span>
                  <span>Cancelled</span>
                  <span>Pending</span>
                  <span>Refunded</span>
                  <span>Last 30 Days</span>
                  <span>Last 2 Year</span>
                  <span>Last 3 Year</span>
                </div>
              ) : null}
            </div> */}
          </div>
        </div>

        {/* 1. Prescription sent for approval
          2. Prescription approved
          3. Order processing
          4. Order delivered */}
        {loader ? (
          <SkeletonTheme color="#ccc" highlightColor="#fff">
            <Skeleton count={2} height={300} />
          </SkeletonTheme>
        ) : alldata.length > 0 ? (
          <>
            {alldata?.map((e, i) => {
              return (
                <OrderItemCardUp
                  status={
                    e.status === "Order Delivered"
                      ? 4
                      : e.status === "Prescription sent for approval"
                        ? 1
                        : 3
                  }
                  key={i}
                  data={e}
                  setUpdateResponse={setUpdateResponse}
                />
              );
            })}
          </>
        ) : (
          <p className="text-center mt-4">No Active Order</p>
        )}

        {totalItems > 0 ? (
          <div className="mt-4 order-pagination">
            <select
              style={{ width: "70px", height: "30px" }}
              value={totalItemToBeFetched}
              onChange={(e) => {
                setTotalItemToBeFetched(e.target.value);
                setPageNo(1);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>

            <Pagination
              {...bootstrap5PaginationPreset}
              current={pageNo}
              total={TotalNumPages}
              maxWidth={550}
              onPageChange={(e) => setPageNo(e)}
              activeItemClassName="active"
            />
          </div>
        ) : null}

        {/* <OrderItemCardUp status={1} />
        <OrderItemCardUp status={2} />
        <OrderItemCardUp status={3} />
        <OrderItemCardUp status={4} /> */}

        {/* <div className="my-order-items mt-4">
          {alldata === "" ? (
            
          ) : (
            <>
              {alldata.map((data, index) => {
                return <Orderitemcard sts="shipped" data={data} key={index} />;
              })}
            </>
          )}
        </div> */}
      </div>
    </>
  );
};

export default MyOrders;
