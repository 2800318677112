/* eslint-disable no-unused-vars */
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "../../Header/Navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  addToCart,
  apiUrl,
  displayTopBarCart,
} from "../../../redux/action/action";
import "./WishlistDisplay.css";
import { Link, useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DISPLAY_CART_POPUP } from "../../../redux/state/state";
import { toast } from "react-toastify";
import { getUserToken, removeUserToken } from "../../app/userToken";

const WishlistDisplay = () => {
  const accessToken = getUserToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const [wishlists, setWishlists] = useState([]);
  const [activebtn, setActivebtn] = useState(null);
  const [wishlistprdcts, setwishlistprdcts] = useState(null);
  const [showEditWishlis, setShowEditWishlis] = useState(false);
  const [showCreateWishlis, setShowCreateWishlis] = useState(false);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const reduxselectItemUnit = useSelector(
    (state) => state.selectItemUnitReducer
  );
  const [createWishlistName, setCreateWishlistName] = useState();
  const [Listname, setListName] = useState();
  // const [selectPrdctId,setSelectPrdctId] = useState('');

  const [size, setSize] = useState("pcs");
  const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);
  const [showEditPop, setShowEditPop] = useState(false);

  useEffect(() => {
    if (!accessToken) {
      localStorage.setItem("redirect_url", location.pathname);
      navigate("/auth/login");
    } else {
      fetchwishlist();
    }
    // clear all filter
    dispatch({
      type: "CLEAR_FILTER_BY_CATEGORY",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_BRAND",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_PRODUCT_FORM",
    });
  }, []);

  useEffect(() => {
    if (wishlists?.length !== 0) {
      if (localStorage.getItem("wishlistactivebtn")) {
        setActivebtn(Number(localStorage.getItem("wishlistactivebtn")));
        wishlists?.forEach((data) => {
          if (data?.id === Number(localStorage.getItem("wishlistactivebtn"))) {
            setwishlistprdcts(data?.product_list);
            setListName(data?.name);
          }
        });
      } else {
        setActivebtn(wishlists[0]?.id);
        setListName(wishlists[0]?.name);
        setwishlistprdcts(wishlists[0]?.product_list);
      }
    }
  }, [wishlists]);

  const fetchwishlist = async () => {
    try {
      const result = await axios.get(`${apiUrl}/wishlist/get_all_wishlist`, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      });
      if (result.data?.status === 1) {
        setWishlists(result.data?.data);
        setLoader(false);
      }
    }
    catch (err) {
      const { response } = err;
      const { data } = response;
      const { status, message } = data;
      if (status === "400") {
        toast.warning(message);
        removeUserToken()
      } else {
        toast.error(message);
      }
    }
  };

  const onClickWishlist = (e) => {
    const id = e.target.getAttribute("listid");
    const sublist = e.target.getAttribute("sublist");
    const listname = e.target.getAttribute("listname");
    setListName(listname);
    setActivebtn(Number(id));
    // get all products on this active list
    setwishlistprdcts(JSON.parse(atob(sublist)));
    //remove data from localStorage
    localStorage.removeItem("wishlistactivebtn");
  };

  // delete wishlist item
  const onCLickDelete = (e) => {
    const itemid = e.target.getAttribute("itemID");
    deleteItems(itemid);
  };

  const [cartbtnstate, setcartbtnstate] = useState(false);

  const add_to_cart = async (e) => {
    const id = e.target.getAttribute("itemID");
    const itemUnit = e.target.getAttribute("itemUnit");
    const data = {
      product_id: Number(id),
      quantity: 1,
      unit: itemUnit,
    };
    if (accessToken) {
      await setcartbtnstate(true);
      await dispatch(addToCart(data));
      await dispatch(displayTopBarCart());
      setcartbtnstate(false);
      // await deleteItems(prdctid);
    } else {
      localStorage.setItem("cart_data", JSON.stringify(data));
      localStorage.setItem("redirect_url", "/cart");
      navigate("/auth/login");
    }
  };

  const deleteItems = async (itemid) => {
    try {
      const result = await axios.post(
        apiUrl + "/wishlist/delete_wishlist_products",
        {
          product_list: itemid,
          wishlist_id: activebtn.toString(),
        },
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      );
      if (result.data?.status) {
        localStorage.setItem("wishlistactivebtn", activebtn);
        fetchwishlist();
      }
    }
    catch (err) {
      const { response } = err;
      const { data } = response;
      const { status, message } = data;
      if (status === "400") {
        toast.warning(message);
        removeUserToken()
      } else {
        toast.error(message);
      }
    }
  };

  const deleteSuccess = (e) => {
    if (e) {
      setShowEditWishlis(false);
      fetchwishlist();
    }
  };

  const AddAllToCart = async () => {
    try {
      const res = await axios.get(
        apiUrl + "/wishlist/add_complete_wishlist_to_cart/" + activebtn,
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      );

      if (res.data?.status) {
        await dispatch(displayTopBarCart());
        await dispatch({
          type: DISPLAY_CART_POPUP,
          payload: true,
        });
        await setTimeout(() => {
          dispatch({
            type: DISPLAY_CART_POPUP,
            payload: false,
          });
        }, 4000);
      }
    }
    catch (err) {
      const { response } = err;
      const { data } = response;
      const { status, message } = data;
      if (status === "400") {
        toast.warning(message);
        removeUserToken()
      } else {
        toast.error(message);
      }
    }
  };

  const handleClearAll = () => {
    axios
      .get(apiUrl + "/wishlist/clear_all/" + activebtn, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        setShowDelectConfirmation(false);
        fetchwishlist();
        toast.success(res.data.message);
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }

      });
  };

  const createWishlist = () => {
    if (createWishlistName) {
      axios
        .post(
          apiUrl + "/wishlist/add_wishlist",
          {
            name: createWishlistName,
          },
          {
            headers: {
              Authorization: "Token " + accessToken,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          fetchwishlist();
          setShowCreateWishlis(false);
          setCreateWishlistName(null);
        })
        .catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }

        });
    } else {
      toast.warning("Enter a name to create a wishlist");
    }
  };

  const handleChangeUnit = (e) => {
    const val = e.target.value;
    const wishlistId = e.target.getAttribute("wishlistId");
    const productId = e.target.getAttribute("productId");
    setLoader(true);
    axios
      .get(
        apiUrl +
        "/wishlist/update_wishlist_prod_unit/" +
        wishlistId +
        "?product_id=" +
        productId +
        "&unit=" +
        val,
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      )
      .then((res) => {
        setLoader(false);
        localStorage.setItem("wishlistactivebtn", activebtn);
        fetchwishlist();
      }).catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }

      });
  };

  const [listIdRename, setListIdRename] = useState();
  const [listNameRename, setListNameRename] = useState("");

  return (
    <>
      <Navbar />
      <Container className="display-wishlist-main-contaier">
        <Row>
          <Col xs="3" className="border-left border-right p-0">
            <div className="border-bottom">
              <h4 className="p-0 m-0 text-center my-5">My Wishlists</h4>
            </div>
            {loader ? (
              <SkeletonTheme color="#ccc" highlightColor="#fff" width={"100%"}>
                <Skeleton count={4} height={70} className="mr-4" />
              </SkeletonTheme>
            ) : (
              <div className="wishlist-options animate__animated animate__fadeIn">
                {wishlists?.map((data, index) => {
                  return (
                    <div
                      className="d-flex align-items-center justify-content-between pr-4"
                      key={index}
                    >
                      <button
                        className={
                          data?.id === activebtn
                            ? "active-wishlist-btn"
                            : "inactive-wishlist-btn"
                        }
                        sublist={btoa(JSON.stringify(data?.product_list))}
                        listid={data?.id}
                        listname={data?.name}
                        onClick={onClickWishlist}
                      >
                        {data?.name} ({data?.product_list?.length})
                      </button>
                      <i
                        className="fa fa-pencil"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowEditPop(true);
                          setListIdRename(data?.id);
                          setListNameRename(data?.name);
                        }}
                      ></i>
                    </div>
                  );
                })}
              </div>
            )}

            {showEditPop ? (
              <div className="insurance-confirm py-4">
                <i
                  className="fa fa-close"
                  onClick={() => setShowEditPop(false)}
                ></i>
                <h5 className="mt-3">Edit Wishlist Name</h5>
                <div className="mt-4 mb-2">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={listNameRename}
                    onChange={(e) => setListNameRename(e.target.value)}
                  />
                </div>
                <div className="mt-2">
                  <button
                    className="btn border-theme text-theme mr-2"
                    onClick={() => setShowEditPop(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn bg-theme text-light"
                    onClick={() => {
                      axios
                        .put(
                          apiUrl + "/wishlist/update_wishlist/" + listIdRename,
                          {
                            name: listNameRename,
                          },
                          {
                            headers: {
                              Authorization: "Token " + accessToken,
                            },
                          }
                        )
                        .then((res) => {
                          setShowEditPop(false);
                          const { data } = res;
                          const { message, status } = data;
                          if (status === 200) {
                            fetchwishlist();
                            toast.success(message);
                          } else {
                            toast.warning(message);
                          }
                        })
                        .catch((err) => {
                          const { response } = err;
                          const { data } = response;
                          const { status, message } = data;
                          if (status === "400") {
                            toast.warning(message);
                            removeUserToken()
                          } else {
                            if (data.data.name) {
                              toast.error(data.data.name[0]);
                            }
                            else {
                              toast.error(message);
                            }
                          }

                        });
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}
          </Col>
          <Col xs="9" className="body-color">
            {/* {JSON.stringify(wishlistprdcts)} */}
            <div className="w-100 wishlist-header body-white mb-3">
              <div>
                <h4>{Listname}</h4>
              </div>
              <div>
                <button
                  className="btn border-theme text-theme"
                  onClick={() => setShowCreateWishlis(true)}
                >
                  <i className="fa fa-plus-circle"></i>
                  &nbsp; Create Wishlist
                </button>
              </div>
            </div>
            <div className="w-100 wishlist-action-btn py-3 d-flex justify-content-end">
              <div>
                <button
                  className="btn border-theme text-theme"
                  onClick={() => setShowEditWishlis(true)}
                >
                  <i className="fa fa-trash"></i>
                  &nbsp; Remove Wishlist
                </button>
                &nbsp;
                {wishlistprdcts?.length !== 0 ? (
                  <>
                    <button
                      className="btn border-theme text-theme"
                      onClick={AddAllToCart}
                    >
                      <i className="fa fa-shopping-bag"></i>
                      &nbsp; Add All to Cart
                    </button>
                    &nbsp;
                    <button
                      className="btn border-theme text-theme"
                      onClick={() => setShowDelectConfirmation(true)}
                    >
                      Clear All
                    </button>
                  </>
                ) : null}
              </div>
            </div>

            {showDelectConfirmation ? (
              <div className="insurance-confirm py-4">
                <i
                  className="fa fa-close"
                  onClick={() => setShowDelectConfirmation(false)}
                ></i>
                <h5 className="mt-3">Are you sure ?</h5>
                <div className="mt-2">
                  <button
                    className="btn border-theme text-theme mr-2"
                    onClick={() => setShowDelectConfirmation(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn bg-theme text-light"
                    onClick={handleClearAll}
                  >
                    Yes
                  </button>
                </div>
              </div>
            ) : null}

            {loader ? (
              <SkeletonTheme
                color="#ccc"
                highlightColor="#fff"
                inline={true}
                width={"30%"}
              >
                <Skeleton count={6} height={300} className="mr-4" />
              </SkeletonTheme>
            ) : (
              <>
                {wishlistprdcts?.length !== 0 ? (
                  wishlistprdcts?.map((data, index) => {
                    return (
                      <div
                        className="wishlist-i body-white animate__animated animate__fadeIn"
                        key={index}
                      >
                        <i
                          className="fa fa-trash"
                          itemID={data?.id}
                          onClick={onCLickDelete}
                        ></i>
                        {data?.product_data?.avg_rating > 0 ? (
                          <div className="rating text-dark">
                            <i
                              className="fa fa-star text-theme"
                              aria-hidden="true"
                            ></i>{" "}
                            {data?.product_data?.avg_rating}
                          </div>
                        ) : null}
                        <Link to={"/product-details/" + data?.product_data?.id}>
                          <div className="wishlist-i-img">
                            <img
                              src={
                                apiUrl + data?.product_data?.product_images[0]
                              }
                              alt={"prdct_img"}
                            />
                          </div>
                        </Link>
                        <div className="wishlist-i-txt px-3">
                          <p className="truncate-wish">
                            {data?.product_data?.itemName}
                          </p>
                          <div className="w-100 d-flex align-items-center justify-content-between">
                            {/* <span className="text-theme">
                              QAR {data?.product_data?.pricePerPcs}
                            </span> */}
                            {/* {console.log(data?.unit)} */}
                            {data?.unit === "pcs" ? (
                              data?.product_data?.discounted_price_pcs === 0 ? (
                                <span className="text-theme">
                                  QAR {data?.product_data?.pricePerPcs}
                                </span>
                              ) : (
                                <div>
                                  <span className="text-theme">
                                    QAR{" "}
                                    {data?.product_data?.discounted_price_pcs}
                                  </span>
                                  <span
                                    style={{
                                      position: "relative",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    {data?.product_data?.pricePerPcs}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "11px",
                                        left: 0,
                                        background: "#F5832B",
                                        width: "100%",
                                        height: "2px",
                                      }}
                                    />
                                  </span>
                                </div>
                              )
                            ) : data?.product_data?.discounted_price_pack === 0 ? (
                              <span className="text-theme">
                                QAR {data?.product_data?.pricePerPack}
                              </span>
                            ) : (
                              <div>
                                <span className="text-theme">
                                  QAR{" "}
                                  {data?.product_data?.discounted_price_pack}
                                </span>
                                <span
                                  style={{
                                    position: "relative",
                                    marginLeft: "6px",
                                  }}
                                >
                                  {data?.product_data?.pricePerPack}
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "11px",
                                      left: 0,
                                      background: "#F5832B",
                                      width: "100%",
                                      height: "2px",
                                    }}
                                  />
                                </span>
                              </div>
                            )}

                            {data?.product_data?.pcsUnit?.toLowerCase() ===
                              data?.product_data?.packUnit.toLowerCase() ? (
                              data?.unit !== "pcs" ? (
                                data?.product_data?.pcsUnit
                              ) : (
                                data?.product_data?.packUnit
                              )
                            ) : (
                              <select
                                className="ml-2"
                                style={{ width: "fit-content" }}
                                value={
                                  data?.unit === "pcs"
                                    ? data?.product_data?.pcsUnit
                                    : data?.product_data?.packUnit
                                }
                                wishlistId={data?.wishlist_id}
                                productId={data?.product_id}
                                onChange={handleChangeUnit}
                              >
                                <option>
                                  {data?.unit === "pcs"
                                    ? data?.product_data?.pcsUnit
                                    : data?.product_data?.packUnit}
                                </option>
                                <option>
                                  {data?.unit !== "pcs"
                                    ? data?.product_data?.pcsUnit
                                    : data?.product_data?.packUnit}
                                </option>
                              </select>
                            )}

                            <button className="btn" disabled={cartbtnstate}>
                              {data.prescription_required ? (
                                <i
                                  className="fa fa-upload text-theme"
                                  title="Upoad prescription"
                                  onClick={() => navigate("/upload-prescription")}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-cart-plus text-theme"
                                  itemID={data?.product_id}
                                  prdctid={data?.id}
                                  itemUnit={data?.unit}
                                  onClick={add_to_cart}
                                ></i>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="w-100 text-center body-white animate__animated animate__fadeIn py-5">
                      <i
                        className="fa fa-heart text-theme"
                        style={{ fontSize: "60px" }}
                      ></i>
                      <h5>No Items Found</h5>
                    </div>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>

      {/* edit wishlist popup */}
      {showEditWishlis ? (
        <div className="edit-wishlist p-5 animate__animated animate__fadeIn animate__faster">
          <i
            className="fa fa-close"
            onClick={() => setShowEditWishlis(false)}
          ></i>
          <h5 className="text-center mb-5">Remove Wishlist</h5>
          {wishlists?.map((data, index) => {
            return (
              <RemoveWishlistDisplay
                key={index}
                data={data}
                deleteSuccess={deleteSuccess}
              />
            );
          })}
        </div>
      ) : null}
      {/* create wishlist */}
      {showCreateWishlis ? (
        <div className="edit-wishlist p-5 animate__animated animate__fadeIn animate__faster">
          <i
            className="fa fa-close"
            onClick={() => setShowCreateWishlis(false)}
          ></i>
          <h5 className="text-center mb-5">Create Wishlist</h5>

          <input
            type="text"
            className="form-control"
            placeholder="Enter wishlist name"
            value={createWishlistName}
            onChange={(e) => setCreateWishlistName(e.target.value)}
          />
          <button
            className="btn bg-theme text-light float-right mt-3"
            onClick={createWishlist}
          >
            Create
          </button>
        </div>
      ) : null}
    </>
  );
};

const RemoveWishlistDisplay = ({ data, deleteSuccess }) => {
  const accessToken = getUserToken();
  const [removebtnstate, setRemovebtnstate] = useState("Remove");
  const removewishlist = async (e) => {
    setRemovebtnstate("Removing...");
    try {
      const res = await axios.delete(
        apiUrl + "/wishlist/delete_wishlist/" + e.target.getAttribute("listid"),
        {
          headers: {
            Authorization: "Token " + accessToken,
          },
        }
      );

      if (res.data?.status === 1) {
        setRemovebtnstate("Removed");
        setTimeout(() => {
          setRemovebtnstate("Remove");
          deleteSuccess(true);
        }, 1000);
      }
    }
    catch (err) {
      const { response } = err;
      const { data } = response;
      const { status, message } = data;
      if (status === "400") {
        toast.warning(message);
        removeUserToken()
      } else {
        toast.error(message);
      }

    };
  };

  return (
    <div className="wishlist-list">
      <div className="d-flex">
        <div className="icon">
          <p className="text-uppercase">
            {data?.name === "My Wishlist" ? (
              <i className="fa fa-heart-o"></i>
            ) : (
              data?.name.charAt(0)
            )}
          </p>
        </div>
        <div className="title-details">
          <h5>{data?.name}</h5>
          <p className="text-grey">{data?.product_list.length} items</p>
        </div>
      </div>
      <div className="px-2">
        {!data?.is_default ? (
          <button
            className="btn border-dark"
            listid={data?.id}
            onClick={removewishlist}
          >
            {removebtnstate}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default WishlistDisplay;
