import { Row, Col } from "react-bootstrap";
import $ from "jquery";
import "./Prdctsec.css";
import Filteredoption from "./Filter/Filteredoption";
import Prdcts from "./Prdcts/Prdcts";
import Filters from "./Filter/Filters";
import { useParams } from "react-router-dom";

const Prdctsec = ({
  setTotalItems,
  pageNo,
  totalItemToBeFetched,
  totalItems,
}) => {
  const { key } = useParams();
  setInterval(() => {
    if ($(".filtered-items").html() === "") {
      $(".clear-all-filter").addClass("d-none");
    } else {
      $(".clear-all-filter").removeClass("d-none");
    }
  }, 10);

  return (
    <>
      <Row className="body-color">
        <Col xs={3} className="p-0 m-0 pt-2 px-5">
          <h4 className="p-0 m-0 float-left" style={{ fontWeight: "600" }}>
            Products
          </h4>
          <h4
            className="text-grey float-left ml-2"
            style={{ fontWeight: "400" }}
          >
            ({totalItems})
          </h4>
        </Col>
        <Col xs={9} className="d-flex justify-content-start align-items-center pr-4">
          {key && (
            <h6>
              Showing total {totalItems} result{totalItems > 1 && 's'} for "{key?.replace('|', '/')}"
            </h6>
          )}
        </Col>
      </Row>

      {/* filter and short by sec */}
      <Filteredoption />

      <Row className="body-color mt-4 p-0">
        <Col xs="3" className="p-0 filter-checkbox border-line-top">
          <Filters />
        </Col>

        {/* display product */}
        <Prdcts
          setTotalItems={setTotalItems}
          pageNo={pageNo}
          totalItemToBeFetched={totalItemToBeFetched}
        />
      </Row>
      <Row className="body-color">
        <Col xs="3"></Col>
        <Col xs="9" className="p-0 m-0">
          {/* ( */}
          {/* {totalItems.map((e, i) => {
            return i;
          })} */}
        </Col>
      </Row>
    </>
  );
};

export default Prdctsec;
