import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./Products.css";

// import components
import Navbar from "../../Header/Navbar/Navbar";
import Footer from "../../Footer/Footer";
import Prdctsec from "./PrdctsectionDesktop/Prdctsec";
import Prdctsecmob from "./PrdctsectionMob/Prdctsecmob";
import { useEffect, useState } from "react";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import Bottommenumob from "../../Footer/Bottommenumob/Bottommenumob";

const Categories = () => {
  const [state, setState] = useState({});

  const [totalItems, setTotalItems] = useState();
  const [pageNo, setPageNo] = useState(1);
  const totalItemToBeFetched = 52;
  const [TotalNumPages, setTotalNumPages] = useState(1);

  useEffect(() => {
    window.scroll(0, 0);
  }, [pageNo]);

  useEffect(() => {
    setTotalNumPages(Math.ceil(totalItems / totalItemToBeFetched));
  }, [totalItems, totalItemToBeFetched]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      setState({});
    };
  }, []);


  return (
    <>
      {/* navbar */}
      <Navbar />
      <Navbarmob />
      {/* carousel */}
      {/* <Container fluid className="p-0 m-0">
        <Row className="body-color p-md-0 m-0 py-3 px-1 pb-0  animate__animated animate__fadeIn">
          <Mycarousel data={carouselData} />
        </Row>
      </Container> */}

      <div id="prdctsec">
        <Container fluid>
          <Row className="body-color mt-4">
            <Col xs="12" className="p-0 m-0 px-5">
              <p className="display-route">
                <Link to="/" className="text-decoration-none text-grey">
                  Home
                </Link>
                &nbsp;/&nbsp;products
              </p>
            </Col>
          </Row>
          <Prdctsec
            setTotalItems={setTotalItems}
            pageNo={pageNo}
            totalItemToBeFetched={totalItemToBeFetched}
            totalItems={totalItems}
          />
        </Container>
      </div>

      {/* prdct section for mobiles */}
      <Container>
        <Row className="body-color">
          <Col className="p-0 m-0">
            <Prdctsecmob totalItems={totalItems} />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="body-color">
          <Col md={4}></Col>
          <Col md={8}>
            {totalItems > 0 ? (
              <div className="prdct-pagination w-100 mt-4 px-3 mb-5 pb-5">
                <Pagination
                  {...bootstrap5PaginationPreset}
                  current={pageNo}
                  total={TotalNumPages}
                  maxWidth={270}
                  onPageChange={(e) => setPageNo(e)}
                  activeItemClassName="active"
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
      {/* footer link */}
      <Bottommenumob />
      <Footer />
    </>
  );
};

export default Categories;
