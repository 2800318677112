import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Carousel } from "react-responsive-carousel-custom";
import { apiUrl } from "../../../redux/action/action";
import "react-responsive-carousel-custom/lib/styles/carousel.min.css";

import "./Mycarousel.css";

const Mycarousel = ({ data }) => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (data) {
      setLoader(false);
    }
  }, [data]);
  return (
    <>
      <Row className="body-color m-0 p-0 mt-5 mt-md-0">
        <Col className="px-0 mycarousel" md={12}>
          {loader ? (
            <SkeletonTheme
              color="#ccc"
              highlightColor="#fff"
              width={"100%"}
              inline={true}
            >
              <Skeleton count={1} height={450} className="mr-3" />
            </SkeletonTheme>
          ) : (
            <Carousel dynamicHeight={true}>
              {data?.map((e, i) => {
                return (
                  e.is_active && (
                    <div>
                      <a href={e.url ? e.url : "#"}>
                        <img
                          className="d-block w-100"
                          src={apiUrl + e.image}
                          alt="carousel_image"
                          style={{ maxHeight: "450px" }}
                        />
                      </a>
                    </div>
                  )
                );
              })}
            </Carousel>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Mycarousel;
