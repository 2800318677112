import { Col, Container, Row } from "react-bootstrap";
import Navbar from "../../Header/Navbar/Navbar";
import order_success_icon from "../../../assets/box (1) 1.svg";
import "./Style.css";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import {
  DeliveryAddress,
  PaymentDetails,
} from "../Accounts/MyOrders/Orderdetails/Orderdetails";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiUrl } from "../../../redux/action/action";

const Order = () => {
  const navigate = useNavigate();
  const [orderdetails, setOrderdetails] = useState({});
  useEffect(() => {
    setOrderdetails(JSON.parse(localStorage.getItem("order_details_data")));
  }, []);
  const OnClickGoToMyOrder = () => {
    if (window.innerWidth < 768) {
      navigate("/profile/my-order");
    } else {
      navigate("/account/my-order");
    }
  };
  return (
    <>
      <Navbar />
      <Navbarmob />
      <Container fluid>
        <Row className="border-bottom">
          <Col className="order-success">
            <h3>Order Successfully Placed</h3>
            <div className="order-success-icon">
              <img src={order_success_icon} alt="order_success_icon" />
            </div>
            <div className="order-success-btn">
              <button
                className="btn border border-dark"
                onClick={OnClickGoToMyOrder}
              >
                Go to MyOrders
              </button>
              <Link to="/product/all">
                <button className="btn bg-btn-theme border-theme">
                  Keep Shopping
                </button>
              </Link>
            </div>
          </Col>
        </Row>

        <Row className="body-color mt-4">
          <Col md={8} className="mx-auto p-0">
            <div className="w-100">
              <h4 className="text-center">Order Details</h4>
            </div>

            {/* details */}
            <div className="w-100 border body-white order-success-details mt-3">
              {orderdetails?.cart_data?.map((data, index) => {
                return (
                  <div
                    className="w-100 px-md-5 px-4 pt-md-5 pt-4 pb-4 d-flex align-items-center"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/product-details/" + data?.product_id)
                    }
                  >
                    <div className="w-25 d-flex align-items-center justify-content-center">
                      <img
                        src={apiUrl + data?.image}
                        alt="img"
                        width={"100%"}
                      />
                    </div>
                    <div className="w-75 d-flex flex-column pl-2">
                      <h5 className="p-0 m-0">{data?.itemName}</h5>
                      {/* <h6 className="p-0 m-0 mt-2 text-theme">
                        QAR {data?.itemGrossAmount}
                      </h6> */}
                      <small className="p-0 m-0 text-grey d-flex">
                        {data?.itemOfferAmount === 0 ? (
                          <span className="text-theme">
                            QAR {data?.itemGrossAmount}
                          </span>
                        ) : (
                          <>
                            <span className="text-theme">
                              QAR {data?.itemAmountAfterOffer}
                            </span>
                            <span
                              style={{
                                position: "relative",
                                marginLeft: "6px",
                              }}
                            >
                              {data?.itemGrossAmount}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  left: 0,
                                  background: "#F5832B",
                                  width: "100%",
                                  height: "2px",
                                }}
                              />
                            </span>
                          </>
                        )}
                      </small>
                      <small>
                        Quantity : {data?.itemQuantity} {data?.itemUnitType}
                      </small>
                    </div>
                  </div>
                );
              })}

              <div className="w-100">
                <div className="px-md-5 px-4 py-4 border-top">
                  <DeliveryAddress data={orderdetails.address_data} />
                </div>
                <div className="px-md-5 px-4 py-4 border-top">
                  <PaymentDetails
                    amount={orderdetails?.amount?.value}
                    card_data={orderdetails?.details}
                    loyalty_points_amount_credited={
                      orderdetails?.loyalty_points_amount_credited
                    }
                    loyalty_points_amount_deducted={
                      orderdetails?.loyalty_points_amount_deducted
                    }
                    total_amount={orderdetails?.total_original_price}
                    discount_amount={orderdetails?.discount_amount}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Order;
