import { Col } from "react-bootstrap";
import "./Safetyad.css";

// import images
import drugs from "../../../../../assets/home/drugs 2.png";
import shield from "../../../../../assets/home/shield 1.png";
import mask from "../../../../../assets/home/mask 1.png";

const SafetyAd = () => {
  return (
    <>
      <Col className="safetyad">
        <div className="safety-img">
            <img src={drugs} alt="drugs" />
        </div>
        <div className="safety-text text-center">
          <p>Genuine Products</p>
        </div>
      </Col>
      <Col className="safetyad">
        <div className="safety-img">
            <img src={shield} alt="drugs" />
        </div>
        <div className="safety-text text-center">
          <p>Secure Payment</p>
        </div>
      </Col>
      <Col className="safetyad">
        <div className="safety-img">
            <img src={mask} alt="drugs" />
        </div>
        <div className="safety-text text-center">
          <p>Contactless Delivery</p>
        </div>
      </Col>
    </>
  );
};

export default SafetyAd;
