import {
  ADD_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_ADDRESS,
  FETCH_ALL_ADDRESS,
} from "../state/state";
import { apiUrl } from "./action";
import axios from "axios";
import { toast } from "react-toastify";
import { getUserToken } from "../../pages/app/userToken";
import { GetDeviceId } from "../../pages/app/GetDeviceId";
// const accessToken = getUserToken();

const addNewAddress = (data) => {
  return async (dispatch) => {
    if (getUserToken()) {
      try {
        const result = await axios.post(apiUrl + "/order/add_address", data, {
          headers: {
            Authorization: "Token " + getUserToken(),
          },
        });
        if (result.data.status === 200) {
          dispatch({
            type: ADD_ADDRESS,
            payload: false,
          });
          if (localStorage.getItem("redirect_url")) {
            window.location = localStorage.getItem("redirect_url");
            localStorage.removeItem("redirect_url");
          } else {
            window.location = "/setaddress";
          }
        } else {
          toast.warning(result.data.message);
        }
        await dispatch(fetchAllAddress());
      } catch (err) {
        toast.error(err.response.data.message);
      }
    } else {
      const deviceId = await GetDeviceId();
      delete data["is_default"];
      data["method"] = "creditcard";
      data["country_code"] = "+974";
      data["name"] = "Home";
      data["unique_id"] = deviceId;

      axios
        .post(apiUrl + "/user/guest_checkout", data)
        .then((res) => {
          const { _links } = res.data.data.data;
          window.location = _links.checkout.href;
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };
};

const fetchAllAddress = () => {
  return async (dispatch) => {
    if (getUserToken()) {
      try {
        const result = await axios.get(apiUrl + "/order/get_all_address", {
          headers: {
            Authorization: "Token " + getUserToken(),
          },
        });
        if (result.data.status === 200) {
          dispatch({
            type: FETCH_ALL_ADDRESS,
            payload: result.data.data,
          });
        }
      } catch (err) {}
    }
  };
};

const deleteAddress = (id) => {
  return async (dispatch) => {
    try {
      const result = await axios.delete(
        apiUrl + "/order/delete_address/" + id,
        {
          headers: {
            Authorization: "Token " + getUserToken(),
          },
        }
      );
      if (result.data.status === 200) {
        dispatch({
          type: DELETE_ADDRESS,
          payload: result.data.message,
        });
        toast.success(result.data.message);
      }
    } catch (err) {
      try {
        toast.error(err.response.data.message);
      } catch (e) {
        toast.error(err.toString());
      }
    }
  };
};

const updateAddress = (data) => {
  return async (dispatch) => {
    try {
      const result = await axios.put(
        apiUrl + "/order/update_address/" + data.id,
        data,
        {
          headers: {
            Authorization: "Token " + getUserToken(),
          },
        }
      );
      if (result.data.status === 200) {
        dispatch({
          type: UPDATE_ADDRESS,
          payload: false,
        });
        toast.success(result.data.message);
      }
    } catch (err) {
      const { response } = err;
      const { data } = response;
      const { status, message } = data;
      if (status === 400) {
        toast.warning(message);
      } else {
        toast.error(err.toString());
      }
    }
  };
};

export { addNewAddress, fetchAllAddress, deleteAddress, updateAddress };
