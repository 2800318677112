import PaymentDesktop from "./PaymentDesktop/PaymentDesktop";
import PaymentMob from "./PaymentMob/PaymentMob";
import "./Payment.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserToken } from "../../app/userToken";
import Bottommenumob from "../../Footer/Bottommenumob/Bottommenumob";

const Payment = () => {
  const accessToken = getUserToken();
  let navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
    if (!accessToken) {
      navigate("/auth/login");
    }
  }, []);

  return (
    <>
      <div className="PaymentforDesktop">
        <PaymentDesktop />
      </div>
      <div className="PaymentforMob mb-5 pb-2">
        <PaymentMob />
      </div>
      <div className="py-3 mt-4" />
      <Bottommenumob />
    </>
  );
};

export default Payment;
