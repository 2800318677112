import { useNavigate } from "react-router-dom";
import { removeUserToken } from "../../app/userToken";

const Logout = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="insurance-confirm py-4">
        <h5 className="mt-3">Are you sure ?</h5>
        <div className="mt-2">
          <button
            className="btn border-theme text-theme mr-2"
            onClick={() => navigate(-1)}
          >
            No
          </button>
          <button
            className="btn bg-theme text-light"
            onClick={() => {
              removeUserToken();
              window.location = "/";
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default Logout;
