import "./Cartitem.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartCard from "./Subcmp/CartCard";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import { getUserToken, removeUserToken } from "../../../../app/userToken";
import { apiUrl, displayCart } from "../../../../../redux/action/action";
import { GetDeviceId } from "../../../../app/GetDeviceId";
import { toast } from "react-toastify";
import { CART_COUNT } from "../../../../../redux/state/state";

const Cartitem = () => {
  const reduxresponse = useSelector((state) => state.displayCartReducer);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const accessToken = getUserToken();
  const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);

  useEffect(() => {
    setLoader(reduxresponse.loader);
  }, [reduxresponse.data]);

  const handleClearCart = async () => {
    if (accessToken) {
      axios
        .get(apiUrl + "/order/clear_cart", {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then(() => {
          setShowDelectConfirmation(false);
          dispatch(displayCart());
          dispatch({
            type: CART_COUNT,
            payload: { data: [] },
          });
        }).catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        });
    } else {
      const deviceId = await GetDeviceId();
      axios
        .get(apiUrl + "/order/clear_guest_cart?unique_id=" + deviceId)
        .then(() => {
          dispatch(displayCart());
        });
    }
  };

  return (
    <>
      <div className="w-100 cartitem">
        <div className="w-100 cartitem-title body-color">
          {reduxresponse.data.length === 0 ? null : (
            <h5>{reduxresponse?.data.length} {reduxresponse?.data?.length === 1 ? 'Item' : 'Items'}</h5>
          )}
          <h4
            className="remove-all-cart text-danger"
            onClick={() => setShowDelectConfirmation(true)}
          >
            Clear Cart
          </h4>
        </div>

        {/* display added item in cart */}
        {loader ? (
          <SkeletonTheme color="#ccc" highlightColor="#fff" width={"100%"}>
            <Skeleton count={3} height={170} className="mr-4" />
          </SkeletonTheme>
        ) : (
          <>
            {reduxresponse?.data.map((data, index) => {
              return <CartCard key={index} data={data} />;
            })}
          </>
        )}

        {showDelectConfirmation ? (
          <div className="insurance-confirm py-4">
            <i
              className="fa fa-close"
              onClick={() => setShowDelectConfirmation(false)}
            ></i>
            <h5 className="mt-3">Are you sure ?</h5>
            <div className="mt-2">
              <button
                className="btn border-theme text-theme mr-2"
                onClick={() => setShowDelectConfirmation(false)}
              >
                No
              </button>
              <button
                className="btn bg-theme text-light"
                onClick={handleClearCart}
              >
                Yes
              </button>
            </div>
          </div>
        ) : null}
        {/* <UploadPrescription carditems={reduxresponse.data} /> */}
      </div>
    </>
  );
};

export default Cartitem;
