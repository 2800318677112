import {
  ADD_TO_CART,
  DISPLAY_CART,
  COUNT_QUANTITY,
  DISPLAY_CART_POPUP,
  CART_COUNT,
} from "../state/state";

const cartReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        message: action.payload,
      };
    default:
      return state;
  }
};

const initialCart = {
  data: [],
  loader: true,
};
const displayCartReducer = (state = initialCart, action) => {
  switch (action.type) {
    case DISPLAY_CART:
      return {
        data: action.payload.data,
        total_cart_amount: action.payload.total_cart_amount,
        discount_amount: action.payload.discount_amount,
        loyalty_points: action.payload.loyalty_points,
        discount_amount: action.payload.discount_amount,
        mrp_amount: action.payload.mrp_amount,
        total_loyalty_points_deducted:
          action.payload.total_loyalty_points_dedcuted,
        total_loyalty_points_to_be_credited:
          action.payload.total_loyalty_points_to_be_credited,
        discount_coupon_code: action.payload.discount_coupon_code,
        loader: false,
      };
    default:
      return state;
  }
};

const topBarCartCounter = (state = "", action) => {
  switch (action.type) {
    case CART_COUNT:
      return {
        state: action.payload,
      };
    default:
      return state;
  }
};

const countQuantity = (state = { total: 1 }, action) => {
  switch (action.type) {
    case COUNT_QUANTITY:
      return {
        total: action.payload,
      };
    default:
      return state;
  }
};
const displayCartPopReducer = (state = { action: false }, action) => {
  switch (action.type) {
    case DISPLAY_CART_POPUP:
      return {
        action: action.payload,
      };
    default:
      return state;
  }
};

export {
  cartReducer,
  displayCartReducer,
  countQuantity,
  displayCartPopReducer,
  topBarCartCounter,
};
