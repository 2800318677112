import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import "./Bottommenumob.css";

const Bottommenumob = () => {
  const location = useLocation();

  const footermenu = [
    {
      lable: "HOME",
      icon: "fa-home",
      url: "/",
    },
    {
      lable: "ORDERS",
      icon: "fa-truck",
      url: "/profile/my-order",
    },
    {
      lable: "UPLOAD",
      icon: "fa-plus-circle",
      url: "/upload-prescription",
    },
    {
      lable: "NOTIFICATION",
      icon: "fa-bell",
      url: "/mob/notification",
    },
    {
      lable: "PROFILE",
      icon: "fa-user",
      url: "/profile",
    },
  ];

  return (
    <>
      <Container fluid className="fixed-bottom bottom-menu-mob">
        <Row className="px-3">
          {footermenu.map((e, i) => (
            <Col>
              <Link to={e.url}>
                <i
                  className={`fa ${e.icon} ${
                    e.url === location.pathname ? `text-theme` : null
                  }`}
                ></i>
              </Link>
              <p className={e.url === location.pathname ? `text-theme` : null}>
                {e.lable}
              </p>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};
export default Bottommenumob;
