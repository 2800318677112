import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  apiUrl,
  displayCart,
  displayTopBarCart,
} from "../../../../redux/action/action";
import { DISPLAY_WISHLIST } from "../../../../redux/state/state";
import { getUserToken, removeUserToken } from "../../../app/userToken";

const Wishlist = ({
  title,
  totalitem,
  iconcolor,
  prdctid,
  editable,
  listid,
  action = "add_wishlist",
}) => {
  const accessToken = getUserToken();
  const location = useLocation();
  const [listtitle, setListtitle] = useState(title);
  const dispatch = useDispatch();
  const [counttotalitem, settotalitem] = useState(totalitem);
  const [savebtnstate, setsavebtnstate] = useState("Add");
  const [savebtn, setSavebtn] = useState(false);
  const reduxselectItemUnit = useSelector(
    (state) => state.selectItemUnitReducer
  );
  const [editableH5, setEditableH5] = useState(editable);

  const savetowishlist = async () => {
    // debugger;
    setSavebtn(true);
    if (editable) {
      const data__ = {
        name: listtitle,
        product_list: prdctid.toString(),
        unit: reduxselectItemUnit.unittype,
      };
      axios
        .post(apiUrl + "/wishlist/" + action, data__, {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((result) => {
          if (result.data.status === 1) {
            dispatch(displayCart());
            toast.success(result.data.message);
            setTimeout(() => {
              dispatch({
                type: DISPLAY_WISHLIST,
                payload: false,
              });
            }, 2000);
          }
        })
        .catch((err) => {
          setSavebtn(false);
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        });
    } else {
      let data = {};
      if (action === "save_for_later") {
        data = {
          wishlist_id: listid,
          cart_id: Number(prdctid)
        };
      } else {
        data = {
          wishlist_id: listid,
          product_list: prdctid?.toString()
        };
      }

      data["unit"] = reduxselectItemUnit.unittype;
      axios
        .post(apiUrl + "/wishlist/" + action, data, {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((result) => {
          if (result.data.status === 1) {
            settotalitem(totalitem + 1);
            toast.success(result.data.message);
            setTimeout(() => {
              dispatch({
                type: DISPLAY_WISHLIST,
                payload: false,
              });
            }, 2000);
          }
          else {
            toast.warning(result.data.message);
          }
          // if (result.data.status === 200) {
          //   toast.success(result.data.message);
          //   dispatch(displayCart());
          // }
        })
        .catch((err) => {
          setSavebtn(false);

          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }

        });
    }
  };

  return (
    <>
      <div className="wishlist-list">
        <div className="d-flex align-items-center">
          <div
            className={`icon ${title === "My Wishlist" ? "my-wishlist-color" : iconcolor
              }`}
          >
            <p className="text-uppercase">
              {listtitle === "My Wishlist" ? (
                <i className="fa fa-heart-o"></i>
              ) : (
                title.charAt(0)
              )}
            </p>
          </div>
          <div className="title-details">
            {editableH5 ? (
              <input
                className="form-control"
                defaultValue={title}
                onChange={(e) => setListtitle(e.target.value)}
              />
            ) : (
              <h5>{listtitle}</h5>
            )}
            <p className="text-grey">{counttotalitem} items</p>
          </div>
        </div>
        <div className="px-2 d-flex">
          {/* <button
            className="btn bg-theme text-light animate__animated animate__fadeIn animate__fast"
            onClick={savetowishlist}
            disabled={savebtn}
          >
            <i className="fa fa-plus"></i>
          </button> */}
          {!editable ? (
            <i
              className={`fa ${editableH5
                ? editableH5 === `loader`
                  ? `fa-circle-o-notch fa-spin`
                  : `fa-save`
                : `fa-pencil`
                } cust-wishlist-btn-2`}
              onClick={() => {
                if (listtitle.length < 15) {
                  setEditableH5(true);
                  if (editableH5) {
                    setEditableH5("loader");
                    axios
                      .put(
                        apiUrl + "/wishlist/update_wishlist/" + listid,
                        {
                          name: listtitle,
                        },
                        {
                          headers: {
                            Authorization: "Token " + accessToken,
                          },
                        }
                      )
                      .then((res) => {
                        setEditableH5(false);
                        const { data } = res;
                        const { message, status } = data;
                        if (status === 200) {
                          toast.success(message);
                        } else {
                          toast.warning(message);
                        }
                      })
                      .catch((err) => {
                        const { response } = err;
                        const { data } = response;
                        const { status, message } = data;
                        if (status === "400") {
                          toast.warning(message);
                          removeUserToken()
                        } else {
                          toast.error(message);
                        }
                      });
                  }
                } else {
                  toast.warning("Please enter a name less than 15 character");
                }
              }}
            ></i>
          ) : null}
          &nbsp; &nbsp;
          <i
            className="fa fa-plus cust-wishlist-btn"
            onClick={savetowishlist}
          ></i>
        </div>
      </div>
    </>
  );
};

export default Wishlist;
