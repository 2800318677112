import { Col } from "react-bootstrap";

const Prdcthighlights = ({ data }) => {
  return (
    <Col md="12" className="p-0 mb-0">
      <h5 className="p-0 m-0">Information about {data?.itemName}</h5>
      {data?.longDescription ? (
        <>
          {/* <h5 className="mb-3">Product Highlights</h5>
          <small className="text-grey">
            <div dangerouslySetInnerHTML={{ __html: data?.shortDescription }} />
          </small> */}
          <h6 className="mb-3 mt-5">Product Description</h6>
          <small className="text-grey">
            <div dangerouslySetInnerHTML={{ __html: data?.longDescription }} />
          </small>
        </>
      ) : null}
    </Col>
  );
};

export default Prdcthighlights;
