import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CartNavbar.css";
import LogoDesktop from "../../../assets/img/logo-dark.png";
import userimg from "../../../assets/icons/user_circle.png";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { USER_DATA } from "../../../redux/state/state";
import axios from "axios";
import { apiUrl } from "../../../redux/action/action";
import { getUserToken, removeUserToken } from "../../app/userToken";
import { getUserDetails } from "../../app/userDetails";
import { toast } from "react-toastify";

const CartNavbar = ({ data }) => {
  const accessToken = getUserToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (accessToken) {
      axios
        .get(apiUrl + "/user/customerDetails", {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          dispatch({
            type: USER_DATA,
            payload: getUserDetails(res.data.encrypted),
          });
        }).catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            toast.error(message);
            window.location = "/";
            removeUserToken();
          } else {
            toast.error(message);
          }
        });
    }
  }, []);

  return (
    <>
      <Navbar
        expand="lg"
        id="desktop-nav"
        className="shadow-sm py-3 sticky-top"
        style={{ backgroundColor: "white" }}
      >
        <Container fluid>
          <Link className="navbar-brand" to="/">
            <img src={LogoDesktop} alt="logo" id="logoDesktop" />
          </Link>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="w-75 mx-auto">
              <div className="c-w-15">
                <Link to="/cart" className={data.cart}>
                  CART
                </Link>
              </div>
              <div className={data.firstLine}></div>
              <div className="c-w-15">
                <Link to="/setaddress" className={data.address}>
                  ADDRESS
                </Link>
              </div>
              <div className={data.secondLine}></div>
              <div className="c-w-15">
                <p className={data.payment}>PAYMENT</p>
              </div>
            </Nav>
          </Navbar.Collapse>
          {/* seaach input field */}
          {/* fa fa icons for account and cart */}
          {/* <Link className="navbar-brand" to='/cart'>
                        {hideCartIcon ? <img src={cartimg} alt="cartimg" className="menufa" /> : null}
                    </Link> */}
          <Link className="navbar-brand" to="/account/my-account">
            <img src={userimg} alt="userimg" className="menufa" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Container>
      </Navbar>
    </>
  );
};

export default CartNavbar;
