import Navbar from "../../Header/Navbar/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AddressCardView from "../SetAddress/SetAddressDesktop/AddressCardView/AddressCardView";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAddress,
  fetchAllAddress,
} from "../../../redux/action/address.action";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Geocode from "react-geocode";
import MapPicker from "react-google-map-picker";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { GoogleMapsAPI } from "../SetAddress/SetAddressDesktop/AddAddress/client-config";
import upload_insu from "../../../assets/upload_insu.png";
import icon2 from "../../../assets/icons/UploadPres/icon2.png";
import axios from "axios";
import { apiUrl } from "../../../redux/action/action";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import { getUserToken, removeUserToken } from "../../app/userToken";
import Bottommenumob from "../../Footer/Bottommenumob/Bottommenumob";
import UploadInsuranceCpm from "./UploadInsuranceCmp";
import ImageViewer from "../../app/components/ImageViewer";
Geocode.setApiKey(GoogleMapsAPI);

const PresCheckout = () => {
  const location = useLocation()
  const accessToken = getUserToken();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GoogleMapsAPI,
    libraries: ["places"],
  });
  const navigate = useNavigate();
  const reduxresponsefetchaddressReducer = useSelector(
    (state) => state.fetchaddressReducer
  );
  const reduxresponse = useSelector((state) => state.addressReducer);
  const reduxuserdata = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const [showAllowMap, setShowAllowMap] = useState(true);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [country, setContry] = useState("");
  const [showAddressPicker, setShowAddressPicker] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [AddAddressState, SetAddAddressState] = useState("Add");
  const [defaultLocation, setDefaultLocation] = useState({});
  const [address, setAddress] = useState("Loading address...");
  const [is_default, setis_default] = useState(false);
  const [userdata, setuserdata] = useState({
    name: "",
    phone_number: "",
  });
  const [uploadInsuranceShow, setUploadInsuranceShow] = useState(false);
  const [savebtnstateInsu, setSavebtnstateInsu] = useState("Save");
  const [insuranceList, setInsuranceList] = useState(null);
  const [subinsuranceList, setSubInsuranceList] = useState(null);
  const [PlaceholderTXT, setPlaceholderTXT] = useState(
    "Attach File JPEG, PNG or PDF format (Max File Size : 5mb)"
  );
  const [insurancefile, setInsurancefile] = useState(null);
  const [InsuranceType, setInsuranceType] = useState(null);
  const [SubInsurance, setSubInsurance] = useState(null);
  const [InsurancePremiumNo, setInsurancePremiumNo] = useState(null);
  const [InsuranceExpiryDate, setInsuranceExpiryDate] = useState(null);
  const [SaveInsuBtnState, setSaveInsuBtnState] = useState(false);
  const [savedInsuData, setSavedInsuData] = useState(null);
  const [savedPresData, setSavedPresData] = useState(null);
  const [callrequestmsgShow, setCallrequestmsgShow] = useState(false);
  const [locationval, setLocationval] = useState("");
  const [CallMeToConfirmMyOrderTxt, setCallMeToConfirmMyOrderTxt] = useState(
    () => (
      <>
        <i className="fa fa-volume-control-phone mr-1"></i> Call me to Confirm
        My Order
      </>
    )
  );
  const [radioId, setRadioId] = useState("");

  useEffect(() => {
    dispatch(fetchAllAddress());
  }, [reduxresponse.message]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setShowAllowMap(true);
        fetchAddress(position.coords.latitude, position.coords.longitude);
        setDefaultLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => {
        if (err.message === "User denied Geolocation") {
          setShowAllowMap(false);
        }
      },
      { enableHighAccuracy: true }
    );
  }, [showAllowMap]);

  useEffect(() => {
    setSavedPresData(JSON.parse(localStorage.getItem("saved_pres_data")));
    setSavedInsuData(JSON.parse(localStorage.getItem("saved_insu_data")));
    setuserdata({
      name: reduxuserdata?.userdata?.name,
      phone_number: reduxuserdata?.userdata?.phone_number,
    });
  }, [reduxuserdata?.userdata]);

  // fetch address from lat and lng
  const fetchAddress = (lat, lng) => {
    setDefaultLocation({
      lat: lat,
      lng: lng,
    });
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        let addressjson = {};
        const address_components = response.results[0].address_components;
        address_components.forEach((element) => {
          addressjson[element.types[0]] = element.long_name;
        });

        //mohalla - political
        //premise + neighbour hood (opt) - hosuse no
        debugger;

        if (addressjson?.premise && addressjson?.neighborhood) {
          setAddress1(
            (addressjson?.premise + " " + addressjson?.neighborhood).trim()
          );
        } else if (addressjson?.premise) {
          setAddress1(addressjson?.premise);
        } else if (addressjson?.neighborhood) {
          setAddress1(addressjson?.neighborhood);
        }

        setAddress2(addressjson?.political);
        setCity(addressjson?.locality);
        setState(addressjson?.administrative_area_level_1);
        setContry(addressjson?.country);
        setPinCode(addressjson?.postal_code);
        setAddress(response.results[0].formatted_address);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  function handleChangeLocation(lat, lng) {
    fetchAddress(lat, lng);
  }

  function handleChangeZoom(newZoom) {
    //   setZoom(newZoom);
  }
  const oncheckdefaultaddress = () => {
    let checkBox = document.getElementById("address-default");
    // Check if the element is selected/checked
    if (checkBox.checked) {
      setis_default(true);
    } else {
      setis_default(false);
    }
  };

  const OnSubmit_AddNewAddress = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    SetAddAddressState("Adding...");
    const data = {
      name: formData.get("name"),
      address1: formData.get("address1"),
      address2: formData.get("address2"),
      zip_code: formData.get("pincode"),
      city: formData.get("city"),
      state: formData.get("state"),
      country: formData.get("country"),
      phone_number: formData.get("phone"),
      is_default: is_default,
      customer_id: 1,
    };
    await dispatch(addNewAddress(data));
    await form.reset();
    await setShowAddressFields(false);
    await SetAddAddressState("Add");
  };

  const handleChangeInputInsurance = (e) => {
    setInsurancefile(e.target.files[0]);
    setPlaceholderTXT(e.target.files[0].name);
  };

  const handleSaveInsurance = () => {
    if (
      insurancefile &&
      InsuranceType &&
      SubInsurance &&
      InsurancePremiumNo &&
      InsuranceExpiryDate
    ) {
      setSaveInsuBtnState(true);
      setSavebtnstateInsu("Saving...");
      const formData = new FormData();
      formData.append("file", insurancefile);
      formData.append("insurance_id", InsuranceType);
      formData.append("sub_insurance_id", SubInsurance);
      formData.append("premium_no", InsurancePremiumNo);
      formData.append("expiry_date", InsuranceExpiryDate);
      axios
        .post(apiUrl + "/user/save_insurance", formData, {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          setSaveInsuBtnState(false);
          if (res.data.status === 400) {
            toast.error(res.data.message);
            setSavebtnstateInsu("Save");
          } else {
            toast.success(res.data.message);
            setInsurancefile("");
            setInsuranceType("");
            setSubInsurance("");
            setInsurancePremiumNo("");
            setInsuranceExpiryDate("");
            setPlaceholderTXT(
              "Attach File JPEG, PNG or PDF format (Max File Size : 3mb)"
            );
            setSavebtnstateInsu("Saved");
            localStorage.setItem(
              "saved_insu_data",
              JSON.stringify(res.data.data)
            );
            setSavedInsuData(res.data.data);
            setTimeout(() => {
              setSavebtnstateInsu("Save");
              setUploadInsuranceShow(false);
            }, 2000);
          }
        })
        .catch((err) => {
          setSaveInsuBtnState(false);
          setSavebtnstateInsu("Save");
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        });

    } else {
      toast.warning("All fields are required");
    }
  };

  const PlaceOrder = () => {
    setCallMeToConfirmMyOrderTxt("Wait a sec...");
    const selectedAddress_id = localStorage.getItem("selectedAddress_id");
    let data = {};
    if (selectedAddress_id) {
      data = {
        prescription_id: savedPresData?.id,
        insurance_id: savedInsuData?.id,
        address_id: selectedAddress_id,
        remark: savedPresData?.remark,
      };
      localStorage.removeItem("selectedAddress_id");
    } else {
      data = {
        prescription_id: savedPresData?.id,
        insurance_id: savedInsuData?.id,
        remark: savedPresData?.remark,
      };
    }
    axios
      .post(apiUrl + "/order/place_prescription_order", data, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 400) {
          toast.warning(res.data.data.remark[0]);
        } else {
          toast.success(res.data.message);
          setCallMeToConfirmMyOrderTxt("Done");
          setCallrequestmsgShow(true);
          localStorage.removeItem("saved_pres_data");
          localStorage.removeItem("saved_insu_data");
          setTimeout(() => {
            navigate(
              window.innerWidth > 768
                ? "/account/my-order"
                : "/profile/my-order"
            );
          }, 3000);
        }
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  const [previewPres, setPreviewPres] = useState(false);
  const [previewPresImg, setPreviewPresImg] = useState(null);

  return (
    <>
      <Navbar />
      <Navbarmob />

      <Container fluid className="mt-md-5 mt-3 px-md-5 px-3">
        <Row className="body-color upload-prescription">
          <Col md={6} className="px-md-4 px-3">
            <div className="select-address-title">
              <h5>
                {/* <i
                  className="fa fa-arrow-left"
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                ></i>{" "} */}
                Select Delivery Address
              </h5>
              <button onClick={() => {
                navigate("/address/add/checkout")
                localStorage.setItem("redirect_url", location.pathname);
              }}>
                Add New Address
              </button>
            </div>
            {reduxresponsefetchaddressReducer.data.length === 0 ? (
              <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-5">
                <img
                  src="/img/icons8-address.png"
                  alt="add-address"
                  width={"150px"}
                />
                <h4
                  className="p-0 m-0 text-theme mt-3"
                  style={{ fontSize: "18px" }}
                >
                  Add an address before checkout
                </h4>
              </div>
            ) : (
              <>
                {reduxresponsefetchaddressReducer.data.map((address, index) => {
                  return (
                    <AddressCardView
                      data={address}
                      radioId={radioId}
                      setRadioId={setRadioId}
                      key={index}
                    />
                  );
                })}
              </>
            )}
          </Col>

          <Col md={6} className="mt-md-0 mt-3 p-4">
            <div className="w-100">
              <p>Prescriptions uploaded by you:</p>
              <div
                className="preview-pres p-3 mt-2"
              >
                <i
                  className="fa fa-close mb-4"
                  onClick={() => {
                    localStorage.removeItem("saved_pres_data");
                    navigate(-1);
                  }}
                ></i>
                <h5 className="p-0 m-0">Prescription Details</h5>
                {savedPresData?.file.indexOf(".pdf") >= 0 ? (
                  <a
                    target="_blank"
                    href={apiUrl + savedPresData.file}
                    download={savedPresData.name}
                  >
                    <button>
                      Preview
                    </button>
                  </a>
                ) :
                  <button
                    onClick={() => {
                      setPreviewPresImg(savedPresData?.file)
                      setPreviewPres(true)
                    }}
                  >
                    Preview
                  </button>}
                <div className="w-100 mt-3">
                  Prescription Name : {savedPresData?.name} <br />
                  Remarks : {savedPresData?.remark}
                </div>
                <div>
                  {/* <img
                    src={apiUrl + savedPresData?.file}
                    alt="pres_img"
                    width={"100%"}
                  /> */}
                </div>
              </div>
              <ImageViewer
                show={previewPres}
                setShow={setPreviewPres}
                url={apiUrl + previewPresImg}
              />

              {savedInsuData ? (
                <div className="preview-insu p-4 mt-3">
                  <i
                    className="fa fa-close"
                    onClick={() => {
                      localStorage.removeItem("saved_insu_data");
                      setSavedInsuData(null);
                    }}
                  ></i>
                  <div className="w-100">
                    <h5>Insurance Details</h5>
                    {savedInsuData?.file.indexOf(".pdf") >= 0 ? (
                      <a
                        target="_blank"
                        href={apiUrl + savedInsuData.file}
                        download={savedInsuData.name}
                      >
                        <button>
                          Preview
                        </button>
                      </a>
                    ) :
                      <button onClick={() => {
                        setPreviewPresImg(savedInsuData?.file)
                        setPreviewPres(true)
                      }}>
                        Preview
                      </button>
                    }
                    <p>
                      Insurance Type :{" "}
                      {savedInsuData.insurance_data.insuranceName}
                    </p>
                    <p>
                      Sub Insurance :{" "}
                      {savedInsuData.sub_insurance_data.subInsuranceName}
                    </p>
                    <p>Insurance Premium No : {savedInsuData.premium_no}</p>
                    <p>Insurance Expiry Date : {savedInsuData.expiry_date}</p>
                  </div>
                </div>
              ) : null}
            </div>

            {savedInsuData ? null : (
              <button
                className="btn border-dark w-100 mt-4"
                onClick={() => {
                  setUploadInsuranceShow(true);
                  // axios request for insurance list
                  axios
                    .get(apiUrl + "/master/get_insurance_list")
                    .then((res) => {
                      setInsuranceList(res.data.data);
                    })
                    .catch((err) => {
                      toast.error(err.toString());
                    });
                }}
              >
                Upload Insurance
              </button>
            )}

            <button
              className="btn bg-theme text-light w-100 mt-2"
              onClick={PlaceOrder}
              disabled={
                reduxresponsefetchaddressReducer.data.length === 0 ||
                CallMeToConfirmMyOrderTxt === "Wait a sec..." ||
                CallMeToConfirmMyOrderTxt === "Done"
              }
            >
              {CallMeToConfirmMyOrderTxt}
            </button>
          </Col>
        </Row>
      </Container>

      {showAddressPicker ? (
        <div
          id="write-review"
          className="animate__animated animate__fadeIn animate__faster"
        >
          <div className="w-100">
            <p>Add New Address</p>
          </div>
          <div className="w-100 mt-3">
            <div>
              <h5>{address}</h5>
              {isLoaded ? (
                <Autocomplete>
                  <input
                    id="search-location"
                    className="form-control"
                    label="Search your location..."
                    placeholder="Search location..."
                    variant="filled"
                    defaultValue={locationval}
                    onChange={(e) => setLocationval(e.target.value)}
                    onBlur={(e) => {
                      //////////////////////////////
                      geocodeByAddress(e.target.value)
                        .then((results) => getLatLng(results[0]))
                        .then((latLng) => {
                          fetchAddress(latLng.lat, latLng.lng);
                          // update center state
                          this.setState({ mapCenter: latLng });
                        })
                        .catch((error) => console.error("Error", error));
                    }}
                  />
                </Autocomplete>
              ) : null}
              <div>
                <MapPicker
                  defaultLocation={defaultLocation}
                  zoom={15}
                  mapTypeId="roadmap"
                  style={{ height: "400px" }}
                  onChangeLocation={handleChangeLocation}
                  onChangeZoom={handleChangeZoom}
                  id="map"
                  apiKey={GoogleMapsAPI}
                />
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center mt-3">
              <button
                className="btn border-dark"
                onClick={() => {
                  setShowAddressPicker(false);
                  // navigate(-1);
                }}
                type="button"
              >
                Close
              </button>
              <button
                className="btn bg-theme text-light ml-2"
                onClick={() => {
                  setShowAddressFields(true);
                  setShowAddressPicker(false);
                }}
                type="button"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {showAddressFields ? (
        <div
          id="write-review"
          className="animate__animated animate__fadeIn animate__faster"
        >
          <div className="w-100">
            <p>Add New Address</p>
          </div>
          <div className="w-100 mt-3">
            <form className="row" onSubmit={OnSubmit_AddNewAddress}>
              <div className="input-group-cust col-6">
                <label htmlFor="address-name">
                  Address Name<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Home, Office, Other"
                  id="address-name"
                  defaultValue={userdata.name}
                  required
                />
              </div>
              <div className="input-group-cust col-6">
                <label htmlFor="address-phone">
                  Phone Number<sup>*</sup>
                </label>
                <input
                  type="number"
                  name="phone"
                  className="form-control"
                  id="address-phone"
                  defaultValue={userdata.phone_number}
                  required
                />
              </div>
              <div className="input-group-cust col-6">
                <label htmlFor="address-address1">
                  Address 1<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="address1"
                  className="form-control"
                  defaultValue={address1}
                  id="address-address1"
                  required
                />
              </div>
              <div className="input-group-cust col-6">
                <label htmlFor="address-address1">
                  Address 2<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="address2"
                  className="form-control"
                  id="address-address2"
                  defaultValue={address2}
                  required
                />
              </div>
              <div className="input-group-cust col-6">
                <label htmlFor="address-city">
                  City<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  id="address-city"
                  defaultValue={city}
                  required
                />
              </div>
              <div className="input-group-cust col-6">
                <label htmlFor="address-state">State</label>
                <input
                  type="text"
                  name="state"
                  className="form-control"
                  defaultValue={state}
                  id="address-state"
                />
              </div>
              <div className="input-group-cust col-6">
                <label htmlFor="address-country">
                  Country<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="country"
                  defaultValue={country}
                  className="form-control"
                  id="address-country"
                  required
                />
              </div>
              <div className="input-group-cust col-6">
                <label htmlFor="address-country">PO box</label>
                <input
                  type="number"
                  name="pincode"
                  defaultValue={pinCode}
                  className="form-control"
                  id="address-pincode"
                />
              </div>
              <div className="input-group-cust col-6 mt-3 ml-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="true"
                  name="default"
                  id="address-default"
                  onClick={oncheckdefaultaddress}
                />
                <label
                  className="form-check-label text-grey"
                  htmlFor="address-default"
                >
                  Make as Default Address
                </label>
              </div>

              <div className="w-100 d-flex justify-content-center mt-3">
                <button
                  className="btn border-dark"
                  onClick={() => {
                    setShowAddressPicker(false);
                    setShowAddressFields(false);
                    // navigate(-1);
                  }}
                  type="button"
                >
                  Close
                </button>
                <button className="btn bg-theme text-light ml-2" type="submit">
                  {AddAddressState}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}

      {uploadInsuranceShow ? (
        <UploadInsuranceCpm setUploadInsuranceShow={setUploadInsuranceShow} />
      ) : null}

      {callrequestmsgShow ? (
        <div className="insurance-confirm p-5">
          <div className="icons">
            <img src={icon2} alt="upload_insu" />
          </div>
          <small className="mt-3 text-center">
            After prescription verification, expect a call by 2 PM tomorrow ,
            that’s our pharmacist.
          </small>
        </div>
      ) : null}

      <div className="py-4 mt-4" />
      <Bottommenumob />
    </>
  );
};
export default PresCheckout;
