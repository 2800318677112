import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { storeUserToken } from "../../app/userToken";


const AppLogin = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        storeUserToken(token)
        navigate("/");
    }, [navigate, token])

    return (
        <>
            wait a sec...
        </>
    )
}
export default AppLogin;