
import { Row, Col } from "react-bootstrap";

import "./Other.css";
// import images
import drugs from "../../../../assets/home/drugs 2.png";
import shield from "../../../../assets/home/shield 1.png";
import mask from "../../../../assets/home/mask 1.png";

const Other = () => {
    return (
        <>
            <Row className="my-5 shadow-sm px-5 py-4" id="home-others">
                <Col>
                    <div className="card border-0">
                        <div className="card-body">
                            <div className="card-title text-center">
                                <h5>
                                    <img src={drugs} alt="drugs" className="other-cat-img" />
                                </h5>
                            </div>
                            <div className="card-text text-center">
                                <h4 className="other-cat-txt">Genuine Products</h4>
                            </div>
                        </div>
                    </div>
                </Col>
                <div className="separator"></div>
                <Col>
                    <div className="card border-0 left">
                        <div className="card-body">
                            <div className="card-title text-center">
                                <h5>
                                    <img src={shield} alt="drugs" className="other-cat-img" />
                                </h5>
                            </div>
                            <div className="card-text text-center">
                                <h4 className="other-cat-txt">Secure Payment</h4>
                            </div>
                        </div>
                    </div>
                </Col>
                <div className="separator"></div>
                <Col>
                    <div className="card border-0 left">
                        <div className="card-body">
                            <div className="card-title text-center">
                                <h5>
                                    <img src={mask} alt="drugs" className="other-cat-img" />
                                </h5>
                            </div>
                            <div className="card-text text-center">
                                <h4 className="other-cat-txt">Contactless Delivery</h4>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Other;