import CartDesktop from "./CartDesktop/CartDesktop";
import CartMob from "./CartMob/CartMob";
import "./Cart.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CART_COUNT, DISPLAY_CART_POPUP } from "../../../redux/state/state";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import CartNavbar from "../../Header/CartNavbar/CartNavbar";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserToken, removeUserToken } from "../../app/userToken";
import { apiUrl, displayCart } from "../../../redux/action/action";
import { useSelector } from "react-redux";
import { useState } from "react";
import Bottommenumob from "../../Footer/Bottommenumob/Bottommenumob";
import { toast } from "react-toastify";
import { GetDeviceId } from "../../app/GetDeviceId";

const Cart = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window]);

  const reduxresponse = useSelector((state) => state.displayCartReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = getUserToken();
  const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);

  useEffect(() => {
    dispatch(displayCart());
    window.scrollTo(0, 0);
    // clear all filter
    dispatch({
      type: "CLEAR_FILTER_BY_CATEGORY",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_BRAND",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_PRODUCT_FORM",
    });
    dispatch({
      type: DISPLAY_CART_POPUP,
      payload: false,
    });
  }, []);
  const trackerColor = {
    cart: "text-theme",
    address: "tracker-default",
    payment: "tracker-default",
    firstLine: "c-w-25 border-top-tracker-default",
    secondLine: "c-w-25 border-top-tracker-default",
  };

  const handleClearCart = async () => {
    if (accessToken) {
      axios
        .get(apiUrl + "/order/clear_cart", {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then(() => {
          setShowDelectConfirmation(false);
          dispatch(displayCart());
          dispatch({
            type: CART_COUNT,
            payload: { data: [] },
          });
        }).catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        });
    } else {
      const deviceId = await GetDeviceId();
      axios
        .get(apiUrl + "/order/clear_guest_cart?unique_id=" + deviceId)
        .then(() => {
          dispatch(displayCart());
          setShowDelectConfirmation(false)
        });
    }
  };

  return (
    <>
      <CartNavbar data={trackerColor} />
      <Navbarmob />
      <div className="CartforDesktop">
        <CartDesktop />
      </div>
      <div className="CartforMob">
        {reduxresponse?.data?.length === 0 ? null : (
          <>
            <Link
              to="/setaddress"
              className="btn bg-btn-theme cart-price-details-footer-btn mt-4"
              style={{
                width: "90%",
                margin: "5px 5%",
              }}
            >
              Place Order
            </Link>

            <div className="d-flex align-items-center justify-content-between pl-3">
              <p className="p-0 m-0">{reduxresponse?.data?.length} item{reduxresponse?.data?.length > 1 && `'s`}</p>
              <button
                className="btn border-danger text-danger mr-3 my-2 float-right"
                onClick={() => setShowDelectConfirmation(true)}
              >
                Clear Cart
              </button>
            </div>
          </>
        )}
        <CartMob />
        {showDelectConfirmation ? (
          <div className="insurance-confirm py-4">
            <i
              className="fa fa-close"
              onClick={() => setShowDelectConfirmation(false)}
            ></i>
            <h5 className="mt-3">Are you sure ?</h5>
            <div className="mt-2">
              <button
                className="btn border-theme text-theme mr-2"
                onClick={() => setShowDelectConfirmation(false)}
              >
                No
              </button>
              <button
                className="btn bg-theme text-light"
                onClick={handleClearCart}
              >
                Yes
              </button>
            </div>
          </div>
        ) : null}
      </div>

      <Bottommenumob />
    </>
  );
};

export default Cart;
