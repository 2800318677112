import { useDispatch } from "react-redux";
import { useState } from "react";
import {
  deleteAddress,
  fetchAllAddress,
  updateAddress,
} from "../../../../../redux/action/address.action";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ContryCodeList from "../AddAddress/CountryCodes.json";

const AddressCardView = ({ data, radioId, setRadioId, index }) => {
  const location = useLocation();
  const [Editaddress, setEditAddress] = useState({});
  const dispatch = useDispatch();
  const [showAddAddress, setshowAddAddress] = useState(false);

  const add_new_addressClose = () => setshowAddAddress(false);
  const edit_addressShow = () => {
    setshowAddAddress(true);
    setEditAddress(data);
  };

  useEffect(() => {
    if (index === 0) {
      setTimeout(() => {
        setRadioId(data?.id);
      });
    }
  }, []);

  const delete_address = async (e) => {
    const id = e.target.getAttribute("addressid");
    await dispatch(deleteAddress(id));
    await dispatch(fetchAllAddress());
  };

  // set changed value on input fields
  const onchange_input = (e) => {
    const { name, value } = e.target;
    setEditAddress({ ...Editaddress, [name]: value });
  };

  // submit form to update edited address
  const OnSubmit_EditAddress = async (e) => {
    e.preventDefault();
    setshowAddAddress(false);
    await dispatch(updateAddress(Editaddress));
    await dispatch(fetchAllAddress());
  };

  const handleSelectAddress = async (e) => {
    setRadioId(data.id);
    const val = JSON.parse(e.target.value);
    localStorage.setItem("selectedAddress_id", val.id);
  };

  const handelSelectAddressAnywhere = () => {
    setRadioId(data.id);
    document.getElementById("address" + data.id).checked = true;
    localStorage.setItem("selectedAddress_id", data.id);
  };

  const handleDefaultAddress = async (e) => {
    const data = JSON.parse(e.target.getAttribute("data"));
    data["is_default"] = true;
    await dispatch(updateAddress(data));
    await dispatch(fetchAllAddress());
  };

  return (
    <>
      <div
        className={`w-100 mt-3 ${data?.id == radioId ? `border-theme-2` : `border`
          } rounded addresses p-md-1 p-3 animate__animated animate__fadeIn`}
      >
        <div className="w-100 address-header d-flex justify-content-between">
          <div
            className="address-header-left px-2 px-md-3 pb-2 pt-3 w-100"
            style={{ cursor: "pointer" }}
            onClick={handelSelectAddressAnywhere}
          >
            <div className="d-flex align-items-center">
              {location.pathname.match("/saved-address") ? null : (
                <input
                  type="radio"
                  name="setdefaultaddress"
                  id={"address" + data.id}
                  className="checkBox mr-2"
                  defaultChecked={data.is_default}
                  value={JSON.stringify(data)}
                  onClick={handleSelectAddress}
                />
              )}
              &nbsp;
              <label htmlFor={"address" + data.id} className="p-0 m-0">
                {data.name}
              </label>
            </div>

            {/* <div className="address-type ml-2">{data.category}</div> */}
          </div>
          <div className="address-header-right px-0 px-md-2 pb-2 pt-3">
            <div>
              <i
                className="fa fa-pencil"
                title="Edit Address"
                onClick={edit_addressShow}
              ></i>
            </div>
            <div className="border-left border-left">
              <i
                className="fa fa-trash"
                title="Delete Address"
                addressid={data.id}
                onClick={delete_address}
              ></i>
            </div>
          </div>
        </div>

        <div
          className="w-100 px-1 px-md-4 py-0 py-md-2"
          style={{ cursor: "pointer" }}
          onClick={handelSelectAddressAnywhere}
        >
          <small className="p-0 m-0 text-grey">
            Address : {data?.address} <br />
            {window.innerWidth > 768 ? (
              <>
                Building no : {data?.building_number}
                <br />
                Street : {data?.street}
                <br />
                Zone : {data?.zone}
                <br />
                Unit no : {data?.unit_number}
                {data?.apartment_name && (
                  <>
                    <br />
                    Apartment Name : {data?.apartment_name}
                  </>
                )}
                {data?.door_number && (
                  <>
                    <br />
                    Door Number : {data?.door_number}
                  </>
                )}
                <br />
              </>
            ) : (
              <>
                Building-{data?.building_number}, Street-{data?.street},
                Zone-{data?.zone}, Unit-{data?.unit_number}
                {data?.apartment_name && (<>, Apartment Name-{data?.apartment_name}</>)}
                {data?.door_number && (<>, Door Number-{data?.door_number}</>)}
              </>
            )}
          </small>
          <div className="d-flex justify-content-between">
            <small className="p-0 m-0">
              Mobile : {data?.country_code}{" "}
              {data?.phone_number
                ? data?.phone_number.toString().slice(0, 4) +
                " " +
                data?.phone_number.toString().slice(4)
                : " "}
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-end mt-2 mt-md-0">
            {data.is_default ? (
              <span className="text-grey">Default</span>
            ) : (
              <button
                className="btn p-0 m-0 text-theme"
                data={JSON.stringify(data)}
                onClick={handleDefaultAddress}
              >
                Mark as default
              </button>
            )}
          </div>
        </div>

        {/* <div className="w-100 mt-4 cart-delivery-date">
          <p>
            <i className="fa fa-truck"></i>
            &nbsp;&nbsp; Estimated Delivery : Thursday, 10 Feb
          </p>
        </div> */}
      </div>

      {/* add new address */}
      <Modal
        show={showAddAddress}
        onHide={add_new_addressClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="w-100 add-new-address-header">
            <h3>Edit Address</h3>
          </div>

          <div className="w-100 px-md-5 pb-4 add-new-address-body">
            <form onSubmit={OnSubmit_EditAddress}>
              <div className="input-group-cust">
                <label htmlFor="address-name">
                  Name<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="address-name"
                  onChange={onchange_input}
                  value={Editaddress.name}
                  required
                />
              </div>
              <div className="input-group-cust">
                <label htmlFor="address-phone">
                  Country Code<sup>*</sup>
                </label>
                <select
                  name="country_code"
                  className="w-100"
                  style={{ height: "30px" }}
                  defaultValue={
                    Editaddress.country_code === "974"
                      ? "+974"
                      : Editaddress.country_code
                  }
                >
                  {ContryCodeList.map((e, i) => {
                    return (
                      <option value={e.dial_code} key={i}>
                        {e.dial_code} ({e.name})
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="input-group-cust">
                <label htmlFor="address-phone">
                  Phone Number<sup>*</sup>
                </label>
                <input
                  type="number"
                  name="phone_number"
                  className="form-control"
                  id="address-phone"
                  onChange={onchange_input}
                  value={Editaddress.phone_number}
                  required
                />
              </div>
              <div className="input-group-cust">
                <label htmlFor="address">
                  Address<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  id="address"
                  onChange={onchange_input}
                  value={Editaddress.address}
                  required
                  disabled
                />
              </div>
              <div className="input-group-cust">
                <label htmlFor="building_number">
                  Building no<sup>*</sup>
                </label>
                <input
                  type="number"
                  name="building_number"
                  className="form-control"
                  id="building_number"
                  onChange={onchange_input}
                  value={Editaddress.building_number}
                  required
                />
              </div>

              <div className="input-group-cust">
                <label htmlFor="street">
                  Street<sup>*</sup>
                </label>
                <input
                  type="number"
                  name="street"
                  className="form-control"
                  id="street"
                  onChange={onchange_input}
                  value={Editaddress.street}
                  required
                />
              </div>
              <div className="input-group-cust">
                <label htmlFor="zone">
                  Zone<sup>*</sup>
                </label>
                <input
                  type="number"
                  name="zone"
                  className="form-control"
                  id="zone"
                  value={Editaddress.zone}
                  onChange={onchange_input}
                  required
                />
              </div>
              <div className="input-group-cust">
                <label htmlFor="unit_number">Unit Number</label>
                <input
                  type="number"
                  name="unit_number"
                  className="form-control"
                  id="unit_number"
                  value={Editaddress.unit_number}
                  onChange={onchange_input}
                />
              </div>
              <div className="w-100 mt-4 add-new-address-footer">
                <center>
                  <button type="submit" className="add-add-new-address mt-3">
                    Update
                  </button>

                  <button
                    type="button"
                    className="close-add-new-address mt-3"
                    onClick={add_new_addressClose}
                  >
                    Cancel
                  </button>
                </center>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddressCardView;
