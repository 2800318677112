import React, { useEffect, useState } from "react";
import "./css/Style.css";
import { useNavigate } from "react-router-dom";
import logoMob from "../../../assets/img/logo-dark.png";
import signupimg from "../../../assets/signup.png";

import axios from "axios";
import { apiUrl } from "../../../redux/action/action";
import { toast } from "react-toastify";
import { getUserToken } from "../../app/userToken";
var x;
function Signup() {
  const accessToken = getUserToken();
  const navigate = useNavigate();
  const [salutation, setSalutation] = useState("");
  const [gender, setGender] = useState("");
  const [Localdata, setLocaldata] = useState(JSON.parse(sessionStorage.getItem("logindtls")))

  useEffect(() => {
    setSalutation();
    if (gender === "male") {
      setSalutation("Mr");
    } else if (gender === "female") {
      setSalutation("Miss");
    }
  }, [gender]);

  useEffect(() => {
    setSalutation();
    if (salutation === "Mr") {
      setGender("male");
    } else if (salutation === "Miss") {
      setGender("female");
    }
  }, [salutation]);

  const [phone, setPhone] = useState()

  // useEffect(() => {
  //   const localdata = JSON.parse(sessionStorage.getItem("logindtls"));
  //   setLocaldata(localdata.type);
  //   console.log(localdata);
  // }, [])

  // submit form for signin
  const submitSignup = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const name = formData.get("name");
    const email = formData.get("email");
    const gender = formData.get("gender");
    const phone_number = phone;
    const salutation = formData.get("salutation");
    const data = {
      salutation,
      gender,
      name
    };

    if (Localdata.type === "email") {
      data['email'] = Localdata.email;
      data['phone_number'] = Number(phone_number);
    }
    else {
      data['phone_number'] = Number(Localdata.phone);
      data['email'] = email;
    }


    if (phone_number && phone_number.length !== 8) {
      toast.warning('Please enter a 8 digit number')
    }
    else {
      try {
        const response = await axios.put(
          apiUrl + "/user/updatecustomerDetails",
          data,
          {
            headers: {
              Authorization: "Token " + accessToken,
            },
          }
        );

        if (response.data.status === 200) {
          if (localStorage.getItem("redirect_url")) {
            navigate(localStorage.getItem("redirect_url"));
            localStorage.removeItem("redirect_url");
          } else {
            navigate("/");
          }
          // remove logindtls from local storage after login success
          sessionStorage.removeItem("logindtls");
        }
        else {
          toast.warning(response.data.message)
        }
      }
      catch (err) {
        const { status, message } = err.response.data;
        if (status === 400) {
          toast.warning(message);
        } else {
          toast.error(message);
        }
      }
    }

  };

  return (
    <>
      <div id="authbox" className="row">
        <i className="fa fa-arrow-left" onClick={() => navigate(-1)}></i>
        <div id="imgside" className="col-md-6">
          {/* <Authcarousel /> */}
          <img src={signupimg} alt="signupimg" />
        </div>

        <div id="authside" className="col-md-6 d-flex flex-column">
          <img src={logoMob} alt="logo" id="logoMob" />
          <h5>Welcome</h5>
          <p>Help us with below details to get started</p>

          {/* signup form */}
          <form
            className="mt-4 mb-md-5 mb-4 w-100"
            onSubmit={submitSignup}
            id="signupform"
          >
            <label htmlFor="salutation">Salutation*</label>
            <select
              className="form-select"
              name="salutation"
              id="salutation"
              value={salutation}
              onChange={(e) => setSalutation(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Dr">Dr</option>
            </select>

            <label htmlFor="name">Name*</label>
            <input
              type="text"
              name="name"
              id="name"
              defaultValue={Localdata?.name}
              className="mb-3"
              required
            />
            {Localdata?.type === "phone" ? (
              <>
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="mb-3"
                  required
                />
              </>
            ) : (
              <div className="w-100 d-flex flex-column">
                <label htmlFor="Phone" className="w-100">Phone*</label>
                <div className="d-flex">
                  <input
                    type="text"
                    name="country_code"
                    className="form-control p-0 text-center"
                    id="country_code"
                    value={"+974"}
                    disabled
                    style={{
                      width: "70px",
                      height: "46px",
                      borderRadius: "4px 0 0 4px",
                    }}
                  />
                  <input
                    type="number"
                    name="phone_number"
                    id="number"
                    min={0}
                    className="mb-3"
                    required
                    style={{
                      height: "46px",
                      borderRadius: "0 4px 4px 0",
                    }}
                    value={phone}
                    onChange={(e) => {
                      if (e.target.value.length <= 8) {
                        setPhone(e.target.value)
                      }
                      else {
                        return false
                      }
                    }}
                  />
                </div>
              </div>
            )}


            <label htmlFor="gender">Gender*</label>
            <select
              name="gender"
              id="gender"
              className="mb-3"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="">Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">other</option>
            </select>

            {/* <input type="Submit" /> */}
            <button
              type="submit"
              id="submit-btn"
              className="form-control mt-2 py-2"
            >
              Submit
            </button>
          </form>

          <button
            className="btn text-theme"
            onClick={() => {
              if (localStorage.getItem("redirect_url")) {
                navigate(localStorage.getItem("redirect_url"));
                localStorage.removeItem("redirect_url");
              } else {
                navigate("/");
              }
            }}
          >
            Skip for now
          </button>
        </div>
      </div>
    </>
  );
}

export default Signup;
