import Footer from "../../Footer/Footer";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import Navbar from "../../Header/Navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../redux/action/action";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const EditorialPolicy = () => {
  const [data, getData] = useState();
  const location = useLocation();
  useEffect(() => {
    axios
      .get(apiUrl + "/master/get_static_page_detail" + location.pathname.replace('/policies', ''))
      .then((res) => {
        getData(res.data.data[0]);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  }, []);

  return (
    <>
      <Navbar />
      <Navbarmob />
      <div className="container mt-5">
        <div className="row body-color">
          <div className="col-12" style={{ minHeight: "400px" }}>
            <div className="d-flex mb-4 justify-content-center">
              <h3 className="mr-4"><i className={data?.page_icon} /></h3>
              <strong><h3 dangerouslySetInnerHTML={{ __html: data?.page_title }} style={{ float: 'center' }} /></strong>
            </div>
            <div dangerouslySetInnerHTML={{ __html: data?.text }} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default EditorialPolicy;
