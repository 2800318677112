import React, { useEffect, useState } from "react";
import "./css/Style.css";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import OtpInput from "react-otp-input";
import logoMob from "../../../assets/img/logo-dark.png";
import otpimg from "../../../assets/otp.png";
import { apiUrl } from "../../../redux/action/action";
import { toast } from "react-toastify";
import { storeUserToken } from "../../app/userToken";
import { useDispatch } from "react-redux";
import { CART_COUNT } from "../../../redux/state/state";
import { GetDeviceId } from "../../app/GetDeviceId";

function Auth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(29);
  const [otp, setOtp] = useState();
  const [otpActionBtnTxt, setOtpActionBtnTxt] = useState("Verify OTP");

  useEffect(() => {
    if (localStorage.getItem("logindtls") === null) {
      navigate("/auth/login");
    }
  });

  useEffect(() => {
    localStorage.removeItem("searchkey");
  }, []);

  useEffect(() => {
    const timerConst =
      timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(timerConst);
  }, [timer]);

  // submit form for otp
  const submitOtp = async (e) => {
    e.preventDefault();
    setOtpActionBtnTxt("Verifing...");
    const unique_id = await GetDeviceId();
    const local_data = localStorage.getItem("logindtls");
    const phone_number = JSON.parse(local_data).number;
    const type = JSON.parse(local_data).type;
    const prescription_uuid = sessionStorage.getItem("prescription_uuid");
    let data = {};
    if (prescription_uuid) {
      data = {
        prescription_uuid,
        phone_number,
        otp,
        unique_id,
      };
    } else {
      data = {
        phone_number,
        otp,
        unique_id,
      };
    }

    // axios request
    try {
      const response = await axios.post(apiUrl + "/user/verify", data);

      if (response.data.status === 200) {
        setOtpActionBtnTxt("Verified");
        // store user token
        storeUserToken(response.data.token);
        console.log(response.data);
        // check login
        // if (!prescription_uuid) {
        //   if (type === "login") {
        //     if (localStorage.getItem("redirect_url")) {
        //       window.location = localStorage.getItem("redirect_url");
        //       localStorage.removeItem("redirect_url");
        //     } else {
        //       window.location = "/";
        //     }
        //     // remove logindtls from local storage after login success
        //     localStorage.removeItem("logindtls");
        //   } else {
        //     navigate("/auth/sign-up");
        //   }
        // } else {
        //   // sessionStorage.removeItem("prescription_uuid");
        //   sessionStorage.setItem(
        //     "prescription_id",
        //     response.data.prescription_id
        //   );
        //   navigate("/upload-prescription");
        // }
        // header cart count
        axios
          .get(apiUrl + "/order/header_cart_api", {
            headers: {
              Authorization: "Token " + response.data.token,
            },
          })
          .then((res) => {
            dispatch({
              type: CART_COUNT,
              payload: res.data,
            });
          });
      } else {
        setOtpActionBtnTxt("Verify");
        toast.warning(response.data.message);
      }
    } catch (error) {
      setOtpActionBtnTxt("Verify");
      try {
        toast.error(error.response.data.message);
      } catch (e) {
        toast.error(error.toString());
      }
    }
  };

  // resend otp
  const onClickResenOTP = async () => {
    const response = await axios.post(apiUrl + "/user/add_customerDetails", {
      phone_number: JSON.parse(localStorage.getItem("logindtls")).number,
    });
    if (response.data.status === 200) {
      $(".alert-box").removeClass("d-none");
      setTimeout(() => {
        $(".alert-box").addClass("d-none");
      }, 4000);
    } else {
      try {
        toast.error(response.data.message);
      } catch (e) {
        toast.error("Request Failed with status code 404");
      }
    }
  };

  return (
    <>
      <div id="authbox" className="row">
        <i className="fa fa-arrow-left" onClick={() => navigate(-1)}></i>
        <div id="imgside" className="col-md-6">
          {/* <Authcarousel /> */}
          <img src={otpimg} alt="otpimg" />
        </div>

        <div id="authside" className="col-md-6">
          <center>
            <img src={logoMob} alt="logo" id="logoMob" />
            <h5 className="mt-5 mt-md-0">OTP Verification</h5>
            <p className="mt-5">
              Enter the 6 digit code we’ve sent to your registered mobile no
            </p>
            {/* login form */}
            <div className="my-4 w-100 d-flex justify-content-center">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                separator={<span>&nbsp;&nbsp;</span>}
                isInputNum={true}
                inputStyle={{
                  width: "45px",
                  height: "45px",
                }}
              />
            </div>
            <button
              id="submit-btn"
              className="form-control mt-3 py-2"
              disabled={otpActionBtnTxt === "Verifing..."}
              onClick={submitOtp}
            >
              {otpActionBtnTxt}
            </button>
            {timer === 0 ? (
              <div className="animate__animated animate__fadeIn">
                <p className="mt-4">Didn’t receive OTP</p>
                <Link
                  to="#"
                  style={{ fontSize: "12px" }}
                  onClick={onClickResenOTP}
                >
                  Resend OTP
                </Link>
              </div>
            ) : (
              <p className="mt-4">{`00 : ${
                timer.toString().length === 1 ? "0" + timer : timer
              }`}</p>
            )}
          </center>
        </div>
      </div>
    </>
  );
}

export default Auth;
