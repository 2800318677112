/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Prdctsecmob.css";
import { useSelector, useDispatch } from "react-redux";
import {
  addToCart,
  apiUrl,
  displayTopBarCart,
} from "../../../../redux/action/action";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import image
import Addtowishlist from "../Addtowishlist/Addtowishlist";
import { useParams } from "react-router-dom";
import { getUserToken } from "../../../app/userToken";

const Prdctsecmob = ({ totalItems }) => {
  const { key } = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [allprdct, setAllprdct] = useState([]);
  const reduxresponse = useSelector((state) => state.reducer);

  const [prdctid, setPrdctid] = useState("");
  const [show, setShow] = useState(false);
  const reduxresponseFilter = useSelector((state) => state);
  const reduxresponsesort = useSelector((state) => state.sortReducer);
  const handleClose = () => setShow(false);

  // useEffect(async () => {
  //   getPrdcts();
  //   setShow(reduxresponseFilter.wishlistReducer.state);

  // }, [setLoad, reduxresponse]);
  useEffect(() => {
    setShow(reduxresponseFilter.wishlistReducer.state);
    getPrdcts();
  }, [
    key,
    reduxresponsesort,
    reduxresponse,
    reduxresponseFilter.filterByCategory,
    reduxresponseFilter.filterByBrand.brand,
    reduxresponseFilter.filterByProductForm.productForm,
    reduxresponseFilter.wishlistReducer,
  ]);

  const getPrdcts = () => {
    setLoad(true);
    if (reduxresponse.allProducts) {
      setAllprdct(reduxresponse.allProducts);
      setLoad(false);
    }
  };

  return (
    <>
      <div id="prdctsecmob">
        <div id="prdctslistmob">
          {/* <h6>All Products ({totalItems})</h6> */}
          {load ? (
            <div className="w-100 d-flex align-items-center justify-content-center py-5">
              <i
                className="fa fa-spinner fa-spin text-theme"
                style={{ fontSize: "40px" }}
              ></i>
            </div>
          ) : (
            <>
              {allprdct.length > 0 ? allprdct.map((datas, index) => {
                return (
                  <div
                    className="mob-prdct animate__animated animate__fadeIn pt-3"
                    key={index}
                  >
                    <div className="w-100 d-flex align-items-center justify-content-between float-left">
                      <div className="mob-prdct-img">
                        <Link to={"/product-details/" + datas.id}>
                          <img src={apiUrl + datas.product_images[0]} />
                        </Link>
                      </div>
                      <div
                        className="mob-prdct-info px-2"
                        onClick={() => navigate("/product-details/" + datas.id)}
                      >
                        <p
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {datas.itemName}
                        </p>
                        {datas.avg_rating > 0 ? (
                          <div className="mob-prdct-rating mt-2">
                            <i
                              className="fa fa-star text-theme mr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            {datas.avg_rating}
                          </div>
                        ) : null}

                        <div className="w-100 d-flex flex-column">
                          <small className="p-0 m-0 text-grey d-flex">
                            {Number(datas?.discounted_price_pcs) === 0 ? (
                              <span className="text-theme">
                                QAR {datas?.pricePerPcs}
                              </span>
                            ) : (
                              <>
                                <span className="text-theme">
                                  QAR {datas?.discounted_price_pcs}
                                </span>
                                <span
                                  style={{
                                    position: "relative",
                                    marginLeft: "6px",
                                  }}
                                >
                                  {datas?.pricePerPcs}
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "8px",
                                      left: 0,
                                      background: "#F5832B",
                                      width: "100%",
                                      height: "1.2px",
                                    }}
                                  />
                                </span>
                              </>
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                    <ActionBtns
                      datas={datas}
                      setPrdctid={setPrdctid}
                      setShow={setShow}
                    />
                  </div>
                );
              }) :
                <div className="w-100 text-center body-white animate__animated animate__fadeIn py-5">
                  <h5>No Items Found</h5>
                </div>
              }
            </>
          )}
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Addtowishlist prdctid={prdctid} action="add_wishlist" />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const ActionBtns = ({ datas, setPrdctid, setShow }) => {
  const dispatch = useDispatch();
  const accessToken = getUserToken();
  const location = useLocation();
  const navigate = useNavigate();
  const reduxselectItemUnit = useSelector(
    (state) => state.selectItemUnitReducer
  );
  const [AddtoCartTxt, setAddtoCartTxt] = useState("Add to Cart");
  // const [addtocomparetxt, setaddtocomparetxt] = useState("Add to compare");

  const add_to_cart = async (e) => {
    await setAddtoCartTxt("Adding...");
    const id = e.target.getAttribute("data-prdctid");
    const data = {
      product_id: id,
      quantity: 1,
      unit: reduxselectItemUnit.unittype,
    };
    await dispatch(addToCart(data));
    await dispatch(displayTopBarCart());
    setTimeout(() => {
      setAddtoCartTxt("Added to Cart");
      setTimeout(() => {
        setAddtoCartTxt("Add to Cart");
      }, 1500);
    }, 1000);
  };

  const add_to_wishlist = async (e) => {
    if (accessToken) {
      const id = e.target.getAttribute("data-prdctid");
      setPrdctid(id);
      setShow(true);
    } else {
      localStorage.setItem("redirect_url", location.pathname);
      navigate("/auth/login");
    }
  };
  return (
    <div className="p-3">
      <div className="mob-prdct-btn mb-3">
        {datas.prescription_required ? (
          <button className="btn"></button>
        ) : datas.wishlist_status ? (
          <button className="btn add-to-wishlist full-width-truncate px-2">Added to Wishlist</button>
        ) : (
          <button
            className="btn text-light bg-theme add-to-wishlist-active full-width-truncate"
            data-prdctid={datas.id}
            onClick={add_to_wishlist}
            disabled={datas.prescription_required}
            style={{ overflow: 'hidden' }}
          >
            Add to Wishist
          </button>
        )}

        {datas.prescription_required ? (
          <button
            className="btn add-to-bag p-2 full-width-truncate"
            data-prdctid={datas.id}
            onClick={() => navigate("/upload-prescription")}
            style={{ overflow: 'hidden' }}
            disabled={datas.stock === 0}
          >
            {datas.stock === 0 ? "Out of Stock" : "Upload Prescription"}
          </button>
        ) : (
          <button
            className="btn add-to-bag p-2 full-width-truncate"
            style={{ overflow: 'hidden' }}
            data-prdctid={datas.id}
            onClick={add_to_cart}
            disabled={datas.stock === 0 || AddtoCartTxt === "Adding..."}
          >
            {datas.stock === 0 ? "Out of Stock" : AddtoCartTxt}
          </button>
        )}
      </div>

      {/* {accessToken && (
        <button
          className="btn border-theme text-theme mt-3 w-100"
          onClick={() => {
            setaddtocomparetxt("Adding...");
            const compareitem = localStorage.getItem("compareitem");
            if (compareitem) {
              const localcompdata = JSON.parse(compareitem);
              axios
                .post(
                  apiUrl + "/products/save_compared_products",
                  {
                    prod_id_1: localcompdata?.id,
                    prod_id_2: Number(datas.id),
                  },
                  {
                    headers: {
                      Authorization: "Token " + accessToken,
                    },
                  }
                )
                .then((res) => {
                  setaddtocomparetxt("Added to compare");
                  navigate("/compare");
                })
                .catch((err) => {
                  setaddtocomparetxt("Add to compare");
                  toast.error(err.toString());
                });
            } else {
              localStorage.setItem("compareitem", JSON.stringify(datas));
              navigate("/compare");
            }
          }}
        >
          {addtocomparetxt}
        </button>
      )} */}
    </div>
  );
};

export default Prdctsecmob;
