import "./UpdateQuantityCart.css";
import { useSelector,useDispatch } from "react-redux";
import { COUNT_QUANTITY } from "../../../redux/state/state";


const UpdateQuantityCart = ({ data,totalQ,selectunit}) => {
    const dispatch = useDispatch();
    const reduxcountquantity = useSelector((state) => state.countQuantity);

    const onClickIncrease = () => {
        dispatch({
            type: COUNT_QUANTITY,
            payload: reduxcountquantity.total+1
        })
    }
    
    const onClickDecrease = () => {
        if(reduxcountquantity.total !== 1)
        {
            dispatch({
                type: COUNT_QUANTITY,
                payload: reduxcountquantity.total-1
            })
        }
    }

    return (
        <div className="UpdateQuantityCart">
            <div className="UpdateQuantityCart-button">
                <i className="fa fa-minus-circle text-grey" onClick={onClickDecrease}></i>
                {/* <p>{totalQ} {data ? data.packUnit : null}</p> */}
                <p>{totalQ} {selectunit ? selectunit : data?.pcsUnit}</p>
                <i className="fa fa-plus-circle text-theme" onClick={onClickIncrease}></i>
            </div>
            <div className="UpdateQuantityCart-unit">
                {/* <p className="p-0 m-0"> 
                of {data ? data.noOfPcsPerPack : null } {data ? data.pcsUnit : null}
                </p> */}
            </div>
        </div>
    )
}
export default UpdateQuantityCart;