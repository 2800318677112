import { Col } from "react-bootstrap";
import $ from "jquery";
import "./Customerreview.css";
import shortby from "../../../../assets/icons/shortby.png";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../../../redux/action/action";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { toast } from "react-toastify";
import { getUserToken, removeUserToken } from "../../../app/userToken";

const Customerreview = () => {
  const accessToken = getUserToken();
  const { id } = useParams();
  const [writeReview, setWriteReview] = useState(false);
  const [totalRate, setTotalRate] = useState(0);
  const [allReview, setAllReview] = useState([]);
  const [loader, setLoader] = useState(true);
  const [handleReviewBtnDisable, setHandleReviewBtnDisable] = useState(true);

  useEffect(async () => {
    fetchAllReview();
  }, []);

  const fetchAllReview = () => {
    const enddataurl = accessToken ? id + "?token=" + accessToken : id;
    axios
      .get(apiUrl + "/order/get_all_review/" + enddataurl)
      .then((res) => {
        if (res.data.status === 400) {
          toast.warning(res.data.message);
        } else {
          setAllReview(res.data.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === 400) {
          toast.warning(message);
        } else {
          toast.error(message);
        }
      });
  };

  const onClickStar = (e) => {
    setHandleReviewBtnDisable(false);
    const startCount = Number(e.target.getAttribute("count"));
    setTotalRate(startCount);
    const stars = $(".star");
    for (let i = 0; i < stars.length; i++) {
      const element = stars[i];
      if (startCount >= i + 1) {
        $(element).removeClass("fa-star-o");
        $(element).addClass("fa-star");
      } else {
        $(element).removeClass("fa-star");
        $(element).addClass("fa-star-o");
      }
    }
  };
  const onSubmitReview = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    if (totalRate !== 0) {
      formData.append("rating", totalRate);
      try {
        axios
          .post(
            apiUrl + "/order/give_review",
            {
              product_id: id,
              rating: formData.get("rating"),
              review: formData.get("review-comment"),
            },
            {
              headers: {
                Authorization: "Token " + accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data.status === 200) {
              fetchAllReview();
              toast.success(res.data.message);
              setWriteReview(false);
            } else {
              toast.error(res.data.message);
            }
          }).catch((err) => {
            const { response } = err;
            const { data } = response;
            const { status, message } = data;
            if (status === "400") {
              toast.warning(message);
              removeUserToken()
            } else {
              toast.error(message);
            }
          });
      } catch (err) {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      }
    }
  };

  return (
    <>
      <Col className="p-0 m-0">
        <div className="w-100 customerreview-titlesec">
          <div>
            <h5>
              Customer Reviews &nbsp;
              {loader ? (
                <SkeletonTheme
                  color="#ccc"
                  highlightColor="#fff"
                  width={"20px"}
                >
                  <Skeleton count={1} height={"20px"} />
                </SkeletonTheme>
              ) : (
                <>({allReview?.data?.length})</>
              )}
            </h5>
          </div>
          <div className="d-flex">
            {allReview?.allow_review ? (
              <button
                className="btn bg-theme text-light shadow-none mr-md-3"
                onClick={() => setWriteReview(true)}
              >
                Rate & Review
              </button>
            ) : null}
            <div className="short-by">
              <div className="input-group">
                <div className="input-group-prepend bg-light">
                  <span className="input-group-text">
                    <div>
                      <img src={shortby} alt="shortby" />
                    </div>
                    <div className="ml-2">Sort By : </div>
                  </span>
                </div>
                <select
                  className="form-control text-theme"
                  style={{ cursor: "pointer" }}
                >
                  <option>Latest to oldest</option>
                  <option>Oldest to latest</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="customerreview-rating">
          <div className="w-25">
            {loader ? (
              <SkeletonTheme color="#ccc" highlightColor="#fff" width={"100px"}>
                <Skeleton count={1} height={"40px"} />
                <Skeleton count={2} height={"20px"} />
              </SkeletonTheme>
            ) : (
              <>
                <h1>
                  <center>
                    <i className="fa fa-star text-theme"></i> <br />{" "}
                    {allReview?.average_rating.toFixed(1)} / 5
                  </center>
                </h1>
                <p className="text-grey">{allReview?.count_rating} Ratings</p>
                <p className="text-grey">{allReview?.count_reviews} Reviews</p>
              </>
            )}
          </div>

          <div className="w-75 px-3">
            {loader ? (
              <SkeletonTheme color="#ccc" highlightColor="#fff" width={"100%"}>
                <Skeleton count={5} height={"20px"} />
              </SkeletonTheme>
            ) : (
              <>
                <div className="w-100">
                  <div className="rating-name">
                    5 <i className="fa fa-star text-theme"></i>
                  </div>
                  <div className="rating-meter">
                    <div
                      className="bg-rating-5"
                      style={{ width: allReview?.rating?.["5"] + "%" }}
                    ></div>
                  </div>
                  <div className="rating-perc">{allReview?.rating?.[5]}%</div>
                </div>
                <div className="w-100">
                  <div className="rating-name">
                    4 <i className="fa fa-star text-theme"></i>
                  </div>
                  <div className="rating-meter">
                    <div
                      className="bg-rating-4"
                      style={{ width: allReview?.rating?.["4"] + "%" }}
                    ></div>
                  </div>
                  <div className="rating-perc">{allReview?.rating?.["4"]}%</div>
                </div>
                <div className="w-100">
                  <div className="rating-name">
                    3 <i className="fa fa-star text-theme"></i>
                  </div>
                  <div className="rating-meter">
                    <div
                      className="bg-rating-3"
                      style={{ width: allReview?.rating?.["3"] + "%" }}
                    ></div>
                  </div>
                  <div className="rating-perc">{allReview?.rating?.["3"]}%</div>
                </div>
                <div className="w-100">
                  <div className="rating-name">
                    2 <i className="fa fa-star text-theme"></i>
                  </div>
                  <div className="rating-meter">
                    <div
                      className="bg-rating-2"
                      style={{ width: allReview?.rating?.["2"] + "%" }}
                    ></div>
                  </div>
                  <div className="rating-perc">{allReview?.rating?.["2"]}%</div>
                </div>
                <div className="w-100">
                  <div className="rating-name">
                    1 <i className="fa fa-star text-theme"></i>
                  </div>
                  <div className="rating-meter">
                    <div
                      className="bg-rating-1"
                      style={{ width: allReview?.rating?.["1"] + "%" }}
                    ></div>
                  </div>
                  <div className="rating-perc">{allReview?.rating?.["1"]}%</div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="customerreview-review w-100 border-line-top py-2">
          {loader ? (
            <SkeletonTheme color="#ccc" highlightColor="#fff" width={"100%"}>
              <Skeleton count={3} height={"70px"} />
            </SkeletonTheme>
          ) : (
            <>
              {allReview?.data?.map((item, index) => {
                return (
                  <>
                    {item?.review !== "" ? (
                      <div
                        className="border-line-bottom w-100 py-3 d-flex"
                        key={index}
                      >
                        <div style={{ width: '40px' }}>
                          <div
                            className={`customerreview-rating-display w-100 ${item?.rating === 5
                              ? `bg-rating-5`
                              : item?.rating === 4
                                ? `bg-rating-4`
                                : item?.rating === 3
                                  ? `bg-rating-3`
                                  : item?.rating === 2
                                    ? `bg-rating-2`
                                    : `bg-rating-1`
                              }`}
                          >
                            <small>{item?.rating}</small>
                          </div>
                        </div>

                        <div className="customerreview-rating-review ml-2">
                          <p className="review-comment">{item?.review}</p>
                          <span className="reviewer-detqails text-grey">
                            {item?.customer_data?.name} | {item?.created_on}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </>
          )}
        </div>
        <div className="show-all-review">
          {/* <center>
            <button>Show All 39 Reviews</button>
          </center> */}
        </div>
      </Col>

      {/* write review form */}
      {writeReview ? (
        <div
          id="write-review"
          className="animate__animated animate__fadeIn animate__faster"
        >
          <i
            className="fa fa-close float-right"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setWriteReview(false);
              setHandleReviewBtnDisable(true);
              setTotalRate(0);
            }}
          ></i>
          <div className="w-100 border-bottom">
            <p>Rate this product</p>
            <div className="rating-starts mb-3">
              <i
                className="fa fa-star-o star"
                count="1"
                onClick={onClickStar}
              ></i>
              <i
                className="fa fa-star-o star"
                count="2"
                onClick={onClickStar}
              ></i>
              <i
                className="fa fa-star-o star"
                count="3"
                onClick={onClickStar}
              ></i>
              <i
                className="fa fa-star-o star"
                count="4"
                onClick={onClickStar}
              ></i>
              <i
                className="fa fa-star-o star"
                count="5"
                onClick={onClickStar}
              ></i>
              <div id="rating-counter">
                <small>Your Rating : {totalRate}</small>
              </div>
            </div>
          </div>
          <div className="w-100 mt-3">
            <p>Write Review</p>
            <form className="w-100" onSubmit={onSubmitReview}>
              <textarea
                name="review-comment"
                className="form-control"
                rows="5"
                placeholder="What did you like or dislike? Or What did you use this product for?"
              ></textarea>

              <div className="w-100 d-flex justify-content-center mt-3">
                <button
                  className="btn border-dark"
                  type="button"
                  onClick={() => {
                    setWriteReview(false);
                    setTotalRate(0);
                    setHandleReviewBtnDisable(true);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-theme text-light ml-2"
                  type="submit"
                  disabled={handleReviewBtnDisable}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Customerreview;
