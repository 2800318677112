import SaveCardItem from "../SaveCardItem/SaveCardItem";
import mastercard from "../../../../assets/icons/Mastercard-logo.png";
import "./style.css";
import { useEffect } from "react";
import axios from "axios";
import {
  addToCart,
  apiUrl,
  displayTopBarCart,
} from "../../../../redux/action/action";
import { getUserToken, removeUserToken } from "../../../app/userToken";
import { useState } from "react";
import { toast } from "react-toastify";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const SaveForLater = () => {
  const accessToken = getUserToken();
  const [itemlist, setItemList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [fresh_, reFresh] = useState(0);
  const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);

  useEffect(() => {
    fetchdata();
  }, [fresh_]);

  const fetchdata = () => {
    setLoader(true);
    axios
      .get(apiUrl + "/wishlist/get_saved_for_later", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        const { data } = res;
        const { data: data_, message, status } = data;
        if (status === 200) {
          setItemList(data_);
        } else {
          toast.warning(message);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  const handle_clear_list = () => {
    axios
      .get(apiUrl + "/wishlist/clear_save_for_later", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then(() => {
        setItemList([]);
        setShowDelectConfirmation(false);
      }).catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  return (
    <>
      <div className="my-account">
        <div className="my-account-title d-flex mb-3">
          <div className="w-50">
            <h3>Save For Later</h3>
          </div>
          <div className="w-50 d-flex justify-content-end">
            <button
              className="btn border-theme text-theme"
              onClick={() => setShowDelectConfirmation(true)}
            >
              Clear All
            </button>
          </div>
        </div>

        {loader ? (
          <SkeletonTheme
            color="#ccc"
            highlightColor="#fff"
            inline={true}
            width={"30%"}
          >
            <Skeleton count={6} height={300} className="mr-4" />
          </SkeletonTheme>
        ) : (
          <>
            {itemlist?.length !== 0 ? (
              itemlist?.map((data, index) => (
                <Items data={data} key={index} reFresh={reFresh} />
              ))
            ) : (
              <>
                <div className="w-100 text-center body-white animate__animated animate__fadeIn py-5">
                  <i
                    className="fa fa-heart text-theme"
                    style={{ fontSize: "60px" }}
                  ></i>
                  <h5>No Items Found</h5>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {showDelectConfirmation ? (
        <div className="insurance-confirm py-4">
          <i
            className="fa fa-close"
            onClick={() => setShowDelectConfirmation(false)}
          ></i>
          <h5 className="mt-3">Are you sure ?</h5>
          <div className="mt-2">
            <button
              className="btn border-theme text-theme mr-2"
              onClick={() => setShowDelectConfirmation(false)}
            >
              No
            </button>
            <button
              className="btn bg-theme text-light"
              onClick={handle_clear_list}
            >
              Yes
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

const Items = ({ data, reFresh }) => {
  const accessToken = getUserToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addtocartLoading, setAddtoCartLoading] = useState(false);

  // delete wishlist item
  const onCLickDelete = (e) => {
    const itemid = e.target.getAttribute("itemID");
    deleteItems(itemid);
  };

  const add_to_cart = async (e) => {
    setAddtoCartLoading(true);
    const id = e.target.getAttribute("itemID");
    const itemUnit = e.target.getAttribute("itemUnit");
    const data = {
      product_id: Number(id),
      quantity: 1,
      unit: itemUnit,
    };

    await dispatch(addToCart(data));
    await dispatch(displayTopBarCart());
    // await deleteItems(prdctid);
    setAddtoCartLoading(false);
  };

  const deleteItems = (itemid) => {
    axios
      .delete(apiUrl + "/wishlist/delete_saved_for_later/" + itemid, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then(() => {
        reFresh(1);
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          removeUserToken()
        } else {
          toast.error(message);
        }
      });
  };

  // const handleChangeUnit = (e) => {
  //   const val = e.target.value;
  //   const wishlistId = e.target.getAttribute("wishlistId");
  //   const productId = e.target.getAttribute("productId");
  //   axios
  //     .get(
  //       apiUrl +
  //       "/wishlist/update_wishlist_prod_unit/" +
  //       wishlistId +
  //       "?product_id=" +
  //       productId +
  //       "&unit=" +
  //       val,
  //       {
  //         headers: {
  //           Authorization: "Token " + accessToken,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       reFresh(1);
  //     }).catch((err) => {
  //       const { response } = err;
  //       const { data } = response;
  //       const { status, message } = data;
  //       if (status === "400") {
  //         toast.warning(message);
  //         removeUserToken()
  //       } else {
  //         toast.error(message);
  //       }
  //     });
  // };

  return (
    <div className="wishlist-i body-white animate__animated animate__fadeIn">
      <i className="fa fa-trash" itemID={data?.id} onClick={onCLickDelete}></i>
      {data?.product_data?.avg_rating > 0 ? (
        <div className="rating text-dark">
          <i className="fa fa-star text-theme" aria-hidden="true"></i>{" "}
          {data?.product_data?.avg_rating}
        </div>
      ) : null}
      <Link to={"/product-details/" + data?.product_data?.id}>
        <div className="wishlist-i-img">
          <img
            src={apiUrl + data?.product_data?.product_images[0]}
            alt={"prdct_img"}
          />
        </div>
      </Link>
      <div className="wishlist-i-txt px-3">
        <p className="truncate-wish">{data?.product_data?.itemName}</p>
        <div className="w-100 d-flex align-items-center justify-content-between">
          {/* <span className="text-theme">
                              QAR {data?.product_data?.pricePerPcs}
                            </span> */}
          {/* {console.log(data?.unit)} */}
          {data?.unit === "pcs" ? (
            Number(data?.product_data?.discounted_price_pcs) === Number(data?.product_data?.pricePerPcs) ? (
              <span className="text-theme">
                QAR {data?.product_data?.pricePerPcs}
              </span>
            ) : (
              <div>
                <span className="text-theme">
                  QAR {data?.product_data?.discounted_price_pcs}
                </span>
                <span
                  style={{
                    position: "relative",
                    marginLeft: "6px",
                  }}
                >
                  {data?.product_data?.pricePerPcs}
                  <div
                    style={{
                      position: "absolute",
                      top: "8px",
                      left: 0,
                      background: "#F5832B",
                      width: "100%",
                      height: "2px",
                    }}
                  />
                </span>
              </div>
            )
          ) : Number(data?.product_data?.discounted_price_pack) === Number(data?.product_data?.pricePerPack) ? (
            <span className="text-theme">
              QAR {data?.product_data?.pricePerPack}
            </span>
          ) : (
            <div>
              <span className="text-theme">
                QAR {data?.product_data?.discounted_price_pack}
              </span>
              <span
                style={{
                  position: "relative",
                  marginLeft: "6px",
                }}
              >
                {data?.product_data?.pricePerPack}
                <div
                  style={{
                    position: "absolute",
                    top: "8px",
                    left: 0,
                    background: "#F5832B",
                    width: "100%",
                    height: "2px",
                  }}
                />
              </span>
            </div>
          )}

          {/* {data?.product_data?.pcsUnit?.toLowerCase() ===
            data?.product_data?.packUnit.toLowerCase() ? (
            data?.unit !== "pcs" ? (
              data?.product_data?.pcsUnit
            ) : (
              data?.product_data?.packUnit
            )
          ) : (
            <select
              className="ml-2"
              style={{ width: "fit-content" }}
              value={
                data?.unit === "pcs"
                  ? data?.product_data?.pcsUnit
                  : data?.product_data?.packUnit
              }
              wishlistId={data?.wishlist_id}
              productId={data?.product_id}
              onChange={handleChangeUnit}
            >
              <option>
                {data?.unit === "pcs"
                  ? data?.product_data?.pcsUnit
                  : data?.product_data?.packUnit}
              </option>
              <option>
                {data?.unit !== "pcs"
                  ? data?.product_data?.pcsUnit
                  : data?.product_data?.packUnit}
              </option>
            </select>
          )} */}

          {data.prescription_required ? (
            <i
              className="fa fa-upload text-theme"
              title="Upoad prescription"
              onClick={() => navigate("/upload-prescription")}
            ></i>
          ) : (
            <button className="btn p-0 m-0" disabled={addtocartLoading}>
              <i
                className="fa fa-cart-plus text-theme"
                itemID={data?.product_id}
                prdctid={data?.id}
                itemUnit={data?.unit}
                onClick={add_to_cart}
              ></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SaveForLater;
