import axios from "axios";
import { useEffect, useState } from "react";
import documentNotFound from "../../../../assets/documentNotFound.png";
import { apiUrl } from "../../../../redux/action/action";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { toast } from "react-toastify";
import pdficon from "../../../../assets/pdf-icon.png";
import imgicon from "../../../../assets/img-icon.png";
import insuranceImg from "../../../../assets/home/health-insurance 1.png";
import { useNavigate } from "react-router-dom";
import { getUserToken, removeUserToken } from "../../../app/userToken";
import './insurance.css';
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";

const MyInsurance = () => {
  const accessToken = getUserToken();
  const navigate = useNavigate();
  const [pageItems, setPageItems] = useState([]);
  const [allpresdata, setAllpresdata] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [TotalNumPages, setTotalNumPages] = useState(1);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [imageError, setImageError] = useState(false);

  const [uploadInsuranceShow, setUploadInsuranceShow] = useState(false);
  const [PlaceholderTXT, setPlaceholderTXT] = useState(
    "Attach File JPEG, PNG or PDF format (Max File Size : 5mb)"
  );
  const [insurancefile, setInsurancefile] = useState(null);
  const [InsuranceType, setInsuranceType] = useState(null);
  const [SubInsurance, setSubInsurance] = useState(null);
  const [InsurancePremiumNo, setInsurancePremiumNo] = useState(null);
  const [InsuranceExpiryDate, setInsuranceExpiryDate] = useState(null);
  const [insuranceList, setInsuranceList] = useState(null);
  const [subinsuranceList, setSubInsuranceList] = useState(null);
  const [SaveInsuBtnState, setSaveInsuBtnState] = useState(false);
  const [savebtnstateInsu, setSavebtnstateInsu] = useState("Save");
  const [showDelectConfirmation, setShowDelectConfirmation] = useState(false);
  const [SelectIsurance, setSelectIsurance] = useState(null);

  useEffect(() => {
    fetchInsurance();
  }, []);

  const fetchInsurance = async () => {
    setLoader(true);
    try {
      const res = await axios.get(apiUrl + "/user/get_insurances", {
        headers: {
          Authorization: "Token " + accessToken,
        },
      });

      if (res.data.status === 200) {
        setAllpresdata(res.data.data);
        if (res?.data?.data && res.data.data.length > 0) {
          setPageItems(res.data.data.slice(0, 6));
        } else {
          setPageItems([]);
        }
        setLoader(false);
      }
    }
    catch (err) {
      const { status, message } = err.response.data.data;
      if (status === "400") {
        toast.error(message)
        window.location = "/"
        removeUserToken();
      }
      else {
        toast.error(message)
      }
    }

  };

  useEffect(() => {
    if (allpresdata.length > 0) {
      setTotalNumPages(Math.ceil(allpresdata.length / 6));
    }
  }, [allpresdata]);

  const PrescriptionItems = ({ data }) => {
    const [dateFormat, setDateFormat] = useState("");

    useEffect(() => {
      const date = new Date(data.created_on);
      //extract the parts of the date
      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      setDateFormat(day + " " + months[month - 1] + " " + year);
    }, []);

    const deleteInsurance = async () => {
      try {
        const res = await axios.delete(apiUrl + "/user/update_insurance/" + deleteItemId, {
          headers: {
            Authorization: "Token " + accessToken,
          },
        });

        if (res.data.status) {
          setShowDelectConfirmation(false);
          fetchInsurance();
          toast.success(res.data.message);
        }
      }
      catch (err) {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
        } else {
          toast.error(message);
        }
      }
    };

    return (
      <>
        <div className="PrescriptionItems border shadow-sm rounded">
          <div className="PrescriptionItems-header">
            <div className="d-flex w-75">
              <div>
                <a target="_blank" href={apiUrl + data.file} download>
                  {data.file.indexOf(".pdf") >= 0 ? (
                    <img src={pdficon} alt="pdficon" />
                  ) : (
                    <img src={imgicon} alt="imgicon" />
                  )}
                </a>
              </div>
              <div className="border-left">
                <p className="p-0 m-0 pres-insu-name">
                  {data.insurance_data.insuranceName}
                </p>
              </div>
            </div>
            <div>
              <i
                className="fa fa-trash"
                onClick={() => {
                  setShowDelectConfirmation(true);
                  setDeleteItemId(data.id);
                }}
              ></i>
            </div>
          </div>

          <div className="PrescriptionItems-body border-top">
            <p>Uploaded On : {dateFormat}</p>
            <p>
              SubInsurance Name : {data.sub_insurance_data.subInsuranceName}
            </p>
            <p>Premium no. : {data.premium_no}</p>
            <p>Expiry Date : {data.expiry_date}</p>

            {/* <Link to="/cart">
              <button className="PrescriptionItems-btn w-100">
                View All products ordered on this prescription
              </button>
            </Link> */}
          </div>
        </div>

        {showDelectConfirmation ? (
          <div className="insurance-confirm py-4">
            <i
              className="fa fa-close"
              onClick={() => setShowDelectConfirmation(false)}
            ></i>
            <h5 className="mt-3">Are you sure ?</h5>
            <div className="mt-2">
              <button
                className="btn border-theme text-theme mr-2"
                onClick={() => setShowDelectConfirmation(false)}
              >
                No
              </button>
              <button
                className="btn bg-theme text-light"
                onClick={deleteInsurance}
              >
                Yes
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const handleSaveInsurance = () => {
    if (
      insurancefile &&
      InsuranceType &&
      SubInsurance &&
      InsurancePremiumNo &&
      InsuranceExpiryDate
    ) {
      setSaveInsuBtnState(true);
      setSavebtnstateInsu("Saving...");
      const formData = new FormData();
      formData.append("file", insurancefile);
      formData.append("insurance_id", InsuranceType);
      formData.append("sub_insurance_id", SubInsurance);
      formData.append("premium_no", InsurancePremiumNo);
      formData.append("expiry_date", InsuranceExpiryDate);
      axios
        .post(apiUrl + "/user/save_insurance", formData, {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          setSaveInsuBtnState(false);
          if (res.data.status === 400) {
            toast.error(res.data.message);
            setSavebtnstateInsu("Save");
          } else {
            fetchInsurance();
            toast.success(res.data.message);
            setInsurancefile("");
            setInsuranceType("");
            setSubInsurance("");
            setInsurancePremiumNo("");
            setInsuranceExpiryDate("");
            setPlaceholderTXT(
              "Attach File JPEG, PNG or PDF format (Max File Size : 3mb)"
            );
            setSavebtnstateInsu("Saved");
            setTimeout(() => {
              setSavebtnstateInsu("Save");
              setUploadInsuranceShow(false);
            }, 2000);
          }
        })
        .catch((err) => {
          setSaveInsuBtnState(false);
          setSavebtnstateInsu("Save");
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
          } else {
            toast.error(message);
          }
        });
    } else {
      toast.warning("All fields are required");
    }
  };

  return (
    <>
      <div className="my-account">
        <div className="my-account-title d-flex justify-content-between mb-3">
          <h4 className="p-0 m-0">My Insurances</h4>
          {allpresdata.length > 0 ? (
            <button
              className="title-btn-right mob-profile-uploadbtn btn border-theme text-theme"
              onClick={() => {
                setUploadInsuranceShow(true);
                // axios request for insurance list
                axios
                  .get(apiUrl + "/master/get_insurance_list??active_list=1")
                  .then((res) => {
                    setInsuranceList(res.data.data);
                  })
                  .catch((err) => {
                    toast.error(err.toString());
                  });
              }}
            >
              Upload Insurance
            </button>
          ) : null}
        </div>

        {loader ? (
          <SkeletonTheme
            color="#ccc"
            highlightColor="#fff"
            width={"100%"}
            inline={true}
          >
            <Skeleton count={2} height={260} className="mr-4" />
          </SkeletonTheme>
        ) : (
          <div style={{ display: allpresdata.length > 0 && 'flex', flexFlow: allpresdata.length > 0 && 'column' }}>
            <div>
              {allpresdata.length > 0 ? (
                pageItems.map((data, index) => {
                  return <PrescriptionItems key={index} data={data} />;
                })
              ) : (
                <div
                  className="w-100 d-flex align-items-center justify-content-center"
                  style={{ height: "600px" }}
                >
                  <div className="d-flex flex-column justify-centent-center align-items-center">
                    <img
                      src={documentNotFound}
                      alt="documentNotFound"
                      style={{ width: "150px" }}
                    />
                    <p className="text-theme mt-4">
                      You have not uploaded any insurances yet
                    </p>
                    <button
                      className="title-btn-right"
                      onClick={() => {
                        setUploadInsuranceShow(true);
                        // axios request for insurance list
                        axios
                          .get(apiUrl + "/master/get_insurance_list")
                          .then((res) => {
                            setInsuranceList(res.data.data);
                          })
                          .catch((err) => {
                            toast.error(err.toString());
                          });
                      }}
                    >
                      Upload Insurance
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="prdct-pagination mt-4 d-flex justify-content-end">
              <Pagination
                {...bootstrap5PaginationPreset}
                current={pageNo}
                total={TotalNumPages}
                maxWidth={300}
                onPageChange={(e) => { setPageNo(e); setPageItems(allpresdata.slice(6 * (e - 1), (6 * (e - 1)) + 6)) }}
                activeItemClassName="active"
              />
            </div>
          </div>
        )}
      </div>

      {uploadInsuranceShow ? (
        <div className="upload-insu">
          <div className="w-100 d-flex flex-column align-items-center pt-4">
            <img src={insuranceImg} alt="icon" />
            <h5>Upload Insurance</h5>
          </div>
          <div className="w-100 px-4 mt-3">
            {SelectIsurance ? (
              <div className="w-100 d-flex justify-content-end mt-3">
                <button
                  className="btn border-theme text-theme py-1 mr-2"
                  onClick={() => setSelectIsurance(null)}
                >
                  Back
                </button>
                <button
                  className="btn bg-theme text-light py-1"
                  onClick={() => {
                    localStorage.setItem("saved_insu_data", SelectIsurance);
                    navigate("/prescription-checkout");
                  }}
                >
                  Next
                </button>
              </div>
            ) : null}
          </div>
          <div className="w-100 px-4 mt-4">
            <div className="w-100 upload-field-box">
              <div className="input-group rounded mb-3 input-group-lg">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-paperclip"></i>
                  </span>
                </div>
                <span className="form-control upload-input-placeholader-txt">
                  <small>{PlaceholderTXT}</small>
                  <input
                    type="file"
                    accept="image/*, .pdf"
                    onChange={(e) => {
                      if (e.target.files[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].some(x => x === e.target.files[0]?.type)) {
                        setInsurancefile(e.target.files[0]);
                        setPlaceholderTXT(e.target.files[0].name);
                        setImageError(false);
                      } else {
                        setImageError(true);
                        setPlaceholderTXT(<span style={{ fontSize: '12px', color: 'red' }}>Attach File JPEG, PNG or PDF format (Max File Size : 3mb)</span>);
                      }
                    }}
                  />
                </span>
              </div>

              <div className="w-100 d-flex">
                <div className="w-50 mr-1">
                  <div className="input-group-cust">
                    <label htmlFor="InsuranceType" className="text-grey">
                      Insurance Type
                    </label>
                    <select
                      name="InsuranceType"
                      id="InsuranceType"
                      className="form-control"
                      value={InsuranceType}
                      onChange={(e) => {
                        setInsuranceType(e.target.value);
                        // axios request for sub insurance list
                        axios
                          .get(
                            apiUrl +
                            "/master/get_sub_insurance_list?insurance_id=" +
                            e.target.value
                          )
                          .then((res) => {
                            setSubInsuranceList(res.data.data);
                          })
                          .catch((err) => {
                            toast.error(err.toString());
                          });
                      }}
                    >
                      <option value={null}>Select</option>
                      {insuranceList?.map((e) => {
                        return (
                          <option value={e.id}>
                            {e.insuranceName} ({e.insuranceType})
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="input-group-cust mt-2">
                    <label htmlFor="InsurancePremiumNo" className="text-grey  two-col">
                      <div>Insurance Premium </div>
                      <div>No.</div>
                    </label>
                    <input
                      type="number"
                      name="InsurancePremiumNo"
                      className="form-control"
                      id="InsurancePremiumNo"
                      value={InsurancePremiumNo}
                      onChange={(e) => setInsurancePremiumNo(e.target.value)}
                    />
                  </div>
                </div>

                <div className="w-50 ml-1">
                  <div className="input-group-cust">
                    <label htmlFor="SubInsurance" className="text-grey">
                      Sub Insurance
                    </label>
                    <select
                      name="SubInsurance"
                      className="form-control"
                      id="SubInsurance"
                      value={SubInsurance}
                      onChange={(e) => setSubInsurance(e.target.value)}
                    >
                      <option value={null}>Select</option>
                      {subinsuranceList?.map((e) => {
                        return e.is_active ? (
                          <option value={e.id}>{e.subInsuranceName}</option>
                        ) : null;
                      })}
                    </select>
                  </div>
                  <div className="input-group-cust mt-2">
                    <label htmlFor="InsuranceExpiryDate" className="text-grey two-col">
                      <div>Insurance Expiry </div>
                      <div>Date</div>
                    </label>
                    <input
                      type="date"
                      name="InsuranceExpiryDate"
                      className="form-control"
                      id="InsuranceExpiryDate"
                      value={InsuranceExpiryDate}
                      onChange={(e) => {
                        if (
                          new Date(e.target.value).getTime() >
                          new Date().getTime()
                        ) {
                          setInsuranceExpiryDate(e.target.value);
                        } else {
                          toast.warning("Please select a future date");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 mt-3 py-2 px-3 cart-NB">
                <small className="text-theme">
                  Medicines with insurance will be delivered after verification.
                  Payment will be done after approval.
                </small>
              </div>

              <div className="w-100 d-flex justify-content-center mt-4 upload-action-btns">
                <button
                  className="btn border-btn-theme"
                  onClick={() => setUploadInsuranceShow(false)}
                >
                  Close
                </button>
                <button
                  className="btn bg-btn-theme"
                  onClick={handleSaveInsurance}
                  disabled={SaveInsuBtnState || imageError}
                >
                  {savebtnstateInsu}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MyInsurance;
