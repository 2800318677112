import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { displayCart, apiUrl, displayTopBarCart } from "../../../../../../redux/action/action";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Addtowishlist from "../../../../Products/Addtowishlist/Addtowishlist";
import { toast } from "react-toastify";
import { getUserToken, removeUserToken } from "../../../../../app/userToken";
import { GetDeviceId } from "../../../../../app/GetDeviceId";
import { CART_COUNT } from "../../../../../../redux/state/state";

const ItemWithOutPres = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = getUserToken();
  const dispatch = useDispatch();
  const reduxresponse = useSelector((state) => state);
  const [show, setShow] = useState(false);
  const [prdctid, setPrdctid] = useState("");

  const handleClose = () => setShow(false);
  const [size, setSize] = useState(data?.unit);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setShow(false);
  }, [reduxresponse.displayCartReducer]);

  const remove_item_cart = async (e) => {
    const id = e.target.getAttribute("prdctcartid");
    // axios for delete item from cart
    if (accessToken) {
      try {
        axios
          .delete(apiUrl + "/order/update_cart_data/" + id, {
            headers: {
              Authorization: "Token " + accessToken,
            },
          })
          .then((res) => {
            dispatch(displayCart());
            axios
              .get(apiUrl + "/order/header_cart_api", {
                headers: {
                  Authorization: "Token " + accessToken,
                },
              })
              .then((res) => {
                dispatch({
                  type: CART_COUNT,
                  payload: res.data,
                });
              });
          }).catch((err) => {
            const { response } = err;
            const { data } = response;
            const { status, message } = data;
            if (status === "400") {
              toast.warning(message);
              removeUserToken()
            } else {
              toast.error(message);
            }
          });
      } catch (error) {
        try {
          toast.error(error.response.data.message);
        } catch (e) {
          toast.error(error.toString());
        }
      }
    } else {
      const deviceId = await GetDeviceId();
      axios
        .delete(
          apiUrl +
          "/order/delete_guest_cart_data/" +
          id +
          "?unique_id=" +
          deviceId
        )
        .then((res) => {
          dispatch(displayCart());
          dispatch(displayTopBarCart())
        })
        .catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          toast.error(message);
        });
    }
  };

  const add_to_wishlist = async (e) => {
    if (accessToken) {
      const id = e.target.getAttribute("prdctid");
      setPrdctid(id);
      setShow(true);
    } else {
      localStorage.setItem("redirect_url", location.pathname);
      navigate("/auth/login");
    }
  };

  const onClickIncrease = async (e) => {
    setLoader(true);
    const id = e.target.getAttribute("prdctcartid");
    const updatedquantity =
      Number(e.target.getAttribute("currentQuantity")) + 1;

    if (accessToken) {
      axios
        .put(
          apiUrl + "/order/update_cart_data/" + id,
          {
            quantity: updatedquantity,
          },
          {
            headers: {
              Authorization: "Token " + accessToken,
            },
          }
        )
        .then((res) => {
          setLoader(false);
          dispatch(displayCart());
        })
        .catch((err) => {
          setLoader(false);
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }

        });
    } else {
      const deviceId = await GetDeviceId();
      axios
        .put(
          apiUrl +
          "/order/update_guest_cart_data/" +
          id +
          "?unique_id=" +
          deviceId,
          {
            quantity: updatedquantity,
            unit: size,
          }
        )
        .then((res) => {
          setLoader(false);
          dispatch(displayCart());
        })
        .catch((err) => {
          setLoader(false);
          if (err.response.data.status === 0) {
            toast.warning(err.response.data.message);
          }
        });
    }
  };

  const onClickDecrease = async (e) => {
    const id = e.target.getAttribute("prdctcartid");
    if (Number(e.target.getAttribute("currentQuantity")) > 1) {
      setLoader(true);
      const currentquantity = Number(e.target.getAttribute("currentQuantity"));
      const updatedquantity =
        Number(e.target.getAttribute("currentQuantity")) - 1;
      if (currentquantity !== 0) {
        if (accessToken) {
          axios
            .put(
              apiUrl + "/order/update_cart_data/" + id,
              {
                quantity: updatedquantity,
              },
              {
                headers: {
                  Authorization: "Token " + accessToken,
                },
              }
            )
            .then((res) => {
              dispatch(displayCart());
              setLoader(false);
            })
            .catch((err) => {
              setLoader(false);
              const { response } = err;
              const { data } = response;
              const { status, message } = data;
              if (status === "400") {
                toast.warning(message);
                removeUserToken()
              } else {
                toast.error(message);
              }
            });
        } else {
          const deviceId = await GetDeviceId();
          axios
            .put(
              apiUrl +
              "/order/update_guest_cart_data/" +
              id +
              "?unique_id=" +
              deviceId,
              {
                quantity: updatedquantity,
                unit: size,
              }
            )
            .then((res) => {
              dispatch(displayCart());
              setLoader(false);
            })
            .catch((err) => {
              setLoader(false);
              if (err.response.data.status === 0) {
                toast.warning(err.response.data.message);
              }
            });
        }
      }
    } else {
      axios
        .delete(apiUrl + "/order/update_cart_data/" + id, {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          dispatch(displayCart());
        }).catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === "400") {
            toast.warning(message);
            removeUserToken()
          } else {
            toast.error(message);
          }
        });
    }
  };

  return (
    <>
      <div className="w-100 border mt-md-4 p-md-4 p-3 animate__animated animate__fadeIn rounded d-flex">
        <div className="w-25 d-flex justify-content-center align-items-center">
          <Link to={"/product-details/" + data.product_id} className="w-100">
            <img src={apiUrl + data.image1} alt="prdct_img" width={"80%"} />
          </Link>
        </div>
        <div className="w-75">
          <div className="w-100 cartitem-prdct-head">
            <Link
              to={"/product-details/" + data.product_id}
              className="text-dark w-75"
            >
              <h6 className="truncate-wish">{data.itemName}</h6>
            </Link>

            {data?.total_product_amount === data?.actual_product_amount ? (
              <span className="text-theme">
                QAR {data?.total_product_amount}
              </span>
            ) : (
              <small className="p-0 m-0 text-grey d-flex">
                <span className="text-theme">
                  QAR {data?.total_product_amount}
                </span>
                <span
                  style={{
                    position: "relative",
                    marginLeft: "6px",
                  }}
                >
                  {data?.actual_product_amount}
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: 0,
                      background: "#F5832B",
                      width: "100%",
                      height: "2px",
                    }}
                  />
                </span>
              </small>
            )}
          </div>
          <div className="w-100 mt-3 cart-select-maindiv d-flex align-items-center">
            <div className="Cartitem-button">
              <i
                className="fa fa-minus-circle text-grey"
                prdctcartid={data.id}
                currentquantity={data.quantity}
                onClick={onClickDecrease}
              ></i>
              {loader ? (
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ fontSize: "15px" }}
                ></i>
              ) : (
                <p>{data.quantity}</p>
              )}
              <i
                className="fa fa-plus-circle text-theme"
                prdctcartid={data.id}
                currentquantity={data.quantity}
                onClick={onClickIncrease}
              ></i>
            </div>

            {data?.pcsUnit === data?.packUnit ? (
              <div className="px-3 py-1 ml-2">{data?.pcsUnit}</div>
            ) : (
              <select
                className="ml-2"
                style={{ width: "fit-content" }}
                value={size}
                onChange={(e) => {
                  setSize(e.target.value);
                  axios
                    .put(
                      apiUrl + "/order/update_cart_data/" + data?.id,
                      {
                        quantity: data.quantity,
                        unit: e.target.value,
                      },
                      {
                        headers: {
                          Authorization: "Token " + accessToken,
                        },
                      }
                    )
                    .then(() => {
                      dispatch(displayCart());
                    }).catch((err) => {
                      const { response } = err;
                      const { data } = response;
                      const { status, message } = data;
                      if (status === "400") {
                        toast.warning(message);
                        removeUserToken()
                      } else {
                        toast.error(message);
                      }
                    });
                }}
              >
                <option value="pack" selected={"pack" === data?.unit}>
                  {data?.packUnit}
                </option>
                <option value="pcs" selected={"pcs" === data?.unit}>
                  {data?.pcsUnit}
                </option>
              </select>
            )}
            {/* <span className="ml-2 border-left pl-2">
              {data?.unit === "pcs" ? data?.pcsUnit : data?.packUnit}
            </span> */}
          </div>
          <div className="w-100 mt-3">
            <Link
              to="#"
              className="text-dark text-theme"
              // style={{ fontSize: "13px" }}
              onClick={() => {
                if (accessToken) {
                  console.log(data);
                  axios
                    .post(
                      apiUrl + "/wishlist/add_saved_for_later",
                      {
                        product_id: data.product_id,
                      },
                      {
                        headers: {
                          Authorization: "Token " + accessToken,
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.status === 200) {
                        toast.success(res.data.message);
                        dispatch(displayCart());
                      } else {
                        toast.warning(res.data.message);
                      }
                    }).catch((err) => {
                      const { response } = err;
                      const { data } = response;
                      const { status, message } = data;
                      if (status === "400") {
                        toast.warning(message);
                        removeUserToken()
                      } else {
                        toast.error(message);
                      }
                    });
                } else {
                  localStorage.setItem("redirect_url", location.pathname);
                  navigate("/auth/login");
                }
              }}
            >
              Save for later
            </Link>
            <Link
              to="#"
              className="text-dark ml-2 border-left pl-2 text-theme"
              // style={{ fontSize: "13px" }}
              onClick={remove_item_cart}
              prdctid={data.product_id}
              prdctcartid={data.id}
            >
              Delete
            </Link>

            <Link
              to="#"
              className="text-dark ml-2 border-left pl-2 text-theme"
              onClick={add_to_wishlist}
              prdctid={data.product_id}
              prdctcartid={data.id}
            >
              {window.innerWidth < 768 ? (
                <i className="fa fa-heart-o"
                  onClick={add_to_wishlist}
                  prdctid={data.product_id}
                  prdctcartid={data.id}></i>
              ) : (
                "Add to wishlist"
              )}
            </Link>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Addtowishlist prdctid={prdctid} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ItemWithOutPres;
