import { createStore, applyMiddleware, combineReducers } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import {
  reducer,
  sortReducer,
  filterByCategory,
  filterByBrand,
  filterByProductForm,
  filterByAgeGroup,
  paymentmethodReducer,
  wishlistReducer,
  selectItemUnitReducer,
  withPresfilterReducer,
  withOutPresfilterReducer,
} from "./reducer/reducer";
import { menuReducer } from "./reducer/menu.reducer";
import {
  cartReducer,
  displayCartReducer,
  countQuantity,
  displayCartPopReducer,
  topBarCartCounter,
} from "./reducer/cart.reducer";

import { userReducer } from "./reducer/user.reducer";

import {
  addressReducer,
  fetchaddressReducer,
  fetchCurrentCity,
} from "./reducer/address.reducer";

const rootReducer = combineReducers({
  reducer,
  sortReducer,
  filterByCategory,
  filterByBrand,
  filterByProductForm,
  filterByAgeGroup,
  cartReducer,
  displayCartReducer,
  displayCartPopReducer,
  countQuantity,
  addressReducer,
  fetchaddressReducer,
  paymentmethodReducer,
  wishlistReducer,
  selectItemUnitReducer,
  userReducer,
  fetchCurrentCity,
  topBarCartCounter,
  menuReducer,
  withPresfilterReducer,
  withOutPresfilterReducer,
});

const middlewares = applyMiddleware(
  thunk
  // logger
);

const storage = createStore(rootReducer, {}, middlewares);

export default storage;
