import { fetchAllAddress } from "../../../../../redux/action/address.action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AddAddress.css";
import Geocode from "react-geocode";
import MapPicker from "react-google-map-picker";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { GoogleMapsAPI } from "./client-config";
import { Autocomplete } from "@react-google-maps/api";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "react-toastify";
import AddAddressFormCmp from "./AddAddressFormCmp";
import { getUserToken } from "../../../../app/userToken";
const DefaultZoom = 15;
Geocode.setApiKey(GoogleMapsAPI);

const AddAddress = () => {
  const accessToken = getUserToken();
  const reduxresponse = useSelector((state) => state.addressReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableFirstFetchaddressbtn, setdisableFirstFetchaddressbtn] =
    useState(true);

  useEffect(() => {
    dispatch(fetchAllAddress());
  }, [reduxresponse.message, dispatch]);

  const [defaultPosition, setDefaultPosition] = useState({
    lat: 25.310230428776755,
    lng: 51.48198334399912,
  });

  const [location, setLocation] = useState(defaultPosition);
  // const [showAddressPicker, setShowAddressPicker] = useState(false);
  const [showSearchBarFields, setShowSearchBarFields] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState(false);

  const [locationval, setLocationval] = useState("");

  const [address, setAddress] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setDefaultPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        fetchAddress(position.coords.latitude, position.coords.longitude);
      },
      (err) => {
        if (err.message === "User denied Geolocation") {
          // setShowAllowMap(false);
          fetchAddress(25.310230428776755, 51.48198334399912);
        }
      },
      { enableHighAccuracy: true }
    );
  }, []);

  function handleChangeLocation(lat, lng) {
    fetchAddress(lat, lng);
  }

  function handleChangeZoom(newZoom) {
    //setZoom(newZoom);
  }

  const fetchAddress = (lat, lng) => {
    setLocation({ lat: lat, lng: lng });
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        let addressjson = {};
        const address_components = response.results[0].address_components;
        address_components.forEach((element) => {
          addressjson[element.types[0]] = element.long_name;
        });

        if (addressjson?.country !== "Qatar") {
          toast.warning(
            "This Location is not serviceable yet.We are currently serviceable only in Qatar"
          );
          setdisableFirstFetchaddressbtn(true);
        } else {
          setdisableFirstFetchaddressbtn(false);
        }

        //mohalla - political
        //premise + neighbour hood (opt) - hosuse no
        // debugger;

        // if (addressjson?.premise && addressjson?.neighborhood) {
        //   setAddress1(
        //     (addressjson?.premise + " " + addressjson?.neighborhood).trim()
        //   );
        // } else if (addressjson?.premise) {
        //   setAddress1(addressjson?.premise);
        // } else if (addressjson?.neighborhood) {
        //   setAddress1(addressjson?.neighborhood);
        // }

        // setAddress2(addressjson?.political);
        // setCity(addressjson?.locality);
        // setState(addressjson?.administrative_area_level_1);
        // setContry(addressjson?.country);
        // setPinCode(addressjson?.postal_code);
        setAddress(response.results[0].formatted_address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const Map = ({ height }) => {
    return (
      <div>
        <MapPicker
          defaultLocation={location}
          zoom={DefaultZoom}
          mapTypeId="roadmap"
          style={{ height: height }}
          onChangeLocation={handleChangeLocation}
          onChangeZoom={handleChangeZoom}
          id="map"
          apiKey="AIzaSyB-i8_omurLDIXbP2yI1v0Ry5MtHtd-FyU"
        />
      </div>
    );
  };

  return (
    <>
      {!showSearchBarFields && !showAddressFields ? (
        <div className="animate__animated animate__fadeIn animate__faster addAddressBox">
          <div className="w-100">
            <div>
              <Map height="400px" />

              <h3 className="my-2">Set your delivery location</h3>
              <div class="input-group-cust">
                <label for="name">DELIVERY AREA</label>
                <input
                  type="text"
                  name="name"
                  onClick={() => {
                    setShowSearchBarFields(true);
                    setShowAddressFields(false);
                    // setShowAddressPicker(false);
                  }}
                  class="form-control"
                  value={address}
                />
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center mt-3">
              <button
                className="btn border-dark"
                onClick={() => navigate(-1)}
                type="button"
              >
                Close
              </button>
              <button
                className="btn bg-theme text-light ml-2"
                onClick={() => {
                  if (accessToken) {
                    setShowAddressFields(true);
                    // setShowAddressPicker(false);
                  } else {
                    navigate("/setaddress");
                    sessionStorage.setItem(
                      "guest_address",
                      JSON.stringify(address)
                    );
                  }
                }}
                type="button"
                disabled={disableFirstFetchaddressbtn}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {showSearchBarFields ? (
        <div className="animate__animated animate__fadeIn animate__faster addAddressBox">
          <div className="w-100 mt-3">
            <div
              className="input-group-cust col-12"
              style={{ height: "360px" }}
            >
              <label htmlFor="address-name">
                <i class="fa fa-search"></i> Search Address
              </label>

              <Autocomplete>
                <input
                  id="search-location"
                  className="form-control"
                  label="Search your location..."
                  placeholder="Search location..."
                  variant="filled"
                  autoFocus
                  defaultValue={locationval}
                  onChange={(e) => setLocationval(e.target.value)}
                  onBlur={(e) => {
                    geocodeByAddress(e.target.value)
                      .then((results) => getLatLng(results[0]))
                      .then((latLng) => {
                        fetchAddress(latLng.lat, latLng.lng);
                        // update center state
                        setLocation({
                          lat: latLng.lat,
                          lng: latLng.lng,
                        });

                        setShowSearchBarFields(false);
                        setShowAddressFields(false);
                        // setShowAddressPicker(true);

                        this.setState({ mapCenter: latLng });
                      })
                      .catch((error) => console.error("Error", error));
                  }}
                />
              </Autocomplete>
            </div>

            <div className="w-100 d-flex justify-content-center mt-3">
              <button
                className="btn border-dark"
                onClick={() => {
                  setShowSearchBarFields(false);
                  // setShowAddressPicker(false);
                  setShowAddressFields(false);
                }}
                type="button"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {showAddressFields ? (
        <div className="animate__animated animate__fadeIn animate__faster addAddressBox">
          <Map height="300px" />

          <AddAddressFormCmp address={address} />
        </div>
      ) : null}
    </>
  );
};
export default AddAddress;
