/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Modal } from "react-bootstrap";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useLocation, useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  storeAllProducts,
  apiUrl,
  addToCart,
  displayTopBarCart,
} from "../../../../../redux/action/action";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Prdcts.css";
import { useParams } from "react-router-dom";
import { ADD_FILTER_BY_CATEGORY } from "../../../../../redux/state/state";
import Addtowishlist from "../../Addtowishlist/Addtowishlist";
import { toast } from "react-toastify";
import not_found from "../../../../../assets/ITEM-NOT-FOUND.png";
import NoImg from "../../../../../assets/no-image.jpeg";
import { getUserToken } from "../../../../app/userToken";
import { compareProduct } from "../../../../app/compareProduct";

const Prdcts = ({ setTotalItems, pageNo, totalItemToBeFetched }) => {
  const accessToken = getUserToken();
  const { key, id } = useParams();
  const searchQuery = useLocation().search;
  const isSubCategory = new URLSearchParams(searchQuery).get("subcategory");
  const navigate = useNavigate();
  const location = useLocation();
  const [load, setLoad] = useState(true);
  const [prdctid, setPrdctid] = useState("");
  const dispatch = useDispatch();
  const reduxresponse = useSelector((state) => state.reducer);
  const [show, setShow] = useState(false);
  const reduxresponsesort = useSelector((state) => state.sortReducer);
  const reduxresponseFilter = useSelector((state) => state);
  const reduxresponseWithPres = useSelector(
    (state) => state.withPresfilterReducer.prescription
  );
  const reduxresponseWithOutPres = useSelector(
    (state) => state.withOutPresfilterReducer.not_prescription
  );
  const reduxselectItemUnit = useSelector(
    (state) => state.selectItemUnitReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [key, id]);

  useEffect(() => {
    setShow(reduxresponseFilter.wishlistReducer.state);
    getPrdcts();
  }, [
    key,
    id,
    pageNo,
    totalItemToBeFetched,
    reduxresponseWithOutPres,
    reduxresponseWithPres,
    reduxresponsesort,
    reduxresponseFilter.filterByCategory,
    reduxresponseFilter.filterByBrand.brand,
    reduxresponseFilter.filterByProductForm.productForm,
    reduxresponseFilter.wishlistReducer,
  ]);

  const handleClose = () => setShow(false);

  const getPrdcts = async () => {
    if (key) {
      await filterByAll(key, reduxresponsesort.data);
    } else if (id && !isSubCategory) {
      const cat_id = id.split("-")[1];
      const cat_name = id.split("-")[0];
      /////////////////////
      filterByAll("", reduxresponsesort.data, cat_id);
      const data = {
        category_id: cat_id,
        category_name: cat_name,
      };
      if (
        reduxresponseFilter.filterByCategory.category_id.indexOf(cat_id) === -1
      ) {
        dispatch({
          type: ADD_FILTER_BY_CATEGORY,
          payload: data,
        });
      }
      //////////////////////
    } else if (isSubCategory === "true" && id) {
      const sub_cat_id = id.split("-")[1];
      const sub_cat_name = id.split("-")[0];
      /////////////////////
      filterByAll("", reduxresponsesort.data, sub_cat_id);
      const data = {
        sub_category_id: sub_cat_id,
        sub_category_name: sub_cat_name,
      };
      if (
        reduxresponseFilter.filterByCategory.sub_category_id.indexOf(
          sub_cat_id
        ) === -1
      ) {
        dispatch({
          type: ADD_FILTER_BY_CATEGORY,
          payload: data,
        });
      }
      //////////////////////
    } else {
      if (reduxresponsesort.data === "none") {
        filterByAll();
      } else {
        filterByAll("", reduxresponsesort.data);
      }
    }
  };

  const filterByAll = async (searchkey, sortby, id) => {
    let categoryArray = [];
    if (id) {
      categoryArray.push(id);
    } else {
      categoryArray = reduxresponseFilter.filterByCategory.category_id;
    }
    if (key) {
      axios
        .get(
          apiUrl +
          "/search/products/" +
          searchkey +
          "?page_no=" +
          pageNo +
          "&page_count=" +
          totalItemToBeFetched +
          "&sort_by=" +
          sortby
        )
        .then((res) => {
          setLoad(false);
          dispatch(storeAllProducts([]));
          dispatch(storeAllProducts(res.data.data));
          setTotalItems(res.data.total_count);
        })
        .catch((err) => {
          setLoad(false);
          toast.error(err.toString());
        });
    } else {
      setLoad(true);
      // fetch filtered items
      axios
        .post(apiUrl + "/products/getProductsData", {
          search_string: key,
          sort_by: sortby,
          category_id: !isSubCategory ? categoryArray : undefined,
          sub_category_id:
            isSubCategory === "true"
              ? categoryArray
              : reduxresponseFilter?.filterByCategory?.sub_category_id,
          brand: reduxresponseFilter.filterByBrand.brand,
          product_form: reduxresponseFilter.filterByProductForm.productForm,
          token: accessToken ? accessToken : null,
          is_prescription_mandatory: reduxresponseWithPres,
          is_prescriotion_not_mandatory: reduxresponseWithOutPres,
          page_no: pageNo,
          page_count: totalItemToBeFetched,
        })
        .then((res) => {
          if (res.data.length === 0) {
            setLoad(false);
          } else {
            dispatch(storeAllProducts(res.data.data));
            setTotalItems(res.data.total_count);
            setLoad(false);
          }
        })
        .catch((err) => {
          setLoad(false);
          toast.error(err.toString());
        });
    }
  };

  return (
    <>
      <Col xs="9" className="p-0 pb-3 border-line">
        {load ? (
          <SkeletonTheme color="#ccc" highlightColor="#fff">
            <Skeleton count={5} height={300} />
          </SkeletonTheme>
        ) : (
          <>
            {reduxresponse.allProducts?.length > 0 ? (
              reduxresponse.allProducts?.map((datas, index) => {
                return (
                  <div
                    className="prdct wow animate__animated animate__fadeIn"
                    key={index}
                  >
                    <Link to={"/product-details/" + datas.id}>
                      <div className="prdct-img">
                        {datas?.avg_rating > 0 ? (
                          <div className="prdct-rating">
                            <i
                              className="fa fa-star text-theme"
                              aria-hidden="true"
                            ></i>{" "}
                            {datas?.avg_rating}
                          </div>
                        ) : null}
                        <img
                          src={
                            datas.product_images[0] === ""
                              ? NoImg
                              : apiUrl + datas.product_images[0]
                          }
                          // src={NoImg}
                          alt={"prdct_img"}
                        // onLoad="javascript: alert('The image has been loaded')"
                        // onError="javascript: alert('The image has failed to load')"
                        />
                      </div>
                      <div className="prdct-txt d-flex flex-column">
                        <p className="p-0 m-0 mb-3 text-dark">
                          {datas.itemName}
                        </p>
                        {/* <p className="text-theme">QAR {datas.discounted_price}</p>
                    <span className="text-grey">QAR {datas.pricePerPcs}</span> */}
                        <div
                          className="w-100 d-flex flex-column"
                          style={{
                            position: "absolute",
                            bottom: 0,
                          }}
                        >
                          <small className="p-0 m-0 text-grey d-flex">
                            {Number(datas?.discounted_price_pcs) === 0 ? (
                              <span className="text-theme">
                                QAR {datas?.pricePerPcs}
                              </span>
                            ) : (
                              <>
                                <span className="text-theme">
                                  QAR {datas?.discounted_price_pcs}
                                </span>
                                <span
                                  style={{
                                    position: "relative",
                                    marginLeft: "6px",
                                  }}
                                >
                                  {datas?.pricePerPcs}
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      left: 0,
                                      background: "#F5832B",
                                      width: "100%",
                                      height: "2px",
                                    }}
                                  />
                                </span>
                              </>
                            )}
                          </small>
                        </div>
                      </div>
                    </Link>
                    <div className="add-to-cart pb-2 animate__animated animate__slideInUp animate__faster">
                      <ActionBtns
                        datas={datas}
                        setPrdctid={setPrdctid}
                        setShow={setShow}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="w-100 text-center mt-2">
                <img src={not_found} alt="not_found" />
                <h5 className="text-theme">No product found</h5>
              </div>
            )}
          </>
        )}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Addtowishlist prdctid={prdctid} action="add_wishlist" />
          </Modal.Body>
        </Modal>
      </Col>
    </>
  );
};

const ActionBtns = ({ datas, setPrdctid, setShow }) => {
  const dispatch = useDispatch();
  const accessToken = getUserToken();
  const location = useLocation();
  const navigate = useNavigate();
  const reduxselectItemUnit = useSelector(
    (state) => state.selectItemUnitReducer
  );
  const [AddtoCartTxt, setAddtoCartTxt] = useState("Add to Cart");
  const [addtocomparetxt, setaddtocomparetxt] = useState("Add to compare");

  const add_to_cart = async (e) => {
    setAddtoCartTxt("Adding...");
    const id = e.target.getAttribute("data-prdctid");
    const data = {
      product_id: id,
      quantity: 1,
      unit: reduxselectItemUnit.unittype,
    };
    await dispatch(addToCart(data));
    setAddtoCartTxt("Add");
  };

  const add_to_wishlist = async (e) => {
    if (accessToken) {
      const id = e.target.getAttribute("data-prdctid");
      setPrdctid(id);
      setShow(true);
    } else {
      localStorage.setItem("redirect_url", location.pathname);
      navigate("/auth/login");
    }
  };

  return (
    <div className="px-3">
      <div className="w-100 d-flex align-items-center">
        {/* add to wishlist */}
        {!datas.prescription_required ? (
          <div className="w-25 mx-2 d-flex justify-content-center">
            {datas.wishlist_status ? (
              <button className="btn mt-2 w-100 add-to-wishlist-btn border border-dark">
                <i
                  className="fa fa-heart text-theme"
                  data-prdctid={datas.id}
                ></i>
              </button>
            ) : (
              <button
                className="btn text-dark border border-dark mt-2 w-100 add-to-wishlist-btn"
                data-prdctid={datas.id}
                onClick={add_to_wishlist}
              >
                <i className="fa fa-heart-o" data-prdctid={datas.id}></i>
              </button>
            )}
          </div>
        ) : null}
        {/* add to cart */}
        {datas.stock > 0 ? (
          datas.prescription_required ? (
            <div
              className={`${datas.prescription_required ? `w-100` : `w-75`
                } mx-2`}
            >
              <button
                className="btn bg-theme text-light mt-2 w-100"
                onClick={() => navigate("/upload-prescription")}
              >
                Upload Prescription
              </button>
            </div>
          ) : (
            <div className="w-75 mx-2">
              <button
                className="btn bg-theme text-light mt-2 w-100"
                data-prdctid={datas.id}
                onClick={add_to_cart}
                disabled={AddtoCartTxt === "Adding..."}
              >
                {AddtoCartTxt}
              </button>
            </div>
          )
        ) : (
          <div className="w-100 mx-2">
            <button className="btn border-theme text-theme mt-2 w-100" disabled>
              Out of Stock
            </button>
          </div>
        )}
      </div>

      <button
        className="btn border-theme text-theme mt-3 w-100"
        onClick={() => {
          setaddtocomparetxt("Adding...");
          compareProduct(datas);
        }}
      >
        {addtocomparetxt}
      </button>
    </div>
  );
};

export default Prdcts;
