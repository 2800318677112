import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../../../../redux/action/action";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getUserToken } from "../../../../app/userToken";

const Order = () => {
  const accessToken = getUserToken();
  const { id } = useParams();
  const [alldata, setalldata] = useState("");

  useEffect(() => {
    axios
      .get(apiUrl + "/order/get_prescription_order_data/" + id, {
        headers: {
          Authorization: "Token " + accessToken,
        },
      })
      .then((res) => {
        setalldata(res.data.data);
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === "400") {
          toast.warning(message);
          window.location = "/"
        } else {
          toast.error(message);
        }
      });
  }, []);

  return (
    <>
      <div className="my-account">
        <div className="my-account-title mb-3 d-flex">
          <div className="w-50">
            <h4 className="p-0 m-0">
              My Prescription Orders ({alldata?.length})
            </h4>
          </div>
        </div>

        <div className="my-order-items mt-4">
          {alldata === "" ? (
            <SkeletonTheme color="#ccc" highlightColor="#fff">
              <Skeleton count={2} height={300} />
            </SkeletonTheme>
          ) : (
            <>
              {alldata.length === 0 ? (
                <p className="text-center mt-5">No Order Placed Yet</p>
              ) : (
                alldata.map((data, index) => {
                  return (
                    <Orderitemcard sts="shipped" data={data} key={index} />
                  );
                })
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const Orderitemcard = ({ data }) => {
  let [OderDetailsUrl, setOderDetailsUrl] = useState("");
  const [orderPlacedDate, setOrderPlacedDate] = useState("");

  useEffect(() => {
    if (window.innerWidth > 768) {
      setOderDetailsUrl("/account/prescription/order/details");
    } else {
      setOderDetailsUrl("/profile/prescription/order/details");
    }
  });

  useEffect(() => {
    const date = new Date(data?.orderdate);
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    setOrderPlacedDate(day + " " + months[month - 1] + " " + year);
  }, []);

  return (
    <>
      <div className="w-100 border rounded overflow-hidden mt-2 mt-md-0">
        <div className="Orderitemcard-header">
          <div className="Orderitemcard-header-left">
            <div>
              {/* order date */}
              <p>Order Placed - {orderPlacedDate}</p>
            </div>
            <div className="border-left">
              {/* order price */}
              <p>Total Order Amount - QAR {data?.total_amount}</p>
            </div>
          </div>

          <div className="Orderitemcard-header-right">
            <div>
              {/* order id */}
              <p>Id : # {data?.id}</p>
            </div>
            <div className="border-left">
              {/* download invoice */}
              <button disabled>Download Invoice</button>
            </div>
          </div>
        </div>
        <Orderitemsingleproduct data={data} orderdetailurl={OderDetailsUrl} />
      </div>
    </>
  );
};

const Orderitemsingleproduct = ({ data, orderdetailurl }) => {
  const navigate = useNavigate();
  const onClickViewDetails = () => {
    navigate(orderdetailurl + "/" + data?.id);
  };

  return (
    <>
      <div className="Orderitemsingleproduct border-top">
        <div className="w-100 mx-2 mt-3">
          <h6 className="p-0 m-0">{data?.product_data?.item_name}</h6>
        </div>

        <div className="w-100 variety d-flex mt-1 text-grey">
          <div>
            <p>Quantity : {data?.product_data?.quantity}</p>
          </div>
        </div>

        <div className="w-100 mt-2 btns">
          <button className="border-dark w-100" onClick={onClickViewDetails}>
            View Details
          </button>
        </div>
      </div>
    </>
  );
};

export default Order;
