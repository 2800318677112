import { Container, Row, Col } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./Accounts.css";
import { useDispatch } from "react-redux";
// import components
import Navbar from "../../Header/Navbar/Navbar";
import AccountOptions from "./AccountOptions/AccountOptions";
import { useNavigate } from "react-router-dom";
import { getUserToken } from "../../app/userToken";

const Accounts = () => {
  const accessToken = getUserToken();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(async () => {
    localStorage.removeItem("discount_coupon_id");
    localStorage.removeItem("total_loyalty_points_deducted");
    localStorage.removeItem("discount_coupon_code");
    if (!accessToken) {
      localStorage.setItem("redirect_url", location.pathname);
      navigate("/auth/login");
    }

    const path = location.pathname.split("/");
    if (window.innerWidth < 1000 && path[1] === "account") {
      window.location = location.pathname.replace("account", "profile");
    }

    // clear all filter
    dispatch({
      type: "CLEAR_FILTER_BY_CATEGORY",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_BRAND",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_PRODUCT_FORM",
    });

    localStorage.removeItem("keywordSearch");
  }, []);

  return (
    <>
      <Navbar />
      <Container className="accounts-desktop">
        <Row style={{ minHeight: "90vh" }}>
          <Col xs="3" className="border-left border-right p-0">
            <AccountOptions />
          </Col>

          <Col xs="9" className="pt-5 mt-3">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Accounts;
