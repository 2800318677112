import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl } from "../../redux/action/action";
import { getUserToken, removeUserToken } from "./userToken";

const accessToken = getUserToken();

export const compareProduct = (data) => {
  const compareitem = localStorage.getItem("compareitem");
  if (compareitem) {
    const localcompdata = JSON.parse(compareitem);
    if (accessToken) {
      axios
        .post(
          apiUrl + "/products/save_compared_products",
          {
            prod_id_1: localcompdata?.id,
            prod_id_2: data?.id,
          },
          {
            headers: {
              Authorization: "Token " + accessToken,
            },
          }
        )
        .then((res) => {
          window.location = "/compare";
        })
        .catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            toast.error(message);
            window.location = "/";
            removeUserToken();
          } else {
            toast.error(message);
          }
        });
    } else {
      localStorage.setItem("compareitem2", JSON.stringify(data));
      window.location = "/compare";
    }
  } else {
    localStorage.setItem("compareitem", JSON.stringify(data));
    window.location = "/compare";
  }
};
