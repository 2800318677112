import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Bottommenumob from "../../../../Footer/Bottommenumob/Bottommenumob";
import CartNavbarMob from "../../../../Header/CartNavbarMob/CartNavbarMob";
import Navbarmob from "../../../../Header/NavbarMob/Navbarmob";
import Orderdetails from "../../MyOrders/Orderdetails/Orderdetails";

const MobOrderDetails = () => {
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname.split("/");
    if (window.innerWidth > 1000 && path[1] === "profile") {
      window.location = location.pathname.replace("profile", "account");
    }
  }, []);
  return (
    <>
      <Navbarmob />
      <Orderdetails />
      <div className="mt-4" />
      <Bottommenumob />
    </>
  );
};

export default MobOrderDetails;
