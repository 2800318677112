import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Navbarmob.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LogoDesktop from "../../../assets/img/logo-dark.png";
import wallet_icon from "../../../assets/wallet-solid.svg";
import Geocode from "react-geocode";
import { getUserToken, removeUserToken } from "../../app/userToken";
import axios from "axios";
import { getUserDetails } from "../../app/userDetails";
import { apiUrl } from "../../../redux/action/action";
import { useDispatch } from "react-redux";
import { USER_DATA } from "../../../redux/state/state";
import Filtermob from "../../Section/Products/PrdctsectionMob/Filtermob/Filtermob";
import MobSearch from "../../Section/MobSearch/MobSearch";
import { toast } from "react-toastify";
Geocode.setApiKey("AIzaSyB-i8_omurLDIXbP2yI1v0Ry5MtHtd-FyU");
Geocode.enableDebug();

const Navbarmob = ({ topCategories }) => {
  const accessToken = getUserToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const reduxresponseTopCart = useSelector((state) => state.topBarCartCounter);
  const reduxcurrentcity = useSelector((state) => state.fetchCurrentCity);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [usename, setUsername] = useState(null);
  const [showSearchCmp, setShowSearchCmp] = useState(false);
  let static_menu = [
    {
      url: "/contact-us",
      icon: "fa fa-headphones",
      txt: "Contact Us",
    },
    // {
    //   url: "/privacy-policy",
    //   icon: "fa fa-legal",
    //   txt: "Privacy Policy",
    // },
    {
      url: "/cancellation-return-and-refund-policy",
      icon: "fa fa-exchange",
      txt: "Return Policy",
    },
    // {
    //   url: "/terms-conditions",
    //   icon: "fa fa-file-text",
    //   txt: "Terms & Conditions",
    // },
    // {
    //   url: "/editorial-policy",
    //   icon: "fa fa-file-text",
    //   txt: "Editorial Policy",
    // },
    // {
    //   url: "/intellectual-property-infringement-policy",
    //   icon: "fa fa-file-text",
    //   txt: "IP Policy",
    // },
    // {
    //   url: "/grievance-redressal-policy",
    //   icon: "fa fa-file-text",
    //   txt: "Grievances Redressal Policy",
    // },
    // {
    //   url: "help",
    //   icon: "fa fa-headphones",
    //   txt: "Help",
    // },
    // {
    //   url: "faq",
    //   icon: "fa fa-question-circle",
    //   txt: "FAQ’s",
    // },
  ];
  const [all_static_pages, setStaticPages] = useState(static_menu);

  useEffect(() => {
    if (accessToken && window.innerWidth < 768) {
      axios
        .get(apiUrl + "/user/customerDetails", {
          headers: {
            Authorization: "Token " + accessToken,
          },
        })
        .then((res) => {
          setUsername(getUserDetails(res.data.encrypted).name);
          dispatch({
            type: USER_DATA,
            payload: getUserDetails(res.data.encrypted),
          });
        }).catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            toast.error(message);
            window.location = "/";
            removeUserToken();
          } else {
            toast.error(message);
          }
        });
    }
  }, [accessToken]);

  const btncont = [
    {
      url: "/profile/my-order",
      icon: "fa fa-truck",
      txt: "Active Orders",
    },
    {
      url: "/profile/order-history",
      icon: "fa fa-history",
      txt: "Order History",
    },
    {
      url: "/profile/my-wallet",
      // icon: "fa fa-wallet",
      img: wallet_icon,
      txt: "My Wallet",
    },
    {
      url: "/profile/my-prescription",
      icon: "fa fa-file-text",
      txt: "My Prescriptions",
    },
    {
      url: "/profile/my-insurance",
      icon: "fa fa-shield",
      txt: "My Insurances",
    },
    {
      url: "/profile/saved-address",
      icon: "fa fa-map-marker",
      txt: "Saved Address",
    },
    {
      url: "/profile/my-wishlist",
      icon: "fa fa-heart",
      txt: "My Wishlist",
    },
    // {
    //   url: "/profile/saved-card",
    //   icon: "fa fa-credit-card",
    //   txt: "Saved Cards",
    // },
  ];


  const fetchAllStaticPages = () => {
    axios
      .get(apiUrl + "/master/get_static_page_list")
      .then((res) => {
        static_menu = [
          ...static_menu,
          ...res.data.data.map(({ page_slug, page_icon, page_title }) => ({
            url: '/policies/' + page_slug,
            icon: page_icon ? (page_icon.indexOf('fa') === 0 ? page_icon : 'fa fa-file-text') : 'fa fa-file-text',
            txt: page_title && page_title.toLowerCase(),
          }))
        ];
        setStaticPages(static_menu);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  useEffect(() => {
    if (window.innerWidth < 786)
      fetchAllStaticPages();
  }, [])

  return (
    <>
      <Container
        fluid
        className={`${location.pathname.match('/profile/') ? `navbar-mobile-tab` : `navbar-mobile`} border-bottom bottom-radius-20 sticky-top`}
      >
        <Row>
          <Col md={12} className="navbar-mob-top">
            {location.pathname === "/" || location.pathname.match("/status/order") ? (
              <i
                className="fa fa-bars"
                id="fa-bars"
                onClick={() => setShowHamburgerMenu(true)}
              ></i>
            ) : (
              <i
                className="fa fa-arrow-left text-dark ml-2"
                style={{ fontSize: "18px" }}
                onClick={() => {
                  if (location.pathname.match("/product/search/"))
                    navigate("/");
                  else navigate(-1);
                }}
              ></i>
            )}
            {location.pathname.match("/product/") ? (
              <>
                {/* <Link to="/mob/search"> */}
                <input
                  type="text"
                  name="keyinput"
                  className="form-control"
                  id="keyinput"
                  placeholder="Search for products..."
                  autoComplete="off"
                  style={{ width: "200px" }}
                  onClick={() => setShowSearchCmp(true)}
                />
                {/* </Link> */}
              </>
            ) : (
              <Link to="/">
                <img src={LogoDesktop} alt="logo" className="mobNavLogo" width={120} />
              </Link>
            )}
            <Link to="/cart" className="text-dark nav-cart-icon-mob">
              <i className="fa fa-shopping-cart"></i>
              {reduxresponseTopCart?.state?.data.length > 0 ? (
                <sup>{reduxresponseTopCart?.state?.data.length}</sup>
              ) : null}
            </Link>
          </Col>
          <Col md={12} className="p-0 m-0 my-1">
            {location.pathname === "/" ? (
              <div id="display-location">
                <p>{reduxcurrentcity?.city}</p>
                <i className="fa fa-map-marker text-grey"></i>
              </div>
            ) : (
              location.pathname.match("/product/") && <Filtermob />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {!(
              location.pathname.match("/profile/") ||
              location.pathname.match("/notification") ||
              location.pathname.match("/product/") ||
              location.pathname.match("/setaddress") ||
              location.pathname.match("/contact-us") ||
              location.pathname.match("/cancellation-return-and-refund-policy") ||
              location.pathname.match("/terms-conditions") ||
              location.pathname.match("/editorial-policy") ||
              location.pathname.match("/intellectual-property-infringement-policy") ||
              location.pathname.match("/grievance-redressal-policy") ||
              location.pathname.match("/about-us") ||
              location.pathname.match("/faq") ||
              location.pathname.match("/profile") ||
              location.pathname.match("/policies/")
            ) && (
                <>
                  {/* <Link to="/mob/search"> */}
                  <div id="seach-box">
                    <div className="input-group">
                      <div className="input-group-prepend bg-light">
                        <span className="input-group-text">
                          <i className="fa fa-search text-grey"></i>
                        </span>
                      </div>

                      <input
                        type="text"
                        name="keyinput"
                        className="form-control"
                        id="keyinput"
                        placeholder="Search for products, brands and more"
                        autoComplete="off"
                        onClick={() => setShowSearchCmp(true)}
                      />
                    </div>
                  </div>
                  {/* </Link> */}
                </>
              )}
          </Col>
        </Row>

        <MobSearch
          showSearchCmp={showSearchCmp}
          setShowSearchCmp={setShowSearchCmp}
        />

        {/* hamburger menu */}
        {showHamburgerMenu ? (
          <div className="hamburger-menu d-flex" id="hamburger-menu">
            <div className="menu-side animate__animated animate__slideInLeft animate__faster">
              <div
                className="hamburger-account border-bottom pt-3"
                onClick={() => {
                  if (accessToken) navigate("/profile/my-account");
                  else navigate("auth/login");
                }}
              >
                <i className="fa fa-user-circle"></i>
                <div className="px-2 w-100 d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    {accessToken ? (
                      <>
                        <p
                          className="p-0 m-0"
                          style={{
                            width: "110px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Hi, {usename}
                        </p>
                        <small className="text-grey">view profile</small>
                      </>
                    ) : (
                      <>
                        <p className="p-0 m-0">Hi, there!</p>
                        <small className="text-grey">login / sign up</small>
                      </>
                    )}
                  </div>
                  <div>
                    <i
                      className="fa fa-angle-right text-grey"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              </div>

              <div className="w-100 mt-3">
                <ul class="list-group">
                  {topCategories?.map((data, index) => {
                    return (
                      <Link
                        to={`product/category/${data.name}-${data.id}`}
                        className="w-100"
                        key={index}
                      >
                        <li
                          class="list-group-item"
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <img src={data.image} width="17" />
                          &nbsp; {data.name}
                        </li>
                      </Link>
                    );
                  })}
                  <hr />
                  {accessToken ? (
                    <>
                      {btncont.map((data, index) => {
                        return (
                          <Link to={data.url} className="w-100" key={index}>
                            <li class="list-group-item">
                              {data.img ? (
                                <img src={data.img} width="17" />
                              ) : (
                                <i className={data.icon}></i>
                              )}
                              &nbsp; {data.txt}
                            </li>
                          </Link>
                        );
                      })}
                      <hr />
                    </>
                  ) : null}

                  {all_static_pages.map((data, index) => {
                    return (
                      <Link to={data.url} className="w-100" key={index}>
                        <li class="list-group-item" style={{ textTransform: 'capitalize' }}>
                          {data.img ? (
                            <img src={data.img} width="17" />
                          ) : (
                            <i className={data.icon}></i>
                          )}
                          &nbsp; {data.txt}
                        </li>
                      </Link>
                    );
                  })}

                  {accessToken ? (
                    <>
                      <hr />
                      <Link to={"/logout"} className="w-100">
                        <li class="list-group-item">
                          <i className="fa fa-sign-out"></i>
                          &nbsp; {"Logout"}
                        </li>
                      </Link>
                    </>
                  ) : null}
                </ul>
              </div>
            </div>
            <div
              className="action-side"
              onClick={() => setShowHamburgerMenu(false)}
            ></div>
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default Navbarmob;
