import { Container, Row, Col } from "react-bootstrap";
import "./Individualproduct.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { apiUrl } from "../../../redux/action/action";
import { useEffect, useState } from "react";
import Navbar from "../../Header/Navbar/Navbar";
import Footer from "../../Footer/Footer";
import Prdctcarousel from "./Prdctcarousel/Prdctcarousel";
import Prdctdetail from "./Prdctdetail/Prdctdetail";
import Prdctpricedetail from "./Prdctpricedetail/Prdctpricedetail";
import Prdcthighlights from "./Prdcthighlights/Prdcthighlights";
import Customerreview from "./Customerreview/Customerreview";
import Other from "../Home/Others/Other";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import Otherdetails from "./Otherdetails/Otherdetails";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import SuggetionPrdct from "../SuggetionPrdct/SuggetionPrdct";
import { toast } from "react-toastify";
import Topprdctsoldmobile from "../Home/Topprdctsold/Topprdctsoldmobile/Topprdctsoldmobile";
import { getUserToken } from "../../app/userToken";
import Bottommenumob from "../../Footer/Bottommenumob/Bottommenumob";
import Breadcrumbs from "./Breadcrumbs";

const Individualproduct = () => {
  const accessToken = getUserToken();
  const navigate = useNavigate();
  const [SingleProductData, setSingleProductData] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loader, setloader] = useState(true);
  const [relatedprdct, setRelatedprdct] = useState([]);
  const reduxresponseFilter = useSelector((state) => state);

  useEffect(() => {
    axios
      .get(`${apiUrl}/products/get_single_product/${id}?token=${accessToken}`)
      .then((res) => {
        if (res.data.status === 1) {
          setSingleProductData(res.data.data);
          setloader(false);
        }
      });
    window.scrollTo(0, 0);
    // clear all filter
    dispatch({
      type: "CLEAR_FILTER_BY_CATEGORY",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_BRAND",
    });
    dispatch({
      type: "CLEAR_FILTER_BY_PRODUCT_FORM",
    });
  }, [reduxresponseFilter.wishlistReducer, id]);

  useEffect(() => {
    fetchReatedPrdct(id);
  }, []);

  const fetchReatedPrdct = (id) => {
    axios
      .get(apiUrl + "/products/related_products/" + id)
      .then((res) => {
        setRelatedprdct(res.data.data);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  return (
    <>
      {SingleProductData === 0 ? (
        <>{navigate("/product/all")}</>
      ) : (
        <>
          <Navbar />
          <Navbarmob />
          <Container
            fluid
            className="px-4 px-md-5 body-white pt-2 pt-md-3 mt-1"
          >
            <Breadcrumbs data={SingleProductData} />
            <Row className="show-ind-prdct animate__animated animate__fadeIn px-md-4 px-2">
              <Col md="3" className="p-0 m-0">
                {loader ? (
                  <SkeletonTheme
                    color="#ccc"
                    highlightColor="#fff"
                    width={"100%"}
                  >
                    <Skeleton count={1} height={"400px"} />
                  </SkeletonTheme>
                ) : (
                  <>
                    {SingleProductData ? (
                      <Prdctcarousel data={SingleProductData} loader={false} />
                    ) : null}
                  </>
                )}
              </Col>
              <Col md="5" className="p-0 m-0 p-md-3">
                {loader ? (
                  <SkeletonTheme
                    color="#ccc"
                    highlightColor="#fff"
                    width={"100%"}
                  >
                    <Skeleton count={1} height={"30px"} />
                    <Skeleton
                      count={1}
                      height={"25px"}
                      width={"100px"}
                      className="mt-3"
                    />
                    <hr />
                    <Skeleton count={1} height={"27px"} width={"100px"} />
                    <Skeleton count={1} height={"27px"} width={"300px"} />
                    <Skeleton
                      count={1}
                      height={"27px"}
                      width={"100px"}
                      className="mt-3"
                    />
                    <Skeleton count={1} height={"27px"} width={"300px"} />
                  </SkeletonTheme>
                ) : (
                  <>
                    <Prdctdetail data={SingleProductData} />
                  </>
                )}
              </Col>

              <Col md="4" className="p-0 m-0 p-md-3">
                <Prdctpricedetail data={SingleProductData} loader={loader} />
              </Col>
            </Row>
            <Row className="wow animate__animated animate__fadeIn py-3 px-md-4 px-2 mt-md-4">
              <Prdcthighlights data={SingleProductData} />
              <Otherdetails data={SingleProductData} />
            </Row>
          </Container>

          <Container
            fluid
            className="px-4 px-md-5 body-white pt-2 pt-md-5 mt-1"
          >
            <Row className="wow animate__animated animate__fadeIn py-4 px-md-0">
              <Customerreview />
            </Row>

            {/* Similar Products */}
            {relatedprdct?.length === 0 ? null : (
              <SuggetionPrdct
                data={relatedprdct}
                title="Related Products"
                bgcolor=""
                class="similar-product"
              />
            )}

            {/* <Topprdctsoldmobile data={relatedprdct} /> */}

            <Other />
          </Container>
          <div className="py-4 mt-4" />
          <Bottommenumob />
          <Footer />
        </>
      )}
    </>
  );
};
export default Individualproduct;
