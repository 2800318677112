import { Container, Row, Col } from "react-bootstrap";

import CartNavbar from "../../../Header/CartNavbar/CartNavbar";
import Footer from "../../../Footer/Footer";
import AvailableOffer from "../../../Section/AvailableOffer/AvailableOffer";
import Other from "../../Home/Others/Other";
import Cartpricedetails from "../../Cart/CartDesktop/Cartpricedetails/Cartpricedetails";
import PaymentOption from "../PaymentOption/PaymentOption";

const PaymentDesktop = () => {
  const trackerColor = {
    cart: "text-theme",
    address: "text-theme",
    payment: "text-theme",
    firstLine: "c-w-25 border-top-tracker-active",
    secondLine: "c-w-25 border-top-tracker-active",
  };

  return (
    <>
      <CartNavbar data={trackerColor} />
      <Container className="mt-5">
        <Row className="mb-5 body-color">
          <Col xs="7">
            <PaymentOption />
          </Col>
          <Col xs="1"></Col>
          <Col xs="4">
            <Cartpricedetails />
          </Col>
        </Row>
        <Other />
      </Container>
      <Footer />
    </>
  );
};

export default PaymentDesktop;
