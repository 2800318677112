import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchAllAddress } from "../../../../redux/action/address.action";
import { useState } from "react";
import "./SetAddressDesktop.css";
import CartNavbar from "../../../Header/CartNavbar/CartNavbar";
import Footer from "../../../Footer/Footer";
import Cartpricedetails from "../../Cart/CartDesktop/Cartpricedetails/Cartpricedetails";
import AddressCardView from "./AddressCardView/AddressCardView";
import { getUserToken } from "../../../app/userToken";
import AddAddressFormCmp from "./AddAddress/AddAddressFormCmp";

const SetAddressDesktop = () => {
  const accessToken = getUserToken();
  const navigate = useNavigate();
  const reduxresponse = useSelector((state) => state.addressReducer);
  const reduxresponsefetchaddressReducer = useSelector(
    (state) => state.fetchaddressReducer
  );
  const dispatch = useDispatch();

  const [radioId, setRadioId] = useState("");

  useEffect(() => {
    dispatch(fetchAllAddress());
  }, [reduxresponse.message]);

  const trackerColor = {
    cart: "text-theme",
    address: "text-theme",
    payment: "tracker-default",
    firstLine: "c-w-25 border-top-tracker-active",
    secondLine: "c-w-25 border-top-tracker-default",
  };

  return (
    <>
      <CartNavbar data={trackerColor} />
      <Container className="mt-5">
        <Row className="mb-5 body-color">
          <Col xs="7">
            <Row className="body-color">
              <Col className="select-address-title">
                <h4>{accessToken ? 'Select Delivery Address' : 'Checkout as Guest'}</h4>
                {/* <button onClick={add_new_address}>Add New Address</button> */}
                {accessToken && (
                  <button onClick={() => navigate("/address/add/checkout")}>
                    Add New Address
                  </button>
                )}
              </Col>

              {!accessToken ? <Col className="d-flex justify-content-end">
                <p>
                  Already have an account?{" "}
                  <Link
                    to="/auth/login"
                    onClick={() => {
                      localStorage.setItem("redirect_url", "/setaddress");
                    }}
                  >
                    Login
                  </Link>
                </p>
              </Col> : null}
            </Row>

            {accessToken ? (
              reduxresponsefetchaddressReducer.data.length === 0 ? (
                <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-5">
                  <img
                    src="/img/icons8-address.png"
                    alt="add-address"
                    width={"150px"}
                  />
                  <h4
                    className="p-0 m-0 text-theme mt-3"
                    style={{ fontSize: "18px" }}
                  >
                    Add an address before checkout
                  </h4>
                </div>
              ) : (
                <form>
                  {reduxresponsefetchaddressReducer.data.map(
                    (address, index) => {
                      return (
                        <AddressCardView
                          data={address}
                          radioId={radioId}
                          setRadioId={setRadioId}
                          index={index}
                          key={index}
                        />
                      );
                    }
                  )}
                </form>
              )
            ) : (
              <div className="p-5 body-white mt-3">
                <AddAddressFormCmp
                  address={
                    sessionStorage.getItem("guest_address")
                      ? JSON.parse(sessionStorage.getItem("guest_address"))
                      : null
                  }
                />
              </div>
            )}
          </Col>

          <Col xs="1"></Col>
          <Col xs="4">
            <Cartpricedetails />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default SetAddressDesktop;
