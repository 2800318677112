import {
  ADD_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_ADDRESS,
  FETCH_ALL_ADDRESS,
  CURRENT_CITY,
} from "../state/state";

const initialaddress = {
  message: "",
  data: [],
};

const addressReducer = (state = initialaddress, action) => {
  switch (action.type) {
    case ADD_ADDRESS:
      return {
        message: action.payload,
      };
    case DELETE_ADDRESS:
      return {
        message: action.payload,
      };
    case UPDATE_ADDRESS:
      return {
        message: action.payload,
      };
    default:
      return state;
  }
};

const fetchaddressReducer = (state = initialaddress, action) => {
  switch (action.type) {
    case FETCH_ALL_ADDRESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

const fetchCurrentCity = (state = { city: "Qatar" }, action) => {
  switch (action.type) {
    case CURRENT_CITY:
      return {
        city: action.payload,
      };
    default:
      return state;
  }
};

export { addressReducer, fetchaddressReducer, fetchCurrentCity };
