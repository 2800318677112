import { useState } from "react";
import { useDispatch } from "react-redux";
import { SELECT_UNIT } from "../../../../redux/state/state";
import "../Prdctpricedetail/Prdctdetails.css";

const Prdctdetail = ({ data }) => {
  const dispatch = useDispatch();
  const [checkUnit, setCheckUnit] = useState(data.pcsUnit);


  const onClickPackSizeBtn = (e) => {
    const unit = e.target.getAttribute("unit");
    const unittype = e.target.getAttribute("unittype");
    setCheckUnit(unit);
    dispatch({
      type: SELECT_UNIT,
      payload: {
        unit,
        unittype,
      },
    });
  };

  const AttributeList = (obj) => {
    if (Object.keys(obj).length > 0) {
      return Object.entries(obj).map((e) => (
        <div className="mt-3">
          <h6 className="p-0 m-0 mb-2">{e[0]}</h6>
          {
            e[1]?.map((e) => (
              <a href={"/product-details/" + e.prod_id}>
                <button className='active-btn'>
                  {e.value}
                </button>
              </a>
            ))
          }
        </div>
      ))
    }
  }

  return (
    <>
      <div className="w-100 prdctdetails mt-md-0 mt-4">
        <div className="w-100">
          <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
            {data ? data.itemName : null}
          </h5>
        </div>
        <br />
        {data?.avg_rating > 0 ? (
          <div className="prdctdetails-rating mb-3">
            <div>
              <i className="fa fa-star text-theme"></i>
              &nbsp;
            </div>
            <div className="pr-1">{data?.avg_rating}</div>
            {/* <div className="border-left pl-1">400</div> */}
          </div>
        ) : null}

        <div className="w-100 variant border-top">
          {/* <div className="w-100 mt-3">
            <h6 className="p-0 m-0 mb-2">Flavour (2)</h6>
            <button className="active-btn">Tasty Chocolate</button>
            <button className="inactive-btn">Vanila</button>
          </div> */}
          <div className="w-100 mt-3">
            <h6 className="p-0 m-0 mb-2">
              Pack Size ({data.pricePerPcs === data.pricePerPack ? 1 : 2})
            </h6>
            <button
              className={
                checkUnit === data.pcsUnit ? "active-btn" : "inactive-btn"
              }
              onClick={onClickPackSizeBtn}
              unit={data.pcsUnit}
              unittype="pcs"
            >
              {data.pcsUnit} - QAR {data.pricePerPcs}
            </button>
            {data.pricePerPcs === data.pricePerPack ? null : (
              <button
                className={
                  checkUnit === data.packUnit ? "active-btn" : "inactive-btn"
                }
                onClick={onClickPackSizeBtn}
                unit={data.packUnit}
                unittype="pack"
              >
                {data.packUnit} - QAR {data.pricePerPack}
              </button>
            )}
          </div>

          {
            data?.attribute_list && AttributeList(data?.attribute_list)
          }

          {data?.shortDescription ? (
            <div className="w-100 mt-3">
              <h6 className="p-0 m-0 mb-2">Product Highlights</h6>

              <small className="text-grey">
                <div
                  dangerouslySetInnerHTML={{ __html: data?.shortDescription }}
                />
              </small>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Prdctdetail;
