import {
  ALL_PRODUCTS,
  ADD_FILTER_BY_CATEGORY,
  ADD_TO_CART,
  DISPLAY_CART,
  DISPLAY_CART_POPUP,
  CART_COUNT,
  STORE_ALL_CATEGORIES,
  PUSH_SELECTED_CATEGORY,
  POP_SELECTED_CATEGORY,
  RESET_SUB_CATEGORY,
} from "../state/state";

import axios from "axios";
import { toast } from "react-toastify";
import { getUserToken } from "../../pages/app/userToken";
import { GetDeviceId } from "../../pages/app/GetDeviceId";

export const apiUrl = "https://be.medpack.qa";
// export const apiUrl = "https://4labs.be.medpack.helloapps.io";

// for product page
// for all products
const storeAllProducts = (data) => {
  return {
    type: ALL_PRODUCTS,
    payload: data,
  };
};

// for filter by category
const filterByCategory = (data) => {
  return {
    type: ADD_FILTER_BY_CATEGORY,
    payload: data,
  };
};

const setAllCategoryList = (all) => {
  return {
    type: STORE_ALL_CATEGORIES,
    payload: all,
  };
};

const pushSelectedSubCategoryList = (data) => {
  return {
    type: PUSH_SELECTED_CATEGORY,
    payload: data,
  };
};

const popSelectedSubCategoryList = () => {
  return {
    type: POP_SELECTED_CATEGORY,
  };
};

const resetSubCategoryList = () => {
  return {
    type: RESET_SUB_CATEGORY,
  };
};

// for cart
const addToCart = (data) => {
  return async (dispatch) => {
    if (getUserToken()) {
      try {
        const result = await axios.post(
          apiUrl + "/order/cart_data",
          {
            customer_id: data.customer_id,
            product_id: data.product_id,
            quantity: data.quantity,
            unit: data.unit,
          },
          {
            headers: {
              Authorization: "Token " + getUserToken(),
            },
          }
        );

        if (result.data.status === 1) {
          dispatch(displayTopBarCart());
          if (window.innerWidth < 768) {
            toast.success(result.data.message);
          }
          dispatch({
            type: ADD_TO_CART,
            payload: result.data,
          });
          dispatch({
            type: DISPLAY_CART_POPUP,
            payload: true,
          });
          setTimeout(() => {
            dispatch({
              type: DISPLAY_CART_POPUP,
              payload: false,
            });
          }, 4000);
        } else {
          toast.warning(result.data.message);
        }
      } catch (err) {
        const { response } = err;
        const { data } = response;
        const { status, message } = data;
        if (status === 400) {
          toast.warning(message);
        } else {
          toast.error(err.toString());
        }
      }
    } else {
      const deviceId = await GetDeviceId();
      axios
        .post(apiUrl + "/order/add_guest_cart_data", {
          unique_id: deviceId,
          product_id: data.product_id,
          quantity: data.quantity,
          unit: data.unit,
        })
        .then((res) => {
          const { data } = res;
          const { status, message } = data;
          dispatch(displayTopBarCart());
          if (status === 1 || status === 200) {
            if (window.innerWidth < 768) {
              toast.success(message);
            }
            dispatch({
              type: ADD_TO_CART,
              payload: data,
            });
            dispatch({
              type: DISPLAY_CART_POPUP,
              payload: true,
            });
            setTimeout(() => {
              dispatch({
                type: DISPLAY_CART_POPUP,
                payload: false,
              });
            }, 4000);
          } else {
            toast.warning(message);
          }
        })
        .catch((err) => {
          const { response } = err;
          const { data } = response;
          const { status, message } = data;
          if (status === 400) {
            toast.warning(message);
          } else {
            toast.error(message);
          }
        });
    }
  };
};
// fetch all cart item
const displayCart = () => {
  return async (dispatch) => {
    if (getUserToken()) {
      let url = apiUrl + "/order/get_cart_data";
      if (localStorage.getItem("discount_coupon_id")) {
        url +=
          "?discount_coupon_id=" + localStorage.getItem("discount_coupon_id");
      } else if (localStorage.getItem("discount_coupon_code")) {
        url +=
          "?discount_coupon_code=" +
          localStorage.getItem("discount_coupon_code");
      }

      try {
        const viewcart = await axios.get(url, {
          headers: {
            Authorization: "Token " + getUserToken(),
          },
        });
        if (viewcart.data.status === 1) {
          dispatch({
            type: DISPLAY_CART,
            payload: viewcart.data,
          });
        }
      } catch (err) {
        try {
          toast.error(err.response.data.message);
        } catch (e) {
          toast.error(err.toString());
        }
      }
    } else {
      const deviceId = await GetDeviceId();
      axios
        .get(apiUrl + "/order/get_guest_cart_data?unique_id=" + deviceId)
        .then((res) => {
          dispatch({
            type: DISPLAY_CART,
            payload: res.data,
          });
        });
    }
  };
};

// fetch top bar cart item
const displayTopBarCart = () => {
  return async (dispatch) => {
    if (getUserToken()) {
      axios
        .get(apiUrl + "/order/header_cart_api", {
          headers: {
            Authorization: "Token " + getUserToken(),
          },
        })
        .then((res) => {
          dispatch({
            type: CART_COUNT,
            payload: res.data,
          });
        });
    } else {
      const deviceId = await GetDeviceId();
      axios
        .get(apiUrl + "/order/guest_cart_header?unique_id=" + deviceId)
        .then((res) => {
          dispatch({
            type: CART_COUNT,
            payload: res.data,
          });
        });
    }
  };
};

export {
  storeAllProducts,
  filterByCategory,
  addToCart,
  displayCart,
  displayTopBarCart,
  setAllCategoryList,
  pushSelectedSubCategoryList,
  popSelectedSubCategoryList,
  resetSubCategoryList,
};
