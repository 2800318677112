import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { apiUrl } from "../../../redux/action/action";
import { getUserToken } from "../../app/userToken";
import Bottommenumob from "../../Footer/Bottommenumob/Bottommenumob";
import Footer from "../../Footer/Footer";
import Navbar from "../../Header/Navbar/Navbar";
import Navbarmob from "../../Header/NavbarMob/Navbarmob";
import SuggetionPrdct from "../SuggetionPrdct/SuggetionPrdct";
import ItemCompareTable from "./ItemCompareTable";

const CompareProduct = () => {
  const [prdct1, setPrdct1] = useState(null);
  const [prdct2, setPrdct2] = useState(null);
  const [relatedprdct, setRelatedprdct] = useState([]);

  useEffect(() => {
    if (getUserToken()) {
      GetComparedProduct();
      fetchReatedPrdct();
    } else {
      const compareitem = localStorage.getItem("compareitem2");
      setPrdct2(JSON.parse(compareitem));
    }
  }, []);

  useEffect(() => {
    const compareitem = localStorage.getItem("compareitem");
    setPrdct1(JSON.parse(compareitem));
  }, []);

  const GetComparedProduct = () => {
    axios
      .get(apiUrl + "/products/get_compared_products", {
        headers: {
          Authorization: "Token " + getUserToken(),
        },
      })
      .then((res) => {
        res.data.data.prod_1_data && setPrdct1(res.data.data.prod_1_data);
        res.data.data.prod_2_data && setPrdct2(res.data.data.prod_2_data);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  const fetchReatedPrdct = () => {
    axios
      .get(apiUrl + "/products/get_recently_compared_list", {
        headers: {
          Authorization: "Token " + getUserToken(),
        },
      })
      .then((res) => {
        setRelatedprdct(res.data.data);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  return (
    <>
      <Navbar />
      <Navbarmob />

      <ItemCompareTable
        prdct1={prdct1}
        prdct2={prdct2}
        setPrdct1={setPrdct1}
        setPrdct2={setPrdct2}
      />
      <Container fluid className="px-4 px-md-5 body-white pt-2 pt-md-5 mt-1">
        {relatedprdct?.length === 0 ? null : (
          <SuggetionPrdct
            data={relatedprdct}
            title="Recently Compared Product"
            bgcolor=""
            class="recently-compared-product"
          />
        )}
      </Container>
      <Bottommenumob />
      <Footer />
    </>
  );
};

export default CompareProduct;
