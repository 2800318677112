const NUMBER = "NUMBER"; // this for phone number
const ALL_PRODUCTS = "ALL_PRODUCTS"; // this for all products fetch from api
const LOADER = "LOADER"; // this for loader
const SORT = "SORT";
const IS_PRESCRIPTION = "IS_PRESCRIPTION";
const IS_NOT_PRESCRIPTION = "IS_NOT_PRESCRIPTION";


// filter products state
const ADD_FILTER_BY_CATEGORY = "ADD_FILTER_BY_CATEGORY";
const ADD_FILTER_BY_BRAND = "ADD_FILTER_BY_BRAND";
const ADD_FILTER_BY_PRODUCT_FORM = "ADD_FILTER_BY_PRODUCT_FORM";
const ADD_FILTER_BY_AGE_GROUP = "ADD_FILTER_BY_AGE_GROUP";

// for all cart action
const ADD_TO_CART = "ADD_TO_CART";
const DISPLAY_CART = "DISPLAY_CART";
const COUNT_QUANTITY = "COUNT_QUANTITY";
const DISPLAY_CART_POPUP = "DISPLAY_CART_POPUP";
// for address action
const ADD_ADDRESS = "ADD_ADDRESS";
const UPDATE_ADDRESS = "UPDATE_ADDRESS";
const FETCH_ALL_ADDRESS = "FETCH_ALL_ADDRESS";
const DELETE_ADDRESS = "DELETE_ADDRESS";

//payment
const PAY_ON_DELIVERY = "PAY_ON_DELIVERY";
const CARD_SAVED = "CARD_SAVED";
const CARD_WITHOUT_SAVED = "CARD_WITHOUT_SAVED";

//wishlist
const DISPLAY_WISHLIST = "DISPLAY_WISHLIST";

//select unit
const SELECT_UNIT = "SELECT_UNIT";

//user
const USER_DATA = "USER_DATA";

//location
const CURRENT_CITY = "CURRENT_CITY";

const CART_COUNT = "CART_COUNT";

const STORE_ALL_CATEGORIES = "STORE_ALL_CATEGORIES";

const PUSH_SELECTED_CATEGORY = "PUSH_SELECTED_CATEGORY";

const POP_SELECTED_CATEGORY = "POP_SELECTED_CATEGORY";

const RESET_SUB_CATEGORY = "RESET_SUB_CATEGORY";

export {
  STORE_ALL_CATEGORIES,
  PUSH_SELECTED_CATEGORY,
  RESET_SUB_CATEGORY,
  POP_SELECTED_CATEGORY,
  NUMBER,
  ALL_PRODUCTS,
  LOADER,
  ADD_FILTER_BY_CATEGORY,
  ADD_FILTER_BY_BRAND,
  ADD_FILTER_BY_PRODUCT_FORM,
  ADD_FILTER_BY_AGE_GROUP,
  ADD_TO_CART,
  DISPLAY_CART,
  ADD_ADDRESS,
  COUNT_QUANTITY,
  DISPLAY_CART_POPUP,
  UPDATE_ADDRESS,
  FETCH_ALL_ADDRESS,
  DELETE_ADDRESS,
  SORT,
  PAY_ON_DELIVERY,
  CARD_SAVED,
  CARD_WITHOUT_SAVED,
  DISPLAY_WISHLIST,
  SELECT_UNIT,
  USER_DATA,
  CURRENT_CITY,
  CART_COUNT,
  IS_PRESCRIPTION,
  IS_NOT_PRESCRIPTION,
};
