import { Col, Container, Row } from "react-bootstrap";
import payment_success_tick from "../../../assets/payment_success_tick.gif";
import payment_failed_x from "../../../assets/red-x-line.svg";
import "./Style.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const Payment = () => {
  const { status } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (status !== "success" && status !== "failed") {
      navigate(localStorage.getItem("redirect_url"));
    } else if (status === "success") {
      localStorage.removeItem("selectedAddress_id");
      localStorage.removeItem("discount_coupon_id");
      localStorage.removeItem("discount_coupon_code");
      localStorage.removeItem("total_loyalty_points_deducted");
      setTimeout(() => {
        navigate("/status/order");
      }, 3000);
    } else {
      setTimeout(() => {
        navigate(localStorage.getItem("redirect_url"));
      }, 3000);
    }
  }, [status, navigate]);

  return (
    <>
      <Container fluid style={{ height: "100vh" }} className="body-white">
        <Row>
          <Col className="payment-success">
            {status === "success" ? (
              <>
                <img src={payment_success_tick} alt="payment_success_tick" />
                <h2 className="p-0 m-0 text-theme">Payment Successful</h2>
              </>
            ) : null}
            {status === "failed" ? (
              <>
                <img
                  src={payment_failed_x}
                  className="animate__animated animate__heartBeat animate__infinite"
                  alt="payment_failed_x"
                />
                <h2 className="p-0 m-0 text-theme mt-3">Payment Failed</h2>
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Payment;
