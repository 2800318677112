import React from "react";

import Navbar from "../../Header/Navbar/Navbar";
import Footer from "../../Footer/Footer";

import "./404.css";
import img_404 from "../../../assets/404.png"; 

function Notfound() {

    return (
        <>
            <Navbar />
            <img src={img_404} alt="404" id="notfoundimg" className="shadow"/>
            <Footer />
        </>
    );
}

export default Notfound;